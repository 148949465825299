import React, { ChangeEvent, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import emailUser from "../../lib/Handlers/EmailUser.handler";

interface Props extends React.HTMLProps<HTMLElement> {
  subject: string;
  recipient: string;
  toEmailUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EmailUser({ subject, recipient, toEmailUser }: Props) {
  const [emailBody, setEmailBody] = useState("");

  const handlSettingEmailBody = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    setEmailBody(value);
  };

  const handleEmailDispatch = () => {
    // Call the API to send email to the user
    emailUser(recipient, subject, emailBody);

    // Reset the email body text area
    setEmailBody("");
  };

  return (
    <div id="back-action-container" className="w-full">
      <span
        onClick={() => toEmailUser(false)}
        className="go-back flex items-center gap-2 text-lg cursor-pointer w-fit"
      >
        <BsArrowLeft className="text-xl" /> Back
      </span>

      <div className="p-4 flex flex-col gap-6">
        <div className="recipient flex flex-col gap-2">
          <label htmlFor="recipient-email" className="font-bold">
            Recipient email:
          </label>
          <input id="recipient-email" value={recipient} disabled />
        </div>

        <div className="subject flex flex-col gap-2">
          <label htmlFor="email-subject" className="font-bold">
            Subject:
          </label>
          <input id="email-subject" value={subject} disabled />
        </div>

        <div className="email-body flex flex-col gap-2">
          <label htmlFor="email-body" className="font-bold">
            Email body:
          </label>
          <textarea
            onChange={handlSettingEmailBody}
            value={emailBody}
            id="email-body"
            className="border border-gray-400 rounded-lg text-black p-4"
            rows={8}
          />
        </div>
      </div>

      <footer className="send-email-btn-container p-4 text-center">
        <button
          onClick={handleEmailDispatch}
          className="send-email-btn py-2 px-4 rounded-lg bg-taxeve-primary-violet text-white hover:opacity-80"
        >
          Send email
        </button>
      </footer>
    </div>
  );
}
