import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { IoSend } from "react-icons/io5";

interface Props {
  disableCommentForm: React.Dispatch<React.SetStateAction<boolean>>;
  initialComment?: string;
  handlePostingComment: (comment: string) => void;
}

export default function CommentForm({
  disableCommentForm,
  initialComment,
  handlePostingComment,
}: Props) {
  const [commentBody, setCommentBody] = useState("");

  // Set the initial comment if any
  useEffect(() => {
    initialComment && setCommentBody(initialComment);

    return () => setCommentBody("");
  }, [initialComment]);

  const handleSettingCommentBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    setCommentBody(value);
  };

  const handleCommentPost = () => {
    // Call the handler handling the post and pass the comment as an argument
    handlePostingComment(commentBody);

    // Reset the comment body text area
    setCommentBody("");
  };

  return (
    <div id="back-action-container" className="w-full">
      <span
        onClick={() => disableCommentForm(true)}
        className="go-back flex items-center gap-2 text-lg cursor-pointer w-fit"
      >
        <BsArrowLeft className="text-xl" /> Back
      </span>

      <form className="p-4 flex flex-col gap-6">
        <textarea
          name="comment"
          id="comment"
          placeholder="Write your comment here..."
          className="border border-gray-400 rounded-lg text-black p-4"
          rows={8}
          onChange={handleSettingCommentBody}
          value={commentBody}
        />
      </form>

      <footer className="send-email-btn-container p-4 flex items-center justify-center">
        <button
          onClick={handleCommentPost}
          className="send-email-btn py-2 px-4 rounded-lg bg-taxeve-primary-yellow text-white hover:opacity-80 flex items-center justify-center gap-1"
        >
          <IoSend /> Post
        </button>
      </footer>
    </div>
  );
}
