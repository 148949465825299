import React from "react";
import filterList from "../../lib/form/utils/filterList";
import Dropdown from "./Dropdown";
import { useEffect, useRef, useState } from "react";
import logger from "../../logger";
import { useSelector, useDispatch } from "react-redux";
import "../../styles/decorations/CustomSearch.css";
import { PAYER_NAME, RECIPIENT_NAME } from "../../lib/constants";
import { TO_SHOW_FORM_VALIDATION_ERR } from "../../redux/actions/action.types";
import devLogInstance from "../../lib/loggerConfig";

export default function CustomSearch({
  value,
  updateGlobalData,
  searchList,
  handleAPICall,
  ddType,
  isCorrection,
}: any) {
  const input = useRef<HTMLInputElement | null>(null);
  const [showDropdown, setShowDD] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [filterIdx, setFilterIdx] = useState(0);
  const [removeHover, setRemoveHover] = useState(false);
  const payerNames = useSelector((state: any) => state.setPayerNames);
  const recipientNames = useSelector((state: any) => state.recipientNames);
  const dispatch = useDispatch();

  // Update the initial filter list
  useEffect(() => {
    setFilteredList(searchList);
  }, [searchList]);

  // Handle input change
const handleChange = (e: any) => {
  const { value, id } = e.target;

  // Allow only letters, numbers, spaces, @, and .
  const sanitizedValue = value.replace(/[^a-zA-Z0-9&\.\s]/g, "");

  if (sanitizedValue.length <= 40) {
    if (id === "payer") {
      // Remove any mandatory field warning
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: PAYER_NAME,
          toShowErr: false,
        },
      });

      // Update the global data
      updateGlobalData(
        {
          payer_name: sanitizedValue,
        },
        "new"
      );

      // Filter the list
      const filteredList = filterList(sanitizedValue, payerNames, "payer");

      // Update the filtered list
      setFilteredList(filteredList);
    } else if (id === "recipient") {
      // Remove any mandatory field warning
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: RECIPIENT_NAME,
          toShowErr: false,
        },
      });

      // Update the global data
      updateGlobalData(
        {
          recipient_name: sanitizedValue,
        },
        "new"
      );

      // Filter the list
      const filteredList = filterList(sanitizedValue, recipientNames, "recipient");

      // Update the filtered list
      setFilteredList(filteredList);
    }
  }
};


  // Handle input focus
  const handleFocus = () => {
    // Display the dropdown
    setShowDD(true);
  };

  // Handle input out of focus
  const handleBlur = () => {
    // Hide the dropdown
    setShowDD(false);
  };

  // Handle dd click
  const handleDDClick = async (e: any) => {
    // Call the API to get existing payer/recipient data
    const selectedName: any[] = filteredList.filter((itemObj, i) => {
      return i === filterIdx;
    });
    const id = ddType === "rec_names" ? selectedName[0].recipient_id : selectedName[0].payer_id;
    devLogInstance.log(`Payer/Recipient ID ---> ${id}`);
    handleAPICall(id);
  };

  // Handle mouse enter
  const handleMouseEnter = (e: any) => {
    // e.target.id > filterIdx && setFilterIdx(filterIdx + 1);
    // e.target.id < filterIdx && setFilterIdx(filterIdx - 1);
    setFilterIdx(parseInt(e.target.id));
    setRemoveHover(false);
  };

  // Handle key down
  const handleKeydown = (e: any) => {
    const key = e.key;
    setRemoveHover(true);
    let currentIdx = 0;
    switch (key) {
      case "ArrowDown":
        // Increase the filter idx
        if (filterIdx >= filteredList.length - 1) {
          currentIdx = -1;
        } else {
          currentIdx = filterIdx;
        }
        const set = currentIdx + 1;
        setFilterIdx(set);
        break;

      case "ArrowUp":
        // Decrease the filter idx
        if (filterIdx <= 0) {
          currentIdx = filteredList.length;
        } else {
          currentIdx = filterIdx;
        }
        setFilterIdx(currentIdx - 1);
        break;

      case "Enter":
        // Call the API to get existing payer/recipient data
        const selectedName: any[] = filteredList.filter((itemObj, i) => {
          return i === filterIdx;
        });
        const id = ddType === "rec_names" ? selectedName[0].recipient_id : selectedName[0].payer_id;
        logger.log(`Payer ID ---> ${id}`);
        handleAPICall(id);

        // Hide the dropdown
        setShowDD(false);
        break;

      case "Escape":
        // Remove focus from input
        input.current?.blur();
        break;

      default:
        break;
    }
  };

  return (
    <div className="search-container" tabIndex={0} onKeyDown={handleKeydown}>
      {ddType === "payer_names" && (
        <input
          className="payer-details-input rec-details-input"
          value={value}
          onChange={handleChange}
          ref={input}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
          id="payer"
          disabled={isCorrection}
        />
      )}
      {ddType === "rec_names" && (
        <input
          className="payer-details-input rec-details-input"
          value={value}
          onChange={handleChange}
          ref={input}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
          id="recipient"
        />
      )}
      {showDropdown && filteredList.length !== 0 && (
        <Dropdown
          type={ddType}
          displayList={filteredList}
          filterIdx={filterIdx}
          handleMouseEnter={handleMouseEnter}
          handleClick={handleDDClick}
          removeHover={removeHover}
          handleKeyDown={handleKeydown}
        />
      )}
    </div>
  );
}
