import React, {
  useState,
  useEffect,
  useMemo,
  Dispatch,
  useContext,
  useRef,
  LegacyRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { calCurrentTaxYear } from "./calculateTaxYear";
import {
  TicketDetailsData,
  TicketNotificationData,
  YearSelectionInterface,
} from "../../interfaces/main";
import devLogInstance from "./loggerConfig";
import toDisplayHammenu from "./StateSetters/HammenuRelated/toDisplayHammenu";
import { ProgressContext } from "../App";
import logger from "../logger";
import { Link, useNavigate } from "react-router-dom";
import handlePopup from "./popup/handlePopup";
import { CSV, ERROR_POPUP, XLSX } from "./constants";
import { progressTexts, uploadPageDisplayTexts } from "./languagePacks/en-us";
import { HOME, REVIEW } from "./routes";
import Handler from "./Handlers/main";
import { FaCheckCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import displayInfoModal from "./StateSetters/displayInfoModal";
import InvalidDataModalMsg from "../components/UploadPage/InvalidDataModalMsg";
import { io } from "socket.io-client";
import Cookie from "./utils/Cookie";
import markTicketAsSeen from "./Handlers/MarkTicketAsSeen.handler";
import { RecTinUnknownCtx } from "../pages/E-Form";

// hook to disable button
export const useDisableBtn = (
  initial: boolean
): [toDisableBtn: boolean, setToDisableBtn: Dispatch<React.SetStateAction<boolean>>] => {
  const [toDisableBtn, setToDisableBtn] = useState(initial);
  return [toDisableBtn, setToDisableBtn];
};

type ResentTimerReturnType = [toStartTimer: Dispatch<React.SetStateAction<boolean>>, timer: number];

// resend mail timer hook
export const useResendTimer = (initial: boolean): ResentTimerReturnType => {
  const [toStartTimer, setToStartTimer] = useState(initial);
  const [timer, setTimer] = useState(30);
  useMemo(() => {
    if (timer >= 0 && toStartTimer) {
      setTimeout(() => {
        setTimer((prevState) => {
          return prevState - 1;
        });
      }, 1000);
    } else {
      setTimer(30);
    }
    // if (timer === 0) {
    //   setToStartTimer(false);
    // }
  }, [toStartTimer, timer]);

  return [setToStartTimer, timer];
};

export const usePopup = () => {
  const displayPopup = useSelector((state: any) => state.displayPopup);
  const popupMessage = useSelector((state: any) => state.popupMessage);
  const popupStatus = useSelector((state: any) => state.popupStatus);

  return [displayPopup, popupMessage, popupStatus];
};

export const useHover = (): [
  isHovered: any,
  handleHoverIn: (val: string) => void,
  handleHoverOut: (val: string) => void
] => {
  const [isHovered_, setIsHovered] = useState<any>({});

  const handleMouseOver_ = (val: string) => {
    console.log(val);
    const _isHovered = isHovered_;
    _isHovered[val] = true;
    setIsHovered({ ..._isHovered });
  };

  const handleMouseLeave_ = (val: string) => {
    const _isHovered = isHovered_;
    if (_isHovered[val]) {
      _isHovered[val] = false;
    }
    setIsHovered({ ..._isHovered });
  };

  useEffect(() => {
    return () => {
      setIsHovered({});
    };
  }, []);

  return [isHovered_, handleMouseOver_, handleMouseLeave_];
};

export const useInactive = () => {
  const [initialTime, setInitialTime] = useState(new Date().getSeconds());

  const handleInactivity = () => {
    const currentTime = new Date().getSeconds();
    // logger.log(`Current time --> ${currentTime}`);
    // Refresh the page if inactive for 50 seconds
    const inActiveTime = currentTime - initialTime;
    const maxInactiveTime: string | undefined = process.env.REACT_APP_INACTIVE_TIME_PERIOD_SECONDS;
    if (maxInactiveTime) {
      if (inActiveTime >= parseInt(maxInactiveTime)) {
        window.location.reload(); // Refresh the page
      } else {
        setInitialTime(currentTime); // Set the current time as the new initial time
      }
    }
  };

  return handleInactivity;
};

export const usePasswordToggle = (
  initial: boolean
): [showPassword: boolean, toShowPassword: Dispatch<React.SetStateAction<boolean>>] => {
  const [showPassword, toShowPassword] = useState(initial);
  return [showPassword, toShowPassword];
};

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {object} HookReturn[2] - errors
 */

/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */
export const useScreenshot = ({ type = "", quality = "" } = {}): [
  image: string | null,
  takeScreenshot: (node: any) => Promise<string | void>
] => {
  const [image, setImage] = useState<string | null>(null);
  const [error, setError] = useState(null);
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = (node: HTMLElement) => {
    if (!node) {
      throw new Error("You should provide correct html node.");
    }
    return html2canvas(node)
      .then((canvas) => {
        const croppedCanvas = document.createElement("canvas");
        const croppedCanvasContext = croppedCanvas.getContext("2d");
        // init data
        const cropPositionTop = -20;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext?.drawImage(canvas, cropPositionLeft, cropPositionTop);

        const base64Image = croppedCanvas.toDataURL(type, quality);

        setImage(base64Image);
        return base64Image;
      })
      .catch(setError);
  };

  return [image, takeScreenShot];
};

export const useReactSelectTaxYear = (): [
  year: YearSelectionInterface,
  handleYearChange: (option: YearSelectionInterface | null) => void
] => {
  const [year, setYear] = useState({
    label: calCurrentTaxYear(),
    value: calCurrentTaxYear(),
  });

  // Handle year change
  const handleYearChange = (option: YearSelectionInterface | null) => {
    option && setYear(option);
  };

  return [year, handleYearChange];
};

// Convert blob to data url blob
export const useDataURL = (): [
  dataUrl: string,
  setBlob: Dispatch<React.SetStateAction<Blob | null>>
] => {
  const [blob, setBlob] = useState<Blob | null>(null);
  const [dataUrl, setDataUrl] = useState("");

  useEffect(() => {
    if (blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const result = reader.result;
        if (result && typeof result === "string") {
          setDataUrl(result);
        }
      };
    }

    return () => {
      setDataUrl("");
    };
  }, [blob]);

  return [dataUrl, setBlob];
};

// Custom hook to control ham menu
export const useHam = (menutype: string) => {
  const dispatch = useDispatch();
  const displayHammenu: { toDisplay: boolean; menutype: string } = useSelector(
    (state: any) => state.displayHammenu
  );

  useEffect(() => {
    const handleClick = (e: any) => {
      const id = e.target.id;

      if (id && id.includes("HAM")) {
        devLogInstance.log("Clicked on " + id);
        devLogInstance.log(`Menu type: ${menutype}`);

        toDisplayHammenu(dispatch, !displayHammenu.toDisplay, menutype);
      } else {
        toDisplayHammenu(dispatch, false, "");
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [menutype, dispatch, displayHammenu.toDisplay]);

  useEffect(() => {
    return () => {
      toDisplayHammenu(dispatch, false, "");
    };
  }, [dispatch]);
};

export const useInProgress = () => {
  const InProgress = useContext(ProgressContext);

  if (!InProgress) throw new Error("Inprogress context value is null!");

  const [inProgress, setInProgress] = InProgress;

  return { inProgress, setInProgress };
};

export const useMaxScreenHeight = (): number => {
  const [maxScreenHeight, setMaxScreenHeight] = useState(document.documentElement.clientHeight);

  devLogInstance.log(`Max Screen height: ${maxScreenHeight}`);

  useEffect(() => {
    const handleResize = (e: any) => {
      setMaxScreenHeight(document.documentElement.clientHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [maxScreenHeight]);

  return maxScreenHeight;
};

// Hook to calculate an html element height
export const useElemHeight = (): { elemRef: LegacyRef<any>; elemHeight: number } => {
  const elemRef: LegacyRef<HTMLElement> | null = useRef(null);
  const [elemHeight, setElemHeight] = useState(0);

  useEffect(() => {
    const ch = elemRef?.current?.clientHeight;
    ch && setElemHeight(ch + 50);

    return () => setElemHeight(0);
  }, []);

  return { elemRef, elemHeight };
};

export const useYear = (): {
  year: { label: number; value: number };
  handleYearChange: (option: any) => void;
} => {
  const [year, setYear] = useState({
    label: calCurrentTaxYear(),
    value: calCurrentTaxYear(),
  });

  // Year change event handler
  const handleYearChange = (option: any) => {
    // Handle the change
    setYear({
      label: option.label,
      value: option.value,
    });
  };

  return { year, handleYearChange };
};

// Custom hook to manage file upload
export const useFileUpload = ({
  payerId,
  userId,
  formType,
  taxYear,
  payerName,
  noForm,
}: {
  payerId: string;
  userId: string;
  formType: string;
  noForm: boolean;
  taxYear: number;
  payerName?: string;
}): {
  originalFile: File | null;
  handleChange: (file: File) => void;
  handleError: (err: Error) => void;
  handleSizeError: (err: Error) => void;
  handleFileUpload: () => void;
} => {
  const [file, setFile] = useState<File | null>(null); // This is the original file state
  const [fileToUpload, setFileToUpload] = useState<File | null>(null); // This is the file to actually upload
  // state where the name is formatted and then stored
  const dispatch = useDispatch();
  const { setInProgress } = useInProgress();
  const navigate = useNavigate();

  const filenameChange = (file: File, formType: string, payerId: string, userId: string) => {
    const filenameChunks = file.name?.split(".");
    const fileExt = filenameChunks[filenameChunks.length - 1];

    if (formType === "business") {
      // If the form type is business, it means the user is uplaoding
      // a bulk business adding template.
      const newFileName = `Business_Template.${fileExt}`;
      // In that case the name formatting will be pretty simple
      // and different than when an actual form is uploaded.
      const newFileInstance = new File([file], newFileName, {
        type: `${file.type}`,
      });
      return newFileInstance;
    } else if (formType === "recipient") {
      // If the form type is recipient, it means the user is uplaoding
      // a bulk recipient adding template.
      const newFileName = `Recipient_Template.${fileExt}`;
      // In that case the name formatting will be pretty simple
      // and different than when an actual form is uploaded.
      const newFileInstance = new File([file], newFileName, {
        type: `${file.type}`,
      });
      return newFileInstance;
    } else if (formType === "staff" || formType === "group") {
      // No need to format the file name if the user is trying to upload
      // a staff or a group template
      const newFileInstance = new File([file], file.name, { type: `${file.type}` });
      return newFileInstance;
    }

    const newFilenameTemplate = `Form${formType?.replace(
      /-/gi,
      ""
    )}_Template_${userId}_${payerId}.${fileExt}`;

    const newFileInstance = new File([file], newFilenameTemplate, {
      type: `${file.type}`,
    });
    return newFileInstance;
  };

  // Handle when file is dropped to in the dropbox
  const handleChange = (file: File) => {
    logger.log("Uploaded file:");
    logger.log(file);

    // Update the file name
    logger.log("File subclass with updated name");
    logger.log(filenameChange(file, formType, payerId, userId));
    // Update the file state with the original file
    setFile(file);
    // Update the file to upload state
    setFileToUpload(filenameChange(file, formType, payerId, userId));
  };

  const handleError = (err: Error) => {
    devLogInstance.error(err);
    handlePopup("File type is not supported!", ERROR_POPUP, dispatch);
  };

  // Handle any error related to the file size uploaded by the user
  const handleSizeError = (err: Error) => {
    logger.log(err);
    handlePopup("File size too big!", ERROR_POPUP, dispatch);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    try {
      if (fileToUpload) {
        const filenameDivisions = fileToUpload.name.split(".");
        const format = filenameDivisions[filenameDivisions.length - 1];
        // Upload the file
        const uploadOptions = {
          format: format === "xlsx" ? XLSX : CSV,
          payerId: payerId,
          formType: formType.replace(/-/g, "").toUpperCase(),
        };

        setInProgress({
          inProgress: true,
          message: progressTexts.uploadInProgress,
        });

        navigate(HOME);

        const res = await Handler.handleFileUpload(fileToUpload, dispatch, uploadOptions, taxYear);
        devLogInstance.log(res);

        // Set the file state to default
        setFile(null);
        // Set the file to upload state to default
        setFileToUpload(null);

        // End the uplodad progress
        setInProgress({
          inProgress: false,
          message: (
            <div className="flex gap-2 items-center">
              <FaCheckCircle className="text-2xl text-green-600" />
              {progressTexts.uploadComplete}{" "}
              {/* Dislay the 'Go to review page' option only when a form is uploaded */}
              {!noForm && (
                <span>
                  <Link
                    onClick={() => setInProgress({ inProgress: null, message: "" })}
                    to={REVIEW}
                    className="text-taxeve-primary-violet underline-offset-1"
                  >
                    Go to Review Page
                  </Link>
                </span>
              )}
            </div>
          ),
        });
        // Store the payer id in ls if payerId is not empty
        payerId && localStorage.setItem("payer_id", payerId);
      } else {
        // Show an error on the toast message
        handlePopup(uploadPageDisplayTexts.noFileSelectedError, ERROR_POPUP, dispatch);
      }
    } catch (err: any) {
      // Stop the progress container with an error message
      // if the upload fails.
      setInProgress({
        inProgress: false,
        message: (
          <div className="flex gap-2 items-center">
            <RiCloseCircleFill className="text-red-500 text-2xl" />{" "}
            <p>{progressTexts.uploadFailed}</p>
          </div>
        ),
      });
      // Error will be handled by the handler itself
      // Only error related to invalid data will be handled here
      // Display the SNS modal
      err.length >= 0 &&
        displayInfoModal(true, <InvalidDataModalMsg errors={err} />, "Invalid Data", dispatch);
    }
  };

  return {
    originalFile: file,
    handleChange,
    handleError,
    handleSizeError,
    handleFileUpload,
  };
};

export const useDistributionCode = () => {
  const [distrCode1, setDistrCode1] = useState("");
  const [distrCode2, setDistrCode2] = useState("");

  const [distrCode2Options, setDistrCode2Options] = useState<{ label: string; value: string }[]>(
    []
  );

  const handleDistrCode1Change = useCallback((option: any) => {
    setDistrCode1(option.value);
  }, []);
  const handleDistrCode2Change = useCallback((option: any) => {
    setDistrCode2(option.value);
  }, []);

  useMemo(() => {
    switch (distrCode1) {
      case "1":
      case "2":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "8",
            value: "8",
          },
          {
            label: "B",
            value: "B",
          },
          {
            label: "D",
            value: "D",
          },
          {
            label: "K",
            value: "K",
          },
          {
            label: "L",
            value: "L",
          },
          {
            label: "M",
            value: "M",
          },
          {
            label: "P",
            value: "P",
          },
        ]);
        break;

      case "3":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "D",
            value: "D",
          },
        ]);
        break;

      case "4":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "8",
            value: "8",
          },
          {
            label: "A",
            value: "A",
          },
          {
            label: "B",
            value: "B",
          },
          {
            label: "D",
            value: "D",
          },
          {
            label: "G",
            value: "G",
          },
          {
            label: "H",
            value: "H",
          },
          {
            label: "K",
            value: "K",
          },
          {
            label: "L",
            value: "L",
          },
          {
            label: "M",
            value: "M",
          },
          {
            label: "P",
            value: "P",
          },
        ]);
        break;

      case "6":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "W",
            value: "W",
          },
        ]);
        break;

      case "7":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "A",
            value: "A",
          },
          {
            label: "B",
            value: "B",
          },
          {
            label: "D",
            value: "D",
          },
          {
            label: "K",
            value: "K",
          },
          {
            label: "L",
            value: "L",
          },
          {
            label: "M",
            value: "M",
          },
        ]);
        break;

      case "8":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "B",
            value: "B",
          },
          {
            label: "J",
            value: "J",
          },
          {
            label: "K",
            value: "K",
          },
        ]);
        break;

      case "A":
        setDistrCode2Options([
          {
            label: "4",
            value: "4",
          },
          {
            label: "7",
            value: "7",
          },
        ]);
        break;

      case "B":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "7",
            value: "7",
          },
          {
            label: "8",
            value: "8",
          },
          {
            label: "G",
            value: "G",
          },
          {
            label: "L",
            value: "L",
          },
          {
            label: "M",
            value: "M",
          },
          {
            label: "P",
            value: "P",
          },
          {
            label: "U",
            value: "U",
          },
        ]);
        break;

      case "C":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "D",
            value: "D",
          },
        ]);
        break;

      case "D":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "3",
            value: "3",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "7",
            value: "7",
          },
          {
            label: "C",
            value: "C",
          },
        ]);
        break;

      case "G":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "B",
            value: "B",
          },
          {
            label: "K",
            value: "K",
          },
        ]);
        break;

      case "H":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "4",
            value: "4",
          },
        ]);
        break;

      case "J":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "8",
            value: "8",
          },
          {
            label: "P",
            value: "P",
          },
        ]);
        break;

      case "K":
        setDistrCode2Options([
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "7",
            value: "7",
          },
          {
            label: "8",
            value: "8",
          },
          {
            label: "G",
            value: "G",
          },
        ]);
        break;

      case "L":
      case "M":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "7",
            value: "7",
          },
          {
            label: "B",
            value: "B",
          },
        ]);
        break;

      case "P":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "B",
            value: "B",
          },
          {
            label: "J",
            value: "J",
          },
        ]);
        break;

      case "U":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "B",
            value: "B",
          },
        ]);
        break;

      case "W":
        setDistrCode2Options([
          {
            label: "None",
            value: "",
          },
          {
            label: "6",
            value: "6",
          },
        ]);
        break;

      default:
        setDistrCode2Options([]);
        break;
    }
  }, [distrCode1]);

  return {
    distrCode1,
    distrCode2,
    distrCode2Options,
    handleDistrCode1Change,
    handleDistrCode2Change,
  };
};

// Socket related
export const useTicketSocket = () => {
  const [newTickets, setNewTickets] = useState<TicketNotificationData[]>([]);

  const { isPermitted: isNotificationPermitted } = useNotification();

  const displayPushNotification = useCallback(
    (ticketId: string) => {
      devLogInstance.log({ is_push_not_permitted: isNotificationPermitted });

      isNotificationPermitted &&
        new Notification("New ticket assignment!", {
          body: `Ticket ID: ${ticketId} has been assigned to you.`,
        });
    },
    [isNotificationPermitted]
  );

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_ENDPOINT || "");
    // Send admin token when connection to socket
    const adminToken = Cookie.retrieveCookie("admin_token");
    socket.emit("connected-admin-token", adminToken);
    socket.emit("get-assigned-tickets", adminToken);

    socket.on("new-ticket-assignment-notification", ({ ticketId, ticketQuery }) => {
      devLogInstance.log({ newTicketAssigned: { ticketId, ticketQuery } });

      setNewTickets((prevState) => [{ ticketId, ticketQuery }, ...prevState]);

      // Display a push notification
      displayPushNotification(ticketId);
    });

    socket.on("send-assigned-tickets", (assignedTickets: TicketNotificationData[]) => {
      devLogInstance.log({ assignedTickets });

      setNewTickets((prevState) => [...assignedTickets, ...prevState]);
    });

    return () => {
      socket.disconnect();
      setNewTickets([]);
    };
  }, [displayPushNotification]);

  return { newTickets };
};

// This hook will handle calling the api and marking a ticket as seen
// on initial component mount
export const useMarkTicketAsSeen = (ticketId: string) => {
  useEffect(() => {
    const cleanSeenTicketCache = (): Promise<void> => {
      return new Promise((res, rej) => {
        setTimeout(() => {
          sessionStorage.removeItem("seenTicketIds");
          res();
        }, 1000 * 60 * 60 * 2);
      });
    };

    const handleMarkingTicket = () => {
      // Pull the seenTicketIds from cache
      const seenTicketIdsStr = sessionStorage.getItem("seenTicketIds");
      const seenTicketIds: string[] = seenTicketIdsStr ? JSON.parse(seenTicketIdsStr) : []; // Parse it properly
      devLogInstance.log({ seenTicketIds });

      if (!seenTicketIds.includes(ticketId)) {
        // Push the ticket id to the seen ticket ids list
        // and update the cache
        const newSeenTicketIdsList = [...seenTicketIds, ticketId];
        sessionStorage.setItem("seenTicketIds", JSON.stringify(newSeenTicketIdsList));

        // Call the API to mark the ticket as seen
        markTicketAsSeen(ticketId);
      }
    };

    if (ticketId) handleMarkingTicket();

    // Set an async process where after 2 hours the cache related to seen ticket ids
    // will be cleaned
    cleanSeenTicketCache();
  }, [ticketId]);
};

// This hook will set the permission for showing notification
export const useNotification = () => {
  const [isPermitted, setIsPermitted] = useState(false);

  useLayoutEffect(() => {
    const askPermission = () => {
      Notification.requestPermission((perm) => {
        devLogInstance.log({ notification_perm: perm });
        if (perm.includes("granted")) setIsPermitted(true);
      });
    };

    askPermission(); // Ask permission to show push notification
  }, []);

  return { isPermitted };
};

// Custom hook for checking if rec tin is unknown
export const useRecTinUnknown = () => {
  const recTinUnknownCtx = useContext(RecTinUnknownCtx);
  if (!recTinUnknownCtx) throw new Error("Recipient tin unknown, ctx is null!");

  return recTinUnknownCtx;
};
