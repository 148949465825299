import React, { useEffect, useState } from "react";
import Select from "react-select";
import customFilter from "../../../../lib/form/utils/reactSelectFilter";
import { arrangeStates } from "../../../../lib/form/arrangeSelectValues";
import states from "../../../../lib/json/state_filing_list.json";
import { useSelector, useDispatch } from "react-redux";
import handleAmountFormat from "../../../../lib/form/utils/handleAmountFormat";
import {
  SET_MODAL_HEADING,
  SET_MODAL_MESSAGE,
  // SET_STATE_AMOUNT,
  TO_CLEAR,
  TO_DISPLAY_MODAL,
  TO_SHOW_FORM_VALIDATION_ERR,
} from "../../../../redux/actions/action.types";
import { FiDollarSign } from "react-icons/fi";
import Indicator from "../../../Indicator";
import {
  FORM1099_B,
  FORM1099_DIV,
  FORM1099_G,
  FORM1099_INT,
  FORM1099_K,
  MIN_LEN_FOR_UTAH_NOT_MET,
  MIN_LEN_FOR_WV_NOT_MET,
  NUMBER_OF_W2_FILED,
  STATE1_ALL_DETAILS,
  STATE2_ALL_DETAILS,
  STATE_1,
  STATE_2,
  STATE_INCOME_1,
  STATE_INCOME_2,
  STATE_NUMBER_1,
  STATE_NUMBER_2,
  STATE_TAX_1,
  STATE_TAX_2,
  TOTAL_TAX_WITHHELD_FOR_W2,
} from "../../../../lib/constants";
import checkStateFilingValue from "../../../../lib/form/utils/checkStateFilingValue";
import { modalDisplayTexts, stateFilingErrMsg } from "../../../../lib/languagePacks/en-us";
import { isValidAmount } from "../../Form1099-B";
import devLogInstance from "../../../../lib/loggerConfig";
import WestVirginiaExtraFields from "./StateFilingRelated/WestVirginiaExtraFields";
import { NUMBER_ONLY_REGEX } from "../../../../lib/patterns";
import SelectStyle from "../../../../lib/ReactSelectTheme/reactSelectStyle";
import { isEditable } from "@testing-library/user-event/dist/utils";
import displayInfoModal from "../../../../lib/StateSetters/displayInfoModal";
// import logger from "../../../../logger"

// Forms without payer state number
const _forms = [FORM1099_DIV, FORM1099_INT, FORM1099_B, FORM1099_K, FORM1099_G];

export default function StateDetails({
  taxWithheldLabel,
  stateVolLabel,
  stateNoLabel,
  stateIncomeLabel,
  compId,
  gData,
  setGData,
  formType,
}: any) {
  // const stateAmount = useSelector((state: any) => state.stateAmount);
  const style = useSelector((state: any) => state.themeChange);
  const clearStatus = useSelector((state: any) => state.clearDataStatus);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const dispatch = useDispatch();
  const [displayWestVirginiaExtraFields, toDisplayWestVirginiaExtraFields] = useState({
    onState1: false,
    onState2: false,
  });

  // Custom theme for the dd
  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: "0.5rem",
    colors: {
      ...theme.colors,
      primary25: style.ddOptionHover,
    },
  });

  // const customStyle = {
  //   control: (styles: any) => ({
  //     ...styles,
  //     border: `1px solid ${style.ddBorder}`,
  //   }),
  //   option: (styles: any, { isSelected, isFocused }: any) => {
  //     return {
  //       ...styles,
  //       color: isSelected && style.isSelectedColor,
  //       backgroundColor: isSelected ? style.isSelectedBg : isFocused && style.ddOptionHover,
  //     };
  //   },
  // };

  // Arrange the dd options for react-select
  const getSelectOptions = () => {
    const defaultOptions = arrangeStates(states);
    const updatedOptions = defaultOptions.map((option: any) => {
      return {
        ...option,
        group: compId,
      };
    });
    return updatedOptions;
  };

  // Handle any input change
  const handleChange = (e: any) => {
    const componentId = e.target.parentElement.id;
    devLogInstance.log(componentId);
    const { value, id } = e.target;

    if (componentId === "state1") {
      // Remove all error indicators from State group 1
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: STATE1_ALL_DETAILS,
          toShowErr: false,
        },
      });
      // change values of state 1
      switch (id) {
        case "state-tax":
          const formattedAmt = handleAmountFormat(value);

          // update the state tax 1 value in global form data
          isValidAmount(formattedAmt) &&
            setGData({
              ...gData,
              state_income_tax_withheld1:
                formattedAmt.length !== 0 ? parseFloat(formattedAmt.replace(/,/gi, "")) * 100 : 0,
            });
          break;

        case "state-number":
          let maxLength = gData.state1 === "West Virginia" ? 8 : gData.state1 === "Utah" ? 14 : 20;
          if (value.length <= maxLength) {
            // Update the state number val in global data
            setGData({
              ...gData,
              payer_state_number1: value,
            });
          }
          break;

        case "state-income":
          // Update the local data
          const formattedIncome = handleAmountFormat(value);
          console.log("Formatted income: ", formattedIncome);

          // Update the global data
          isValidAmount(formattedIncome) &&
            setGData({
              ...gData,
              state_income1:
                formattedIncome.length !== 0
                  ? parseFloat(formattedIncome.replace(/,/gi, "")) * 100
                  : 0,
            });
          break;

        default:
          break;
      }
    } else if (componentId === "state2") {
      // Remove all error indicators from State group 2
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: STATE2_ALL_DETAILS,
          toShowErr: false,
        },
      });
      // change values of state 2
      switch (id) {
        case "state-tax":
          const formattedAmt = handleAmountFormat(value);

          // update the state tax 1 value in global form data
          isValidAmount(formattedAmt) &&
            setGData({
              ...gData,
              state_income_tax_withheld2:
                formattedAmt.length !== 0 ? parseFloat(formattedAmt.replace(/,/gi, "")) * 100 : 0,
            });
          break;

        case "state-number":
          let maxLength = gData.state2 === "West Virginia" ? 8 : gData.state2 === "Utah" ? 14 : 20;
          if (value.length <= maxLength) {
            // Update the state number val in global data
            setGData({
              ...gData,
              payer_state_number2: value,
            });
          }
          break;

        case "state-income":
          // Update the local data
          const formattedIncome = handleAmountFormat(value);

          // Update the global data
          isValidAmount(formattedIncome) &&
            setGData({
              ...gData,
              state_income2:
                formattedIncome.length !== 0
                  ? parseFloat(formattedIncome.replace(/,/gi, "")) * 100
                  : 0,
            });
          break;

        default:
          break;
      }
    }
    // West Virginia extra fields value
    if (id === "W2S-FILED") {
      // Reove any pre-displayed error
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: NUMBER_OF_W2_FILED,
          toShowErr: false,
        },
      });

      // Validate the input val
      const isValid = value ? new RegExp(NUMBER_ONLY_REGEX).test(value) : true;
      isValid &&
        setGData({
          ...gData,
          number_of_w2_filed: value ? parseInt(value) : 0,
        });
    } else if (id === "W2-TAX-WITHHELD") {
      // Reove any pre-displayed error
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: TOTAL_TAX_WITHHELD_FOR_W2,
          toShowErr: false,
        },
      });

      // Validate the input val
      // const isValid = value ? new RegExp(DECIMAL_REGEX).test(value) : true;
      const formattedAmt = value ? handleAmountFormat(value) : "";
      (!formattedAmt || isValidAmount(formattedAmt)) &&
        setGData({
          ...gData,
          total_tax_withheld_for_all_w2s: formattedAmt
            ? parseFloat(formattedAmt.replace(/,/gi, ""))
            : 0,
        });
    }
  };

  // Handle when state is selected from dd
  const handleStateSelectChange = (option: any) => {
    devLogInstance.log(option);
    if (option.group === "state1") {
      // Remove all error indicators on group 1 state details
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: STATE1_ALL_DETAILS,
          toShowErr: false,
        },
      });

      // Remove all errors related to wv extra fields
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: NUMBER_OF_W2_FILED,
          toShowErr: false,
        },
      });

      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: TOTAL_TAX_WITHHELD_FOR_W2,
          toShowErr: false,
        },
      });

      // Compare the value of state1 and state2
      const isValidState = checkStateFilingValue(option.value, gData.state2);
      if (isValidState) {
        setGData({
          ...gData,
          state1: option.value, // Set the entire state name and not the code
        });
      } else {
        // Display modal with error message
        // Display the modal
        dispatch({
          type: TO_DISPLAY_MODAL,
          payload: true,
        });

        // Set the displayy message
        dispatch({
          type: SET_MODAL_MESSAGE,
          payload: stateFilingErrMsg.nec1099.sameStateErrMsg,
        });

        // Set the display heading
        dispatch({
          type: SET_MODAL_HEADING,
          payload: modalDisplayTexts.topPanelHeading,
        });
      }
    } else if (option.group === "state2") {
      // Remove all error indicators on group 2 state details
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: STATE2_ALL_DETAILS,
          toShowErr: false,
        },
      });

      // Compare the value of state1 and state2
      const isValidState = checkStateFilingValue(gData.state1, option.value);
      if (isValidState) {
        setGData({
          ...gData,
          state2: option.value, // Set the state full name and not the state code
        });

        // Update the clear status state
        dispatch({
          type: TO_CLEAR,
          payload: {
            ...clearStatus,
            group_state2: true,
          },
        });
      } else {
        // Display the modal with error message
        // Display the modal
        dispatch({
          type: TO_DISPLAY_MODAL,
          payload: true,
        });

        // Set the displayy message
        dispatch({
          type: SET_MODAL_MESSAGE,
          payload: stateFilingErrMsg.nec1099.sameStateErrMsg,
        });

        // Set the display heading
        dispatch({
          type: SET_MODAL_HEADING,
          payload: modalDisplayTexts.topPanelHeading,
        });
      }
    }
  };

  return (
    <div
      className=""
      style={{
        flexDirection: _forms.includes(formType) ? "row-reverse" : "row",
      }}
    >
      <div className="state-details-container">
        <div
          className="state-tax-withheld-container"
          style={{ width: _forms.includes(formType) ? "50%" : "" }}
        >
          <div className="state-tax-input-wrapper">
            <label htmlFor="state-tax">
              <h4>{taxWithheldLabel}</h4>
            </label>
            <div className="amount-input-container" id={compId}>
              <div className="dollar-sign">
                <FiDollarSign />
              </div>
              <input
                className="payer-details-input amount-input"
                type="text"
                id="state-tax"
                placeholder="0.00"
                value={
                  compId === "state1"
                    ? gData.state_income_tax_withheld1 > 0
                      ? handleAmountFormat((gData.state_income_tax_withheld1 / 100).toString())
                      : ""
                    : gData.state_income_tax_withheld2 > 0
                    ? handleAmountFormat((gData.state_income_tax_withheld2 / 100).toString())
                    : ""
                }
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          {compId === "state1" && showValidationError.onStateTax1 && (
            <Indicator type={STATE_TAX_1} message={stateFilingErrMsg.nec1099.stateTaxErrMsg} />
          )}
          {compId === "state2" && showValidationError.onStateTax2 && (
            <Indicator type={STATE_TAX_2} message={stateFilingErrMsg.nec1099.stateTaxErrMsg} />
          )}
        </div>
        <div
          className="state-stateNo-container"
          style={{
            width: _forms.includes(formType) ? "100%" : "",
            borderRight: _forms.includes(formType) ? `1px solid black` : "",
          }}
        >
          <div className="state-container">
            <div className="state-select-wrapper" id={compId}>
              <label htmlFor="state">
                <h4>{stateVolLabel}</h4>
              </label>
              <Select
                theme={customTheme}
                styles={new SelectStyle(style).customStyle}
                id="state"
                options={getSelectOptions()}
                filterOption={customFilter}
                onChange={handleStateSelectChange}
                value={
                  compId === "state1"
                    ? arrangeStates(states).filter((option: any) => {
                        return option.value === gData.state1;
                      })
                    : arrangeStates(states).filter((option: any) => {
                        return option.value === gData.state2;
                      })
                }
                placeholder=""
              />
            </div>
            {compId === "state1" && showValidationError.onState1 && (
              <Indicator type={STATE_1} message={stateFilingErrMsg.nec1099.noStateSelectedErrMsg} />
            )}
            {compId === "state2" && showValidationError.onState2 && (
              <Indicator type={STATE_2} message={stateFilingErrMsg.nec1099.noStateSelectedErrMsg} />
            )}
          </div>
          <div className="state-number-container">
            <div className="state-number-input-wrapper" id={compId}>
              <label htmlFor="state-number">
                <h4>{stateNoLabel}</h4>
              </label>
              <input
                className="payer-details-input"
                type="text"
                id="state-number"
                value={compId === "state1" ? gData.payer_state_number1 : gData.payer_state_number2}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            {compId === "state1" && showValidationError.onStateNum1 && (
              <Indicator type={STATE_NUMBER_1} message={stateFilingErrMsg.nec1099.stateNumErrMsg} />
            )}
            {compId === "state2" && showValidationError.onStateNum2 && (
              <Indicator type={STATE_NUMBER_2} message={stateFilingErrMsg.nec1099.stateNumErrMsg} />
            )}
            {((compId === "state1" && showValidationError.onWVStateNum1) ||
              (compId === "state2" && showValidationError.onWVStateNum2)) && (
              <Indicator
                type={MIN_LEN_FOR_WV_NOT_MET}
                message={stateFilingErrMsg.wvStateNumMinLenErr}
              />
            )}
            {((compId === "state1" && showValidationError.onUtahStateNum1) ||
              (compId === "state2" && showValidationError.onUtahStateNum2)) && (
              <Indicator
                type={MIN_LEN_FOR_UTAH_NOT_MET}
                message={stateFilingErrMsg.utahStateNumMinLenErr}
              />
            )}
            {/* {compId === "state2" && showValidationError.onWVStateNum2 && (
              <Indicator type={WV_STATE_NUMBER_2} message={"min length not reached"} />
            )} */}
          </div>
        </div>
        {stateIncomeLabel && (
          <div className="state-income-container">
            <div className="state-income-wrapper">
              <label htmlFor="state-income">
                <h4>{stateIncomeLabel}</h4>
              </label>
              <div className="amount-input-container" id={compId}>
                <div className="dollar-sign">
                  <FiDollarSign />
                </div>
                <input
                  className="payer-details-input amount-input"
                  type="text"
                  id="state-income"
                  placeholder="0.00"
                  value={
                    compId === "state1"
                      ? gData.state_income1 > 0
                        ? handleAmountFormat((gData.state_income1 / 100).toString())
                        : ""
                      : gData.state_income2 > 0
                      ? handleAmountFormat((gData.state_income2 / 100).toString())
                      : ""
                  }
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
            </div>
            {compId === "state1" && showValidationError.onStateIncome1 && (
              <Indicator
                type={STATE_INCOME_1}
                message={stateFilingErrMsg.nec1099.stateIncomeErrMsg}
              />
            )}
            {compId === "state2" && showValidationError.onStateIncome2 && (
              <Indicator
                type={STATE_INCOME_2}
                message={stateFilingErrMsg.nec1099.stateIncomeErrMsg}
              />
            )}
          </div>
        )}
      </div>
      {((compId === "state1" && displayWestVirginiaExtraFields.onState1) ||
        (compId === "state2" && displayWestVirginiaExtraFields.onState2)) && (
        <WestVirginiaExtraFields
          onChangeHandler={handleChange}
          w2sFiledVal={
            !isEditable
              ? gData.number_of_w2_filed <= 0
                ? ""
                : gData.number_of_w2_filed.toString()
              : ""
          }
          w2sTaxWithheldVal={
            !isEditable
              ? gData.total_tax_withheld_for_all_w2s <= 0
                ? ""
                : handleAmountFormat(gData.total_tax_withheld_for_all_w2s.toString())
              : ""
          }
        />
      )}
    </div>
  );
}
