import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReviewForm from "../components/forms/ReviewForm/ReviewForm";
import Navbar from "../components/navbar";
import loader from "../components/decorations/Loader";
import { useEffect, useState } from "react";
import decryptCartIds from "../lib/Cart/decryptCartIds";
import Handler from "../lib/Handlers/main";
import ReviewTable from "../components/forms/ReviewTable/ReviewTable";
import selectDefaultPayer from "../lib/utils/selectDefaultPayer";
import filterNRFforRecData from "../lib/utils/filterNRFforRecData";
import setRPRecipientData from "../lib/StateSetters/setRPRecipientData";
import { TO_START_LOADER } from "../redux/actions/action.types";
import globalFormData from "../lib/globalFormData";
import setNonReviewedData from "../lib/StateSetters/setNonReviewedData";
import selectCurrentPayer from "../lib/utils/selectCurrentPayer";
import setNoDefault from "../lib/utils/setNoDefault";
import { REVIEW_PAGE_EFORM } from "../lib/constants";
import devLogInstance from "../lib/loggerConfig";
import { useLocation } from "react-router-dom";
import saveReviewPageType from "../lib/SaveData/saveReviewPageType";
import deleteReviewPageType from "../lib/DeleteDataLocally/deleteReviewPageType";
import deleteCartIds from "../lib/DeleteDataLocally/deleteCartIds";
import maskTin from "../lib/StateSetters/maskTin";
import { useInactive, useYear } from "../lib/customHooks";
import { axiosInstance } from "../lib/axiosConfig";
import {
  ReviewCopyPayerData,
  ReviewCopyPaymentData,
  ReviewCopyRecipientData,
} from "../../interfaces/main";
import { Review1099GModifier } from "../lib/PdfModificationRelated/Review1099GModifier";
import Review1099NECModifier from "../lib/PdfModificationRelated/Review1099NECModifier";
import Review1099MISCModifier from "../lib/PdfModificationRelated/Review1099MISCModifier";
import Review1099INTModifier from "../lib/PdfModificationRelated/Review1099INTModifier";
import Review1099DIVModifier from "../lib/PdfModificationRelated/Review1099DIVModifier";
import Review1099SModifier from "../lib/PdfModificationRelated/Review1099SModifier";
import Review1099BModifier from "../lib/PdfModificationRelated/Review1099BModifier";
import Review1099KModifier from "../lib/PdfModificationRelated/Review1099KModifier";
import { Review1099AModifier } from "../lib/PdfModificationRelated/Review1099AModifier";
import { Review1099CModifier } from "../lib/PdfModificationRelated/Review1099CModifier";
import Review1099PATRModifier from "../lib/PdfModificationRelated/Review1099PATRModifier";
import Review1099RModifier from "../lib/PdfModificationRelated/Review1099RModifier";
import Review1099QModifier from "../lib/PdfModificationRelated/Review1099QModifier";
import Review1099SAModifier from "../lib/PdfModificationRelated/Form1099SAModifier";

export default function Review({ showFeedbackWindow: feedbackWindowObj }: any) {
  const location = useLocation();
  const isIntdReviewPage = new URLSearchParams(location.search).get("eform") === "true";
  const startLoader = useSelector((state: any) => state.startLoader);
  const nonReviewedData = useSelector((state: any) => state.nonReviewedData);
  const selectedPayerId = useSelector((state: any) => state.selectedPayerId);
  const style = useSelector((state: any) => state.themeChange);
  const [formData, setFormData] = useState<any | null>(null);
  // const [initialTime, setInitialTime] = useState(0);
  const [payerId, setPayerId] = useState<string | null>(null);
  const selectedRecData = useSelector((state: any) => state.selectedRecData);
  const dispatch = useDispatch();
  const handleInactivity = useInactive();

  const { year, handleYearChange } = useYear();

  // Log the feedback window object
  devLogInstance.log(feedbackWindowObj);

  // logger.log(recData);
  // logger.log(`Initial Time -> ${initialTime}`);

  // Check if payer id already exists in ls
  useEffect(() => {
    const payerId_ = localStorage.getItem("payer_id");
    payerId_
      ? devLogInstance.log("PAYER ID EXISTS...UPDATING STATE")
      : devLogInstance.log("PAYER_ID DOSENT EXIST!");
    payerId_ && setPayerId(payerId_);
  }, []);

  // Get the non reviewed form data
  useEffect(() => {
    const cartIds = isIntdReviewPage ? localStorage.getItem("cart_ids") : null;
    // Store the current status of the review page type for proper redirection from the e filing page
    if (isIntdReviewPage) {
      // Save the review page type
      saveReviewPageType(REVIEW_PAGE_EFORM);
    } else {
      // Delete the review page type
      deleteReviewPageType();
      // Delete any stored cart ids
      deleteCartIds();
    }
    let decryptedCartIds = [];
    if (cartIds) {
      // Get the decrypted cart ids
      decryptedCartIds = decryptCartIds(cartIds);
      devLogInstance.log("Decrypted Cart ids:");
      devLogInstance.log(decryptedCartIds);
    }
    // Handle getting the non reviewed forms
    Handler.handleGettingSelectedNRF(dispatch, decryptedCartIds, year.value);
    return () => {
      setNonReviewedData(dispatch, []);
    };
  }, [dispatch, isIntdReviewPage, year]);

  // Select the first payer by default if no payer id is present in localstorage or select the current payer
  useEffect(() => {
    if (nonReviewedData.length !== 0 && payerId === null) {
      selectDefaultPayer(nonReviewedData, dispatch);
    } else if (nonReviewedData.length !== 0 && payerId !== null) {
      selectCurrentPayer(payerId, nonReviewedData, dispatch);
    }
  }, [nonReviewedData, dispatch, payerId]);

  // Set the recipient data as per the selected payer id
  useEffect(() => {
    if (nonReviewedData.length !== 0 && selectedPayerId.length !== 0) {
      const recData_ = filterNRFforRecData(nonReviewedData, selectedPayerId);
      recData_ && setRPRecipientData(recData_, dispatch);
      // Stop the loader
      dispatch({
        type: TO_START_LOADER,
        payload: false,
      });
    }
    //setInitialTime(new Date().getSeconds()); // Set the initial time in seconds after the first load
    return () => {
      setRPRecipientData([], dispatch);
      setNoDefault(dispatch, false); // On each render, so that the first rec gets selected
    };
  }, [selectedPayerId, nonReviewedData, dispatch]);

  // Update the global form data when a recipient record is selected
  useEffect(() => {
    if (selectedRecData) {
      const updatedData = selectedRecData.selectedRecRecord;
      setFormData({
        ...globalFormData,
        ...updatedData,
      });
    }
  }, [selectedRecData]);

  const [reviewCopyDataURL, setReviewCopyDataURL] = useState("");
  const [reviewCopyBuffer, setReviewCopyBuffer] = useState<ArrayBuffer | null>(null);

  const toMaskTin = useSelector((state: any) => state.toMaskTin);

  // Get the review copy from backend
  useMemo(() => {
    const getReviewCopy = async () => {
      if (formData?.form_type && formData?.year) {
        devLogInstance.log(`@@@@ Getting new review copy for FORM ${formData?.form_type} @@@@`);
        const formTypeWithoutHyphens = formData?.form_type.replace(/-/g, "");
        const res = await axiosInstance.get(
          `/get_review_copy/${formTypeWithoutHyphens}/${formData?.year}`,
          {
            responseType: "arraybuffer",
          }
        );
        const reviewCopyBuffer: ArrayBuffer = res.data;
        setReviewCopyBuffer(reviewCopyBuffer);
      }
    };

    getReviewCopy();

    return () => setReviewCopyBuffer(null);
  }, [formData?.form_type, formData?.year]);

  // Modify the review copy whenever the buffer or the formData changes
  useMemo(() => {
    const modifyReviewCopy = async () => {
      if (formData && reviewCopyBuffer) {
        let modifiedBytes: Uint8Array | null = null;

        // Serialize the payer data
        const payerData: ReviewCopyPayerData = {
          payer_address_line1: formData.payer_address_line1,
          payer_address_line2: formData.payer_address_line2,
          payer_city: formData.payer_city,
          payer_country: formData.payer_country,
          payer_name: formData.payer_name,
          payer_phone_number: formData.payer_phone_number,
          payer_state: formData.payer_state,
          payer_tin: formData.payer_tin,
          payer_tin_type: formData.payer_tin_type,
          payer_zipcode: formData.payer_zipcode,
        };

        // Serialize the recipient data
        const recData: ReviewCopyRecipientData = {
          recipient_name: formData.recipient_name,
          recipient_name_cont: formData.recipient_name_cont,
          recipient_address_line1: formData.recipient_address_line1,
          recipient_address_line2: formData.recipient_address_line2,
          recipient_city: formData.recipient_city,
          recipient_state: formData.recipient_state,
          recipient_country: formData.recipient_country,
          recipient_zipcode: formData.recipient_zipcode,
          recipient_tin_type: formData.recipient_tin_type,
          recipient_tin: formData.recipient_tin,
        };

        // Modify the pdf document
        switch (formData.form_type) {
          case "1099-A":
            const _1099AModifier = new Review1099AModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.date_of_lenders_acquisition,
              formData.payment_amount2,
              formData.payment_amount4,
              formData.personal_liability_indicator,
              formData.description_of_property
            );
            await _1099AModifier.loadReviewCopy();
            modifiedBytes = await _1099AModifier.getModifiedBytes();
            break;

          case "1099-G":
            // Serialize payment amounts
            const paymentAmounts: ReviewCopyPaymentData = {
              payment_amount1: formData.payment_amount1,
              payment_amount2: formData.payment_amount2,
              payment_amount4: formData.payment_amount4,
              payment_amount5: formData.payment_amount5,
              payment_amount6: formData.payment_amount6,
              payment_amount7: formData.payment_amount7,
              payment_amount9: formData.payment_amount9,
            };

            const _1099GModifier = new Review1099GModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              paymentAmounts,
              formData.tax_year_of_refund,
              formData.trade_or_business_indicator,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2
            );
            await _1099GModifier.loadReviewCopy();
            modifiedBytes = await _1099GModifier.getModifiedBytes();

            break;

          case "1099-NEC":
            const _1099NECModifier = new Review1099NECModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount1,
              formData.payment_amount4,
              formData.direct_sales_indicator,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2,
              formData.state_income1,
              formData.state_income2
            );
            await _1099NECModifier.loadReviewCopy();
            modifiedBytes = await _1099NECModifier.getModifiedBytes();

            break;

          case "1099-MISC":
            const _1099MISCModifier = new Review1099MISCModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amount4,
              formData.payment_amount5,
              formData.payment_amount6,
              formData.payment_amount8,
              formData.payment_amountA,
              formData.payment_amountC,
              formData.payment_amountF,
              formData.payment_amountD,
              formData.payment_amountB,
              formData.payment_amountE,
              formData.direct_sales_indicator,
              formData.fatca_filing_requirement_indicator,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2,
              formData.state_income1,
              formData.state_income2
            );
            await _1099MISCModifier.loadReviewCopy();
            modifiedBytes = await _1099MISCModifier.getModifiedBytes();

            break;

          case "1099-INT":
            const _1099INTModifier = new Review1099INTModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amount4,
              formData.payment_amount5,
              formData.payment_amount6,
              formData.payment_amount8,
              formData.payment_amount9,
              formData.payment_amountA,
              formData.payment_amountD,
              formData.payment_amountB,
              formData.payment_amountE,
              formData.payers_rtn,
              formData.foreign_country_or_us_Possession,
              formData.cusip_Number,
              formData.fatca_filing_requirement_indicator,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2
            );
            await _1099INTModifier.loadReviewCopy();
            modifiedBytes = await _1099INTModifier.getModifiedBytes();
            break;

          case "1099-DIV":
            const _1099DIVModifier = new Review1099DIVModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amountC,
              formData.payment_amount5,
              formData.payment_amount6,
              formData.payment_amount7,
              formData.payment_amount8,
              formData.payment_amount9,
              formData.payment_amountA,
              formData.payment_amountD,
              formData.payment_amountB,
              formData.payment_amountE,
              formData.payment_amountH,
              formData.payment_amountJ,
              formData.payment_amountF,
              formData.payment_amountG,
              formData.foreign_country_or_us_Possession,
              formData.fatca_filing_requirement_indicator,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2
            );
            await _1099DIVModifier.loadReviewCopy();
            modifiedBytes = await _1099DIVModifier.getModifiedBytes();
            break;

          case "1099-S":
            const _1099SModifier = new Review1099SModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.payment_amount2,
              formData.payment_amount5,
              formData.date_of_closing,
              formData.address_or_legal_description,
              formData.property_or_services_indicator,
              formData.foreign_transferor
            );
            await _1099SModifier.loadReviewCopy();
            modifiedBytes = await _1099SModifier.getModifiedBytes();
            break;

          case "1099-B":
            const _1099BModifier = new Review1099BModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amount4,
              formData.payment_amount5,
              formData.payment_amount7,
              formData.payment_amount9,
              formData.payment_amountA,
              formData.payment_amountC,
              formData.payment_amountD,
              formData.payment_amountB,
              formData.cusip_Number,
              formData.fatca_filing_requirement_indicator,
              formData.applicable_check_box_of_form_8949,
              formData.description_of_property,
              formData.date_acquired,
              formData.date_sold_or_disposed,
              formData.type_of_gain_or_loss_indicator,
              formData.gross_proceeds_indicator,
              formData.applicable_check_box_for_collectables,
              formData.applicable_check_box_for_qof,
              formData.noncovered_security_indicator,
              formData.loss_not_allowed_indicator,
              formData.basis_reported_to_irs,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2
            );
            await _1099BModifier.loadReviewCopy();
            modifiedBytes = await _1099BModifier.getModifiedBytes();
            break;

          case "1099-K":
            const _1099KModifier = new Review1099KModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount4,
              formData.payment_amount5,
              formData.payment_amount6,
              formData.payment_amount7,
              formData.payment_amount8,
              formData.payment_amount9,
              formData.payment_amountA,
              formData.payment_amountC,
              formData.payment_amountF,
              formData.payment_amountD,
              formData.payment_amountB,
              formData.payment_amountE,
              formData.payment_amountG,
              formData.type_of_filer_indicator,
              formData.type_of_payment_indicator,
              formData.p_s_e_name,
              formData.p_s_e_name_cont,
              formData.p_s_e_phone_number,
              formData.merchant_category_code,
              formData.number_of_payment_transactions,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2,
              formData.state_income1,
              formData.state_income2
            );
            await _1099KModifier.loadReviewCopy();
            modifiedBytes = await _1099KModifier.getModifiedBytes();
            break;

          case "1099-C":
            const _1099CModifier = new Review1099CModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.date_of_identifiable_event,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amount7,
              formData.personal_liability_indicator,
              formData.debt_description,
              formData.identifiable_event_code
            );
            await _1099CModifier.loadReviewCopy();
            modifiedBytes = await _1099CModifier.getModifiedBytes();
            break;

          case "1099-PATR":
            const _1099PATRModifier = new Review1099PATRModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.second_tin_notice,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amount4,
              formData.payment_amount5,
              formData.payment_amount6,
              formData.payment_amountB,
              formData.payment_amountC,
              formData.payment_amountD,
              formData.payment_amount7,
              formData.payment_amount8,
              formData.payment_amountA,
              formData.specified_coop
            );
            await _1099PATRModifier.loadReviewCopy();
            modifiedBytes = await _1099PATRModifier.getModifiedBytes();
            break;

          case "1099-R":
            const _1099RModifier = new Review1099RModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.first_year_of_designated_roth_contrib,
              formData.date_of_payment,
              formData.fatca_filing_requirement_indicator,
              formData.taxable_amount_not_determined,
              formData.total_distribution_indicator,
              formData.distribution_code,
              formData.ira_sep_simple_indicator,
              formData.percentage_of_total_distribution,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount3,
              formData.payment_amount4,
              formData.payment_amount5,
              formData.payment_amount6,
              formData.payment_amountB,
              formData.payment_amount8,
              formData.payment_amount9,
              formData.state1,
              formData.state2,
              formData.payer_state_number1,
              formData.payer_state_number2,
              formData.state_income_tax_withheld1,
              formData.state_income_tax_withheld2,
              formData.state_income1,
              formData.state_income2,
              formData.locality1,
              formData.local_tax_withheld1,
              formData.local_income1,
              formData.locality2,
              formData.local_tax_withheld2,
              formData.local_income2
            );
            await _1099RModifier.loadReviewCopy();
            modifiedBytes = await _1099RModifier.getModifiedBytes();
            break;

          case "1099-Q":
            const _1099QModifier = new Review1099QModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.trustee_to_trustee_transfer_indicator,
              formData.private_tution_indicator,
              formData.state_tution_indicator,
              formData.coverdell_esa_indicator,
              formData.designated_benificiary_indicator,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount3
            );
            await _1099QModifier.loadReviewCopy();
            modifiedBytes = await _1099QModifier.getModifiedBytes();
            break;

          case "1099-SA":
            const _1099SAModifier = new Review1099SAModifier(
              formData.form_type,
              formData.year,
              reviewCopyBuffer,
              payerData,
              recData,
              toMaskTin,
              formData.payers_account_number_for_payee,
              formData.distribution_code,
              formData.hsa_indicator,
              formData.archer_msa_indicator,
              formData.medicare_advantage_msa_indicator,
              formData.payment_amount1,
              formData.payment_amount2,
              formData.payment_amount4
            );
            await _1099SAModifier.loadReviewCopy();
            modifiedBytes = await _1099SAModifier.getModifiedBytes();
            break;

          default:
            break;
        }

        if (modifiedBytes) {
          // Convert bytes to blob
          const reviewCopyBlob = new Blob([modifiedBytes], { type: "application/pdf" });

          // Convert the blob to dataUrl and update the data url state
          const reader = new FileReader();
          reader.readAsDataURL(reviewCopyBlob);
          reader.onloadend = () => {
            const result = reader.result;
            if (result && typeof result === "string") setReviewCopyDataURL(result);
          };
        }
      }
    };

    modifyReviewCopy();

    return () => setReviewCopyDataURL("");
  }, [reviewCopyBuffer, formData, toMaskTin]);

  // Revert to default mask tin state when this page component is destroyed
  useEffect(() => {
    return () => {
      maskTin(true, dispatch);
    };
  }, [dispatch]);

  return !startLoader ? (
    <div
      style={{ position: "relative", height: "100vh" }}
      onMouseMove={handleInactivity}
      onKeyDown={handleInactivity}
    >
      <Navbar />
      <div
        style={{
          paddingTop: "5rem",
          display: "flex",
          height: "100vh",
        }}
      >
        <ReviewTable year={year} handleYearChange={handleYearChange} />
        <ReviewForm
          formType={formData?.form_type}
          taxYear={formData?.year}
          formData={formData}
          recDataUrl={reviewCopyDataURL}
        />
      </div>
    </div>
  ) : (
    loader.start(style)
  );
}
