import React from "react";
import {
  borrowerReferredRecLabels,
  formFieldErrMsg,
  payeeReferredrecipientDetails,
  recipientDetails,
  recipientDetailsFor1099C,
  recipientDetailsFor1099S,
} from "../../../../lib/languagePacks/en-us";
import Select from "react-select";
import { arrangeStates } from "../../../../lib/form/arrangeSelectValues";
import { useSelector, useDispatch } from "react-redux";
import states from "../../../../lib/json/states.json";
import countries from "../../../../lib/json/countries.json";
import customFilter from "../../../../lib/form/utils/reactSelectFilter";
import { arrangeCountries } from "../../../../lib/form/arrangeSelectValues";
import validateFALimit from "../../../../lib/form/utils/ForeignAddressUtils/validateFALimit";
import {
  TO_SHOW_FA_WARNING,
  TO_SHOW_AL_WARNING,
  TO_SHOW_FORM_VALIDATION_ERR,
} from "../../../../redux/actions/action.types";
import {
  FA_WARNING_INDICATOR,
  AL_WARNING_INDICATOR,
  RECIPIENT_ADDRESS_1,
  RECIPIENT_CITY,
  RECIPIENT_STATE,
  RECIPIENT_ZIPCODE,
  RECIPIENT_COUNTRY,
  FORM1099_C,
} from "../../../../lib/constants";
import Indicator from "../../../Indicator";
import validateAddressLineLimit from "../../../../lib/form/utils/AddressLine/validateAddressLineLimit";
import {
  formsWithRecAsBorrower,
  formsWithRecAsPayee,
  formsWithRecAsTransferor,
} from "../RecipientDetails";
import SelectStyle from "../../../../lib/ReactSelectTheme/reactSelectStyle";

export default function RecipientAddressBlock({
  globalFormData,
  handleChange,
  setGlobalFormData,
  zipcode,
  formType,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const showFAWarning = useSelector((state: any) => state.showFAWarning);
  const warnMessage = useSelector((state: any) => state.FAWarnMsg);
  const showALWarning = useSelector((state: any) => state.showALWarning);
  const ALWarnMsg = useSelector((state: any) => state.ALWarnMsg);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const dispatch = useDispatch();

  // Handle input focus
  const handleInputFocus = (e: any) => {
    const { id } = e.target;
    if (globalFormData.recipient_is_foreign_address) {
      // Run this block only when the payer is foreign address
      const { status } = validateFALimit(
        globalFormData.recipient_city,
        globalFormData.recipient_state,
        globalFormData.recipient_zipcode
      );
      switch (id) {
        case "city":
          if (status === true) {
            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onRecipientCity: true,
              },
            });
          }
          break;

        case "state":
          if (status === true) {
            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onRecipientState: true,
              },
            });
          }
          break;

        case "zip-code":
          if (status === true) {
            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onRecipientZipcode: true,
              },
            });
          }
          break;

        default:
          break;
      }
    }
  };

  // Handle address line focus
  const handleALFocus = (e: any) => {
    const { id } = e.target;
    const { status } = validateAddressLineLimit(
      globalFormData.recipient_address_line1,
      globalFormData.recipient_address_line2
    );
    switch (id) {
      case "address-1":
        if (status === true) {
          // // Update the remaining chars state
          // dispatch({
          //   type: SET_REMAINING_CHARS,
          //   payload: remainingChars
          // })

          // Set the show warning state to true
          dispatch({
            type: TO_SHOW_AL_WARNING,
            payload: {
              ...showALWarning,
              onRecipientAL1: true,
            },
          });
        }
        break;

      case "address-2":
        if (status === true) {
          // // Update the remaining chars state
          // dispatch({
          //   type: SET_REMAINING_CHARS,
          //   payload: remainingChars
          // })

          // Set the show warning state to true
          dispatch({
            type: TO_SHOW_AL_WARNING,
            payload: {
              ...showALWarning,
              onRecipientAL2: true,
            },
          });
        }
        break;

      default:
        break;
    }
  };

  //  Handle input blur
  const handleInputBlur = (e: any) => {
    const { id } = e.target;
    switch (id) {
      case "city":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_FA_WARNING,
          payload: {
            ...showFAWarning,
            onRecipientCity: false,
          },
        });
        break;

      case "state":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_FA_WARNING,
          payload: {
            ...showFAWarning,
            onRecipientState: false,
          },
        });
        break;

      case "zip-code":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_FA_WARNING,
          payload: {
            ...showFAWarning,
            onRecipientZipcode: false,
          },
        });
        break;

      case "address-1":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_AL_WARNING,
          payload: {
            ...showALWarning,
            onRecipientAL1: false,
          },
        });
        break;

      case "address-2":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_AL_WARNING,
          payload: {
            ...showALWarning,
            onRecipientAL2: false,
          },
        });
        break;

      default:
        break;
    }
  };

  // Update the global payer state data
  const updateGlobalRecipientState = (selectedObj: any) => {
    const splits = selectedObj.label.split(" ");
    const recStateCode = splits[splits.length - 1];
    setGlobalFormData({
      ...globalFormData,
      recipient_state: recStateCode.replace(/\(/g, "").replace(/\)/g, ""),
    });

    // Remove any mandatory field warning from recipient state select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: RECIPIENT_STATE,
        toShowErr: false,
      },
    });
  };

  // Update the global recipient coountry data
  const updateGlobalRecipientCountry = (selectedObj: any) => {
    setGlobalFormData({
      ...globalFormData,
      recipient_country: selectedObj.value,
    });

    // Remove any mandatory field warning from payer state select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: RECIPIENT_COUNTRY,
        toShowErr: false,
      },
    });
  };

  // Custom theme for the dd
  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: "0.5rem",
    colors: {
      ...theme.colors,
      primary25: style.ddOptionHover,
    },
  });

  // const customStyle = {
  //   control: (styles: any) => ({
  //     ...styles,
  //     border: `1px solid ${style.ddBorder}`,
  //   }),
  //   option: (styles: any, { isSelected, isFocused }: any) => {
  //     return {
  //       ...styles,
  //       color: isSelected && style.isSelectedColor,
  //       backgroundColor: isSelected ? style.isSelectedBg : isFocused && style.ddOptionHover,
  //     };
  //   },
  // };
  return (
    <div className="address-block recipient-address-block">
      <div className="recipient-address-block-heading">
        <h4>
          {formType === FORM1099_C
            ? recipientDetailsFor1099C.addressHeading
            : !formsWithRecAsPayee.includes(formType) &&
              !formsWithRecAsTransferor.includes(formType) &&
              !formsWithRecAsBorrower.includes(formType)
            ? recipientDetails.addressHeading
            : formsWithRecAsTransferor.includes(formType)
            ? recipientDetailsFor1099S.addressHeading
            : formsWithRecAsPayee.includes(formType) &&
              payeeReferredrecipientDetails.addressHeading}

          {formsWithRecAsBorrower.includes(formType) && borrowerReferredRecLabels.addressHeading}
        </h4>
      </div>
      <div className="foreign-address-confirmation">
        <input
          type="checkbox"
          id="foreign-address-checkbox"
          onChange={handleChange}
          checked={globalFormData.recipient_is_foreign_address}
        />
        <label>{recipientDetails.foreignAddressConfirmText} ?</label>
      </div>

      <div className="address-wrapper">
        <div className="address-line-1">
          <label htmlFor="address-1">
            {recipientDetails.addressLine1Placeholder}{" "}
            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>
          <input
            className="payer-details-input"
            id="address-1"
            value={globalFormData.recipient_address_line1}
            onChange={handleChange}
            onFocus={handleALFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
          />
          {showALWarning.onRecipientAL1 && (
            <Indicator type={AL_WARNING_INDICATOR} message={ALWarnMsg} />
          )}
          {showValidationError.onRecipientAddress1 && (
            <Indicator
              type={RECIPIENT_ADDRESS_1}
              message={formFieldErrMsg.nec1099.recipient.emptyAddress1}
            />
          )}
        </div>
        <div className="address-line-2">
          <label htmlFor="address-2">{recipientDetails.addressLine2Placeholder}</label>
          <input
            className="payer-details-input"
            id="address-2"
            value={globalFormData.recipient_address_line2}
            onChange={handleChange}
            onFocus={handleALFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
          />
          {showALWarning.onRecipientAL2 && (
            <Indicator type={AL_WARNING_INDICATOR} message={ALWarnMsg} />
          )}
        </div>
      </div>

      <div className="city-state-wrapper">
        <div className="city-wrapper">
          <label htmlFor="city">
            {globalFormData.recipient_is_foreign_address
              ? recipientDetails.foreignCityPlaceholder
              : recipientDetails.cityPlaceholder}{" "}
            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>
          <input
            className="payer-details-input"
            id="city"
            value={globalFormData.recipient_city}
            onChange={handleChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
          />
          {showFAWarning.onRecipientCity && (
            <Indicator type={FA_WARNING_INDICATOR} message={warnMessage} />
          )}
          {showValidationError.onRecipientCity && (
            <Indicator
              type={RECIPIENT_CITY}
              message={formFieldErrMsg.nec1099.recipient.emptyCity}
            />
          )}
        </div>
        <div className="state-wrapper">
          <label htmlFor="state">
            {globalFormData.recipient_is_foreign_address
              ? recipientDetails.foreignStatePlaceholder
              : recipientDetails.statePlaceholder}{" "}
            {!globalFormData.recipient_is_foreign_address && (
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
            )}
          </label>
          {!globalFormData.recipient_is_foreign_address ? (
            <Select
              theme={customTheme}
              styles={new SelectStyle(style).customStyle}
              id="state"
              options={arrangeStates(states)}
              onChange={updateGlobalRecipientState}
              filterOption={customFilter}
              value={arrangeStates(states).filter((option: any) => {
                const splits = option.label.split(" ");
                const recStateCode = splits[splits.length - 1];
                return (
                  recStateCode.replace(/\(/g, "").replace(/\)/g, "") ===
                  globalFormData.recipient_state
                );
              })}
              placeholder=""
            />
          ) : (
            <input
              className="payer-details-input"
              id="state"
              value={globalFormData.recipient_state}
              onChange={handleChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              autoComplete="off"
            />
          )}
          {showFAWarning.onRecipientState && (
            <Indicator type={FA_WARNING_INDICATOR} message={warnMessage} />
          )}
          {showValidationError.onRecipientState && !globalFormData.recipient_is_foreign_address && (
            <Indicator
              type={RECIPIENT_STATE}
              message={formFieldErrMsg.nec1099.recipient.emptyState}
            />
          )}
        </div>
      </div>

      <div className="zip-country-wrapper">
        <div
          className="zip-wrapper"
          style={{
            width: !globalFormData.recipient_is_foreign_address ? "48%" : "initial",
          }}
        >
          <label htmlFor="zip-code">
            {globalFormData.recipient_is_foreign_address
              ? recipientDetails.foreignZipCodePlaceholder
              : recipientDetails.zipCodePlaceholder}{" "}
            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>
          <input
            className="payer-details-input"
            type="text"
            id="zip-code"
            value={zipcode.recipient}
            onChange={handleChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
          />
          {showFAWarning.onRecipientZipcode && (
            <Indicator type={FA_WARNING_INDICATOR} message={warnMessage} />
          )}
          {showValidationError.onRecipientZipcode && (
            <Indicator
              type={RECIPIENT_ZIPCODE}
              message={formFieldErrMsg.nec1099.recipient.emptyZipcode}
            />
          )}
        </div>
        {globalFormData.recipient_is_foreign_address && (
          <div className="country-wrapper">
            <label htmlFor="country">
              {recipientDetails.countryPlaceholder}{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
            </label>
            <Select
              theme={customTheme}
              styles={new SelectStyle(style).customStyle}
              id="state"
              options={arrangeCountries(countries)}
              onChange={updateGlobalRecipientCountry}
              filterOption={customFilter}
              value={arrangeCountries(countries).filter((option: any) => {
                return option.value === globalFormData.recipient_country;
              })}
              placeholder=""
            />
            {showValidationError.onRecipientCountry && (
              <Indicator
                type={RECIPIENT_COUNTRY}
                message={formFieldErrMsg.nec1099.recipient.emptyCountry}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
