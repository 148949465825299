import React from "react";

export default function AmountHeading({ heading, amountNum, important }: any) {
  return (
    <div className="heading" style={{ padding: "0" }}>
      <h4 style={{ height: "100%" }}>
        {amountNum ? (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <div>{amountNum}</div>
            <div>{heading}</div>
            {important && <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>}
          </div>
        ) : (
          heading
        )}
      </h4>
    </div>
  );
}
