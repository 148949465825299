import React from "react";
import "../../styles/decorations/Loader.css";
import Navbar from "../navbar";

class Loader {
  start(style: any, toNotShowNavbar?: boolean, options?: { overrideClass?: string }) {
    return (
      <>
        {!toNotShowNavbar && <Navbar />}
        <div className={`${!options?.overrideClass ? "loader-container" : options.overrideClass}`}>
          <div
            className="loader"
            style={{
              borderTop: `15px solid #5E3ABB`,
            }}
          />
        </div>
      </>
    );
  }
}

const loader = new Loader();

export default loader;
