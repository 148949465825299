import {
  INVALID_PAYER_EIN,
  LOCALITY_1,
  LOCALITY_2,
  LOCAL_INCOME_1,
  LOCAL_INCOME_2,
  LOCAL_TAX_1,
  LOCAL_TAX_2,
  NUMBER_OF_W2_FILED,
  PAYER_ADDRESS_1,
  PAYER_ALL_FIELDS,
  PAYER_CITY,
  PAYER_COUNTRY,
  PAYER_NAME,
  PAYER_PHONE_NUMBER,
  PAYER_STATE,
  PAYER_TIN,
  PAYER_ZIPCODE,
  RECIPIENT_ADDRESS_1,
  RECIPIENT_ALL_FIELDS,
  RECIPIENT_CITY,
  RECIPIENT_COUNTRY,
  RECIPIENT_NAME,
  RECIPIENT_PHONE_NUMBER,
  RECIPIENT_STATE,
  RECIPIENT_TIN,
  RECIPIENT_ZIPCODE,
  STATE1_ALL_DETAILS,
  STATE2_ALL_DETAILS,
  STATE_1,
  STATE_2,
  STATE_INCOME_1,
  STATE_INCOME_2,
  STATE_NUMBER_1,
  STATE_NUMBER_2,
  STATE_TAX_1,
  STATE_TAX_2,
  TOTAL_TAX_WITHHELD_FOR_W2,
  UTAH_STATE_NUMBER_1,
  UTAH_STATE_NUMBER_2,
  WV_STATE_NUMBER_1,
  WV_STATE_NUMBER_2,
} from "../../../lib/constants";
import { TO_SHOW_FORM_VALIDATION_ERR } from "../../actions/action.types";

const initialState = {
  // Payer block
  onPayerName: false,
  onPayerTin: false,
  onPayerAddress1: false,
  onPayerCity: false,
  onPayerState: false,
  onPayerZipcode: false,
  onPayerCountry: false,
  onPayerPhone: false,
  onInvalidPayerEin: false,

  // Recipient block
  onRecipientName: false,
  onRecipientTin: false,
  onRecipientAddress1: false,
  onRecipientCity: false,
  onRecipientState: false,
  onRecipientZipcode: false,
  onRecipientCountry: false,
  onRecipientPhone: false,

  // State Filing
  onStateIncome1: false,
  onStateTax1: false,
  onStateNum1: false,
  onWVStateNum1: false,
  onUtahStateNum1: false,
  onState1: false,

  onStateIncome2: false,
  onStateTax2: false,
  onStateNum2: false,
  onWVStateNum2: false,
  onUtahStateNum2: false,
  onState2: false,

  onNumberOfStateFiled: false,
  onTotalTaxForW2: false,

  // Loca filing related
  onLocalTax1: false,
  onLocality1: false,
  onLocalIncome1: false,

  onLocalTax2: false,
  onLocality2: false,
  onLocalIncome2: false,
};

const showValidationErr = (state = initialState, action: any) => {
  if (action.type === TO_SHOW_FORM_VALIDATION_ERR) {
    switch (action.payload.type) {
      // Payer block
      case PAYER_NAME:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerName: true,
          };
        } else {
          return {
            ...state,
            onPayerName: false,
          };
        }

      case PAYER_TIN:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerTin: true,
          };
        } else {
          return {
            ...state,
            onPayerTin: false,
          };
        }

      case INVALID_PAYER_EIN:
        if (action.payload.toShowErr) return { ...state, onInvalidPayerEin: true };
        else return { ...state, onInvalidPayerEin: false };

      case PAYER_ADDRESS_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerAddress1: true,
          };
        } else {
          return {
            ...state,
            onPayerAddress1: false,
          };
        }

      case PAYER_CITY:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerCity: true,
          };
        } else {
          return {
            ...state,
            onPayerCity: false,
          };
        }

      case PAYER_STATE:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerState: true,
          };
        } else {
          return {
            ...state,
            onPayerState: false,
          };
        }

      case PAYER_ZIPCODE:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerZipcode: true,
          };
        } else {
          return {
            ...state,
            onPayerZipcode: false,
          };
        }

      case PAYER_COUNTRY:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerCountry: true,
          };
        } else {
          return {
            ...state,
            onPayerCountry: false,
          };
        }

      case PAYER_PHONE_NUMBER:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerPhone: true,
          };
        } else {
          return {
            ...state,
            onPayerPhone: false,
          };
        }

      // Recipient block
      case RECIPIENT_NAME:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientName: true,
          };
        } else {
          return {
            ...state,
            onRecipientName: false,
          };
        }

      case RECIPIENT_TIN:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientTin: true,
          };
        } else {
          return {
            ...state,
            onRecipientTin: false,
          };
        }

      case RECIPIENT_ADDRESS_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientAddress1: true,
          };
        } else {
          return {
            ...state,
            onRecipientAddress1: false,
          };
        }

      case RECIPIENT_CITY:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientCity: true,
          };
        } else {
          return {
            ...state,
            onRecipientCity: false,
          };
        }

      case RECIPIENT_STATE:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientState: true,
          };
        } else {
          return {
            ...state,
            onRecipientState: false,
          };
        }

      case RECIPIENT_ZIPCODE:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientZipcode: true,
          };
        } else {
          return {
            ...state,
            onRecipientZipcode: false,
          };
        }

      case RECIPIENT_COUNTRY:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientCountry: true,
          };
        } else {
          return {
            ...state,
            onRecipientCountry: false,
          };
        }

      case RECIPIENT_PHONE_NUMBER:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientPhone: true,
          };
        } else {
          return {
            ...state,
            onRecipientPhone: false,
          };
        }

      case PAYER_ALL_FIELDS:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onPayerName: true,
            onPayerTin: true,
            onPayerAddress1: true,
            onPayerCity: true,
            onPayerState: true,
            onPayerZipcode: true,
            onPayerPhone: true,
          };
        } else {
          return {
            ...state,
            onPayerName: false,
            onPayerTin: false,
            onPayerAddress1: false,
            onPayerCity: false,
            onPayerState: false,
            onPayerZipcode: false,
            onPayerPhone: false,
          };
        }

      case RECIPIENT_ALL_FIELDS:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onRecipientName: true,
            onRecipientTin: true,
            onRecipientAddress1: true,
            onRecipientCity: true,
            onRecipientState: true,
            onRecipientZipcode: true,
            onRecipientPhone: true,
          };
        } else {
          return {
            ...state,
            onRecipientName: false,
            onRecipientTin: false,
            onRecipientAddress1: false,
            onRecipientCity: false,
            onRecipientState: false,
            onRecipientZipcode: false,
            onRecipientPhone: false,
          };
        }

      // State filing
      case STATE_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onState1: true,
          };
        } else {
          return {
            ...state,
            onState1: false,
          };
        }

      case STATE_2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onState2: true,
          };
        } else {
          return {
            ...state,
            onState2: false,
          };
        }

      case STATE_INCOME_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateIncome1: true,
          };
        } else {
          return {
            ...state,
            onStateIncome1: false,
          };
        }

      case STATE_INCOME_2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateIncome2: true,
          };
        } else {
          return {
            ...state,
            onStateIncome2: false,
          };
        }

      case STATE_TAX_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateTax1: true,
          };
        } else {
          return {
            ...state,
            onStateTax1: false,
          };
        }

      case STATE_TAX_2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateTax2: true,
          };
        } else {
          return {
            ...state,
            onStateTax2: false,
          };
        }

      case STATE_NUMBER_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateNum1: true,
          };
        } else {
          return {
            ...state,
            onStateNum1: false,
          };
        }

      case STATE_NUMBER_2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateNum2: true,
          };
        } else {
          return {
            ...state,
            onStateNum2: false,
          };
        }

      case STATE1_ALL_DETAILS:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateIncome1: true,
            onStateTax1: true,
            onStateNum1: true,
            onWVStateNum1: true,
            onUtahStateNum1: true,
            onState1: true,
          };
        } else {
          return {
            ...state,
            onStateIncome1: false,
            onStateTax1: false,
            onStateNum1: false,
            onWVStateNum1: false,
            onUtahStateNum1: false,
            onState1: false,
          };
        }

      case STATE2_ALL_DETAILS:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onStateIncome2: true,
            onStateTax2: true,
            onStateNum2: true,
            onWVStateNum2: true,
            onUtahStateNum2: true,
            onState2: true,
          };
        } else {
          return {
            ...state,
            onStateIncome2: false,
            onStateTax2: false,
            onStateNum2: false,
            onWVStateNum2: false,
            onUtahStateNum2: false,
            onState2: false,
          };
        }

      case WV_STATE_NUMBER_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onWVStateNum1: true,
          };
        } else {
          return {
            ...state,
            onWVStateNum1: false,
          };
        }

      case WV_STATE_NUMBER_2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onWVStateNum2: true,
          };
        } else {
          return {
            ...state,
            onWVStateNum2: false,
          };
        }

      case UTAH_STATE_NUMBER_1:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onUtahStateNum1: true,
          };
        } else {
          return {
            ...state,
            onUtahStateNum1: false,
          };
        }

      case UTAH_STATE_NUMBER_2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onUtahStateNum2: true,
          };
        } else {
          return {
            ...state,
            onUtahStateNum2: false,
          };
        }

      case NUMBER_OF_W2_FILED:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onNumberOfStateFiled: true,
          };
        } else {
          return {
            ...state,
            onNumberOfStateFiled: false,
          };
        }

      case TOTAL_TAX_WITHHELD_FOR_W2:
        if (action.payload.toShowErr) {
          return {
            ...state,
            onTotalTaxForW2: true,
          };
        } else {
          return {
            ...state,
            onTotalTaxForW2: false,
          };
        }

      // Local filing related
      case LOCAL_TAX_1:
        if (action.payload.toShowErr) return { ...state, onLocalTax1: true };
        else return { ...state, onLocalTax1: false };

      case LOCALITY_1:
        if (action.payload.toShowErr) return { ...state, onLocality1: true };
        else return { ...state, onLocality1: false };

      case LOCAL_INCOME_1:
        if (action.payload.toShowErr) return { ...state, onLocalIncome1: true };
        else return { ...state, onLocalIncome1: false };

      case LOCAL_TAX_2:
        if (action.payload.toShowErr) return { ...state, onLocalTax2: true };
        else return { ...state, onLocalTax2: false };

      case LOCALITY_2:
        if (action.payload.toShowErr) return { ...state, onLocality2: true };
        else return { ...state, onLocality2: false };

      case LOCAL_INCOME_2:
        if (action.payload.toShowErr) return { ...state, onLocalIncome2: true };
        else return { ...state, onLocalIncome2: false };

      default:
        return state;
    }
  } else {
    return state;
  }
};

export default showValidationErr;
