// This window will look similar to a chatbox.
// There will be two default messages from taxeve.
// First one will ask / instruct the user to write the query.
// After the query is posted and the API called with a success response, there will be another message
// stating the success and that an associate will get in touch within 24hrs.

import React, { useCallback, useEffect, useState } from "react";
import MessageBubble from "../../ChatRelated/MessageBubble";
import getUserDetailsLocally from "../../../lib/RetrieveUserDetailsLocally/getUserDetailsLocally";
import RaiseTicketForm from "./RaiseTicketForm";
import ResponseTypeSelector from "./ResponseTypeSelector";
import raiseTicketHelper from "../../../lib/Handlers/RaiseTicketHelper";

export default function RaiseTicketWindow() {
  const [isMessageLoading, setIsMessageLoading] = useState({
    initial: true,
    responseTypeSelector: true,
    ticketRaiseResponse: true,
  });

  const [toDisplayFinalResponseMessage, displayFinalResponseMessage] = useState(false);
  const [raisedTicketId, setRaisedTicketId] = useState("");

  const [disableTicketRaiseForm, toDisableTicketRaiseForm] = useState(false);

  const [query, setQuery] = useState("");

  const [disableResponseTypeSelector, toDisableResponseTypeSelector] = useState(false);

  const load = useCallback((messageType: string) => {
    switch (messageType) {
      case "initial":
        setIsMessageLoading((prevState) => {
          return { ...prevState, initial: true };
        });

        // Stop loading after 1 second
        setTimeout(() => {
          setIsMessageLoading((prevState) => {
            return { ...prevState, initial: false };
          });
        }, 1000);
        break;

      case "responseTypeSelector":
        setIsMessageLoading((prevState) => {
          return { ...prevState, responseTypeSelector: true };
        });

        // Stop loading after 1 second
        setTimeout(() => {
          setIsMessageLoading((prevState) => {
            return { ...prevState, responseTypeSelector: false };
          });
        }, 1000);
        break;

      case "ticketRaiseResponse":
        setIsMessageLoading((prevState) => {
          return { ...prevState, ticketRaiseResponse: true };
        });

        // Stop loading after 1 second
        setTimeout(() => {
          setIsMessageLoading((prevState) => {
            return { ...prevState, ticketRaiseResponse: false };
          });
        }, 1000);
        break;

      default:
        break;
    }
  }, []);

  // Load the system message for a certain time initially before displaying it
  useEffect(() => {
    load("initial");

    return () =>
      setIsMessageLoading({
        initial: false,
        responseTypeSelector: false,
        ticketRaiseResponse: false,
      });
  }, [load]);

  // Handle posting query
  const handlePostingQuery = (query: string) => {
    setQuery(query);

    // Disable the ticket raising form
    toDisableTicketRaiseForm(true);

    // start the default response type selector message loading animaton
    load("responseTypeSelector");
  };

  // Handle raising ticket
  const handleRaisingTicket = (responseType: string) => {
    displayFinalResponseMessage(true); // Prep the message box to display the final response message

    // Disable the response type selector
    toDisableResponseTypeSelector(true);

    // Call the raise ticket helper
    raiseTicketHelper(query, responseType, setIsMessageLoading).then((ticketId) => {
      setRaisedTicketId(ticketId);
    });
  };

  return (
    <div className="h-full">
      <section
        className={`chat-window p-2 ${
          !disableTicketRaiseForm ? "h-[30rem]" : "h-full"
        } overflow-auto`}
      >
        {/* Display the default message instructing user to post the query */}
        <MessageBubble
          message={`Hi ${
            getUserDetailsLocally().full_name
          }, we are sorry for any inconvenience caused to you. Kindly, write down your complaint/query in the box below and raise a ticket so we can help you further.`}
          messenger="Taxeve"
          isLoading={isMessageLoading.initial}
        />

        {query && (
          <>
            {/* Display the query to user */}
            <MessageBubble
              message={query}
              messenger={getUserDetailsLocally().full_name}
              isLoading={false}
              rightAligned
            />

            {/* Response type selection message */}
            <MessageBubble
              message={
                <ResponseTypeSelector
                  disable={disableResponseTypeSelector}
                  handleRaisingTicket={handleRaisingTicket}
                />
              }
              messenger="Taxeve"
              isLoading={isMessageLoading.responseTypeSelector}
            />
          </>
        )}

        {toDisplayFinalResponseMessage && (
          <MessageBubble
            message={
              <p>
                Thank you for reaching out to us. Your ticket{" "}
                <span className="font-bold">#{raisedTicketId}</span> has been raised successfully!
                One of our associate will get back to you within 24hrs.
              </p>
            }
            messenger="Taxeve"
            isLoading={isMessageLoading.ticketRaiseResponse}
          />
        )}
      </section>
      {!disableTicketRaiseForm && (
        <section className="complaint-type-box">
          <RaiseTicketForm handleRaisingTicket={handlePostingQuery} />
        </section>
      )}
    </div>
  );
}
