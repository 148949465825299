import React, { ChangeEvent, useEffect } from "react";
import "../../styles/forms/Form.css";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_2,
  AMOUNT_4,
  A_1099,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  PAGE_E_FILE,
  PERSONAL_LIABILITY_INDICATOR,
  PROPERTY_DESCRIPTION,
  SUBMIT_ADD_ANOTHER,
  _1099A_GROUP,
} from "../../lib/constants";
import SubmitButton from "../SubmitButton/SubmitButton";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import { useNavigate } from "react-router-dom";
import UpdateButton from "../UpdateButton/UpdateButton";
import { isValidAmount } from "./Form1099-B";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import devLogInstance from "../../lib/loggerConfig";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import DateInput from "../decorations/DateInput/DateInput";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";
import { useRecTinUnknown } from "../../lib/customHooks";

export default function Form1099A({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount2: "",
    amount4: "",
  }); // for displaying on the input field

  const [date, setDate] = useState("");
  // const [isValidDate, setIsValidDate] = useState<boolean | null>(null);
  const [clearDate, setClearDate] = useState<boolean | null>(null);
  const [showDateRequiredIndicator, toShowDateRequiredIndicator] = useState(false);

  const [miscFields, setMiscFields] = useState({
    personal_liability_indicator: false,
    description_of_property: "",
  });

  const { recTinUnknown } = useRecTinUnknown();

  // Date related callback
  const callbackRelatedToDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date?.split("/");
    const date_ = dateChunks[2] + dateChunks[0] + dateChunks[1];

    setDate(date_);
    // setIsValidDate(!isInvalidDate);
  };

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
    });

    // Set the misc field values
    setMiscFields({
      personal_liability_indicator: formData?.personal_liability_indicator || false,
      description_of_property: formData?.description_of_property || "",
    });

    // Reset
    return () => {
      setAmount({
        amount2: "",
        amount4: "",
      });

      setMiscFields({
        personal_liability_indicator: false,
        description_of_property: "",
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount4,
    formData?.personal_liability_indicator,
    formData?.description_of_property,
  ]);

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;

      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;

      case "direct-sales":
        setFormData({
          ...formData,
          direct_sales_indicator: !formData.direct_sales_indicator,
        });
        break;

      default:
        break;
    }
  };

  const handleMiscFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.currentTarget;

    switch (id) {
      case PERSONAL_LIABILITY_INDICATOR:
        setMiscFields({
          ...miscFields,
          personal_liability_indicator: !miscFields.personal_liability_indicator, // check and uncheck
        });
        break;

      case PROPERTY_DESCRIPTION:
        setMiscFields({
          ...miscFields,
          description_of_property: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,

      // Misc fields
      ...miscFields,

      // date of lenders acquisition
      date_of_lenders_acquisition: date,
    };
    devLogInstance.log({ data_before_submitting: data });

    const incomeAmounts = [data.payment_amount2];

    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [data.payment_amount2, data.payment_amount4],
          data.payment_amount4,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                personal_liability_indicator: false,
                description_of_property: "",
              });
              // Clear date
              setClearDate(true);
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div
                  className="amount-container blank"
                  style={{
                    background: "#F5F5F5",
                    width: "20rem",
                    borderTop: `1px solid ${style.eFormBorderColor}`,
                  }}
                />
                <FormDetail formType={formType} taxYear={taxYear} formName={A_1099} />
              </div>
              <div className="flex items-center border-b border-r border-black">
                <div className="w-1/2 flex flex-col justify-center gap-2 px-4">
                  <AmountHeading heading={"Date of lenders acquisition"} amountNum={"1"} />
                  <DateInput
                    minDate={new Date(taxYear - 1 - 22, 11, 31)}
                    maxDate={new Date(taxYear - 1, 11, 31)}
                    clearDate={clearDate}
                    callback={callbackRelatedToDate}
                    groupId={_1099A_GROUP}
                    classes="display-from-top-left"
                    value={formData?.date_of_lenders_acquisition || null}
                    showDateRequiredIndicator={showDateRequiredIndicator}
                    toShowDateRequired={toShowDateRequiredIndicator}
                  />
                </div>
                <AmountContainer
                  class_="1099-a"
                  style={{
                    // borderBottom: `1px solid ${style.eFormBorderColor}`,
                    borderLeft: `1px solid ${style.eFormBorderColor}`,
                    width: "50%",
                  }}
                >
                  <AmountHeading heading={"Balance of principal outstanding"} amountNum={"2"} />
                  <Amount
                    type="text"
                    id={AMOUNT_2}
                    placeHolder="0.00"
                    class_="payer-details-input"
                    value={amount.amount2}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div className="flex items-center border-b border-r border-black">
                <div
                  className="blank amount-container"
                  style={{
                    height: "14vh",
                    background: "#F5F5F5",
                    width: "50%",
                  }}
                >
                  <h4>3.</h4>
                </div>

                <AmountContainer
                  class_="1099-a" // Only one class can be passed
                  style={{
                    borderLeft: `1px solid ${style.eFormBorderColor}`,
                    width: "50%",
                  }}
                >
                  <AmountHeading heading={"Fair markte value of property"} amountNum="4" />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div className="border-r border-b border-black p-4 flex items-center justify-between">
                <AmountHeading
                  heading={"Check if borrower is personally liable for repayment of debt"}
                  amountNum={"5"}
                />
                <input
                  id={PERSONAL_LIABILITY_INDICATOR}
                  type="checkbox"
                  checked={miscFields.personal_liability_indicator}
                  onChange={handleMiscFieldChange}
                />
              </div>

              <div className="border-r border-b border-black p-4 flex flex-col justify-center gap-2">
                <AmountHeading heading={"Description of property"} amountNum={"6"} />
                <textarea
                  className="border border-gray-400 rounded-lg p-4 text-black"
                  id={PROPERTY_DESCRIPTION}
                  rows={4}
                  value={miscFields.description_of_property}
                  onChange={handleMiscFieldChange}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
