import React from "react";
import { useSearchParams } from "react-router-dom";
import Navbar from "../components/navbar";
import { ticketStatusMap } from "../components/SupportTicketRelated/UserRelated/RaisedTicketListItem";
import getUserDetailsLocally from "../lib/RetrieveUserDetailsLocally/getUserDetailsLocally";
import { ticketDefaultMessages } from "../lib/languagePacks/en-us";

export default function RaisedTicketDetailsPage() {
  const [searchParams] = useSearchParams();

  return (
    <div className="page">
      <Navbar />
      <div className="page-main-body h-screen pt-24">
        <section className="header py-4 px-10">
          <div className="p-4 flex items-center justify-between border-b border-gray-300">
            <div>
              <h2 className="ticket-status text-taxeve-primary-violet">
                Status:{" "}
                <span className={`${ticketStatusMap[searchParams.get("status") ?? ""].color}`}>
                  {ticketStatusMap[searchParams.get("status") ?? ""].label}
                </span>
              </h2>
            </div>

            <div>
              <h2 className="ticket-id text-taxeve-primary-violet">
                Ticket Id: <span className="text-gray-500">{searchParams.get("id") ?? ""}</span>
              </h2>
            </div>
          </div>
        </section>

        <section className="body px-10">
          <div className="user-query p-4">
            <h2>{getUserDetailsLocally().full_name}</h2>
            <p className="text-lg">{searchParams.get("query") ?? ""}</p>
          </div>
          <div className="system-default-message p-4 text-center text-lg font-bold text-gray-400">
            <em>{ticketDefaultMessages[searchParams.get("status") ?? ""]}</em>
          </div>
        </section>
      </div>
    </div>
  );
}
