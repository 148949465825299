import React from "react";
import Navbar from "../components/navbar";
import { ENQUIRY, REGISTER } from "../lib/routes";
import { TiArrowRightThick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { features } from "./WhyChooseUs";
import Card from "../components/decorations/Card/Card";
import Footer from "../components/Footer/Footer";
import EnquiryForm from "../components/EnquiryRelated/EnquiryForm";

export default function Enquiry() {
  const navigate = useNavigate();

  return (
    <div className="page">
      <Navbar page={ENQUIRY} />
      <section className="banner px-4 lg:px-40">
        <div className="nested-div-in-banner">
          <div className="banner-info w-[100%] lg:w-[50%] flex flex-col justify-center">
            <h1 className="text-4xl px-10 font-semibold text-left text-yellow-500">
              E-file IRS Form{" "}
              <span className="text-yellow-500">1099-NEC, 1099-Misc, 1099-Series</span>
            </h1>
            <ul className="py-4">
              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">
                  E-filing of Federal and State Form 1099
                </p>
              </div>

              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">
                  Supports edelivery to your recipients
                </p>
              </div>

              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">
                  Provide print and mailing services to your recipients
                </p>
              </div>

              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">Easy Excel Integration</p>
              </div>
            </ul>

            <div>
              <button
                className="py-2 px-6 font-semibold rounded-lg bg-yellow-500 hover:bg-yellow-300 text-[1.25rem] text-black"
                onClick={() => {
                  navigate(REGISTER);
                }}
              >
                E-File Now!
              </button>
            </div>
          </div>
          <div className="w-[100%] lg:w-[50%]">
            <EnquiryForm />
          </div>
        </div>
      </section>

      <section className="features-section py-12">
        <div className="features-heading">
          <h1 className="text-center text-4xl">Why choose TaxEve?</h1>
        </div>
        <div className="features flex flex-wrap justify-center gap-4 py-10">
          {features.map((featureObj, i) => {
            return (
              <Card
                key={i}
                heading={featureObj.heading}
                description={featureObj.description}
                icons={featureObj.icon}
                width="w-[24rem]"
              />
            );
          })}
        </div>
      </section>

      <section className="flex justify-center py-12 bg-taxeve-primary-violet">
        <div className="w-[70%]">
          <EnquiryForm />
        </div>
      </section>
      <Footer />
    </div>
  );
}
