import React from "react";
import Navbar from "../components/navbar";
import { _1099_FORMS_PAGE } from "../lib/routes";
import Card from "../components/decorations/Card/Card";
import KnowMoreBtn from "../components/KnowMoreBtn/KnowMoreBtn";
import EFileBtn from "../components/GetStarted/EFileBtn";
import Footer from "../components/Footer/Footer";
import { formsDescription } from "../lib/languagePacks/en-us";
import scrollToTop from "../lib/utils/scrollToTop";

export default function Forms1099() {
  scrollToTop();
  return (
    <div>
      <Navbar page={_1099_FORMS_PAGE} />
      <section id="banner" className="banner font-bold">
        <div className="nested-div-in-banner">1099 Forms</div>
      </section>
      <section
        id="cards-section"
        className="px-2 sm:px-40 py-16 flex flex-wrap justify-center gap-16"
      >
        <Card
          className="hover:scale-110"
          heading={formsDescription.nec1099.heading}
          description={formsDescription.nec1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-nec"
              formType={formsDescription.nec1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
        <Card
          className="hover:scale-110"
          heading={formsDescription.misc1099.heading}
          description={formsDescription.misc1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-misc"
              formType={formsDescription.misc1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
        <Card
          className="hover:scale-110"
          heading={formsDescription.int1099.heading}
          description={formsDescription.int1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-int"
              formType={formsDescription.int1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
        <Card
          className="hover:scale-110"
          heading={formsDescription.div1099.heading}
          description={formsDescription.div1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-div"
              formType={formsDescription.div1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
        <Card
          className="hover:scale-110"
          heading={formsDescription.b1099.heading}
          description={formsDescription.b1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-b"
              formType={formsDescription.b1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
        {/* <Card
          className="hover:scale-110"
          heading={formsDescription.r1099.heading}
          description={formsDescription.r1099.description}
          buttons={[<KnowMoreBtn />, <EFileBtn />]}
          width="w-[100%] sm:w-[40%]"
        /> */}
        <Card
          className="hover:scale-110"
          heading={formsDescription.s1099.heading}
          description={formsDescription.s1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-s"
              formType={formsDescription.s1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
        <Card
          className="hover:scale-110"
          heading={formsDescription.k1099.heading}
          description={formsDescription.k1099.description}
          buttons={[
            <KnowMoreBtn
              linkTo="https://www.irs.gov/forms-pubs/about-form-1099-k"
              formType={formsDescription.k1099.heading}
            />,
            <EFileBtn />,
          ]}
          width="w-[100%] sm:w-[40%]"
        />
      </section>
      <Footer />
    </div>
  );
}
