import React, { ChangeEvent, useEffect } from "react";
import "../../styles/forms/Form.css";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_2,
  AMOUNT_3,
  AMOUNT_4,
  AMOUNT_7,
  CORRECTION_INITIAL,
  C_1099,
  DEBT_DESCRIPTION,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  FAIR_MARKET_VALUE_OF_PROPERTY,
  IDNETIFIABLE_EVENT_CODE,
  PAGE_E_FILE,
  PERSONAL_LIABILITY_INDICATOR,
  SUBMIT_ADD_ANOTHER,
  _1099C_GROUP,
} from "../../lib/constants";
import SubmitButton from "../SubmitButton/SubmitButton";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import { useNavigate } from "react-router-dom";
import UpdateButton from "../UpdateButton/UpdateButton";
import { isValidAmount } from "./Form1099-B";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import devLogInstance from "../../lib/loggerConfig";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import DateInput from "../decorations/DateInput/DateInput";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";
import { useRecTinUnknown } from "../../lib/customHooks";
import { c1099AmountDisplayTexts } from "../../lib/languagePacks/en-us";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import eventCode from "../../lib/json/eventCode.json";
import { arrangeEventCode } from "../../lib/form/arrangeSelectValues";
import customFilter from "../../lib/form/utils/reactSelectFilter";

export default function Form1099C({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount2: "",
    amount3: "",
    amount4: "",
    amount7: "",
  }); // for displaying on the input field

  const [date, setDate] = useState("");
  // const [isValidDate, setIsValidDate] = useState<boolean | null>(null);
  const [clearDate, setClearDate] = useState<boolean | null>(null);
  const [showDateRequiredIndicator, toShowDateRequiredIndicator] = useState(false);

  const [miscFields, setMiscFields] = useState({
    personal_liability_indicator: false,
    debt_description: "",
    identifiable_event_code: "",
    fair_market_value_of_property: "",
  });

  const { recTinUnknown } = useRecTinUnknown();

  // Date related callback
  const callbackRelatedToDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date?.split("/");
    const date_ = dateChunks[2] + dateChunks[0] + dateChunks[1];

    setDate(date_);
    // setIsValidDate(!isInvalidDate);
  };

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount7: formData.payment_amount7
        ? handleAmountFormat((formData.payment_amount7 / 100).toString())
        : "",
    });

    // Set the misc field values
    setMiscFields({
      personal_liability_indicator: formData?.personal_liability_indicator || false,
      debt_description: formData?.debt_description || "",
      identifiable_event_code: formData?.identifiable_event_code
        ? formData?.identifiable_event_code
        : "",
      fair_market_value_of_property: formData?.fair_market_value_of_property
        ? formData?.fair_market_value_of_property
        : "",
    });

    // Reset
    return () => {
      setAmount({
        amount2: "",
        amount3: "",
        amount4: "",
        amount7: "",
      });

      setMiscFields({
        personal_liability_indicator: false,
        debt_description: "",
        identifiable_event_code: "",
        fair_market_value_of_property: "",
      });
    };
  }, [
    // formData.payment_amount1,
    formData?.payment_amount2,
    formData?.debt_description,
    formData?.payment_amount4,
    formData?.personal_liability_indicator,
    formData?.description_of_property,
    formData.payment_amount3,
    formData?.payment_amount7,
    formData?.identifiable_event_code,
    formData?.fair_market_value_of_property,
  ]);

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;

      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;

      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_7:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount7: formattedAmount,
          });
        break;

      case "direct-sales":
        setFormData({
          ...formData,
          direct_sales_indicator: !formData.direct_sales_indicator,
        });
        break;

      default:
        break;
    }
  };

  const handleMiscFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.currentTarget;

    switch (id) {
      case PERSONAL_LIABILITY_INDICATOR:
        setMiscFields({
          ...miscFields,
          personal_liability_indicator: !miscFields.personal_liability_indicator, // check and uncheck
        });
        break;

      case DEBT_DESCRIPTION:
        setMiscFields({
          ...miscFields,
          debt_description: value,
        });
        break;

      case FAIR_MARKET_VALUE_OF_PROPERTY:
        setMiscFields({
          ...miscFields,
          fair_market_value_of_property: value,
        });
        break;

      default:
        break;
    }
  };
  const updateIdentifiableEventCode = (selectedObj: any) => {
    setMiscFields({
      ...miscFields,
      identifiable_event_code: selectedObj.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
      payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,

      // Misc fields
      ...miscFields,

      // date of lenders acquisition
      date_of_identifiable_event: date,
    };
    devLogInstance.log({ data_before_submitting: data });

    const incomeAmounts = [data.payment_amount2];

    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [data.payment_amount2, data.payment_amount3, data.payment_amount4, data.payment_amount7],
          0,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                personal_liability_indicator: false,
                debt_description: "",
                identifiable_event_code: "",
                fair_market_value_of_property: "",
              });
              // Clear date
              setClearDate(true);
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };
  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: "0.5rem",
    colors: {
      ...theme.colors,
      primary25: style.ddOptionHover,
    },
  });

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={c1099AmountDisplayTexts.dateOfIdentifiableEvent}
                      amountNum="1"
                    />
                    <DateInput
                      minDate={new Date(2000, 11, 31)}
                      maxDate={new Date(taxYear, 11, 31)} // Calendar starts counting from 0
                      clearDate={clearDate}
                      callback={callbackRelatedToDate}
                      groupId={_1099C_GROUP}
                      classes="display-from-top-left"
                      value={formData?.date_of_identifiable_event || null}
                      showDateRequiredIndicator={showDateRequiredIndicator}
                      toShowDateRequired={toShowDateRequiredIndicator}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={c1099AmountDisplayTexts.amountOfDebtDischarged}
                      amountNum="2"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={c1099AmountDisplayTexts.interestIfIncludedinBox2}
                      amountNum="3"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_3}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount3}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail formType={formType} taxYear={taxYear} formName={C_1099} />
              </div>
              <div className="border-r border-b border-black p-4 flex flex-col justify-center gap-2">
                <AmountHeading heading={"Debt Description"} amountNum={"4"} />
                <textarea
                  className="border border-gray-400 rounded-lg p-4 text-black"
                  id={DEBT_DESCRIPTION}
                  rows={4}
                  value={miscFields.debt_description}
                  onChange={handleMiscFieldChange}
                />
              </div>
              <div className="border-r border-b border-black p-4 flex items-center justify-between">
                <AmountHeading
                  heading={"Check if the debtor was personally liable for repayment of the debt"}
                  amountNum={"5"}
                />
                <input
                  id={PERSONAL_LIABILITY_INDICATOR}
                  type="checkbox"
                  checked={miscFields.personal_liability_indicator}
                  onChange={handleMiscFieldChange}
                />
              </div>
              <div className="flex items-center border-b border-r border-black">
                <div className="w-1/2 flex flex-col justify-center gap-2 px-4">
                  <AmountHeading
                    heading={"Identifiable event code"}
                    amountNum={"6"}
                    important={true}
                  />

                  <Select
                    theme={customTheme}
                    styles={new SelectStyle(style).customStyle}
                    id={IDNETIFIABLE_EVENT_CODE}
                    options={arrangeEventCode(eventCode)}
                    onChange={updateIdentifiableEventCode}
                    filterOption={customFilter}
                    value={arrangeEventCode(eventCode).filter((option: any) => {
                      return option.value === miscFields?.identifiable_event_code;
                    })}
                    placeholder=""
                  />
                </div>
                <AmountContainer
                  class_="1099-c"
                  style={{
                    // borderBottom: `1px solid ${style.eFormBorderColor}`,
                    borderLeft: `1px solid ${style.eFormBorderColor}`,
                    width: "50%",
                  }}
                >
                  <AmountHeading
                    heading={c1099AmountDisplayTexts.fairMarketValue}
                    amountNum={"7"}
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_7}
                    placeHolder="0.00"
                    class_="payer-details-input"
                    onChangeHandler={handleChange}
                    value={amount.amount7}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
