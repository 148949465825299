import React, { useEffect, useState } from "react";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import { useDispatch, useSelector } from "react-redux";
import checkAdminLevel from "../../lib/AdminRelatedUtils/checkAdminLevel";
import devLogInstance, { prodLogInstance } from "../../lib/loggerConfig";
import { NewAdminData, ViewAdminsData } from "../../../interfaces/main";
import Handler from "../../lib/Handlers/main";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import handlePopup from "../../lib/popup/handlePopup";
import { ERROR_POPUP, SUCCESS_POPUP } from "../../lib/constants";
import { SHA256 } from "crypto-js";
import logoutAdmin from "../../lib/AdminRelatedUtils/logoutAdmin";

const NEW_ADMIN_EMAIL = "NEW_ADMIN_EMAIL";
const NEW_ADMIN_PASS = "NEW_ADMIN_PASS";
const DELETE_ADMIN_EMAIL = "DELETE_ADMIN_EMAIL";
const VIEW_ADMINS = "VIEW_ADMINS";

const adminLevelOptions = [
  {
    label: "None",
    value: "",
  },
  {
    label: "master",
    value: "master",
  },
  {
    label: "regular",
    value: "regular",
  },
];

interface SelectOptions {
  label: string;
  value: string;
}

interface SelectValue {
  label: string;
  value: string;
}

export default function AdminManagement() {
  const style = useSelector((state: any) => state.themeChange);
  const [adminLevel, setAdminLevel] = useState("");
  const [newAdminData, setNewAdminData] = useState<NewAdminData>({
    email: "",
    password: "",
    level: "",
  });
  const [selectVal, setSelectVal] = useState<SelectValue | null>(null);
  const [delAdminEmail, setDelAdminEmail] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [admins, setAdmins] = useState<ViewAdminsData[]>([]);
  const stateSetter = useDispatch();

  // Check admin level
  useEffect(() => {
    const adminlevel_ = checkAdminLevel();
    if (adminlevel_) setAdminLevel(adminlevel_);
    else {
      handlePopup("Session expired! please login again.", ERROR_POPUP, stateSetter);
      logoutAdmin(stateSetter);
    }

    return () => {
      setAdminLevel("");
    };
  }, [stateSetter]);

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: inputType, value } = e.target;

    switch (inputType) {
      case NEW_ADMIN_EMAIL:
        setNewAdminData({
          ...newAdminData,
          email: value,
        });
        break;

      case NEW_ADMIN_PASS:
        setNewAdminData({
          ...newAdminData,
          password: value,
        });
        break;

      case DELETE_ADMIN_EMAIL:
        setDelAdminEmail(value);
        break;

      default:
        break;
    }
  };

  // Handle change in react select value
  const handleAdminLevelSelection = (option: SelectOptions | null) => {
    devLogInstance.log(option);
    option &&
      setNewAdminData({
        ...newAdminData,
        level: option.value,
      });

    option && !option.value
      ? setSelectVal(null)
      : option &&
        setSelectVal({
          label: option.label,
          value: option.value,
        });
  };

  // Handle adding admin
  const handleAddingAdmin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (adminLevel === "master") {
      const encpass = SHA256(process.env.REACT_APP_NONCE + newAdminData.password).toString();
      const _admindata: NewAdminData = {
        ...newAdminData,
        password: encpass,
      };
      devLogInstance.log("New admin data");
      devLogInstance.log(_admindata);
      // Handle adding new admin by submitting form
      Handler.handleAddingAdmin(_admindata)
        .then((res) => {
          devLogInstance.log("Response from server after adding new Admin");
          devLogInstance.log(res);
          handlePopup("Admin added successfully", SUCCESS_POPUP, stateSetter);
          // Clear the input fields
          setNewAdminData({
            email: "",
            password: "",
            level: "",
          });
          setSelectVal(null);
        })
        .catch((err) => {
          prodLogInstance.error(err);
        });
    } else {
      handlePopup("You do not have permission to perform this action", ERROR_POPUP, stateSetter);
    }
  };

  // Handle deleting admin
  const handleDeletingAdmin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (adminLevel === "master") {
      Handler.handleDeletingAdmin(delAdminEmail)
        .then((res) => {
          devLogInstance.log("Response from server after deleting an admin");
          devLogInstance.log(res);
          handlePopup("Admin deleted successfully", SUCCESS_POPUP, stateSetter);
          // Clear the input field
          setDelAdminEmail("");
        })
        .catch((err) => {
          prodLogInstance.error(err);
        });
    } else {
      handlePopup("You do not have permission to perform this action", ERROR_POPUP, stateSetter);
    }
  };

  // Handle viewing admins
  const handleViewingAdmins = () => {
    // Any level of admin can view all the other admins
    Handler.handleGettingAdmins().then((adminData) => {
      adminData && console.log(adminData);
      adminData && setAdmins(adminData); // For viewing
    });
  };

  // Handle any click event
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id: clickedOn } = e.currentTarget;

    switch (clickedOn) {
      case VIEW_ADMINS:
        handleViewingAdmins();
        break;

      default:
        break;
    }
  };

  return (
    <main className="relative">
      <section className="p-6 view_admins">
        <button
          id={VIEW_ADMINS}
          className="py-3 px-4 rounded-xl bg-blue-600 text-white"
          onClick={handleClick}
        >
          View all admins
        </button>
        {admins.length > 0 && (
          <table className="mt-3 text-center">
            <thead>
              <tr className="bg-slate-400 font-bold">
                <td>Admin email</td>
                <td>Admin level</td>
                <td>Created on</td>
                <td>Created at</td>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin, i) => {
                return (
                  <tr key={i}>
                    <td>{admin.email}</td>
                    <td>{admin.level.toUpperCase()}</td>
                    <td>{new Date(admin.createdAt).toLocaleDateString()}</td>
                    <td>{new Date(admin.createdAt).toLocaleTimeString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </section>
      <section className="p-6 w-[50%] add-admin">
        <div>
          <h1 className="text-2xl font-bold underline underline-offset-4">Add admin</h1>
          <form className="py-4 flex flex-col gap-5" onSubmit={handleAddingAdmin}>
            <input
              id={NEW_ADMIN_EMAIL}
              type="email"
              placeholder="Admin email"
              className="w-[100%] py-2"
              onChange={handleChange}
              value={newAdminData.email}
            />
            <div className="relative">
              <input
                id={NEW_ADMIN_PASS}
                type={showPass ? "text" : "password"}
                placeholder="Admin password"
                className="w-[100%] py-2"
                onChange={handleChange}
                value={newAdminData.password}
              />

              <div className="absolute right-0 top-0 h-[100%] px-4 flex items-center">
                {showPass ? (
                  <IoEyeSharp
                    className="text-xl cursor-pointer"
                    onClick={() => {
                      setShowPass(false);
                    }}
                  />
                ) : (
                  <HiMiniEyeSlash
                    className="text-xl cursor-pointer"
                    onClick={() => {
                      setShowPass(true);
                    }}
                  />
                )}
              </div>
            </div>
            <Select
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              options={adminLevelOptions}
              placeholder="Select admin level"
              onChange={handleAdminLevelSelection}
              value={selectVal}
              isSearchable={false}
            />
            <div>
              {newAdminData.email && newAdminData.level && newAdminData.password && (
                <button type="submit" className="py-2 px-8 rounded-xl bg-blue-600 text-white">
                  Add
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
      <section className="p-6 w-[50%] delete-admin">
        <div>
          <h1 className="text-2xl font-bold underline underline-offset-4">Delete admin</h1>
          <form className="py-4 flex flex-col gap-5" onSubmit={handleDeletingAdmin}>
            <input
              id={DELETE_ADMIN_EMAIL}
              type="email"
              placeholder="Admin email"
              className="w-[100%] py-2"
              onChange={handleChange}
              value={delAdminEmail}
            />
            <div>
              {delAdminEmail && (
                <button
                  type="submit"
                  disabled={adminLevel !== "master"}
                  className="py-2 px-8 rounded-xl bg-blue-600 text-white"
                >
                  Delete
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}
