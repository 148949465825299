import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";

export default class Review1099QModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private ttTransferIndicator: boolean;
  private privateTutionIndicator: boolean;
  private stateTutionIndicator: boolean;
  private coverdellEsaIndicator: boolean;
  private designatedBeneficiaryIndicator: boolean;

  private amount1: number;
  private amount2: number;
  private amount3: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    ttTransferIndicator: boolean,
    privateTutionIndicator: boolean,
    stateTutionIndicator: boolean,
    coverdellEsaIndicator: boolean,
    designatedBeneficiaryIndicator: boolean,

    amount1: number,
    amount2: number,
    amount3: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.ttTransferIndicator = ttTransferIndicator;
    this.privateTutionIndicator = privateTutionIndicator;
    this.stateTutionIndicator = stateTutionIndicator;
    this.coverdellEsaIndicator = coverdellEsaIndicator;
    this.designatedBeneficiaryIndicator = designatedBeneficiaryIndicator;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount3 = amount3;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      this.drawCalendarYearPartly();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // *** RIGHT SIDE *** //
      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      // Gross distribution (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 61 - this.getFontSize(),
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount1Pos,
        ...options,
      });

      // Earnings (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 36,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount2Pos,
        ...options,
      });

      // Basis (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 24,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount3Pos,
        ...options,
      });

      // Trustee to trustee transfer indicator (Box 4)
      const ttTransferIndicatorPos = {
        x: amount3Pos.x + 176,
        y: amount3Pos.y + 1,
      };
      if (this.ttTransferIndicator) {
        firstPage.drawText("z", {
          ...ttTransferIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // Private tution indicator (Box 5)
      const privateTutionIndicatorPos = {
        x: amount3Pos.x + 23,
        y: amount3Pos.y - 36,
      };
      if (this.privateTutionIndicator) {
        firstPage.drawText("z", {
          ...privateTutionIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // State tution indicator (Box 5)
      const stateTutionIndicatorPos = {
        x: privateTutionIndicatorPos.x + 24 * 2.2,
        y: privateTutionIndicatorPos.y,
      };
      if (this.stateTutionIndicator) {
        firstPage.drawText("z", {
          ...stateTutionIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // Coverdell ESA indicator (Box 5)
      const coverdellEsaIndicatorPos = {
        x: stateTutionIndicatorPos.x,
        y: stateTutionIndicatorPos.y - 12,
      };
      if (this.coverdellEsaIndicator) {
        firstPage.drawText("z", {
          ...coverdellEsaIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // Designated beneficiary indicator (Box 6)
      const designatedBeneficiaryIndicatorPos = {
        x: ttTransferIndicatorPos.x,
        y: ttTransferIndicatorPos.y - 27,
      };
      if (this.designatedBeneficiaryIndicator) {
        firstPage.drawText("z", {
          ...designatedBeneficiaryIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      //   // Federal Income tax withheld (amount 4)
      //   const amount4Pos = {
      //     x: amount3Pos.x + 100,
      //     y: amount3Pos.y,
      //   };
      //   formattedAmt = (this.amount4 / 100).toFixed(2);
      //   firstPage.drawText(formattedAmt, {
      //     ...amount4Pos,
      //     ...options,
      //   });

      //   // Employee contributions (amount 5)
      //   const amount5Pos = {
      //     x: amount3Pos.x,
      //     y: amount3Pos.y - 24 * 2,
      //   };
      //   formattedAmt = (this.amount5 / 100).toFixed(2);
      //   firstPage.drawText(formattedAmt, {
      //     ...amount5Pos,
      //     ...options,
      //   });

      //   // Net unrealized appreciation in employer’s securities (amount 6)
      //   const amount6Pos = {
      //     x: amount5Pos.x + 100,
      //     y: amount5Pos.y,
      //   };
      //   formattedAmt = (this.amount6 / 100).toFixed(2);
      //   firstPage.drawText(formattedAmt, {
      //     ...amount6Pos,
      //     ...options,
      //   });

      //   // Distribution code(s)
      //   const distributionCodePosCords = {
      //     x: amount5Pos.x,
      //     y: amount5Pos.y - 36,
      //   };
      //   firstPage.drawText(this.distributionCode, {
      //     ...distributionCodePosCords,
      //     ...options,
      //   });

      //   // IRA / SEP / SIMPLE indicator
      //   const iraSepSimpleIndicatorPosCords = {
      //     x: distributionCodePosCords.x + 70,
      //     y: distributionCodePosCords.y,
      //   };
      //   if (this.iraSepSimpleIndicator) {
      //     firstPage.drawText("z", {
      //       ...iraSepSimpleIndicatorPosCords,
      //       ...options,
      //       font: tickMark,
      //     });
      //   }

      //   // Other (amount 8)
      //   const amount8Pos = {
      //     x: distributionCodePosCords.x + 100,
      //     y: distributionCodePosCords.y,
      //   };
      //   formattedAmt = (this.amount8 / 100).toFixed(2);
      //   firstPage.drawText(formattedAmt, {
      //     ...amount8Pos,
      //     ...options,
      //   });

      //   // TODO: There is a percentage field for amount 8 as well
      //   // work on it when the doubt regarding this particular field is resolved

      //   // Percentage of total distr.
      //   const percentOfTotalDistrPosCords = {
      //     x: iraSepSimpleIndicatorPosCords.x - 7,
      //     y: iraSepSimpleIndicatorPosCords.y - 24,
      //   };
      //   const percentOfTotalDistr =
      //     this.percentOfTotalDistr && this.percentOfTotalDistr > 0
      //       ? this.percentOfTotalDistr.toString()
      //       : "";
      //   firstPage.drawText(percentOfTotalDistr, {
      //     ...percentOfTotalDistrPosCords,
      //     ...options,
      //   });

      //   // Total employee contrib (amount 9)
      //   const amount9Pos = {
      //     x: amount8Pos.x,
      //     y: amount8Pos.y - 24,
      //   };
      //   formattedAmt = (this.amount9 / 100).toFixed(2);
      //   firstPage.drawText(formattedAmt, {
      //     ...amount9Pos,
      //     ...options,
      //   });

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
