import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";

export default class Review1099MISCModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;

  private amount1: number;
  private amount2: number;
  private amount3: number;
  private amount4: number;
  private amount5: number;
  private amount6: number;
  private amount8: number;
  private amountA: number;
  private amountC: number;
  private amountF: number;
  private amountD: number;
  private amountB: number;
  private amountE: number;

  private directSalesIndicator: boolean;
  private fatcaFilingIndicator: boolean;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;
  private stateIncome1: number;
  private stateIncome2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount1: number,
    amount2: number,
    amount3: number,
    amount4: number,
    amount5: number,
    amount6: number,
    amount8: number,
    amountA: number,
    amountC: number,
    amountF: number,
    amountD: number,
    amountB: number,
    amountE: number,
    directSalesIndicator: boolean,
    fatcaFilingIndicator: boolean,
    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number,
    stateIncome1: number,
    stateIncome2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amount4 = amount4;
    this.amount5 = amount5;
    this.amount6 = amount6;
    this.amount8 = amount8;
    this.amountA = amountA;
    this.amountC = amountC;
    this.amountF = amountF;
    this.amountD = amountD;
    this.amountB = amountB;
    this.amountE = amountE;

    this.directSalesIndicator = directSalesIndicator;
    this.fatcaFilingIndicator = fatcaFilingIndicator;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
    this.stateIncome1 = stateIncome1;
    this.stateIncome2 = stateIncome2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // *** LEFT SIDE *** //

      await this.fillLSFForFormsWithFatcaFiling(
        this.fatcaFilingIndicator,
        this.accountNumber,
        this.secondTinNotice
      );

      // *** RIGHT SIDE *** //

      // Calendar year
      this.drawCalendarYear();

      // Rents (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: 61,
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount1Pos.x,
        y: height - amount1Pos.y - this.getFontSize(),
        ...options,
      });

      // Royalties (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y + 36,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: height - amount2Pos.y - this.getFontSize(),
        ...options,
      });

      // Other income (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y + 25,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount3Pos.x,
        y: height - amount3Pos.y - this.getFontSize(),
        ...options,
      });

      // Federal income tax withheld (amount 4)
      const amount4Pos = {
        x: amount3Pos.x + 100,
        y: amount3Pos.y,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: height - amount4Pos.y - this.getFontSize(),
        ...options,
      });

      // Fishing boat proceeds (amount 5)
      const amount5Pos = {
        x: amount3Pos.x,
        y: amount3Pos.y + 47,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: height - amount5Pos.y - this.getFontSize(),
        ...options,
      });

      // Medical and health care payments (amount 6)
      const amount6Pos = {
        x: amount4Pos.x,
        y: amount4Pos.y + 47,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount6Pos.x,
        y: height - amount6Pos.y - this.getFontSize(),
        ...options,
      });

      // Direct sales indicator
      const directSalesIndicatorPos = {
        x: amount5Pos.x + 69,
        y: amount5Pos.y + 28,
      };
      if (this.directSalesIndicator) {
        this.getReviewCopy().registerFontkit(fontkit);
        const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
        const fontBuffer = await tickMarkFontRes.arrayBuffer();
        const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
        firstPage.drawText("z", {
          x: directSalesIndicatorPos.x,
          y: height - directSalesIndicatorPos.y - 8 * 2,
          ...this.getOptions(),
          size: 8,
          font: tickMark,
        });
      }

      // Substitute payments in lieu of dividends or interest (amount 8)
      const amount8Pos = {
        x: amount6Pos.x,
        y: amount6Pos.y + 36,
      };
      formattedAmt = (this.amount8 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount8Pos.x,
        y: height - amount8Pos.y - this.getFontSize(),
        ...options,
      });

      // Crop insurance proceeds (amount A)
      const amountAPos = {
        x: amount5Pos.x,
        y: amount5Pos.y + 36 * 2,
      };
      formattedAmt = (this.amountA / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountAPos.x,
        y: height - amountAPos.y - this.getFontSize(),
        ...options,
      });

      // Gross proceeds paid to an attorney (amount C)
      const amountCPos = {
        x: amount8Pos.x,
        y: amount8Pos.y + 36,
      };
      formattedAmt = (this.amountC / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountCPos.x,
        y: height - amountCPos.y - this.getFontSize(),
        ...options,
      });

      // Fish purchased for resale (amount F)
      const amountFPos = {
        x: amountAPos.x,
        y: amountAPos.y + 36,
      };
      formattedAmt = (this.amountF / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountFPos.x,
        y: height - amountFPos.y - this.getFontSize(),
        ...options,
      });

      // Section 409A deferrals (amount D)
      const amountDPos = {
        x: amountCPos.x,
        y: amountCPos.y + 36,
      };
      formattedAmt = (this.amountD / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountDPos.x,
        y: height - amountDPos.y - this.getFontSize(),
        ...options,
      });

      // Excess golden parachute payment (amount B)
      const amountBPos = {
        x: amountFPos.x,
        y: amountFPos.y + 36,
      };
      formattedAmt = (this.amountB / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountBPos.x,
        y: height - amountBPos.y - this.getFontSize(),
        ...options,
      });

      // Nonqualified deferred compensation (amount E)
      const amountEPos = {
        x: amountDPos.x,
        y: amountDPos.y + 36,
      };
      formattedAmt = (this.amountE / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountEPos.x,
        y: height - amountEPos.y - this.getFontSize(),
        ...options,
      });

      //   State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: amountBPos.x,
          prevFieldYCoord: height - amountBPos.y - this.getFontSize(),
        },
        {
          stateNum1: this.stateNum1,
          prevFieldXCoord: amountEPos.x,
          prevFieldYCoord: height - amountEPos.y - this.getFontSize(),
        },
        {
          stateTax1: this.stateTax1,
        },
        this.stateIncome1,
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        },
        this.stateIncome2
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateTax1Obj: { stateTax1: number },
    stateIncome1: number,
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number },
    stateIncome2: number
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord,
      stateNum1Obj.prevFieldXCoord,
      stateNum1Obj.prevFieldYCoord
    );

    // State details
    // State tax
    stateDetailsMod.modifyStateTaxDetail(stateTax1Obj.stateTax1, stateTax2Obj.stateTax2, true);
    // State num
    stateDetailsMod.modifyStateNumDetail(
      stateNum1Obj.stateNum1,
      stateNum2Obj.stateNum2,
      state1Obj.state1,
      state2Obj.state2
    );
    // State income
    stateDetailsMod.modifyStateIncomeDetail(stateIncome1, stateIncome2, -20);
  }
}
