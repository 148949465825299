import { UseQueryResult, useQuery } from "@tanstack/react-query";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import getAssignedTickets from "../../../lib/Handlers/Queries/GetAssignedTickets.handler";
import loader from "../../decorations/Loader";
import { useSelector } from "react-redux";
import FilterComponent from "../../FilterComponent";
import ListOfTickets from "./ListOfTickets";
import { AssignedTicketData, AssignedTicketsResponse } from "../../../../interfaces/main";
import TicketFilterWindow from "../../SupportTicketRelated/TicketFilterWindow";
import devLogInstance from "../../../lib/loggerConfig";
import { TicketSelectionCtx } from "../../../pages/AdminRelatedPages/AdminDashboard";
import getCachedStatusFilterOptions from "../../../lib/utils/Helpers/GetCachedStatusFilterOptions";
import RefreshButton from "../../RefreshButton";

export const TicketFilterCtx = createContext<{
  handleFilterByStatus: (statusOptions: string[]) => void;
} | null>(null);

export default function AssignedTicketList() {
  const style = useSelector((state: any) => state.themeChange);

  const [assignedTickets, setAssignedTickets] = useState<AssignedTicketData[]>([]);

  const assignedTicketsQuery: UseQueryResult<AssignedTicketsResponse, Error> = useQuery({
    queryKey: ["GetAssignedTickets"],
    queryFn: () => getAssignedTickets(),
  });

  const ticketSelectionCtx = useContext(TicketSelectionCtx);
  if (!ticketSelectionCtx) throw new Error("Ticket selection ctx is null");

  // Maintain a seperate state for the assigned tickets (mainly for filter purposes)
  useEffect(() => {
    setAssignedTickets(assignedTicketsQuery.data?.tickets || []);

    return () => setAssignedTickets([]);
  }, [assignedTicketsQuery.data]);

  // Select the first ticket from assigned tickets list initially
  const { selectCurrentTicket } = ticketSelectionCtx;
  useEffect(() => {
    assignedTickets.length > 0 && selectCurrentTicket(assignedTickets[0]._id); // First ticket

    return () => selectCurrentTicket("");
  }, [assignedTickets, selectCurrentTicket]);

  // Function to filter assigned tickets by ticket status
  // NOTE: Only this filter program will filter the original list / data
  // Any other filter methods / program will filter the assigned tickets state list / data
  const filterTicketsByStatus = useCallback(
    (statusOptions: string[]) => {
      devLogInstance.log(statusOptions);

      const tickets = assignedTicketsQuery.data?.tickets || [];

      if (statusOptions.length <= 0) return setAssignedTickets([...tickets]); // Set to default if no filter option is selected

      const filteredTickets = tickets.filter((ticketObj) =>
        statusOptions.includes(ticketObj.ticket_status)
      );
      setAssignedTickets([...filteredTickets]);
    },
    [setAssignedTickets, assignedTicketsQuery.data]
  );

  // Set the initial assigned tickets state on component mount with filter if there are any in the cache
  useEffect(() => {
    const cachedStatusOptions = getCachedStatusFilterOptions();

    filterTicketsByStatus(cachedStatusOptions);
  }, [filterTicketsByStatus]);

  // When loading / fetching data
  if (assignedTicketsQuery.isLoading) return <div className="p-6">{loader.start(style, true)}</div>;

  // When there is an error while fetching data
  if (assignedTicketsQuery.isError)
    return (
      <div className="p-6">
        <pre>{assignedTicketsQuery.error.message}</pre>
      </div>
    );

  // When data is fetched
  return (
    <div className="py-4">
      <div className="actions px-6 flex items-center justify-between">
        <TicketFilterCtx.Provider value={{ handleFilterByStatus: filterTicketsByStatus }}>
          <div>
            <FilterComponent
              className="text-lg cursor-pointer"
              filterOptionsWindow={<TicketFilterWindow />}
            />
          </div>
          <RefreshButton refetchFn={assignedTicketsQuery.refetch} />
        </TicketFilterCtx.Provider>
      </div>
      <ListOfTickets tickets={assignedTickets} />
    </div>
  );
}
