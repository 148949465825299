import React, { ReactNode } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import displayInfoModal from "../lib/StateSetters/displayInfoModal";
import { useDispatch } from "react-redux";

interface Props extends React.HTMLProps<HTMLElement> {
  infoHeading: string;
  infoBody: ReactNode;
}

export default function InfoIcon({ className, infoHeading, infoBody }: Props) {
  const dispatch = useDispatch();

  const openInfoModal = () => {
    displayInfoModal(true, infoBody, infoHeading, dispatch);
  };

  return (
    <div className={`cursor-pointer ${className}`}>
      <BsInfoCircleFill onClick={openInfoModal} />
    </div>
  );
}
