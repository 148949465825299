import { axiosInstance } from "../axiosConfig";
import { SUCCESS_POPUP } from "../constants";
import devLogInstance from "../loggerConfig";
import handlePopup from "../popup/handlePopup";
import DisableScreen from "../utils/disableScreen";
import Handler, { Main } from "./main";

export default async function emailUser(
  recipient_email_id: string,
  email_subject: string,
  email_body: string
) {
  try {
    DisableScreen.enable();

    const { data } = await axiosInstance.post("/admin/send_support_email", {
      recipient_email_id,
      email_subject,
      email_body,
    });

    devLogInstance.log({ response_after_sending_email_to_user: data });

    DisableScreen.disable();

    // Display a success toast message / popup
    handlePopup(
      data.message || "Email sent to user successfully",
      SUCCESS_POPUP,
      Main.stateDispatch
    );
  } catch (err) {
    Handler.handleError(err, Main.stateDispatch, () => {});
  }
}
