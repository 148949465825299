import React, { useState, useEffect } from "react";
import { EMAIL } from "../pages/Contact";
import { PasswordResetData } from "../../interfaces/main";
import devLogInstance from "../lib/loggerConfig";
import { EMAIL_REGEX } from "../lib/patterns";
import handlePopup from "../lib/popup/handlePopup";
import { indicatorTexts } from "../lib/languagePacks/en-us";
import { ADMIN_ACCOUNT, ERROR_POPUP, SUCCESS_POPUP, USER_ACCOUNT } from "../lib/constants";
import { useDispatch } from "react-redux";
import Handler from "../lib/Handlers/main";
import { CONFIRM_PASSWORD, NEW_PASSWORD } from "../pages/AdminRelatedPages/AdminChangePassword";
import { PasswordValidator } from "../lib/validators";
import Password from "./Password";
import { SHA256 } from "crypto-js";
import { useNavigate } from "react-router-dom";
import { ADMIN_LOGIN, SIGNIN } from "../lib/routes";

export const SEND_VERIFY_CODE_FORM = "SEND_VERIFY_CODE_FORM";
export const RESET_PASSWORD_FORM = "RESET_PASSWORD_FORM";
export const VERIFICATION_CODE = "VERIFICATION_CODE";

interface Props {
  accountType: string;
}

export default function PasswordResetForm({ accountType }: Props) {
  const [passwordResetData, setPasswordResetData] = useState<PasswordResetData>({
    email: "",
    new_password: "",
    verification_code: "",
  });
  const [passConfirm, setPassConfirm] = useState("");
  const [verifyCodeSent, isVerifyCodeSent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setPasswordResetData({
        email: "",
        new_password: "",
        verification_code: "",
      });
      setPassConfirm("");
      isVerifyCodeSent(false);
    };
  }, []);

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case EMAIL:
        value.length <= 500 &&
          setPasswordResetData({
            ...passwordResetData,
            email: value,
          });
        break;

      case VERIFICATION_CODE:
        setPasswordResetData({
          ...passwordResetData,
          verification_code: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle change event on password input
  const handlePassChange = (value: string, id: string) => {
    switch (id) {
      case NEW_PASSWORD:
        setPasswordResetData({
          ...passwordResetData,
          new_password: value,
        });
        break;

      case CONFIRM_PASSWORD:
        setPassConfirm(value);
        break;

      default:
        break;
    }
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id: formType } = e.currentTarget;
    devLogInstance.log(`Form type ---> ${formType}`);

    switch (formType) {
      case SEND_VERIFY_CODE_FORM:
        // Validate the email
        const isValidEmail = new RegExp(EMAIL_REGEX).test(passwordResetData.email);
        if (isValidEmail) {
          devLogInstance.log(`Email ---> ${passwordResetData.email}`);
          // Call the API
          Handler.handleSendingResetVerificationCode(passwordResetData.email, accountType)
            .then((res) => {
              devLogInstance.log(res);
              handlePopup(indicatorTexts.verifyCodeSentSuccessfully, SUCCESS_POPUP, dispatch);
              isVerifyCodeSent(true);
            })
            .catch((err: Error) => {
              // Error will be handled by error handler
            });
        } else {
          handlePopup(indicatorTexts.invalidEmail, ERROR_POPUP, dispatch);
        }
        break;

      case RESET_PASSWORD_FORM:
        // Validate password
        const isValidPass = new PasswordValidator(passwordResetData.new_password).validate();
        const passMatch = passwordResetData.new_password === passConfirm;
        const isValidVerificationCode = passwordResetData.verification_code.length > 0;

        if (!isValidPass) handlePopup(indicatorTexts.invalidPassword, ERROR_POPUP, dispatch);
        else if (!passMatch) handlePopup(indicatorTexts.passUnmatch, ERROR_POPUP, dispatch);
        else if (!isValidVerificationCode)
          handlePopup(indicatorTexts.invalidVerificationCode, ERROR_POPUP, dispatch);
        else {
          // Call the reset API
          const data: PasswordResetData = {
            ...passwordResetData,
            new_password: SHA256(
              process.env.REACT_APP_NONCE + passwordResetData.new_password
            ).toString(),
          }; // hash the password before sending

          devLogInstance.log("Password reset data before sending to backend:");
          devLogInstance.log(data);

          Handler.handlePasswordReset(data, accountType)
            .then((res) => {
              devLogInstance.log(res);
              handlePopup(indicatorTexts.passResetSuccessful, SUCCESS_POPUP, dispatch);
              setTimeout(() => {
                // Redirect to login page
                switch (accountType) {
                  case USER_ACCOUNT:
                    navigate(SIGNIN);
                    break;

                  case ADMIN_ACCOUNT:
                    navigate(ADMIN_LOGIN);
                    break;

                  default:
                    navigate(SIGNIN);
                    break;
                }
              }, 1000);
            })
            .catch((err: Error) => {
              // Error will already be handled by error handler
            });
        }

        break;

      default:
        break;
    }
  };

  return (
    <div className="w-[100%] lg:w-[50%] text-center px-4 lg:px-10">
      <h1 className="py-4">Reset Password</h1>
      {!verifyCodeSent ? (
        <form id={SEND_VERIFY_CODE_FORM} onSubmit={handleSubmit}>
          <div className="mb-6">
            <p className="mb-4 text-lg">
              Enter your registered email id to receive a verification code in order to continue
              resetting your password.
            </p>
            <input
              id={EMAIL}
              type="email"
              className="py-2 w-[100%]"
              placeholder="Email*"
              onChange={handleChange}
            />
          </div>
          <button className="primary-button py-3 px-6" type="submit">
            Send verification code
          </button>
        </form>
      ) : (
        <form id={RESET_PASSWORD_FORM} onSubmit={handleSubmit}>
          <div className="mb-6">
            <input
              id={EMAIL}
              type="email"
              className="py-2 w-[100%]"
              placeholder="Email*"
              onChange={handleChange}
              value={passwordResetData.email}
              disabled={true}
            />
          </div>
          <div className="mb-6">
            <Password
              id={NEW_PASSWORD}
              width="w-[100%]"
              placeholder="New Password*"
              onChange={handlePassChange}
            />
          </div>
          <div className="mb-6">
            <Password
              id={CONFIRM_PASSWORD}
              width="w-[100%]"
              placeholder="Confirm Password*"
              onChange={handlePassChange}
            />
          </div>
          <div className="mb-6">
            <input
              id={VERIFICATION_CODE}
              type="text"
              className="py-2 w-[100%]"
              placeholder="Verification code*"
              onChange={handleChange}
              //   value={passwordResetData.verification_code}
            />
          </div>
          <button className="primary-button py-3 px-6" type="submit">
            Reset Password
          </button>
        </form>
      )}
    </div>
  );
}
