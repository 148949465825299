import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import Handler from "../../lib/Handlers/main";
import { TransactionDetailsResponse } from "../../../interfaces/main";

const monthOptions = [
  {
    label: "None",
    value: null,
  },
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

interface MonthOption {
  label: string;
  value: string | null;
}

export default function TrackTransactions() {
  const style = useSelector((state: any) => state.themeChange);
  const [yearAndMonth, setYearAndMonth] = useState({
    year: 0,
    month: "",
  });

  const [monthOption, setMonthOption] = useState<MonthOption | null>(null);

  const [transactionDetailsRes, setTransactionDetailsRes] =
    useState<TransactionDetailsResponse | null>(null);

  const [historyIdsFor, setHistoryIdsFor] = useState<number | null>(null);

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    value.length <= 4 &&
      setYearAndMonth({
        ...yearAndMonth,
        year: parseInt(value),
      });
  };

  const handleMonthChange = (option: MonthOption | null) => {
    // Set the entire option to null if the value is null
    if (!option?.value) return setMonthOption(null);

    // Otherwise set the current option
    setMonthOption(option);
    // Update the month value for yearAndMonth state
    setYearAndMonth({
      ...yearAndMonth,
      month: option.value,
    });
  };

  // Handle click on get transaction details button
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const res: TransactionDetailsResponse = await Handler.handleGettingTransacDetails({
        year: yearAndMonth.year,
        month: yearAndMonth.month,
      });
      setTransactionDetailsRes(res); // Set the transaction details response state
    } catch (err) {
      // Error will be handled in the handler itself
    }
  };

  return (
    <div>
      <section className="flex items-center gap-6 p-6">
        <div className="year-input">
          <input
            className="py-[0.295rem] px-2"
            placeholder="Year"
            type="number"
            value={yearAndMonth.year || ""}
            onChange={handleYearChange}
          />
        </div>

        <div className="month-selection w-[20%]">
          <div className="w-full">
            <Select
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              options={monthOptions}
              placeholder="Select a month"
              onChange={handleMonthChange}
              value={monthOption}
              isSearchable={true}
            />
          </div>
        </div>

        <div className="get-transac-details-btn">
          <button
            onClick={handleClick}
            className="p-4 bg-taxeve-primary-violet text-white rounded-xl hover:opacity-80"
          >
            Get transaction details
          </button>
        </div>
      </section>

      <section className="display-transaction-details">
        {transactionDetailsRes && (
          <>
            <h2 className="sub-total mb-4">
              Total Transaction Amount: {transactionDetailsRes.total_payment}/-
            </h2>
            <table>
              <thead>
                <tr>
                  <th className="text-center">User id</th>
                  <th className="text-center">History ids</th>
                  <th className="text-center">Total Payment</th>
                  <th className="text-center">Payment session id</th>
                </tr>
              </thead>

              <tbody>
                {transactionDetailsRes?.transaction_details.map((transacDetail, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-center">{transacDetail.user_id}</td>
                      {historyIdsFor !== i ? (
                        <td
                          onClick={() => setHistoryIdsFor(i)}
                          className="text-center underline underline-offset-1 text-taxeve-primary-blue cursor-pointer"
                        >
                          Show History Ids
                        </td>
                      ) : (
                        <td className="text-center">
                          <p
                            onClick={() => setHistoryIdsFor(null)}
                            className="text-center underline underline-offset-1 text-taxeve-primary-blue cursor-pointer"
                          >
                            Hide History Ids
                          </p>
                          {transacDetail.history_ids.map((historyId) => {
                            return (
                              <p key={historyId} className="py-2">
                                {historyId}
                              </p>
                            );
                          })}
                        </td>
                      )}
                      <td className="text-center">{transacDetail.total_payment}/-</td>
                      <td className="text-center">{transacDetail.payment_session_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </section>
    </div>
  );
}
