import React, { ReactNode } from "react";

interface Props {
  className?: string;
  message: string | ReactNode;
}

export default function Acknowledge({ className, message }: Props) {
  return (
    <div className={`h-full py-4 flex flex-col items-center ${className}`}>
      <div className="w-[13rem] pl-8">
        <img src="/Assets/check-mark.svg" alt="Check mark" />
      </div>
      <div className="text-center font-bold px-16">{message}</div>
    </div>
  );
}
