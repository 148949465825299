import logger from "../../logger";
import fieldsToValidate from "../../lib/form/FieldsToValidate";
import {
  Form1099BValidation,
  Form1099DivValidation,
  TinValidator,
  amountValidator,
  eventIdentifierValidator,
  formFieldsValidator,
  stateFilingValidator,
  stateNumberValidator,
  taxAmtValidator,
} from "../../lib/validators";
import {
  INVALID_AMOUNT,
  FORM_FIELD_VALIDATION_ERROR,
  STATE_FILING_VALIDATION_ERROR,
  SUBMIT_ADD_ANOTHER,
  STATE_NUM_VALIDATION_ERR,
  SUCCESS_POPUP,
  INVALID_TAX_AMT,
  SUBMIT_CONTINUE,
  PAGE_UPLOAD,
  PAGE_E_FILE,
  UPDATE_CONTINUE,
  FORM1099_B,
  PROPERTY_DESCRIPTION_ERROR,
  NO_FORM_DATA_ERROR,
  PROCEEDS_ERROR,
  FORM1099_DIV,
  INVALID_AMT,
  SUBMIT_CORRECTION,
  FORM1099_S,
  MIN_LEN_FOR_WV_NOT_MET,
  MIN_LEN_FOR_UTAH_NOT_MET,
  INVALID_PAYER_EIN,
  INVALID_PAYER_SSN,
  INVALID_PAYER_TIN_IN_ORDER,
  ALL_SAME_DIGIT_INVALID_PAYER_TIN,
  INVALID_RECIPIENT_EIN,
  INVALID_RECIPIENT_SSN,
  INVALID_RECIPIENT_TIN_IN_ORDER,
  ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN,
  ALL_ZERO_INVALID_PAYER_TIN,
  ALL_ZERO_INVALID_RECIPIENT_TIN,
  NO_SUPPORT_HEADING,
  LOCAL_FILING_VALIDATION_ERR,
  FORM1099_C,
  INVALID_EVENT_CODE,
  FORM1099_Q,
  FORM1099_SA,
  // NO_SUPPORT_HEADING,
} from "../../lib/constants";
import {
  SET_TO_DISABLE_SCREEN,
  TO_SHOW_FORM_VALIDATION_ERR,
  TO_SHOW_INVALID_TIN_ERROR,
} from "../../redux/actions/action.types";
import {
  formSavedMsg,
  invalidAmountWarning,
  invalidEventCode,
  invalidTaxAmtWarning,
  modalDisplayTexts,
  noSupportWV,
  // noSupportWV,
} from "../../lib/languagePacks/en-us";
import submitForm from "../form/submitForm";
import handleFormSubmitAndFillAgain from "../../lib/form/utils/handleFormSubmitAndFillAgain";
import saveCartId from "../../lib/SaveData/saveCartID";
import retrieveToken from "../../lib/authRequests/retrieveTokens";
import getRefreshToken from "../../lib/authRequests/getRefreshToken";
import saveTokens from "../../lib/authRequests/saveTokens";
import logoutUser from "../authRequests/logoutUser";
import handlePopup from "../popup/handlePopup";
import submitPayerData from "../form/submitPayerData";
import devLogInstance from "../loggerConfig";
import Handler from "../Handlers/main";
import displayErrorModal from "../StateSetters/displayErrorModal";
import { REVIEW } from "../routes";
import { ga4 } from "../../App";
import ga4Const from "../json/AnalyticsRelated/analyticsConst.json";
import scrollToTop from "../utils/scrollToTop";
import displayInfoModal from "../StateSetters/displayInfoModal";
import {
  LocalFiling1Validator,
  LocalFiling2Validator,
  LocalFilingValidator,
} from "../validation/LocalFilingValidator";
// import displayInfoModal from "../StateSetters/displayInfoModal";
// import redirect from "../RoutingMethods/redirect";

export default async function formSubmissionHelper(
  data: any,
  paymentAmounts: any,
  taxAmount: any,
  incomeAmounts: any,
  handleClearingFormData: any,
  handleClearingAmountDisplay: any,
  dispatch: any,
  submitType: any,
  navigate: any,
  pageType: any,
  isRecTinUnknown?: boolean
) {
  // // Save the data locally
  // saveFormData(data);

  // Get the form fields array

  const fieldsToValidate_ = fieldsToValidate(data, pageType, isRecTinUnknown);
  logger.log(`Form fields to validate:`);
  logger.log(fieldsToValidate_);

  try {
    // Validate payer tin
    // Validate the form fields
    const isValid = await formFieldsValidator.validate(fieldsToValidate_);
    logger.log(`Are form fields valid? ${isValid}`);
    await TinValidator.validatePayerTin({ tin: data.payer_tin, tinType: data.payer_tin_type });

    // Run this block only when it is not the upload page
    if (pageType !== PAGE_UPLOAD) {
      // Validate recipient tin only when rec tin unknown option is not selected
      !isRecTinUnknown &&
        (await TinValidator.validateRecipientTin({
          tin: data.recipient_tin,
          tinType: data.recipient_tin_type,
        }));

      // Validate the amount fields
      const isValidAmt = await amountValidator.validate(paymentAmounts);
      logger.log(`Are amounts valid? ${isValidAmt}`);

      // Validate the tax amount
      const isValidTaxAmt = await taxAmtValidator.validate(taxAmount, incomeAmounts);
      logger.log(`Is valid tax amount? ${isValidTaxAmt}`);

      if (
        data.form_type !== FORM1099_S &&
        data.form_type !== FORM1099_Q &&
        data.form_type !== FORM1099_SA
      ) {
        // Validate any state filing errors
        const state1Details = {
          state1: data.state1,
          state_income_tax_withheld1: data.state_income_tax_withheld1,
          payer_state_number1: data.payer_state_number1,
          state_income1: data.state_income1,
        };
        const state2Details = {
          state2: data.state2,
          state_income_tax_withheld2: data.state_income_tax_withheld2,
          payer_state_number2: data.payer_state_number2,
          state_income2: data.state_income2,
        };
        const isValidStateFiling = await stateFilingValidator.validate(
          state1Details,
          state2Details,
          data.number_of_w2_filed,
          data.total_tax_withheld_for_all_w2s,
          data.form_type
        );
        logger.log(`Is valid state filing details ---> ${isValidStateFiling}`);

        // Validate State number when filing state details
        const isValidStateNum = await stateNumberValidator.validate(
          state1Details,
          state2Details,
          data.recipient_tin_type
        );
        logger.log(`Is valid state number ---> ${isValidStateNum}`);

        // TODO: Disable form submission for West Virginia state filing untill supported
        // Disable West Virginia state filing
        if (state1Details.state1 === "West Virginia" || state2Details.state2 === "West Virginia") {
          // Show a modal stating WV is not supported
          displayInfoModal(true, noSupportWV, NO_SUPPORT_HEADING, dispatch);
          devLogInstance.warn("West Virginia is not supported!");
          // Dont read rest of the code block
          return;
        }

        // Local filing validation
        const localFilingValidator1 = new LocalFiling1Validator(
          data.local_tax_withheld1,
          data.locality1,
          data.local_income1
        ); // Local filing details 1 related
        const localFilingValidator2 = new LocalFiling2Validator(
          data.local_tax_withheld2,
          data.locality2,
          data.local_income2
        );
        const localFilingValidator = new LocalFilingValidator(
          localFilingValidator1,
          localFilingValidator2,
          data.form_type
        ); // Local filing details 2 related
        const isValidLocalFilingDetails = await localFilingValidator.validate();
        devLogInstance.log({ isValidLocalFilingDetails });
      }

      // Local filing details validation

      // Form 1099-B extra validation
      if (data.form_type === FORM1099_B) {
        const isValidPropertyDescription = await new Form1099BValidation(
          data
        ).vaidatePropertyDescription();
        devLogInstance.log(`Is Valid description of property ---> ${isValidPropertyDescription}`);

        const isValidProceeds = await new Form1099BValidation(data).validateProcceds();
        devLogInstance.log(`Is Valid Proceeds ---> ${isValidProceeds}`);
      }

      // Form 1099-DIV extra validation
      if (data.form_type === FORM1099_DIV) {
        const isValidAmt1 = await new Form1099DivValidation(data).validateAmount1();
        const isValidAmt3 = await new Form1099DivValidation(data).validateAmount3();
        devLogInstance.log(`Is valid amt 3: ${isValidAmt3}. Is valid amt 1: ${isValidAmt1}`);
      }

      //Form 1099-C extra validation
      if (data.form_type === FORM1099_C) {
        const isValidEventCode = await eventIdentifierValidator.validate(
          data?.identifiable_event_code
        );
        logger.log(`Is valid  event code ? ${isValidEventCode}`);
      }
    }

    // Disable the screen
    dispatch({
      type: SET_TO_DISABLE_SCREEN,
      payload: true,
    });

    let payerId_;
    let userId_;
    let payerName_;

    // Do only when the user is on the e-fling page
    if (
      pageType === PAGE_E_FILE &&
      submitType !== UPDATE_CONTINUE &&
      submitType !== SUBMIT_CORRECTION
    ) {
      devLogInstance.log("DATA BEFORE SENDING TO BACKEND: ");
      devLogInstance.log(data);
      const res = await submitForm(data);
      logger.log(res);

      // Custom GA4 analytics event
      ga4.sendEvent({
        category: ga4Const.event.category["e-filing"],
        action: `${ga4Const.event.action.formSubmission} (${data.form_type} for ${data.year})`,
      });

      // Save the Cart ID received from backend
      saveCartId(res.cart_id);
    }
    // Do When the user is on the upload page
    else if (pageType === PAGE_UPLOAD) {
      const updatedData = data.payer_is_new_or_updated ? { ...data, payer_id: "" } : data;
      const res = await submitPayerData(updatedData);
      logger.log("Data after submitting the payer data:");
      logger.log(res);

      // Set the payer id
      payerId_ = res.PayerId;
      // Set the user id
      userId_ = res.UserId;
      // Set the payer name
      payerName_ = res.PayerName;
    }

    // Enable the screen
    dispatch({
      type: SET_TO_DISABLE_SCREEN,
      payload: false,
    });

    // If page type is upload and payerId_, userId_ and payerName_ exists return them
    if (pageType === PAGE_UPLOAD) {
      if (payerId_ && userId_ && payerName_) return [payerId_, userId_, payerName_];
    }

    if (submitType === SUBMIT_ADD_ANOTHER) {
      // Handle popup display only if submit and aadd another is the submit type
      handlePopup(formSavedMsg, SUCCESS_POPUP, dispatch);
      // Handle form submission and fill again functionality
      handleFormSubmitAndFillAgain(
        data,
        handleClearingFormData,
        dispatch,
        handleClearingAmountDisplay
      );
      if (data.payer_is_new_or_updated) window.location.reload();
      return true;
    } else if (submitType === SUBMIT_CONTINUE && pageType === PAGE_E_FILE) {
      navigate(`${REVIEW}?eform=true`);
    } else if (submitType === UPDATE_CONTINUE) {
      devLogInstance.log("UPDATED DATA AFTER EDIT:");
      devLogInstance.log(data);
      // Handle form submission
      await Handler.handleFormUpdate(data, dispatch, navigate);
    } else if (submitType === SUBMIT_CORRECTION) {
      await Handler.handleCorrectedFormSubmission(data);
      navigate(`${REVIEW}`);
    }
  } catch (err: any) {
    if (err.response?.data.Error.includes("Invalid data found")) {
      // Remove screen disabler
      dispatch({
        type: SET_TO_DISABLE_SCREEN,
        payload: false,
      });
      throw err.response.data.ErrorMessage;
    }

    // Handle if validation error occurs on any of the form fields
    if (err.message === FORM_FIELD_VALIDATION_ERROR) {
      logger.log(err.data);

      // Enable validation indicators
      err.data.forEach((field: any) => {
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: field,
            toShowErr: true,
          },
        });
      });
      // Scroll page to top
      scrollToTop();
    }

    // Handle if error occurs in state filing
    if (err.status === STATE_FILING_VALIDATION_ERROR) {
      devLogInstance.log(err.data);
      // Enable  validation indicators
      err.data.forEach((field: any) => {
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: field,
            toShowErr: true,
          },
        });
      });
    }

    // Handle if error occurs in state number
    if (err.status === STATE_NUM_VALIDATION_ERR) {
      logger.log(err.data);
      // Enable  validation indicators
      err.data.forEach((field: any) => {
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: field,
            toShowErr: true,
          },
        });
      });
    }

    if (err.status === LOCAL_FILING_VALIDATION_ERR) {
      const invalidFields: string[] = err.data;
      devLogInstance.log(invalidFields);
      // Enable error indicators
      invalidFields.forEach((field) => {
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: field,
            toShowErr: true,
          },
        });
      });
    }

    // Handle if error occured for west virginia and utah payer state num min len not met
    if (err.status === MIN_LEN_FOR_WV_NOT_MET || err.status === MIN_LEN_FOR_UTAH_NOT_MET) {
      err.data.forEach((field: string) => {
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: field,
            toShowErr: true,
          },
        });
      });
    }

    // Handle if any invalidation occurs in the amount fields
    if (err === INVALID_AMOUNT) {
      // Display the modal
      displayErrorModal(
        modalDisplayTexts.topPanelHeading,
        invalidAmountWarning.warningMessage,
        dispatch
      );
      // Scroll page to top
      scrollToTop();
    }

    if (err.response && err.response.status === 400) {
      displayErrorModal(
        modalDisplayTexts.topPanelHeading,
        err.response?.data?.ErrorMessage,
        dispatch
      );
      dispatch({
        type: SET_TO_DISABLE_SCREEN,
        payload: false,
      });
      // Scroll page to top
      scrollToTop();
    }

    // *********************************** Handle invalid tin error start *******************************
    // ****** Payer block *******
    if (err === INVALID_PAYER_EIN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: INVALID_PAYER_EIN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === INVALID_PAYER_SSN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: INVALID_PAYER_SSN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === INVALID_PAYER_TIN_IN_ORDER) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: INVALID_PAYER_TIN_IN_ORDER,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === ALL_SAME_DIGIT_INVALID_PAYER_TIN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: ALL_SAME_DIGIT_INVALID_PAYER_TIN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    // ****** Recipient block *******
    if (err === INVALID_RECIPIENT_EIN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: INVALID_RECIPIENT_EIN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === INVALID_RECIPIENT_SSN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: INVALID_RECIPIENT_SSN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === INVALID_RECIPIENT_TIN_IN_ORDER) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: INVALID_RECIPIENT_TIN_IN_ORDER,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === ALL_ZERO_INVALID_PAYER_TIN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: ALL_ZERO_INVALID_PAYER_TIN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }

    if (err === ALL_ZERO_INVALID_RECIPIENT_TIN) {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: ALL_ZERO_INVALID_RECIPIENT_TIN,
          toShowErr: true,
        },
      });

      scrollToTop();
    }
    // *********************************** Handle invalid tin error end *******************************

    // Handle if validatorn error occurs in the tax amount
    if (err === INVALID_TAX_AMT) {
      const errorMessage = invalidTaxAmtWarning.warningMessage;
      const errorHeading = modalDisplayTexts.topPanelHeading;
      // Display error modal
      displayErrorModal(errorHeading, errorMessage, dispatch);
      // Scroll page to top
      scrollToTop();
    }

    if (err === INVALID_EVENT_CODE) {
      const errorMessage = invalidEventCode.warningMesssage;
      const errorHeading = modalDisplayTexts.topPanelHeading;
      // Display error modal
      displayErrorModal(errorHeading, errorMessage, dispatch);
      // Scroll page to top
      scrollToTop();
    }

    // Handle validation error in case of no form data
    if (err.status === NO_FORM_DATA_ERROR) {
      // Display the error modal
      const errorHeading = "Error!";
      const errorMessage = err.message;
      displayErrorModal(errorHeading, errorMessage, dispatch);

      // Scroll page to top
      scrollToTop();
    }

    // Handle error received from form 1099B extra validation
    if (err.status === PROPERTY_DESCRIPTION_ERROR) {
      // Display the error modal
      const errorHeading = "Error!";
      const errorMessage = err.message;
      displayErrorModal(errorHeading, errorMessage, dispatch);

      // Scroll page to top
      scrollToTop();
    }
    if (err.status === PROCEEDS_ERROR) {
      // Display the error modal
      const errorHeading = "Error!";
      const errorMessage = err.message;
      displayErrorModal(errorHeading, errorMessage, dispatch);

      // Scroll page to top
      scrollToTop();
    }

    // Handle error from 1099-DIV validation
    if (err.status === INVALID_AMT) {
      // Display the error modal
      const errorHeading = "Error!";
      const errorMessage = err.message;
      displayErrorModal(errorHeading, errorMessage, dispatch);

      // Scroll page to top
      scrollToTop();
    }

    if (err.response && err.response.status === 401) {
      // Handle if 401 error occurs
      // Handle any auth related error
      logger.error(err.response.data.Error);
      logger.error(`Error status --> ${err.response.status}`);

      const refresh_token = retrieveToken("refresh_token");
      getRefreshToken(refresh_token)
        .then((res) => {
          logger.log("New Access and Refresh token");
          logger.log(res);
          saveTokens(res.accessToken, res.refreshToken);
          // Submit the form again
          submitForm(data)
            .then((res) => {
              logger.log(res);
              // Save the Cart ID received from backend
              saveCartId(res.cart_id);
              // Enable the screen
              dispatch({
                type: SET_TO_DISABLE_SCREEN,
                payload: false,
              });
              // Handle popup display
              handlePopup(formSavedMsg, SUCCESS_POPUP, dispatch);
              if (submitType === SUBMIT_ADD_ANOTHER) {
                // Handle form submission and fill again functionality
                handleFormSubmitAndFillAgain(
                  data,
                  handleClearingFormData,
                  dispatch,
                  handleClearingAmountDisplay
                );
              }
            })
            .catch((err) => {
              // Enable the screen
              dispatch({
                type: SET_TO_DISABLE_SCREEN,
                payload: false,
              });
              // Log out the user
              logger.error(err);
              logoutUser(dispatch);

              // Custom GA4 event
              return ga4.sendEvent({
                category: ga4Const.event.category["e-filing"],
                action: `${ga4Const.event.action.formSubmissionFail} (${data.form_type} for ${data.year})`,
              });
            });
        })
        .catch((err) => {
          // Enable the screen
          dispatch({
            type: SET_TO_DISABLE_SCREEN,
            payload: false,
          });
          logger.log(err.response.data.Error);
          logoutUser(dispatch);

          // Custom GA4 event
          return ga4.sendEvent({
            category: ga4Const.event.category["e-filing"],
            action: `${ga4Const.event.action.formSubmissionFail} (${data.form_type} for ${data.year})`,
          });
        });
    }
    // Custom GA4  event
    return ga4.sendEvent({
      category: ga4Const.event.category["e-filing"],
      action: `${ga4Const.event.action.formDataInvalid} (${data.form_type} for ${data.year})`,
    });
  }
}
