import React from "react";
import { IoTicket } from "react-icons/io5";

export default function TicketIcon() {
  return (
    <span>
      <IoTicket className="text-xl" />
    </span>
  );
}
