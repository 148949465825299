import React, { ChangeEvent, useEffect } from "react";
import "../../styles/forms/Form.css";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import { g1099AmountDisplayTexts, stateInfoDisplayTexts } from "../../lib/languagePacks/en-us";
import { useSelector } from "react-redux";
import { useState } from "react";
import logger from "../../logger";
import { useDispatch } from "react-redux";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import StateInfo from "./GlobalFormComponents/StateInfo";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_4,
  AMOUNT_5,
  AMOUNT_6,
  AMOUNT_7,
  AMOUNT_9,
  TAX_YEAR_OF_REFUND,
  TRADE_OR_BUSINESS_INDICATOR,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  G_1099,
  PAGE_E_FILE,
  SUBMIT_ADD_ANOTHER,
} from "../../lib/constants";
import SubmitButton from "../SubmitButton/SubmitButton";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import { useNavigate } from "react-router-dom";
import UpdateButton from "../UpdateButton/UpdateButton";
import { isValidAmount } from "./Form1099-B";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import devLogInstance from "../../lib/loggerConfig";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import { useRecTinUnknown } from "../../lib/customHooks";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";

export default function Form1099G({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    // amount3: "",
    amount4: "",
    amount5: "",
    amount6: "",
    amount7: "",
    amount9: "",
  }); // for displaying on the input field

  const [miscFields, setMiscFields] = useState({
    tax_year_of_refund: 0,
    trade_or_business_indicator: false,
  });

  const { recTinUnknown } = useRecTinUnknown();

  // Set the amount values
  useEffect(() => {
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      // amount3: formData.payment_amount3
      //   ? handleAmountFormat((formData.payment_amount3 / 100).toString())
      //   : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount6: formData.payment_amount6
        ? handleAmountFormat((formData.payment_amount6 / 100).toString())
        : "",
      amount7: formData.payment_amount7
        ? handleAmountFormat((formData.payment_amount7 / 100).toString())
        : "",
      amount9: formData.payment_amount9
        ? handleAmountFormat((formData.payment_amount9 / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      trade_or_business_indicator: formData?.trade_or_business_indicator || false,
      tax_year_of_refund: formData?.tax_year_of_refund ? formData?.tax_year_of_refund : 0,
    });

    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        // amount3: "",
        amount4: "",
        amount5: "",
        amount6: "",
        amount7: "",
        amount9: "",
      });
      setMiscFields({
        trade_or_business_indicator: false,
        tax_year_of_refund: 0,
      });
    };
  }, [
    formData?.payment_amount1,
    formData?.payment_amount2,
    formData?.payment_amount4,
    formData?.payment_amount5,
    formData?.payment_amount6,
    formData?.payment_amount7,
    formData?.payment_amount9,
    formData?.trade_or_business_indicator,
    formData?.tax_year_of_refund,
  ]);

  // const handleMiscFieldChange = (e: any) => {
  //   const { id: fieldType, value } = e.target;
  //   switch (fieldType) {
  //     case BOX2_TRADE_OR_BUSINESS_INDICATOR:
  //       const letterRegex = new RegExp("^[a-zA-Z]*$"); // Allow chars but not special chars
  //       devLogInstance.log(`Foreign country val ---> ${value}`);
  //       const isValid = value.length > 0 ? letterRegex.test(value) : true;
  //       devLogInstance.log(isValid);
  //       isValid &&
  //         value.length <= 40 &&
  //         setMiscFields({
  //           ...miscFields,
  //           box2_trade_or_business: false,
  //         });
  //       break;
  //     case BOX2_AMOUNT_FOR_TAX_YEAR:
  //       const leRegex = new RegExp("^[a-zA-Z]*$"); // Allow chars but not special chars
  //       devLogInstance.log(`Foreign country val ---> ${value}`);
  //       const isVal = value.length > 0 ? leRegex.test(value) : true;
  //       devLogInstance.log(isVal);
  //       isVal &&
  //         value.length <= 40 &&
  //         setMiscFields({
  //           ...miscFields,
  //           box2_amount_for_tax_year: value,
  //         });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleMiscFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.currentTarget;

    switch (id) {
      case TRADE_OR_BUSINESS_INDICATOR:
        setMiscFields({
          ...miscFields,
          trade_or_business_indicator: !miscFields.trade_or_business_indicator, // check and uncheck
        });
        break;

      case TAX_YEAR_OF_REFUND:
        value.length <= 40 &&
          setMiscFields({
            ...miscFields,
            tax_year_of_refund: parseInt(value),
          });
        break;

      default:
        break;
    }
  };

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      // case AMOUNT_3:
      //   isValidAmount(formattedAmount) &&
      //     setAmount({
      //       ...amount,
      //       amount3: formattedAmount,
      //     });
      //   break;

      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_6:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount6: formattedAmount,
          });
        break;
      case AMOUNT_7:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount7: formattedAmount,
          });
        break;
      case AMOUNT_9:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount9: formattedAmount,
          });
        break;

      // case "direct-sales":
      //   setFormData({
      //     ...formData,
      //     direct_sales_indicator: !formData.direct_sales_indicator,
      //   });
      //   break;

      default:
        break;
    }
  };

  // Handle form submit

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;
    // devLogInstance.log(`Clicked on ---> ${submitType}`);

    devLogInstance.log(miscFields.tax_year_of_refund);
    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      // trade_or_business_indicator: miscFields.trade_or_business_indicator,
      // tax_year_of_refund: miscFields.tax_year_of_refund,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      // payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
      payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
      payment_amount6: amount.amount6 ? parseFloat(amount.amount6.replace(/,/gi, "")) : 0,
      payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,
      payment_amount9: amount.amount9 ? parseFloat(amount.amount9.replace(/,/gi, "")) : 0,

      // Misc fields
      ...miscFields,
    };
    logger.log(data);

    const incomeAmounts = [
      data.payment_amount1,
      data.payment_amount2,
      data.payment_amount5,
      data.payment_amount6,
      data.payment_amount7,
      data.payment_amount9,
    ];

    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [
            data.payment_amount1,
            data.payment_amount2,
            data.payment_amount4,
            data.payment_amount5,
            data.payment_amount6,
            data.payment_amount7,
            data.payment_amount9,
          ],
          data.payment_amount4,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                trade_or_business_indicator: false,
                tax_year_of_refund: 0,
              });
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount globalFormData={formData} setGlobalFormData={setFormData} />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={g1099AmountDisplayTexts.unemploymentCompensation}
                      amountNum="1"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={g1099AmountDisplayTexts.stateOrLocalIncomeTaxRefunds}
                      amountNum="2"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail formType={formType} taxYear={taxYear} formName={G_1099} />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={g1099AmountDisplayTexts.box2Amount} amountNum="3" />
                  <input
                    type="number"
                    id={TAX_YEAR_OF_REFUND}
                    value={miscFields.tax_year_of_refund ? miscFields.tax_year_of_refund : ""}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={g1099AmountDisplayTexts.federalIncomeTaxWithheld}
                    amountNum="4"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={g1099AmountDisplayTexts.rtaaPayments} amountNum="5" />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={g1099AmountDisplayTexts.taxableGrants} amountNum="6" />
                  <Amount
                    type="text"
                    id={AMOUNT_6}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount6}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={g1099AmountDisplayTexts.agriculturePayments}
                    amountNum="7"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_7}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount7}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <div className="flex items-center gap-2">
                    <AmountHeading
                      heading={g1099AmountDisplayTexts.checkIfBox2IsTrade}
                      amountNum="8"
                    />
                    <input
                      type="checkbox"
                      id={TRADE_OR_BUSINESS_INDICATOR}
                      checked={formData.trade_or_business_indicator}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={g1099AmountDisplayTexts.marketGain} amountNum="9" />
                  <Amount
                    type="text"
                    id={AMOUNT_9}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount9}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                ></AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.g1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.g1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.g1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.g1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.g1099.stateNoLabel}
          stateIncomeLabel={""}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          formType={formType}
          isCorrection={isCorrection}
        />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
