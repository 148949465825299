import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";

export default class Review1099SModifier extends ReviewCopyModifier {
  private accountNumber: string;

  private amount2: number;
  private amount5: number;

  private address_or_legal_description: string;
  private dateOfClosing: string;
  private property_or_services_indicator: boolean;
  private foreign_transferor: boolean;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    amount2: number,
    amount5: number,
    dateOfClosing: string,
    address_or_legal_description: string,
    property_or_services_indicator: boolean,
    foreign_transferor: boolean
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;

    this.amount2 = amount2;
    this.amount5 = amount5;

    this.dateOfClosing = dateOfClosing;
    this.address_or_legal_description = address_or_legal_description;
    this.property_or_services_indicator = property_or_services_indicator;
    this.foreign_transferor = foreign_transferor;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // *** RIGHT SIDE *** //

      this.drawCalendarYearPartly();

      // Date of closing
      const dateOfClosingPos = {
        x: this.getLeftSideXCoord() + 133 * 2,
        y: height - 53 - this.getFontSize(),
      };
      firstPage.drawText(
        this.dateOfClosing.substring(4, 6) +
          "/" +
          this.dateOfClosing.substring(6) +
          "/" +
          this.dateOfClosing.substring(0, 4),
        {
          x: dateOfClosingPos.x,
          y: dateOfClosingPos.y,
          ...options,
        }
      );

      // Gross proceeds (amount 2)
      const amount2Pos = {
        x: dateOfClosingPos.x - 5,
        y: dateOfClosingPos.y - 44,
      };
      let formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Address or legal description (Box 3)
      const box3Pos = {
        x: amount2Pos.x - 5,
        y: amount2Pos.y - 24,
      };
      firstPage.drawText(this.address_or_legal_description, {
        x: box3Pos.x,
        y: box3Pos.y,
        ...options,
      });

      // Property or services indicator (Box 4)
      const box4Pos = {
        x: box3Pos.x + 179,
        y: box3Pos.y - 45,
      };
      if (this.property_or_services_indicator) {
        this.getReviewCopy().registerFontkit(fontkit);
        const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
        const fontBuffer = await tickMarkFontRes.arrayBuffer();
        const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
        firstPage.drawText("z", {
          x: box4Pos.x,
          y: box4Pos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Foreign transferor indicator (Box 5)
      const box5Pos = {
        x: box4Pos.x,
        y: box4Pos.y - 33,
      };
      if (this.foreign_transferor) {
        this.getReviewCopy().registerFontkit(fontkit);
        const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
        const fontBuffer = await tickMarkFontRes.arrayBuffer();
        const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
        firstPage.drawText("z", {
          x: box5Pos.x,
          y: box5Pos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Buyer’s part of real estate tax (amount 5)
      const amount5Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 33 * 4,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: amount5Pos.y,
        ...options,
      });

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
