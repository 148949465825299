import React from "react";

interface Props {
  selectedList: string;
  selectList: React.Dispatch<React.SetStateAction<string>>;
}

export default function SidePanelListOptions({ selectedList, selectList }: Props) {
  return (
    <div className="list-options px-6 pt-6">
      <div className="flex items-center gap-4 py-3 border-b-2 border-gray-400">
        <span
          onClick={() => selectList("tools")}
          className={`font-bold cursor-pointer ${!selectedList.includes("tools") && "opacity-40"}`}
        >
          Tools
        </span>
        <span
          onClick={() => selectList("assigned-tickets")}
          className={`font-bold cursor-pointer ${
            !selectedList.includes("assigned-tickets") && "opacity-40"
          }`}
        >
          Assigned tickets
        </span>
      </div>
    </div>
  );
}
