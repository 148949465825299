import React, { useEffect, useState } from "react";
import { IoFilterSharp } from "react-icons/io5";
import devLogInstance from "../lib/loggerConfig";

interface Props extends React.HTMLProps<HTMLElement> {
  filterOptionsWindow: React.ReactNode;
}

export default function FilterComponent({ className, filterOptionsWindow }: Props) {
  const [openFilterPopupWindow, toOpenFilterPopupWindow] = useState(false);

  // Handle any click on the filter icon
  useEffect(() => {
    const handleClick = (e: any) => {
      let id: string = "";
      let currentElement = e.target;

      // Drill up the elements to look for an id
      while (!id) {
        id = currentElement.id;
        currentElement = currentElement.parentElement; // The parent element will become the current element on each iteration
        devLogInstance.log(id);
      }

      if (id.includes("filter-icon")) toOpenFilterPopupWindow((prevState) => !prevState); // Toggle
      else if (id.includes("filter-component"))
        toOpenFilterPopupWindow(true); // Keep the popup window open
      else toOpenFilterPopupWindow(false); // Close the filter options popup window
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  return (
    <div id="filter-component" className="w-fit">
      <IoFilterSharp id="filter-icon" className={`${className}`} />
      {openFilterPopupWindow && filterOptionsWindow}
    </div>
  );
}
