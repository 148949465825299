import React from "react";
import Navbar from "../components/navbar";
import TaxEveImg from "../components/TaxEveImg";
import LoginForm from "../components/LoginForm";
import { useLayoutEffect, useState } from "react";
import EmailVerification from "../components/EmailVerification";
import { useDispatch } from "react-redux";
import "../styles/LoginRegisterPage.css";
import { ERROR_POPUP, LOGIN_PAGE } from "../lib/constants";
import checkAuth from "../lib/authRequests/checkAuth";
import { useLocation } from "react-router-dom";
import saveTokens from "../lib/authRequests/saveTokens";
import devLogInstance from "../lib/loggerConfig";
import { SET_IS_AUTHENTICATED } from "../redux/actions/action.types";
import saveUserDetails from "../lib/SaveData/saveUserDetails";
import handlePopup from "../lib/popup/handlePopup";

export default function Login() {
  const [responseStatus, setResponseStatus] = useState<number | null>(null);
  const [input, setInput] = useState({
    email: "",
    name: "",
    password: "",
    phoneNo: "",
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const accessToken = new URLSearchParams(location.search).get("access_token");
  const refreshToken = new URLSearchParams(location.search).get("refresh_token");
  const userDetails = new URLSearchParams(location.search).get("user_details");
  const isEmailVerified = new URLSearchParams(location.search).get("is_verified");

  const loginError = new URLSearchParams(location.search).get("login_error");

  // This is for handling third party authentication
  // Set the access and refresh token if it is present in the url param
  // and authenticate the user
  useLayoutEffect(() => {
    if (accessToken && refreshToken && userDetails && isEmailVerified) {
      const userDetails_ = JSON.parse(userDetails); // Parse the user details

      if (isEmailVerified === "0") {
        setInput((prevState) => {
          return {
            ...prevState,
            email: userDetails_.name,
          };
        });
        return setResponseStatus(401); // No need to execute any code below once a 401 response status is set
      }

      // Save user details locally
      saveUserDetails(userDetails_);

      saveTokens(accessToken, refreshToken);
      devLogInstance.log("tokens saved");
      dispatch({
        type: SET_IS_AUTHENTICATED,
        payload: true,
      });
    } else if (loginError === "1") {
      handlePopup("Failed to login!", ERROR_POPUP, dispatch);
    }
  }, [accessToken, refreshToken, userDetails, loginError, isEmailVerified, dispatch]);

  useLayoutEffect(() => {
    checkAuth(dispatch);
  }, [dispatch]);
  return (
    <main>
      <Navbar page={LOGIN_PAGE} />
      <div className="min-h-[100vh] py-20 px-4 lg:px-16 flex flex-col justify-center">
        <section className="flex justify-between mt-12">
          <TaxEveImg />
          {responseStatus === 401 ? (
            <EmailVerification responseType="emailActivation" userEmail={input.email} />
          ) : (
            <LoginForm input={input} setInput={setInput} responseStatus={setResponseStatus} />
          )}
        </section>
      </div>
    </main>
  );
}
