import React from "react";
import { FiDollarSign } from "react-icons/fi";
import handleAmountFormat from "../../../../../../lib/form/utils/handleAmountFormat";
import { useSelector } from "react-redux";
import Indicator from "../../../../../Indicator";
import {
  LOCALITY_1,
  LOCALITY_2,
  LOCAL_INCOME_1,
  LOCAL_INCOME_2,
  LOCAL_TAX_1,
  LOCAL_TAX_2,
} from "../../../../../../lib/constants";
import { errorInLocalFilingDetails } from "../../../../../../lib/languagePacks/en-us";

interface Props {
  taxWithheldLabel: string;
  nameOfLocalityLabel: string;
  localDistrLabel: string;
  id: string;
  isStateProvided: boolean;

  localtax: number;
  locality: string;
  localIncome: number;

  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function LocalFilingDetails({
  taxWithheldLabel,
  nameOfLocalityLabel,
  localDistrLabel,
  id,
  isStateProvided,
  localtax,
  locality,
  localIncome,
  handleChange,
}: Props) {
  const showValidationError = useSelector((state: any) => state.showValidationErr);

  return (
    <div className="state-details-container">
      <div className="state-tax-withheld-container" style={{ width: "100%" }}>
        <div className="state-tax-input-wrapper">
          <label htmlFor="state-tax">
            <h4>{taxWithheldLabel}</h4>
          </label>
          <div className="amount-input-container" id={id}>
            <div className="dollar-sign">
              <FiDollarSign />
            </div>
            <input
              className="payer-details-input amount-input"
              type="text"
              id={`local-tax-${id}`}
              placeholder="0.00"
              value={localtax > 0 ? handleAmountFormat((localtax / 100).toString()) : ""}
              onChange={handleChange}
              autoComplete="off"
              disabled={!isStateProvided}
            />
          </div>
        </div>
        {id === "1" && showValidationError.onLocalTax1 && (
          <Indicator type={LOCAL_TAX_1} message={errorInLocalFilingDetails.invalidLocalTax} />
        )}
        {id === "2" && showValidationError.onLocalTax2 && (
          <Indicator type={LOCAL_TAX_2} message={errorInLocalFilingDetails.invalidLocalTax} />
        )}
      </div>
      <div
        className="state-stateNo-container"
        style={{
          width: "100%",
        }}
      >
        <div className="state-number-container">
          <div className="state-number-input-wrapper" id={id}>
            <label htmlFor="state-number">
              <h4>{nameOfLocalityLabel}</h4>
            </label>
            <input
              className="payer-details-input"
              type="text"
              id={`locality-${id}`}
              value={locality}
              onChange={handleChange}
              autoComplete="off"
              disabled={!isStateProvided}
            />
          </div>
          {id === "1" && showValidationError.onLocality1 && (
            <Indicator type={LOCALITY_1} message={errorInLocalFilingDetails.invalidLocalAreaName} />
          )}
          {id === "2" && showValidationError.onLocality2 && (
            <Indicator type={LOCALITY_2} message={errorInLocalFilingDetails.invalidLocalAreaName} />
          )}
        </div>
      </div>
      <div className="state-income-container" style={{ width: "100%" }}>
        <div className="state-income-wrapper">
          <label htmlFor="state-income">
            <h4>{localDistrLabel}</h4>
          </label>
          <div className="amount-input-container" id={id}>
            <div className="dollar-sign">
              <FiDollarSign />
            </div>
            <input
              className="payer-details-input amount-input"
              type="text"
              id={`local-income-${id}`}
              placeholder="0.00"
              value={localIncome > 0 ? handleAmountFormat((localIncome / 100).toString()) : ""}
              onChange={handleChange}
              autoComplete="off"
              disabled={!isStateProvided}
            />
          </div>
        </div>
        {id === "1" && showValidationError.onLocalIncome1 && (
          <Indicator type={LOCAL_INCOME_1} message={errorInLocalFilingDetails.invalidLocalIncome} />
        )}
        {id === "2" && showValidationError.onLocalIncome2 && (
          <Indicator type={LOCAL_INCOME_2} message={errorInLocalFilingDetails.invalidLocalIncome} />
        )}
      </div>
    </div>
  );
}
