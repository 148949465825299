import React from "react";
import "../styles/Indicator.css";
import {
  FA_WARNING_INDICATOR,
  AL_WARNING_INDICATOR,
  PAYER_NAME,
  PAYER_TIN,
  PAYER_ADDRESS_1,
  PAYER_CITY,
  PAYER_STATE,
  PAYER_ZIPCODE,
  RECIPIENT_NAME,
  RECIPIENT_TIN,
  RECIPIENT_ADDRESS_1,
  RECIPIENT_CITY,
  RECIPIENT_STATE,
  RECIPIENT_ZIPCODE,
  PAYER_COUNTRY,
  RECIPIENT_COUNTRY,
  PAYER_PHONE_NUMBER,
  STATE_INCOME_1,
  STATE_INCOME_2,
  STATE_TAX_1,
  STATE_TAX_2,
  STATE_NUMBER_1,
  STATE_NUMBER_2,
  STATE_1,
  STATE_2,
  MERCHANT_CATEGORY_CODE,
  FILER_PAYMENT_TYPE,
  NUMBER_OF_PAYMENT_TRANSAC,
  FILER_TYPE,
  MIN_LEN_FOR_WV_NOT_MET,
  MIN_LEN_FOR_UTAH_NOT_MET,
  NUMBER_OF_W2_FILED,
  TOTAL_TAX_WITHHELD_FOR_W2,
  LOCAL_TAX_1,
  LOCALITY_1,
  LOCAL_INCOME_1,
  LOCAL_TAX_2,
  LOCALITY_2,
  LOCAL_INCOME_2,
} from "../lib/constants";

interface Props {
  type?: string;
  message: string | React.ReactNode;
}

export default function Indicator({ type, message }: Props) {
  return (
    <div className="indicator-container-main">
      {type === "email" && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === "name" && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === "phone" && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === "password" && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === FA_WARNING_INDICATOR && (
        <div style={{ fontSize: "0.85rem" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === AL_WARNING_INDICATOR && (
        <div style={{ fontSize: "0.85rem" }}>
          <span>*</span> {message}
        </div>
      )}

      {type === PAYER_NAME && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_TIN && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_ADDRESS_1 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_CITY && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_STATE && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_ZIPCODE && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_COUNTRY && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === PAYER_PHONE_NUMBER && (
        <div style={{ fontSize: "0.85rem" }}>
          <span>*</span> {message}
        </div>
      )}

      {type === RECIPIENT_NAME && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === RECIPIENT_TIN && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === RECIPIENT_ADDRESS_1 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === RECIPIENT_CITY && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === RECIPIENT_STATE && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === RECIPIENT_ZIPCODE && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === RECIPIENT_COUNTRY && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_INCOME_1 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_INCOME_2 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_TAX_1 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_TAX_2 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_NUMBER_1 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_NUMBER_2 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_1 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === STATE_2 && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === MIN_LEN_FOR_WV_NOT_MET && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === MIN_LEN_FOR_UTAH_NOT_MET && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === MERCHANT_CATEGORY_CODE && (
        <div>
          <span>*</span> {message}
        </div>
      )}
      {type === FILER_PAYMENT_TYPE && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === FILER_TYPE && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === NUMBER_OF_PAYMENT_TRANSAC && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === NUMBER_OF_W2_FILED && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === TOTAL_TAX_WITHHELD_FOR_W2 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === "staff-name" && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === "staff-email" && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === "staff-phone" && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === "group-name" && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {/* Local filing related */}
      {type === LOCAL_TAX_1 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === LOCALITY_1 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === LOCAL_INCOME_1 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === LOCAL_TAX_2 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === LOCALITY_2 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
      {type === LOCAL_INCOME_2 && (
        <div style={{ fontWeight: "normal" }}>
          <span>*</span> {message}
        </div>
      )}
    </div>
  );
}
