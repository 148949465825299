import React from "react";

export default function UnknownTinInfoBody() {
  return (
    <div className="p-6">
      <h2>
        If TIN is unknown a default TIN:- 000000000, will be set and transmitted to IRS. You can
        later update the TIN and file for a correction.
      </h2>
    </div>
  );
}
