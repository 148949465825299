import React, { useContext } from "react";
import { useState } from "react";
import { useDisableBtn } from "../lib/customHooks";
import { loginFormText, indicatorTexts } from "../lib/languagePacks/en-us";
import { InputValidator } from "../lib/validators";
import Indicator from "./Indicator";
import loginUser from "../lib/authRequests/loginUser";
import { SHA256 } from "crypto-js";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import logger from "../logger";
import { SET_IS_AUTHENTICATED } from "../redux/actions/action.types";
import saveTokens from "../lib/authRequests/saveTokens";
import "../styles/LoginRegisterPage.css";
import saveUserDetails from "../lib/SaveData/saveUserDetails";
import handlePopup from "../lib/popup/handlePopup";
import { ERROR_POPUP } from "../lib/constants";
import devLogInstance from "../lib/loggerConfig";
import { PASSWORD_RESET } from "../lib/routes";
import { StaffAccountsContext, ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import ThirdPartyLogin from "./ThirdPartyLogin";

export default function LoginForm({ input, setInput, responseStatus }: any) {
  const [isShowPass, setIsShowPass] = useState(false);
  const [toDisableBtn, setToDisableBtn] = useDisableBtn(false);
  const [validInputs, setValidInputs] = useState({
    email: null,
    password: null,
  });

  const staffAccountsCtx = useContext(StaffAccountsContext);

  if (!staffAccountsCtx) throw new Error("Staff Accounts Context is null!");

  const { setTotalStaffAccounts } = staffAccountsCtx;

  // const style = useSelector((state: any) => state.themeChange);
  const authDispatch = useDispatch();
  const dispatch = useDispatch();
  const handlePassDisplay = () => {
    setIsShowPass(!isShowPass);
  };
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    switch (id) {
      case "email":
        const isValidEmail = new InputValidator(value, null, null, null).validateEmail();
        isValidEmail &&
          setValidInputs((prevState: any) => {
            return {
              ...prevState,
              email: true,
            };
          });
        setInput((prevState: any) => {
          return {
            ...prevState,
            email: value,
          };
        });
        break;

      case "password":
        const isValidPasswordInput = new InputValidator(null, null, null, value).validatePassword();
        isValidPasswordInput &&
          setValidInputs((prevState: any) => {
            return {
              ...prevState,
              password: true,
            };
          });
        setInput((prevState: any) => {
          return {
            ...prevState,
            password: value,
          };
        });
        break;

      default:
        break;
    }
  };

  // handles login
  const handleLogin = async (e: any) => {
    e.preventDefault();
    const isValidEmail = new InputValidator(input.email, null, null, null).validateEmail();
    const isValidPasswordInput = new InputValidator(
      null,
      null,
      null,
      input.password
    ).validatePassword();
    !isValidEmail &&
      setValidInputs((prevState: any) => {
        return {
          ...prevState,
          email: false,
        };
      });
    !isValidPasswordInput &&
      setValidInputs((prevState: any) => {
        return {
          ...prevState,
          password: false,
        };
      });
    if (input.email && input.password) {
      setToDisableBtn(true);
      const encryptedPass = SHA256(process.env.REACT_APP_NONCE + input.password).toString();
      logger.log(encryptedPass);
      try {
        const data = await loginUser(input.email, encryptedPass);

        // Send GA4 analytics event
        ga4.sendEvent({
          category: ga4Const.event.category.auth,
          action: ga4Const.event.action.login,
          label: "User Login",
        });

        logger.log(data);
        // Save user details locally
        saveUserDetails({
          ...data.userDetails,
          showFeedbackWindow: data.showFeedbackWindow,
        });

        // Update the total staff accounts state
        setTotalStaffAccounts(data.totalStaffAccounts);

        saveTokens(data.accessToken, data.refreshToken);
        devLogInstance.log("tokens saved");
        authDispatch({
          type: SET_IS_AUTHENTICATED,
          payload: true,
        });
        setToDisableBtn(false);
      } catch (err: any) {
        // Send GA4 analytics event
        ga4.sendEvent({
          category: ga4Const.event.category.auth,
          action: ga4Const.event.action.loginFailure,
          label: "User Login",
        });

        const errStatus = err.response.status;
        if (errStatus !== 401) {
          const message = err.response.data.Error;
          // Handle popup display
          handlePopup(message, ERROR_POPUP, dispatch);
          setToDisableBtn(false);
        } else if (errStatus === 401) {
          responseStatus(errStatus);
        }
      }
    }
  };
  return (
    <div className="login-form-container w-[100%] sm:w-[50%] sm:px-10">
      <div className="login-title">
        <h1>{loginFormText.formTitle}</h1>
      </div>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="email-input-container-wrapper">
          <div className="email-input-container">
            <input
              type="email"
              placeholder={`${loginFormText.emailPlaceholder} *`}
              id="email"
              value={input.email}
              onChange={handleChange}
            />
            <div className="input-logo">
              <i className="fa-solid fa-envelope" />
            </div>
          </div>
          {validInputs.email === false && (
            <Indicator type="email" message={indicatorTexts.emailRequired} />
          )}
        </div>

        <div className="pass-input-container-wrapper">
          <div className="pass-input-container">
            <input
              type={isShowPass ? "text" : "password"}
              placeholder={`${loginFormText.passPlaceholder} *`}
              id="password"
              value={input.password}
              onChange={handleChange}
            />
            <div className="display-pass-container" onClick={handlePassDisplay}>
              {isShowPass ? (
                <i className="fa-solid fa-eye" />
              ) : (
                <i className="fa-solid fa-eye-slash" />
              )}
            </div>
          </div>
          {validInputs.password === false && (
            <Indicator type="password" message={indicatorTexts.passwordRequired} />
          )}
        </div>

        <div className="login-btn-wrapper">
          <button
            disabled={toDisableBtn}
            className={`login-btn bg-taxeve-primary-violet text-white ${
              toDisableBtn && "opacity-50"
            }`}
          >
            {loginFormText.loginText}
          </button>
        </div>

        <div className="forgot-password-container">
          <Link to={PASSWORD_RESET} className="underline underline-offset-2">
            {loginFormText.forgetPassText}
          </Link>
        </div>
        <div className="register-redirect-container">
          {loginFormText.redirectInfo}{" "}
          <span>
            <Link to="/register" className="text-taxeve-primary-blue">
              {loginFormText.redirectText}
            </Link>
          </span>
        </div>

        <div className="py-2">
          <div className="relative py-4">
            <p className="absolute top-1 left-[39%] bg-white px-4">Or Sign In with</p>
            <hr className="border-slate-300" />
          </div>
          <ThirdPartyLogin />
          {/* <div className="intuit-login">
            <Link
              to={`${process.env.REACT_APP_API_ENDPOINT}/auth/intuit`}
              className="flex flex-col items-center justify-center"
            >
              <p className="text-white text-lg py-3 px-6 bg-blue-500 rounded-md hover:opacity-80">
                Sign in with Intuit
              </p>
            </Link>
          </div> */}
        </div>
      </form>
    </div>
  );
}
