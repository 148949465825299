import { UseQueryResult, useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import getRaisedTickets from "../../../lib/Handlers/Queries/GetRaisedTickets.helper";
import loader from "../../decorations/Loader";
import { useSelector } from "react-redux";
import { RaisedTicketQueryData, RaisedTicketsQueryResponse } from "../../../../interfaces/main";
import RaisedTicketList from "./RaisedTicketList";
import { TicketFilterCtx } from "../../AdminRelatedComponents/SupportTicketRelated/AssignedTicketList";
import FilterComponent from "../../FilterComponent";
import TicketFilterWindow from "../TicketFilterWindow";
import getCachedStatusFilterOptions from "../../../lib/utils/Helpers/GetCachedStatusFilterOptions";
import SearchBar from "../../SearchBar";

export default function TrackComplaintsWindow() {
  const style = useSelector((state: any) => state.themeChange);
  const [raisedTickets, setRaisedTickets] = useState<RaisedTicketQueryData[]>([]); // Declare a seperate state for raised tickets
  // for filtering purposes

  const raisedTicketsQuery: UseQueryResult<RaisedTicketsQueryResponse, Error> = useQuery({
    queryKey: ["GetRaisedTickets"],
    queryFn: () => getRaisedTickets(),
    refetchOnWindowFocus: false,
  });

  // Handle filtering tickets by status
  const filterTicketsByStatus = useCallback(
    (statusOptions: string[]) => {
      const allTickets = raisedTicketsQuery.data?.tickets || [];
      if (statusOptions.length <= 0) return setRaisedTickets([...allTickets]);

      const filteredTickets = allTickets.filter((ticketObj) =>
        statusOptions.includes(ticketObj.ticket_status)
      );
      setRaisedTickets([...filteredTickets]);
    },
    [raisedTicketsQuery.data]
  );

  // Handle filtering the raised tickets data set (state) by pulling from the cache on component mount
  useEffect(() => {
    filterTicketsByStatus(getCachedStatusFilterOptions(true));

    return () => setRaisedTickets([]);
  }, [filterTicketsByStatus]);

  // Handle search
  const handleSearch = (searchText: string) => {
    if (!searchText) return filterTicketsByStatus(getCachedStatusFilterOptions(true)); // Return the filtered by status state if no search param is provided

    const allTickets = raisedTicketsQuery.data?.tickets ?? [];

    const filteredTickets = allTickets.filter((ticketObj) => ticketObj._id.includes(searchText));
    setRaisedTickets([...filteredTickets]);
  };

  if (raisedTicketsQuery.isError) return <pre>There was an error fetching the raised tickets</pre>;

  if (raisedTicketsQuery.isLoading)
    return (
      <div className="h-full">
        {loader.start(style, true, { overrideClass: "h-full flex items-center justify-center" })}
      </div>
    );

  return (
    <div className="h-full">
      <section className="tickets h-full">
        <header className="p-2 border-b border-gray-300">
          <h2>Tickets ({raisedTicketsQuery.data?.tickets.length})</h2>
        </header>
        <div className="actions-panel p-2 flex items-center justify-between">
          <TicketFilterCtx.Provider value={{ handleFilterByStatus: filterTicketsByStatus }}>
            <div>
              <FilterComponent
                className="text-lg cursor-pointer"
                filterOptionsWindow={<TicketFilterWindow isRegularUser />}
              />
            </div>
          </TicketFilterCtx.Provider>

          {/* Search bar */}
          <SearchBar placeholder="Type to search by ticket id" handleSearch={handleSearch} />
        </div>
        {/* List of tickets */}
        <RaisedTicketList tickets={raisedTickets} />
      </section>
    </div>
  );
}
