import React from "react";
import { RaisedTicketQueryData } from "../../../../interfaces/main";
import RaisedTicketListItem from "./RaisedTicketListItem";

interface Props {
  tickets: RaisedTicketQueryData[];
}

export default function RaisedTicketList({ tickets }: Props) {
  return (
    <ul className="p-2 h-[85%] overflow-auto">
      {tickets.map((ticketObj, i) => (
        <RaisedTicketListItem
          key={ticketObj._id}
          id={ticketObj._id}
          query={ticketObj.query}
          status={ticketObj.ticket_status}
          creationDateStr={ticketObj.createdAt}
          className={`${i >= tickets.length - 1 ? "border-none" : "border-b border-gray-300"}`}
        />
      ))}
    </ul>
  );
}
