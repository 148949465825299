export default class FormatDate {
  private unformattedDate: string;

  constructor(unformattedDate: string) {
    this.unformattedDate = unformattedDate;
  }

  public format(): string {
    return (
      this.unformattedDate.substring(4, 6) +
      "/" +
      this.unformattedDate.substring(6) +
      "/" +
      this.unformattedDate.substring(0, 4)
    );
  }
}
