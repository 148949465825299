import React, { ChangeEvent, useEffect } from "react";
import "../../styles/forms/Form.css";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import { patr1099AmountDisplayTexts } from "../../lib/languagePacks/en-us";
import { useSelector } from "react-redux";
import { useState } from "react";
import logger from "../../logger";
import { useDispatch } from "react-redux";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_3,
  AMOUNT_4,
  AMOUNT_5,
  AMOUNT_6,
  AMOUNT_7,
  AMOUNT_8,
  AMOUNT_A,
  AMOUNT_B,
  AMOUNT_C,
  AMOUNT_D,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  PAGE_E_FILE,
  PATR_1099,
  SPECIFIED_COOP,
  SUBMIT_ADD_ANOTHER,
} from "../../lib/constants";
import SubmitButton from "../SubmitButton/SubmitButton";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import { useNavigate } from "react-router-dom";
import UpdateButton from "../UpdateButton/UpdateButton";
import { isValidAmount } from "./Form1099-B";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import devLogInstance from "../../lib/loggerConfig";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import { useRecTinUnknown } from "../../lib/customHooks";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";

export default function Form1099PATR({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount3: "",
    amount4: "",
    amount5: "",
    amount6: "",
    amount7: "",
    amount8: "",
    amountA: "",
    amountB: "",
    amountC: "",
    amountD: "",
  }); // for displaying on the input field

  const [miscFields, setMiscFields] = useState({
    specified_coop: false,
  });

  const { recTinUnknown } = useRecTinUnknown();

  // Set the amount values
  useEffect(() => {
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount6: formData.payment_amount6
        ? handleAmountFormat((formData.payment_amount6 / 100).toString())
        : "",
      amount7: formData.payment_amount7
        ? handleAmountFormat((formData.payment_amount7 / 100).toString())
        : "",
      amount8: formData.payment_amount8
        ? handleAmountFormat((formData.payment_amount8 / 100).toString())
        : "",

      amountA: formData.payment_amountA
        ? handleAmountFormat((formData.payment_amountA / 100).toString())
        : "",
      amountB: formData.payment_amountB
        ? handleAmountFormat((formData.payment_amountB / 100).toString())
        : "",
      amountC: formData.payment_amountC
        ? handleAmountFormat((formData.payment_amountC / 100).toString())
        : "",
      amountD: formData.payment_amountD
        ? handleAmountFormat((formData.payment_amountD / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      specified_coop: formData?.specified_coop || false,
    });

    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount3: "",
        amount4: "",
        amount5: "",
        amount6: "",
        amount7: "",
        amount8: "",
        amountA: "",
        amountB: "",
        amountC: "",
        amountD: "",
      });
      setMiscFields({
        specified_coop: false,
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount3,
    formData.payment_amount4,
    formData.payment_amount5,
    formData.payment_amount6,
    formData.payment_amount7,
    formData.payment_amount8,
    formData.payment_amountA,
    formData.payment_amountB,
    formData.payment_amountC,
    formData.payment_amountD,
    formData.specified_coop,
  ]);

  const handleMiscFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case SPECIFIED_COOP:
        setMiscFields({
          ...miscFields,
          specified_coop: !miscFields.specified_coop, // check and uncheck
        });
        break;

      default:
        break;
    }
  };

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;

      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_6:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount6: formattedAmount,
          });
        break;
      case AMOUNT_7:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount7: formattedAmount,
          });
        break;
      case AMOUNT_8:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount8: formattedAmount,
          });
        break;

      case AMOUNT_A:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountA: formattedAmount,
          });
        break;

      case AMOUNT_B:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountB: formattedAmount,
          });
        break;

      case AMOUNT_C:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountC: formattedAmount,
          });
        break;

      case AMOUNT_D:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountD: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  // Handle form submit

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;
    // devLogInstance.log(`Clicked on ---> ${submitType}`);

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
      payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
      payment_amount6: amount.amount6 ? parseFloat(amount.amount6.replace(/,/gi, "")) : 0,
      payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,
      payment_amount8: amount.amount8 ? parseFloat(amount.amount8.replace(/,/gi, "")) : 0,
      payment_amountA: amount.amountA ? parseFloat(amount.amountA.replace(/,/gi, "")) : 0,
      payment_amountB: amount.amountB ? parseFloat(amount.amountB.replace(/,/gi, "")) : 0,
      payment_amountC: amount.amountC ? parseFloat(amount.amountC.replace(/,/gi, "")) : 0,
      payment_amountD: amount.amountD ? parseFloat(amount.amountD.replace(/,/gi, "")) : 0,

      // Misc fields
      ...miscFields,
    };
    logger.log(data);

    const incomeAmounts = [
      data.payment_amount1,
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount5,
      data.payment_amount6,
      data.payment_amountB,
    ]; // check which amounts are income amounts to make calculation in backend

    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [
            data.payment_amount1,
            data.payment_amount2,
            data.payment_amount3,
            data.payment_amount4,
            data.payment_amount5,
            data.payment_amount6,
            data.payment_amount7,
            data.payment_amount8,
            data.payment_amountA,
            data.payment_amountB,
            data.payment_amountC,
            data.payment_amountD,
          ],
          data.payment_amount4,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                specified_coop: false,
              });
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount globalFormData={formData} setGlobalFormData={setFormData} />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={patr1099AmountDisplayTexts.patronageDividends}
                      amountNum="1"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={patr1099AmountDisplayTexts.nonpatronageDistributions}
                      amountNum="2"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={patr1099AmountDisplayTexts.perUnitretainAllocations}
                      amountNum="3"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_3}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount3}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail formType={formType} taxYear={taxYear} formName={PATR_1099} />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.federalIncomeTaxWithheld}
                    amountNum="4"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.redeemedNonqualifiedNotices}
                    amountNum="5"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.section199AgDeduction}
                    amountNum="6"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_6}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount6}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.qualifiedPaymentsSection199Ab7}
                    amountNum="7"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_7}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount7}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.section199aQualItems}
                    amountNum="8"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_8}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount8}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.section199aSSTBItems}
                    amountNum="9"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_A}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountA}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.investmentCredit}
                    amountNum="10"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_B}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountB}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.workOpportunityCredit}
                    amountNum="11"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_C}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountC}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.otherCreditsAndDeduction}
                    amountNum="12"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_D}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountD}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={patr1099AmountDisplayTexts.specifiedCoop}
                    amountNum="13"
                  />
                  <input
                    type="checkbox"
                    id={SPECIFIED_COOP}
                    checked={formData.specified_coop}
                    value={formData.specified_coop}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
