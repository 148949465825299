import React from "react";
import { TbRefresh } from "react-icons/tb";
import { AssignedTicketsResponse } from "../../interfaces/main";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";

interface Props {
  refetchFn: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<AssignedTicketsResponse, Error>>;
}

export default function RefreshButton({ refetchFn }: Props) {
  return (
    <div className="cursor-pointer" onClick={() => refetchFn()}>
      <TbRefresh className="text-xl text-gray-600" />
    </div>
  );
}
