import React from "react";
import "../styles/LoginRegisterPage.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  registerFormText,
  passwordErrorMsg,
  indicatorTexts,
  termsAndConditionTexts,
  signupPagePrivacyPolicyTexts,
} from "../lib/languagePacks/en-us";
import registerUser from "../lib/registerUser";
import { PasswordValidator, InputValidator } from "../lib/validators";
import PassInstructionPopup from "./PassInstructionPopup";
import Indicator from "./Indicator";
import { Link } from "react-router-dom";
import { SHA256 } from "crypto-js";
import logger from "../logger";
import handlePopup from "../lib/popup/handlePopup";
import { ERROR_POPUP } from "../lib/constants";
import devLogInstance from "../lib/loggerConfig";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from "../lib/routes";

export default function RegisterForm({ responseStatus, input, setInput }: any) {
  const [isShowPass, setIsShowPass] = useState(false);
  const [toShowPassInstructions, setToShowPassInstructions] = useState(false);
  const [validInputs, setValidInputs] = useState({
    email: null,
    name: null,
    phone: null,
    phoneLength: null,
    password: null,
  });
  const [toDisableBtn, setToDisableBtn] = useState(false);
  // const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const handlePassDisplay = () => {
    setIsShowPass(!isShowPass);
  };
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    switch (id) {
      case "email":
        const isValidEmail = new InputValidator(value, null, null, null).validateEmail();
        isValidEmail &&
          setValidInputs((prevState: any) => {
            return {
              ...prevState,
              email: true,
            };
          });
        if (value.length <= 100) {
          setInput((prevState: any) => {
            return {
              ...prevState,
              email: value,
            };
          });
        }
        break;

      case "name":
        // Sanitize the input to allow only letters, numbers, spaces, &, and .
        const sanitizedValue = value.replace(/[^a-zA-Z0-9&.\s]/g, "");

        // Validate the sanitized value
        const isValidName = new InputValidator(null, sanitizedValue, null, null).validateName();

        if (isValidName) {
          setValidInputs((prevState: any) => {
            return {
              ...prevState,
              name: true,
            };
          });
        }

        // Only update the input if length is within the limit
        if (sanitizedValue.length <= 100) {
          setInput((prevState: any) => {
            return {
              ...prevState,
              name: sanitizedValue, // Use sanitized value
            };
          });
        }
        break;

      case "phone":
        const isValidPhone = new InputValidator(null, null, value, null).validatePhone();
        isValidPhone &&
          setValidInputs((prevState: any) => {
            return {
              ...prevState,
              phone: true,
            };
          });
        if (value.length <= 10) {
          value.length === 10 &&
            setValidInputs((prevState: any) => {
              return {
                ...prevState,
                phoneLength: true,
              };
            });
          setInput((prevState: any) => {
            return {
              ...prevState,
              phoneNo: value,
            };
          });
        }
        break;

      case "password":
        const isValidPasswordInput = new InputValidator(null, null, null, value).validatePassword();
        isValidPasswordInput &&
          setValidInputs((prevState: any) => {
            return {
              ...prevState,
              password: true,
            };
          });
        if (value.length <= 20) {
          value.length >= 1 ? setToShowPassInstructions(true) : setToShowPassInstructions(false);
          const isPassValid = new PasswordValidator(value).validate();
          isPassValid && setToShowPassInstructions(false);
          setInput((prevState: any) => {
            return {
              ...prevState,
              password: value,
            };
          });
        }
        break;

      default:
        break;
    }
  };

  //handle user registration
  const handleSignUp = async (e: any) => {
    e.preventDefault();

    const isValidEmail = new InputValidator(input.email, null, null, null).validateEmail();
    const isValidName = new InputValidator(null, input.name, null, null).validateName();
    const isValidPhone = new InputValidator(null, null, input.phoneNo, null).validatePhone();
    const isValidPasswordInput = new InputValidator(
      null,
      null,
      null,
      input.password
    ).validatePassword();
    !isValidEmail &&
      setValidInputs((prevState: any) => {
        return {
          ...prevState,
          email: false,
        };
      });
    !isValidName &&
      setValidInputs((prevState: any) => {
        return {
          ...prevState,
          name: false,
        };
      });
    !isValidPhone &&
      setValidInputs((prevState: any) => {
        return {
          ...prevState,
          phone: false,
        };
      });
    !isValidPasswordInput &&
      setValidInputs((prevState: any) => {
        return {
          ...prevState,
          password: false,
        };
      });
    if (input.email && input.name && input.phoneNo && input.password) {
      const isValidPass = new PasswordValidator(input.password).validate();
      const isValidPhoneLength = new InputValidator(
        null,
        null,
        input.phoneNo,
        null
      ).validatePhoneLength();
      if (isValidPass && isValidPhoneLength) {
        const encryptedPass = SHA256(process.env.REACT_APP_NONCE + input.password).toString();
        setToDisableBtn(true);
        try {
          const res = await registerUser(input.email, input.name, input.phoneNo, encryptedPass);
          devLogInstance.log(res.data);
          if (res.data === "Success") {
            responseStatus(res.status);
            // Send GA4 analytics event
            ga4.sendEvent({
              category: ga4Const.event.category.auth,
              action: ga4Const.event.action.registration,
              label: "User Registration",
            });
          }
          setToDisableBtn(false);
        } catch (err: any) {
          // Send GA4 analytics event
          ga4.sendEvent({
            category: ga4Const.event.category.auth,
            action: ga4Const.event.action.registrationFail,
            label: "User Registration",
          });

          logger.error(err.response.data.Error);
          const message = err.response.data.Error;
          // Handle popup display
          handlePopup(message, ERROR_POPUP, dispatch);
          setToDisableBtn(false);
        }
      } else if (!isValidPass) {
        console.log("Password invalid");
        const message = passwordErrorMsg.message;
        // Handle popup display
        handlePopup(message, ERROR_POPUP, dispatch);
        setToDisableBtn(false);
      } else if (!isValidPhoneLength) {
        setValidInputs((prevState: any) => {
          return {
            ...prevState,
            phoneLength: false,
          };
        });
      }
    }
  };

  // handles displaying the password setup instructions
  const handleDisplayPassInstructions = () => {
    const isPassValid = new PasswordValidator(input.password).validate();
    input.password.length >= 1 && setToShowPassInstructions(true);
    isPassValid && setToShowPassInstructions(false);
  };
  return (
    <div className="w-[100%] sm:w-[50%] register-form-container sm:px-10">
      <div className="register-title">
        <h1>{registerFormText.formTitle}</h1>
      </div>
      <form className="register-form" onSubmit={handleSignUp}>
        <div className="email-input-container-wrapper">
          <div className="email-input-container">
            <input
              type="email"
              placeholder={`${registerFormText.emailPlaceholder} *`}
              id="email"
              value={input.email}
              onChange={handleChange}
            />
            <div className="input-logo">
              <i className="fa-solid fa-envelope" />
            </div>
          </div>
          {validInputs.email === false && (
            <Indicator type="email" message={indicatorTexts.emailRequired} />
          )}
        </div>

        <div className="name-input-container-wrapper">
          <div className="name-input-container">
            <input
              type="text"
              placeholder={`${registerFormText.namePlaceholder} *`}
              id="name"
              value={input.name}
              onChange={handleChange}
            />
            <div className="input-logo">
              <i className="fa-solid fa-user" />
            </div>
          </div>
          {validInputs.name === false && (
            <Indicator type="name" message={indicatorTexts.nameRequired} />
          )}
        </div>

        <div className="phone-input-container-wrapper">
          <div className="phone-input-container">
            <input
              type="number"
              placeholder={`${registerFormText.phonePlaceholder} *`}
              id="phone"
              value={input.phoneNo}
              onChange={handleChange}
            />
            <div className="input-logo">
              <i className="fa-solid fa-phone" />
            </div>
          </div>
          {validInputs.phone === false && (
            <Indicator type="phone" message={indicatorTexts.phoneRequired} />
          )}
          {validInputs.phoneLength === false && (
            <Indicator type="phone" message={indicatorTexts.invalidPhoneLength} />
          )}
        </div>

        <div className="pass-input-container-wrapper">
          <div className="pass-input-container">
            <input
              type={isShowPass ? "text" : "password"}
              placeholder={`${registerFormText.passPlaceholder} *`}
              id="password"
              value={input.password}
              onChange={handleChange}
              onFocus={handleDisplayPassInstructions}
              onBlur={() => {
                setToShowPassInstructions(false);
              }}
            />
            <div className="display-pass-container" onClick={handlePassDisplay}>
              {isShowPass ? (
                <i className="fa-solid fa-eye" />
              ) : (
                <i className="fa-solid fa-eye-slash" />
              )}
            </div>
          </div>
          {toShowPassInstructions && <PassInstructionPopup />}
          {validInputs.password === false && (
            <Indicator type="password" message={indicatorTexts.passwordRequired} />
          )}
        </div>

        <div className="register-btn-wrapper">
          <button
            disabled={toDisableBtn}
            className={`register-btn bg-taxeve-primary-violet text-white ${
              toDisableBtn && "opacity-50"
            }`}
          >
            {registerFormText.registerText}
          </button>
        </div>

        <div className="py-4 text-center">
          {termsAndConditionTexts.text}{" "}
          <a
            href={TERMS_AND_CONDITION}
            target="_blank"
            rel="noreferrer"
            className="text-taxeve-primary-violet underline underline-offset-1"
          >
            {termsAndConditionTexts.redirectLinkText}
          </a>{" "}
          and{" "}
          <a
            href={PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
            className="text-taxeve-primary-violet underline underline-offset-1"
          >
            {signupPagePrivacyPolicyTexts.redirectLinkText}
          </a>
        </div>

        <div className="login-redirect-container">
          {registerFormText.redirectInfo}{" "}
          <span>
            <Link to="/signin" className="text-taxeve-primary-blue">
              {registerFormText.redirectText}
            </Link>
          </span>
        </div>
      </form>
    </div>
  );
}
