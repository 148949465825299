import { axiosInstance } from "../axiosConfig";
import devLogInstance from "../loggerConfig";
import DisableScreen from "../utils/disableScreen";
import Handler, { Main } from "./main";

export default async function handleAddingCommentOnTicket(comment: string, ticketId: string) {
  try {
    DisableScreen.enable();

    const { data } = await axiosInstance.post("/admin/add_new_comment_ticket", {
      comment,
      ticket_id: ticketId,
    });

    devLogInstance.log({ response_after_adding_comment: data });

    DisableScreen.disable();
  } catch (err) {
    Handler.handleError(err, Main.stateDispatch, () => {});
  }
}
