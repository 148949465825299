import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_2,
  AMOUNT_5,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  PAGE_E_FILE,
  SUBMIT_ADD_ANOTHER,
  S_1099,
  _1099S_GROUP,
} from "../../lib/constants";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import { s1099AmountDisplayTexts } from "../../lib/languagePacks/en-us";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import DateInput from "../decorations/DateInput/DateInput";
import { isValidAmount } from "./Form1099-B";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import scrollToTop from "../../lib/utils/scrollToTop";
import { useRecTinUnknown } from "../../lib/customHooks";

export default function Form1099S({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({
    amount2: "",
    amount5: "",
  }); // for displaying on the input field
  const [address, setAddress] = useState("");
  const [isForeignTransferor, setIsForeignTransferor] = useState(false);
  const [didTransferorReceiveProperty, setDidTransferorReceiveProperty] = useState(false);
  const [date, setDate] = useState("");
  const [isValidDate, setIsValidDate] = useState<boolean | null>(null);
  const [toShowDateRequired, dateRequired] = useState(false);
  const [emptyAddressIndicator, setEmptyAddressIndicator] = useState(false);
  const [showAddressLimitReached, setShowAddressLimitReached] = useState(false);
  const [clearDate, setClearDate] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const { recTinUnknown } = useRecTinUnknown();

  // devLogInstance.log(formData);

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
    });

    // Set the truthy values
    setIsForeignTransferor(formData.foreign_transferor ? formData.foreign_transferor : false);
    setDidTransferorReceiveProperty(
      formData.property_or_services_indicator ? formData.property_or_services_indicator : false
    );

    // Set the address/legal description
    setAddress(formData.address_or_legal_description ?? "");

    return () => {
      setAmount({
        amount2: "",
        amount5: "",
      });

      setIsForeignTransferor(false);
      setDidTransferorReceiveProperty(false);
    };
  }, [
    formData.payment_amount2,
    formData.payment_amount5,
    formData.foreign_transferor,
    formData.property_or_services_indicator,
    formData.address_or_legal_description,
  ]);

  // Date related callback
  const callbackRelatedToDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date?.split("/");
    const date_ = dateChunks[2] + dateChunks[0] + dateChunks[1];

    setDate(date_);
    setIsValidDate(!isInvalidDate);
  };

  // Handle input change on payment amount fields and any other misc fields
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  // Handle address change
  const handleAddressChange = (e: any) => {
    const { value } = e.target;
    // Remove any displayed error from the field
    setEmptyAddressIndicator(false);
    if (value.length <= 39) {
      setShowAddressLimitReached(false);
      setAddress(value);
    } else {
      setShowAddressLimitReached(true);
    }
  };

  // Handle form submit
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setClearDate(null); // Set clear date to be null
    const { id: submitType } = e.target;
    devLogInstance.log(`Clicked on ---> ${submitType}`);
    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
      address_or_legal_description: address,
      property_or_services_indicator: didTransferorReceiveProperty,
      foreign_transferor: isForeignTransferor,
      date_of_closing: date,
    };
    devLogInstance.log(data);
    const paymentAmounts = [data.payment_amount2, data.payment_amount5];
    const incomeAmounts = [data.payment_amount2];

    if (isValidDate === null) {
      dateRequired(true);
      scrollToTop();
    } else if (!isValidDate) {
      scrollToTop();
    } else if (!data.address_or_legal_description) {
      scrollToTop();
      setEmptyAddressIndicator(true);
    } else {
      // Correction filing middleware
      Middleware.fileCorrection(
        {
          isCorrection: isCorrection,
          correctionSubmissionType: correctionSubmissionType,
          correctionCode1Details: data,
          correctionCode2Details: data,
          formType: data.form_type,
        },
        (err, correctionCode) => {
          if (err) {
            console.error(err);
            handlePopup(err, ERROR_POPUP, dispatch);
            return;
          }

          if (correctionCode) {
            data = {
              ...data,
              file_submission_type: correctionSubmissionType,
              correction_type: correctionCode,
              payer_is_new_or_updated: false,
              history_id: historyId,
            };
            devLogInstance.log("Data before sumbitting correction");
            devLogInstance.log(data);
          }

          devLogInstance.log("Removing cached intial data...");
          SessionStorage.removeCacheItem(CORRECTION_INITIAL);
          devLogInstance.log("Cache removed!");

          devLogInstance.log(submitType);

          formSubmissionHelper(
            data,
            paymentAmounts,
            data.payment_amount5,
            incomeAmounts,
            setFormData,
            setAmount,
            dispatch,
            submitType,
            navigate,
            PAGE_E_FILE,
            recTinUnknown
          ).then((res) => {
            // Clear all the local values
            if (submitType === SUBMIT_ADD_ANOTHER) {
              setClearDate(true);
              setAddress("");
              setDate("");
              setDidTransferorReceiveProperty(false);
              setIsForeignTransferor(false);
            }
          });
        }
      );
      // formSubmissionHelper(
      //   data,
      //   paymentAmounts,
      //   data.payment_amount5,
      //   incomeAmounts,
      //   setFormData,
      //   setAmount,
      //   dispatch,
      //   submitType,
      //   navigate,
      //   PAGE_E_FILE
      // ).then((res) => {
      //   // Clear all the local values
      //   if (submitType === SUBMIT_ADD_ANOTHER) {
      //     setClearDate(true);
      //     setAddress("");
      //     setDate("");
      //     setDidTransferorReceiveProperty(false);
      //     setIsForeignTransferor(false);
      //   }
      // });
    }
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading heading={s1099AmountDisplayTexts.dateOfClosing} amountNum="1" />
                    <DateInput
                      minDate={new Date(2000, 11, 31)}
                      maxDate={new Date(taxYear, 11, 31)} // Calendar starts counting from 0
                      clearDate={clearDate}
                      callback={callbackRelatedToDate}
                      groupId={_1099S_GROUP}
                      classes="display-from-top-left"
                      value={formData?.date_of_closing}
                      showDateRequiredIndicator={toShowDateRequired}
                      toShowDateRequired={dateRequired}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading heading={s1099AmountDisplayTexts.grossProceeds} amountNum="2" />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail formType={formType} taxYear={taxYear} formName={S_1099} />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={s1099AmountDisplayTexts.address} amountNum="3" />
                  <input
                    id="ADDRESS"
                    type="text"
                    style={{ padding: "0.5rem 1rem" }}
                    value={address}
                    onChange={handleAddressChange}
                    autoComplete="off"
                  />
                  {showAddressLimitReached && (
                    <i style={{ fontSize: "0.85rem", opacity: "0.7" }}>
                      {s1099AmountDisplayTexts.addressDisclaimer}
                    </i>
                  )}
                  {emptyAddressIndicator && (
                    <p style={{ color: "red", padding: "0 0.5rem" }}>*Address cannot be empty</p>
                  )}
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row" }}
                >
                  <AmountHeading
                    heading={s1099AmountDisplayTexts.transferorReceivedProperty}
                    amountNum="4"
                  />
                  <input
                    type="checkbox"
                    checked={didTransferorReceiveProperty}
                    onChange={() => {
                      setDidTransferorReceiveProperty(!didTransferorReceiveProperty);
                    }}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="payer-direct-sales" // Only one class can be passed
                  style={{ flexDirection: "row" }}
                >
                  <AmountHeading
                    heading={s1099AmountDisplayTexts.transferorIsForeign}
                    amountNum="5"
                  />
                  <input
                    type="checkbox"
                    checked={isForeignTransferor}
                    onChange={() => {
                      setIsForeignTransferor(!isForeignTransferor);
                    }}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={s1099AmountDisplayTexts.buyersPartOfRealEstateTax}
                    amountNum="6"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
