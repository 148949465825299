import React, { useState } from "react";
import { EnquiryData } from "../../../interfaces/main";
import { EMAIL, NAME, PHONE, QUERY } from "../../pages/Contact";
import formatPhonenum from "../../lib/form/utils/formatPhone";
import devLogInstance from "../../lib/loggerConfig";
import Handler from "../../lib/Handlers/main";
import Indicator from "../Indicator";
import Acknowledge from "../decorations/Acknowledge";
import { acknowledgeText, enquiryPageTexts, indicatorTexts } from "../../lib/languagePacks/en-us";

interface ErrorIndicatorValues {
  email: {
    toDisplay: boolean;
    message: string;
  };
  name: {
    toDisplay: boolean;
    message: string;
  };
  phone: {
    toDisplay: boolean;
    message: string;
  };
}

export default function EnquiryForm() {
  const [enquiryData, setEnquiryData] = useState<EnquiryData>({
    name: "",
    email: "",
    phone: "",
    query: "",
  });
  const [errIndicator, setErrIndicator] = useState<ErrorIndicatorValues>({
    email: {
      toDisplay: false,
      message: "",
    },
    name: {
      toDisplay: false,
      message: "",
    },
    phone: {
      toDisplay: false,
      message: "",
    },
  });
  const [showAcknlgmnt, toShowAcknlgmnt] = useState(false);

  const clearForm = () => {
    setEnquiryData({
      name: "",
      email: "",
      phone: "",
      query: "",
    });
  };

  const changeMap: any = {
    EMAIL: (value: string) => {
      // Remove any preloaded error
      setErrIndicator({
        ...errIndicator,
        email: {
          toDisplay: false,
          message: "",
        },
      });

      setEnquiryData({
        ...enquiryData,
        email: value,
      });
    },

    NAME: (value: string) => {
      // Remove any preloaded error
      setErrIndicator({
        ...errIndicator,
        name: {
          toDisplay: false,
          message: "",
        },
      });

      setEnquiryData({
        ...enquiryData,
        name: value,
      });
    },

    PHONE: (value: string) => {
      // Remove any preloaded error
      setErrIndicator({
        ...errIndicator,
        phone: {
          toDisplay: false,
          message: "",
        },
      });

      value.length <= 14 &&
        setEnquiryData({
          ...enquiryData,
          phone: formatPhonenum(value),
        });
    },

    QUERY: (value: string) => {
      value.length <= 200 &&
        setEnquiryData({
          ...enquiryData,
          query: value,
        });
    },
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id: inputType, value } = e.currentTarget;
    inputType && changeMap[inputType](value);
  };

  const startSubmitProcess = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Promise.resolve()
      .then(async () => {
        // parse the formatted str phone num into integer before submitting
        const parsedPhonenum =
          enquiryData.phone && typeof enquiryData.phone === "string"
            ? enquiryData.phone
                .replace(/\(/g, "")
                .replace(/\)/g, "")
                .replace(/ /g, "")
                .replace(/-/g, "")
            : "";
        const updatedData: EnquiryData = {
          ...enquiryData,
          phone: parsedPhonenum ? parseInt(parsedPhonenum) : 0,
        };

        devLogInstance.log("Updated Enquiry Data:");
        devLogInstance.log(updatedData);

        await Handler.handleEnquirySubmission(updatedData, toShowAcknlgmnt);

        // Clear the form
        clearForm();
      })
      .catch((err: string[]) => {
        err.forEach((field) => {
          setErrIndicator((prevState: ErrorIndicatorValues) => {
            const newState: any = prevState;
            newState[field] = {
              toDisplay: true,
              message: "This field is required and must be provided!",
            };
            return { ...newState };
          });
        });
      });
  };

  return (
    <form
      className="relative bg-white text-black text-lg text-left shadow-xl rounded-xl overflow-hidden h-full"
      onSubmit={startSubmitProcess}
    >
      <div className="bg-yellow-500 py-2 px-4 text-2xl text-center">
        <div className="font-bold">{enquiryPageTexts.enquiryFormheading}</div>
        <p className="font-semibold text-[1.25rem]">{enquiryPageTexts.enquiryFormSubheading}</p>
      </div>

      {!showAcknlgmnt ? (
        <>
          <div className="flex flex-col gap-4 py-6 px-6">
            <div>
              <input
                id={NAME}
                placeholder="Full Name*"
                type="text"
                className={`w-[100%] py-1 px-4 bg-transparent border focus:border-none ${
                  errIndicator.name.toDisplay &&
                  "focus:outline-red-500 border-red-500 placeholder:text-red-500"
                }`}
                value={enquiryData.name}
                onChange={handleChange}
              />
              {errIndicator.name.toDisplay && (
                <Indicator type={"name"} message={indicatorTexts.nameRequired} />
              )}
            </div>
            <div>
              <input
                id={EMAIL}
                placeholder="Email*"
                type="email"
                className={`w-[100%] py-1 px-4 bg-transparent border focus:border-none ${
                  errIndicator.email.toDisplay &&
                  "focus:outline-red-500 border-red-500 placeholder:text-red-500"
                }`}
                value={enquiryData.email}
                onChange={handleChange}
              />
              {errIndicator.email.toDisplay && (
                <Indicator type={"email"} message={indicatorTexts.invalidEmail} />
              )}
            </div>
            <div>
              <input
                id={PHONE}
                placeholder="Phone number"
                type="text"
                className={`w-[100%] py-1 px-4 bg-transparent border focus:border-none ${
                  errIndicator.phone.toDisplay &&
                  "focus:outline-red-500 border-red-500 placeholder:text-red-500"
                }`}
                value={enquiryData.phone}
                onChange={handleChange}
              />
              {errIndicator.phone.toDisplay && (
                <Indicator type={"phone"} message={indicatorTexts.invalidPhoneLength} />
              )}
            </div>
            <textarea
              id={QUERY}
              rows={4}
              placeholder="Write your query in brief here"
              className="w-[100%] text-sm py-1 px-4 bg-transparent border border-slate-400 focus:border-none focus:outline-taxeve-primary-violet rounded-lg resize-none placeholder:text-lg"
              onChange={handleChange}
              value={enquiryData.query}
            />
          </div>
          <div className="py-4 text-center">
            <button
              type="submit"
              disabled={showAcknlgmnt}
              className="text-xl py-2 px-6 bg-taxeve-primary-violet text-white rounded-lg hover:opacity-80"
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <Acknowledge message={acknowledgeText.enquirySubmissionSuccess} />
      )}
    </form>
  );
}
