export const registerFormText = {
  formTitle: "Create your Tax Eve Account",
  emailPlaceholder: "Email",
  namePlaceholder: "Contact Name",
  phonePlaceholder: "Phone Number",
  passPlaceholder: "Password",
  registerText: "Create Account",
  redirectInfo: "Already Have an account?",
  redirectText: "Sign In",
};

export const loginFormText = {
  formTitle: "Sign in with your email",
  emailPlaceholder: "Email",
  passPlaceholder: "Password",
  loginText: "Sign In",
  redirectInfo: "Don't have an account?",
  redirectText: "Sign Up",
  forgetPassText: "Forgot Password?",
};

export const emailActivatePopupText = {
  message: "Please verify your email by clicking the link sent to your registered email id",
  redirectText: "Already verified?",
  redirectLinkText: "Sign In",
  resendEmailText: "Verification link sent",
  linkExpiryText: `Verification link will be valid for ${process.env.REACT_APP_VERIFICATION_LINK_EXPIRY_MINUTES} minute(s)`,
};

export const passwordErrorMsg = {
  message: "Please follow all the instructions to set up a strong password",
};

export const passwordSetupInstructions = {
  instructionTitle: "Your password must contain",
  point1: "At least 8 characters",
  point2: "At least 1 number",
  point3: "At least 1 upper case letter",
  point4: "At least 1 lower case letter",
  point5: "At least 1 special character from (! @ # $ % ^ & * - _ = + { } | ' ? /)",
};

export const indicatorTexts = {
  emailRequired: "Please fill out the email field",
  nameRequired: "Please fill out the name field",
  phoneRequired: "Please fill out the phone number field",
  invalidPhoneLength: "The phone number must be 10 digits",
  passwordRequired: "Please set up a password",
  paymentTypeRequired: "Please check any one payment type",
  filerTypeRequired: "Please check any one filer type",
  merchantCodeRequired: "Please provide a merchant code",
  numberOfPaymentTransacRequired: "Please provide the number of payment transactions",
  invalidEmail: "Please provide a valid email address",
  verifyCodeSentSuccessfully: "Verification code has been successfully sent to your email",
  invalidPassword: "Please provide a valid password",
  passUnmatch: "Passwords don't match",
  invalidVerificationCode: "Please provide a verification code to continue",
  passResetSuccessful: "Password Reset Successful",
};

export const resendBtnText = {
  text: "Resend Email",
  timerText: "You can resend verification email in",
};

export const termsAndConditionTexts = {
  text: "By creating an account, you agree to our",
  redirectLinkText: "Terms and Conditions",
};

export const signupPagePrivacyPolicyTexts = {
  text: "You are agreeing to receive emails regarding your tax return status, IRS notifications, and upcoming deadlines from Tax Eve. Read our",
  redirectLinkText: "Privacy Policy",
};

// Form1099
export const form1099 = {
  main: {
    heading: "1099 Forms - Federal & State Filing",
  },

  forms: [
    {
      id: 1,
      name: "nec_1099",
      title: "Form 1099-NEC",
      description: "Nonemployee Compensation",
      filingDueDate: "January 31",
    },

    {
      id: 2,
      name: "misc_1099",
      title: "Form 1099-MISC",
      description: "Miscellaneous Information",
      filingDueDate: "March 31",
    },

    {
      id: 3,
      name: "int_1099",
      title: "Form 1099-INT",
      description: "Interest Income",
      filingDueDate: "March 31",
    },

    {
      id: 4,
      name: "div_1099",
      title: "Form 1099-DIV",
      description: "Dividends and Distributions",
      filingDueDate: "March 31",
    },

    {
      id: 5,
      name: "r_1099",
      title: "Form 1099-R",
      description: "Distributions Form Pensions, Annuities, IRAs, Contracts",
      filingDueDate: "March 31",
    },

    {
      id: 6,
      name: "s_1099",
      title: "Form 1099-S",
      description: "Proceeds From Real Estate Transactions",
      filingDueDate: "March 31",
    },

    {
      id: 7,
      name: "b_1099",
      title: "Form 1099-B",
      description: "Proceeds from Broker and Barter Exchange Transactions",
      filingDueDate: "March 31",
    },

    {
      id: 8,
      name: "k_1099",
      title: "Form 1099-K",
      description: "Payment Card and Third Party Network Transactions",
      filingDueDate: "March 31",
    },

    {
      id: 9,
      name: "a_1099",
      title: "Form 1099-A",
      description: "Acquisition or Abandonment of Secured Property",
      filingDueDate: "March 31",
    },

    {
      id: 10,
      name: "q_1099",
      title: "Form 1099-Q",
      description: "Payments from Qualified Education Programs (Under Sections 529 and 530)",
      filingDueDate: "March 31",
    },

    {
      id: 11,
      name: "sa_1099",
      title: "Form 1099-SA",
      description: "Distributions From an HSA, Archer MSA, or Medicare Advantage MSA",
      filingDueDate: "March 31",
    },
    {
      id: 12,
      name: "patr_1099",
      title: "Form 1099-PATR",
      description: "Taxable Distributions Received From Cooperatives",
      filingDueDate: "March 31",
      },
    {
      id:13,
      name: "g_1099",
      title: "Form 1099-G",
      description: "Certain Government Payments",
      filingDueDate: "March 31",
    },
    {
      id:14,
      name: "c_1099",
      title: "Form 1099-C",
      description: "Cancellation of Debt", 
      filingDueDate: "March 31",
    },

    {

      id: 15,
      name: "corrections",
      title: "1099-Corrections",
      description: "Correct your 1099 Forms",
      filingDueDate: "",
    },
  ],

  filingText: "File Now",

  taxYearText: "Current Tax Year",

  compensationFilingtext: "paid between",

  nonCompensationFilingtext: "reported from",

  financialYearStart: "January 1",

  financialYearEnd: "December 31",

  fileBtnText_pt1: "File",

  fileBtnText_pt2: "For",

  filingDueDateText: "Filing Due Date",

  formCreationQueryText: "How would you like to create the form?",

  createForm: {
    heading: "Manual Data Entry/Bulk Upload",
    manualDataEntry: {
      heading: "Manual Data Entry",
      description_pt1: "Manually add",
      description_pt2: "details for each recipient",
      startBtnText: "Start Filing",
    },
    fileUpload: {
      heading: "Upload Excel/CSV File",
      description_pt1: "Import your",
      description_pt2: "details using TaxEve's template",
      startBtnText: "Start Filing",
    },
  },

  formDetail: {
    taxYearDisplayTxt: "For Tax Year",
  },
};

// payer details display text
export const payerDetails = {
  requiredDetails:
    "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
  payerNamePlaceholder: "Payer's Name",
  addressLine1Placeholder: "Address Line 1",
  addressLine2Placeholder: "Address Line 2",

  cityPlaceholder: "City",
  statePlaceholder: "State",
  zipCodePlaceholder: "ZIP Code",

  // foreign address display texts
  foreignAddressConfirmText: "Foreign Address (Outside US)",
  foreignCityPlaceholder: "Foreign City",
  foreignZipCodePlaceholder: "Postal Code",
  foreignStatePlaceholder: "Foreign State",
  countryPlaceholder: "Country",

  contactNamePlaceholder: "Contact Name",
  emailPlaceholder: "Email Address",
  phonePlaceholder: "Phone Number",
  extnPlaceholder: "Extn.",

  businessTerminationConfirmText: "Select if your business was terminated this year",

  // payer tin
  payerTinDisplayText: "PAYER'S TIN",
  payerEINPlaceholder: "Payer's EIN",
  payerSSNPlaceholder: "Payer's SSN",
};

// payer details display text for form 1099 S
export const payerDetailsFor1099S = {
  requiredDetails:
    "FILER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
  payerNamePlaceholder: "Filer's Name",

  businessTerminationConfirmText: "Select if your business was terminated this year",

  // payer tin
  payerTinDisplayText: "FILER'S TIN",
  payerEINPlaceholder: "FILER's EIN",
  payerSSNPlaceholder: "FILER's SSN",
};

// creditor details display text for form 1099 C
export const payerDetailsFor1099C = {
  requiredDetails:
    "CREDITOR'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
  creditorNamePlaceholder: "Creditor's Name",

  businessTerminationConfirmText: "Select if your business was terminated this year",

  // creditor tin
  payerTinDisplayText: "CREDITOR'S TIN",
  payerEINPlaceholder: "CREDITOR's EIN",
  payerSSNPlaceholder: "CREDITOR's SSN",
};

// payer labels where payer is referred as LENDER
export const lenderReferredPayerDetails: any = {
  requiredDetails:
    "LENDER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
  payerNamePlaceholder: "Lender's Name",

  // businessTerminationConfirmText: "Select if your business was terminated this year",

  // payer tin
  tinType: {
    EIN: "LENDER's EIN",
    SSN: "LENDER's SSN",
  },
  payerTinDisplayText: "LENDER'S TIN",
};

//recipient details display text
export const recipientDetails = {
  recipientNamePlaceholder: "RECIPIENT'S Name",
  nameContPlaceHolder: "Name Continuation",

  emailPlaceholder: "Email Address",
  phonePlaceholder: "Phone Number",
  extnPlaceholder: "Extn.",
  onlineAccessConfirm: {
    part1: "Select if you want to allow this recipient to access Form",
    part2: "Online",
  },

  // address heading
  addressHeading:
    "Recipient’s street address, city or town, state or province, country, ZIP or foreign postal code",
  addressLine1Placeholder: "Address Line 1",
  addressLine2Placeholder: "Address Line 2",

  cityPlaceholder: "City",
  statePlaceholder: "State",
  zipCodePlaceholder: "ZIP Code",

  // foreign address display texts
  foreignAddressConfirmText: "Foreign Address (Outside US)",
  foreignCityPlaceholder: "Foreign City",
  foreignZipCodePlaceholder: "Postal Code",
  foreignStatePlaceholder: "Foreign State",
  countryPlaceholder: "Country",

  // recipient tin
  recipientTinDisplayText: "RECIPIENT'S TIN",
  recipientEINPlaceholder: "Recipient's EIN",
  recipientSSNPlaceholder: "Recipient's SSN",
  recipientATINPlaceholder: "Recipient's ATIN",
  recipientITINPlaceholder: "Recipient's ITIN",
};

//recipient details display text for form 1099-S
export const recipientDetailsFor1099S = {
  recipientNamePlaceholder: "TRANSFEROR'S Name",

  // address heading
  addressHeading:
    "Transferor's street address, city or town, state or province, country, ZIP or foreign postal code",

  // recipient tin
  recipientTinDisplayText: "TRANSFEROR'S TIN",
  recipientEINPlaceholder: "Transferor's EIN",
  recipientSSNPlaceholder: "Transferor's SSN",
  recipientATINPlaceholder: "Transferor's ATIN",
  recipientITINPlaceholder: "Transferor's ITIN",
};

//recipient details display text for form 1099-C
export const recipientDetailsFor1099C = {
  recipientNamePlaceholder: "DEBITOR'S Name",

  // address heading
  addressHeading:
    "Debitor's street address, city or town, state or province, country, ZIP or foreign postal code",

  // recipient tin
  recipientTinDisplayText: "DEBITOR'S TIN",
  recipientEINPlaceholder: "Debitor's EIN",
  recipientSSNPlaceholder: "Debitor's SSN",
  recipientATINPlaceholder: "Debitor's ATIN",
  recipientITINPlaceholder: "Debitor's ITIN",
};

// Recipient details display text for recipients referred to as payee
export const payeeReferredrecipientDetails = {
  recipientNamePlaceholder: "PAYEE'S Name",

  // address heading
  addressHeading:
    "PAYEE's street address, city or town, state or province, country, ZIP or foreign postal code",

  // recipient tin
  recipientTinDisplayText: "PAYEE'S TIN",
  recipientEINPlaceholder: "Payee's EIN",
  recipientSSNPlaceholder: "Payee's SSN",
  recipientATINPlaceholder: "Payee's ATIN",
  recipientITINPlaceholder: "Payee's ITIN",
};

// Rec labels for recipients referred to as BORROWER
export const borrowerReferredRecLabels: any = {
  recNameLabel: "BORROWER's Name",

  // address heading
  addressHeading:
    "Borrower's street address, city or town, state or province, country, ZIP or foreign postal code",

  // recipient tin
  recipientTinDisplayText: "BORROWER'S TIN",

  tinType: {
    EIN: "Borrower's EIN",
    SSN: "Borrower's SSN",
    ATIN: "Borrower's ATIN",
    ITIN: "Borrower's ITIN",
  },
};

// Account component display texts on e-form
export const accountDisplayTexts = {
  heading: "Account number",
  accountInputPlaceholder: "Account Number",
  secondTinNotDisplayText: "2nd TIN notice",
  accountLeaveBlankDisclaimer:
    "If you leave the field blank, we will auto-generate a unique account number for this recipient.",
  instructionDisplayText: "See Instructions",
};

// Form 1099-NEC amount section display texts
export const nec1099AmountDisplayTexts = {
  compensationHeading: "Nonemployee Compensation",
  directSalesDisplayText:
    "Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale",
  incomeTaxWithheldDisplayText: "Federal income tax withheld",
};

// Form 1099-MISC amount section display texts
export const misc1099AmountDisplayTexts = {
  miscHeading: "Miscellaneous Information",
  directSalesDisplayText:
    "Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale",
  incomeTaxWithheldDisplayText: "Federal income tax withheld",
  otherIncome: "Other Income",
  rents: "Rents",
  royalties: "Royalties",
  fishingBoatProceeds: "Fishing boat proceeds",
  medicalHealthCarePayments: "Medical and health care payments",
  substitutePayments: "Substitute payments in lieu of dividends or interest",
  cropInsProceeds: "Crop insurance proceeds",
  grossProceedsPaidToAttorney: "Gross proceeds paid to an attorney",
  fishPurchasedForResale: "Fish purchased for resale",
  section409ADeferrals: "Section 409A deferrals",
  glodenParachutePayments: "Excess golden parachute payments",
  deferredCompensation: "Nonqualified deferred compensation",
  fatca: "FATCA filing requirement",
};

// Form 1099-INT amount section display texts
export const int1099AmountDisplayTexts = {
  intHeading: "Interest Income",
  // directSalesDisplayText:
  //   "Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale",
  incomeTaxWithheldDisplayText: "Federal income tax withheld",
  // otherIncome: "Other Income",
  interestIncome: "Interest Income",
  payerRTN: "Payer's RTN (optional)",
  earlyWithdrawPenalty: "Early withdrawal penalty",
  intOnUSSavingsbonds: "Interest on U.S. Savings Bonds and Treasury obligations",
  invstExpenses: "Investment Expenses",
  foreignTaxPaid: "Foreign tax paid",
  foreignCountryOrUSPossession: "Foreign country or U.S. possession",
  taxExemptInterest: "Tax-exempt interest",
  pvtActivityBondInterest: "Specified private activity bond interest",
  marketDiscount: "Market Discount",
  bondPremium: "Bond Premium",
  bondPremiumOnTreasuryObl: "Bond premium on Treasury obligations",
  bondPremiumOnTaxExemptBond: "Bond premium on tax-exempt bond",
  cusipNum: "Tax-exempt and tax credit bond CUSIP no.",
  fatca: "FATCA filing requirement",
};

export const div1099AmountDisplayTexts = {
  divHeading: "Dividends and Distributions",
  // directSalesDisplayText:
  //   "Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale",
  incomeTaxWithheldDisplayText: "Federal income tax withheld",
  // otherIncome: "Other Income",
  ordinaryDividends: "Total ordinary dividends",
  qualifiedDividends: "Qualified dividends",
  totalCapitalGain: "Total capital gain distr.",
  sec1250Gain: "Unrecap. Sec. 1250 gain",
  sec1202Gain: "Section 1202 gain",
  collectiblesGain: "Collectibles (28%) gain",
  sec897Div: "Section 897 ordinary dividends",
  sec897CapitalGain: "Section 897 capital gain",
  nondivDistr: "Nondividend distributions",
  sec199ADiv: "Section 199A dividends",
  invstExpenses: "Investment Expenses",
  foreignTaxPaid: "Foreign tax paid",
  foreignCountryOrUSPossession: "Foreign country or U.S. possession",
  cashLiquidDist: "Cash liquidation distributions",
  noncashLiquidDist: "Noncash liquidation distributions",
  exemptIntDiv: "Exempt-interest dividends",
  pvtActivityBondInterest: "Specified private activity bond interest dividends",
  fatca: "FATCA filing requirement",
};

// 1099-S Amount display texts
export const s1099AmountDisplayTexts = {
  dateOfClosing: "Date of closing",
  grossProceeds: "Gross Proceeds",
  address: "Address (including city, state, and ZIP code) or legal description",
  transferorReceivedProperty:
    "Check here if the transferor received or will receive property or services as part of the consideration",
  transferorIsForeign:
    "Check here if the transferor is a foreign person (nonresident alien, foreign partnership, foreign estate, or foreign trust)",
  buyersPartOfRealEstateTax: "Buyer's part of real estate tax",
  addressDisclaimer:
    "Maximum 39 characters are allowed. Try to use suffix (Eg. AVENUE - AVE, STREET - ST)",
};

export const c1099AmountDisplayTexts = {
  dateOfIdentifiableEvent: "Date of identifiable event",
  amountOfDebtDischarged: "Amount of debt discharged",
  interestIfIncludedinBox2: "Interest if included in box 2",
  debtDescription: "Debt description",
  debtorPersonallyLiable:
    "Check here if the the debtor was personally liable for repayment of the debt",
  identifiableEventCode: "Identifiable event code",
  fairMarketValue: "Fair market value of property",
};

// 1099-B amount display texts
export const b1099AmountDisplayTexts = {
  applicableCheckboxForm8949: "Applicable checkbox on Form 8949",
  propertyDescription: "Description of property (Example: 100 sh. XYZ Co.)",
  dateAcquired: "Date acquired",
  dateSold: "Date sold",
  proceeds: "Proceeds",
  costOrOtherBasis: "Cost or other basis",
  accruedMarketDiscount: "Accrued Market Discount",
  washSaleLossDisallowed: "Wash Sale Loss Disallowed",
  shortTermGainOrLoss: "Short term gain or loss",
  longTermGainOrLoss: "Long term gain or loss",
  ordinary: "Ordinary",
  checkProceedsFromHeading: "Check if proceeds from",
  collectibles: "Collectibles",
  qof: "QOF",
  taxWithheld: "Federal income tax withheld",
  nonCoveredSecurityCheck: "Check if noncovered security",
  reportedToIRSHeading: "Reported to IRS",
  grossProceeds: "Gross Proceeds",
  netProceeds: "Net Proceeds",
  lossNotAllowedCheck: "Check if loss is not allowed based on amount in 1d",
  realizedProfitOrLoss: "Profit or (loss) realized in 2023 on closed contracts",
  unrealizedProfitOrLoss: {
    openContracts2022: "Unrealized profit or (loss) on open contracts—12/31/2022",
    openContracts2023: "Unrealized profit or (loss) on open contracts—12/31/2023",
  },
  aggregateProfitOrLoss: "Aggregate profit or (loss) on contracts",
  basisReportedToIRSCheck: "Check if basis reported to IRS",
  bartering: "Bartering",
};

// 1099-R amount display texts
export const r1099AmountDisplayTexts = {
  grossDistro: "Gross distribution",
  taxableAmount: "Taxable Amount",
  taxableAmountNotDetermined: "Taxable amount not determined",
  totalDistro: "Total distribution",
  capitalGain: "Capital gain (included in box 2a)",
  employeeContrib: "Employee contributions/Designated Roth contributions or insurance premiums",
  netUnrealizedAppreciation: "Net unrealized appreciation in employer's securities",
  distroCode: "Distribution code(s)",
  iraSepSimpleIndicator: "IRA/SEP/SIMPLE",
  other: "Other",
  totalDistroPercent: "Your percentage of total distribution",
  totalEmployeeContrib: "Total employee contributions",
  taxWithheld: "Federal income tax withheld",
  irrAmount: "Amount allocable to IRR within 5 years",
  rothContribYear: "1st year of desig. Roth contrib.",
  dateOfPayment: "Date of payment",
};

// 1099-K amount display texts
export const k1099AmountDisplayTexts = {
  grossAmountOfPaymentCard: "Gross amount of payment card/third party network transactions",
  cardNotPresentTransactions: "Card not present transactions",
  merchantCategoryCode: "Merchant category code",
  numberOfPaymentTransac: "Number of payment transactions",
  taxWithheld: "Federal income tax withheld",
  janPayment: "January",
  febPayment: "February",
  marPayment: "March",
  aprPayment: "April",
  mayPayment: "May",
  junPayment: "June",
  julPayment: "July",
  augPayment: "August",
  sepPayment: "September",
  octPayment: "October",
  novPayment: "November",
  decPayment: "December",
};

// 1099-PATR amount display texts
export const patr1099AmountDisplayTexts = {
  patronageDividends: "Patronage dividends",
  nonpatronageDistributions: "Nonpatronage distributions",
  perUnitretainAllocations: "Per-unit retain allocations",
  federalIncomeTaxWithheld: "Federal income tax withheld",
  redeemedNonqualifiedNotices: "Redeemed nonqualified notices",
  section199AgDeduction: "Section 199A(g) deduction",
  qualifiedPaymentsSection199Ab7: "Qualified payments(Section 199A(b)(7))",
  section199aQualItems: "Section 199A(a) qual. items",
  section199aSSTBItems: "Section 199A(a) SSTB items",
  investmentCredit: "Investment credit",
  workOpportunityCredit: "Work opportunity credit",
  otherCreditsAndDeduction: "Other credits and deductions",
  specifiedCoop: "Specified Coop",
}
// 1099-K amount display texts
export const g1099AmountDisplayTexts = {
  unemploymentCompensation: "Unemployment compensation",
  checkIfBox2IsTrade: "Check if box 2 trade or business income",
  stateOrLocalIncomeTaxRefunds: "State or local income tax refunds, credits, or offsets",
  box2Amount: "Box 2 amount is for tax year",
  federalIncomeTaxWithheld: "Federal income tax withheld",
  rtaaPayments: "RTAA payments",
  taxableGrants: "Taxable grants",
  agriculturePayments: "Agriculture payments",
  marketGain: "Market Gain",
};

// State info box display texts
export const stateInfoDisplayTexts = {
  nec1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "5. State tax withheld",
    stateLabel: "6. State",
    stateNoLabel: "Payer's state no.",
    stateIncLabel: "7. State income",
  },
  misc1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "16. State tax withheld",
    stateLabel: "17. State",
    stateNoLabel: "Payer's state no.",
    stateIncLabel: "18. State income",
  },
  int1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "17. State tax withheld",
    stateLabel: "15. State",
    stateNoLabel: "16. State identification no.",
  },
  div1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "16. State tax withheld",
    stateLabel: "14. State",
    stateNoLabel: "15. State identification no.",
  },
  b1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "16. State tax withheld",
    stateLabel: "14. State",
    stateNoLabel: "15. State identification no.",
  },
  r1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "14. State tax withheld",
    stateLabel: "15. State",
    stateNoLabel: "Payer's state no.",
    stateIncLabel: "16. State income",
  },
  k1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "8. State tax withheld",
    stateLabel: "6. State",
    stateNoLabel: "7. State identification no.",
  },
  g1099: {
    state1: {
      heading: "State 1 details",
    },
    state2: {
      heading: "State 2 details",
    },
    stateTaxWithheldLabel: "11. State tax withheld",
    stateLabel: "10a. State",
    stateNoLabel: "10b. State identification no.",
  },
};

// Foreign address limit warning message
export const FAWarningMessage = {
  nec1099: {
    message_pt1: "You have ",
    message_pt2: " characters left",

    // Char exceeded message
    exceed_msg: "City+State+ZipCode characters limit reached",
  },
};

// Address line limit warning message
export const addressLineWarning = {
  nec1099: {
    message_pt1: "You have ",
    message_pt2: " characters left",

    // Char exceeded message
    exceed_msg: "Adress 1+Address 2 characters limit reached",
  },
};

// Mandatory form fields validation error message
export const formFieldErrMsg = {
  nec1099: {
    payer: {
      emptyName: "Payer name is required!",
      emptyTin: "Payer TIN is required and it must contain 9 digits!",
      emptyAddress1: "Address Line 1 is required!",
      emptyCity: "City is required!",
      emptyState: "State is required!",
      emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
      emptyCountry: "Please select a country!",
    },
    recipient: {
      emptyName: "Recipient name is required!",
      emptyTin: "Recipient TIN is required and it must contain 9 digits!",
      emptyAddress1: "Address Line 1 is required!",
      emptyCity: "City is required!",
      emptyState: "State is required!",
      emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
      emptyCountry: "Please select a country!",
    },
    invalidPhoneNum: "Phone number must contain 10 digits!",
  },
  s1099: {
    payer: {
      emptyName: "Filer name is required!",
      emptyTin: "Filer TIN is required and it must contain 9 digits!",
      emptyAddress1: "Address Line 1 is required!",
      emptyCity: "City is required!",
      emptyState: "State is required!",
      emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
      emptyCountry: "Please select a country!",
    },
    recipient: {
      emptyName: "Transferor name is required!",
      emptyTin: "Transferor TIN is required and it must contain 9 digits!",
      emptyAddress1: "Address Line 1 is required!",
      emptyCity: "City is required!",
      emptyState: "State is required!",
      emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
      emptyCountry: "Please select a country!",
    },
    invalidPhoneNum: "Phone number must contain 10 digits!",
  },
  payeeReferredRecipient: {
    recipient: {
      emptyName: "Payee name is required!",
      emptyTin: "Payee TIN is required and it must contain 9 digits!",
      emptyAddress1: "Address Line 1 is required!",
      emptyCity: "City is required!",
      emptyState: "State is required!",
      emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
      emptyCountry: "Please select a country!",
    },
  },
  borrowerReferredRecipient: {
    emptyName: "Borrower name is required!",
    emptyTin: "Borrower TIN is required and it must contain 9 digits!",
    emptyAddress1: "Address Line 1 is required!",
    emptyCity: "City is required!",
    emptyState: "State is required!",
    emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
    emptyCountry: "Please select a country!",
  },

  lenderRefferedPayer: {
    emptyName: "Lender name is required!",
    emptyTin: "Lender TIN is required and it must contain 9 digits!",
    // emptyAddress1: "Address Line 1 is required!",
    // emptyCity: "City is required!",
    // emptyState: "State is required!",
    // emptyZipcode: "Zip code / Postal code is required and it must contain at least 5 characters!",
    // emptyCountry: "Please select a country!",
  },
};

// Invalid amount warning message
export const invalidAmountWarning = {
  warningMessage: "One of the amount fields must contain a value!",
};
export const invalidTaxAmtWarning = {
  warningMessage: "Box 4 amount should not be greater than 35% of the total of all other amounts!",
};

// Identifier Event Code
export const invalidEventCode = {
  warningMesssage: "Identifiable event code is a required field!",
};

// State filing error message
export const stateFilingErrMsg = {
  nec1099: {
    sameStateErrMsg: "State 1 and State 2 cannot be same!",
    stateIncomeErrMsg: "Income is required!",
    stateNumErrMsg: "Payer State Number is required!",
    noStateSelectedErrMsg: "You need to select a state!",
    stateTaxErrMsg: "Tax amount cannot exceed income!",
  },
  wvStateNumMinLenErr: "State number should be of 8 characters when filing for West Virginia",
  utahStateNumMinLenErr: "State number should be of 14 characters when filing for West Virginia",
  wvExtraFieldValidationErr: "This field is required when filing for West Virginia",
};

export const formValidationErrorMsg = {
  noFormDataErrorMsg: "There is no data to validate!",
  form1099b: {
    propertyDescErrorMsg: "Description of property [Box 1a] should not be empty.",
    proceedsErrorMsg:
      "	If Proceeds (Box 1d) has a value, then in Type of Indicator (Box 3) any one checkbox should be checked.",
  },
  form1099Div: {
    invalidAmt3:
      "Box 2a amount should be greater than or equal to Box 2b + Box 2c + Box 2d amounts.",
    invalidAmt1:
      "Total ordinary dividends (Box 1a) cannot be zero if Qualified dividends (Box 1b) contains a dollar amount.",
  },
};

// Modal
export const modalDisplayTexts = {
  closeBtnText: "Ok!",
  topPanelHeading: "Error!",
};

// Form submission related messages
export const formSavedMsg = "Form has been successfully saved!";

// Review form texts
export const reviewFormLabels = {
  recStreetAddress: "Street address (including apt. no.)",
  recLocationAndZip: "City or town, state or province, country, and ZIP or foreign postal code",
  secondTinNotice: "Second TIN Not.",
};

// All forms reviewed toast message
export const toastMessage = {
  allFormsReviewed: "All forms for the current payer has been reviewed",
  postalMailing: {
    enabled: "Postal Mailing enabled successfully",
    disabled: "Postal Mailing disabled successfully",
  },
  onlineAccess: {
    enabled: "Online Access enabled successfully",
    disabled: "Online Access disabled successfully",
  },
  stateFiling: {
    enabled: "State Filing enabled successfully",
    disabled: "State Filing disabled successfully",
  },
};

// Action names
export const actionInstruction = "Please select one or more forms to";

export const action = {
  download: "Download",
  bulkReview: "Review",
  bulkUnreview: "Unreview",
  bulkDelete: "Delete",
};

// Upload page
export const uploadPageDisplayTexts = {
  selectPayer: {
    heading: "Add the company details for the Payer / Filer",
    payerNamelabel: "Company Name",
  },
  noFileSelectedError: "No file selected for uploading",
};

// Button inner texts
export const btnInnerTxts = {
  submitAndContinue: "Submit & Continue",
  submitAndFillAgain: "Submit & Fill New",
  next: "Next",
  edit: "Edit",
  downloadXLTemplate: "Download Excel Template",
  downloadCSVTemplate: "Download CSV Template",
  completePayment: "Continue with payment",
};

// Filer type display texts
export const filerType = {
  heading: "Check to indicate if FILER is a (an):",
  types: {
    pse: "Payment settlement entity",
    epf: "Electronic Payment Facilitator (EPF)/Other third party",
  },
};

export const filerPaymentType = {
  heading: "Check to indicate transactions reported are:",
  types: {
    paymentCard: "Payment Card",
    thirdPartyNetwork: "Third Party Network",
  },
};

// PSE details
export const pseDetails = {
  heading: "PSE'S name and phone number",
  details: {
    name: "PSE'S Name",
    nameContd: "Name continuation",
    phone: "PSE'S Phone No",
  },
};

// Server error message
export const serverErrorMsg =
  "Oh no! This is a rare sight to see. Something went wrong, please try again after sometime.";

export const faq = {
  heading: "Frequently Asked Questions",
  questions: {
    pricing: {
      subfees: "Are there any subscription fees?",
      cdromfiling:
        "My state requires Forms to be reported on CD ROM. What is the filing fee that you charge for CD-ROM reporting?",
      reconciliationForm: "Does Tax Eve charge for filing State Reconciliation Forms?",
      refundPolicy: "What is Tax Eve's refund policy?",
    },
  },
  answers: {
    pricing: {
      subfees:
        "Tax Eve will never charge you an annual or subscription fee. Tax Eve is always free to access and you will be charged only for what you file. You can also pay your filing fee when you are ready to transmit your return",
      cdromfiling:
        "Tax Eve never charge any additional fee for reporting Forms by CD ROM. All the state Forms can be reported for $0.50/form, if you are reporting to both the federal and state through Tax Eve.",
      reconciliationForm:
        "Tax Eve never charges a separate price for filing a reconciliation form. The standard fee of $0.50/form charged to file your State 1099 includes the reconciliation form if you have chosen to file along with your Federal return.",
      refundPolicy: {
        pt1: "No refunds will be allowed once the return has been transmitted to the IRS.",
        pt2: "Refund requests after 30 days will not be processed.",
        pt3: "Refunds will not be provided if the state or federal agency rejects the return based on processing.",
      },
    },
  },
};

// Contact us display texts
export const contactDisplayText = {
  phoneheading: "Phone number",
  emailHeading: "Email address",
  messageHeading: "Message us directly",
  messagePlaceholders: {
    name: "Full name*",
    company: "Company",
    phone: "Phone",
    email: "Work email*",
    message: "How can we help you?*",
  },
  enquiryPlaceholder: "Tell us what query you have.*",
};

// Form 1099-B 8949 options
export const _8949Options = {
  labels: {
    a: "(A) Short-term transactions reported on Forms 1099-B showing basis was reported to the IRS",
    b: "(B) Short-term transactions reported on Forms 1099-B showing basis was not reported to the IRS",
    d: "(D) Long-term transactions reported on Forms 1099-B showing basis was reported to the IRS",
    e: "(E) Long-term transactions reported on Forms 1099-B showing basis was not reported to the IRS",
    x: "(X) Cannot determine whether the recipient should check Box B or Box E on Form 8949 because the holding period is unknown",
  },
};

// Why choose us page texts
export const whyChooseUsTexts = {
  description:
    "Tax Eve is a leading e-file service provider offering you the best cloud-based solutions to perform online filings of Form 1099 series. Our biggest achievements have been to develop very simple, flexible, and user-friendly online application so that users require minimal support during the entire process. Some of the important features listed below are good reasons to choose Tax Eve as your efile service provider for 1099 forms.",
};

// Info
export const info = {
  onlineAccess:
    "Employees can view and download their tax returns through an online portal. Provide the employee's email address to provide them with access.",

  stateFiling: {
    withIncome: {
      income:
        "To report state information, Enter the total amount of state income taxes withheld from the recipient's income in",
      stateAndStateNo:
        "Select the state where taxes withheld and enter the payer's state identification number (assigned by the state) in",
    },
    withoutIncome: {
      state: "To report state information, Select the state where taxes withheld in",
      stateNo: "Enter the payer's state identification number (assigned by the state) in",
    },
    tax: "Enter the total amount of taxable income subject to state income tax in",
    noStateNo:
      "If you have any difficulty finding an employer's state identification number, contact your state department of revenue.",
  },

  localFiling: {
    income:
      "To report local information, Enter the total amount of local income taxes withheld from the recipient's income in",
    locality: "Enter the name of locality in",
    tax: "Enter the total local gross 1099-R distribution amount for which local taxes were withheld in",
  },

  cusip:
    "A CUSIP is a nine-character numeric or alphanumeric code that uniquely identifies a North American financial security for the purposes of facilitating clearing and settlement of trades.",
};

// No ASCII file message
export const noAsciiFileMsg = "There are no forms pending at the moment.";

// IRS filing start ad text
export const irsFilingStart =
  "IRS will start accepting 1099 forms online from January 7th, 2024. We will transmit all submitted forms to IRS soon after that.";

export const featuresText = [
  {
    heading: "Wizard Driven e-filing",
    description: "Wizard-driven e-filing for guided and error-free submissions.",
  },
  {
    heading: "e-File to IRS",
    description: "eFile 1099s to IRS and all states securely.",
  },
  {
    heading: "Print, Mail & eDeliver",
    description: "We print your forms and mail them, and e-deliver the forms to your recipients.",
  },
  {
    heading: "Excel Import",
    description: "Forms data import through the standard Excel templates.",
  },
  {
    heading: "Great e-Filing Experience",
    description: "Easy self e-filing owing to our great User Interface design.",
  },
  {
    heading: "Security & Privacy",
    description: "We use SSL-encrypted connections for transferring sensitive information.",
  },
  {
    heading: "Cloud-Enabled Solution",
    description:
      "Extend the security, intelligence, availability, and agility of the application on cloud.",
  },
];

export const formsDescription = {
  nec1099: {
    heading: "1099-NEC",
    description: "Nonemployee Compensation",
  },
  misc1099: {
    heading: "1099-MISC",
    description: "Miscellaneous Information",
  },
  int1099: {
    heading: "1099-INT",
    description: "Interest Income",
  },
  div1099: {
    heading: "1099-DIV",
    description: "Dividends and Distributions",
  },
  b1099: {
    heading: "1099-B",
    description: "Proceeds from Broker and Barter Exchange Transactions",
  },
  r1099: {
    heading: "1099-R",
    description: "Proceeds From Real Estate Transactions",
  },
  s1099: {
    heading: "1099-S",
    description: "Proceeds From Real Estate Transactions",
  },
  k1099: {
    heading: "1099-K",
    description: "Payment Card and Third Party Network Transactions",
  },
  patr1099: {
    heading: "1099-PATR",
    description: "Taxable Distributions Received From Cooperatives",
  },
};

export const noSupportWV = "West Virginia state filing is not supported at the moment.";

export const noCorrectionRegisteredError =
  "No changes detected! Please correct the form and then try to submit.";

export const acknowledgeText = {
  enquirySubmissionSuccess:
    "Thank you for submitting your inquiry. We will get back to you as soon as possible.",
};

export const enquiryPageTexts = {
  enquiryFormheading: "Inquire Now",
  enquiryFormSubheading: "Get a free consultation call today",
};

export const landingPagePricingText = "Pricing starts from $0.50 per form.";

// Success popup message for enquiry submission from enquiry floater
export const enquiryFloaterSuccessMessage =
  "Thank you for reaching out to us! Our support team will get back to you within 24 hours!";
export const homePageDeadlineAdText =
  "The deadline for filing 1099 forms is January 31st. E-file now to avoid last day rush.";

export const progressTexts = {
  uploadInProgress: "Upload in progress, feel free to do other stuff but do not refresh the page!",
  uploadComplete: "File upload complete!",
  uploadFailed: "File upload failed!",
};

export const invalidTinErrIndicator = {
  invalidEin: "EIN cannot start with 00",
  invalidSsn: "SSN cannot start with 000",
  invalidTinInOrder: "Invalid tin",
  allDigitSameInvalidTin: "111111111, 222222222.....999999999 is not a valid tin",
  allZeroInvalidTin: "000000000 is not a valid tin",
};

export const reviewCopyTagText = "Review Copy - Not for filing";

// Local filing related error indicator texts
export const errorInLocalFilingDetails = {
  invalidLocalTax: "Local tax withheld should be less than local distribution",
  invalidLocalAreaName: "Locality name is mandatory when filing for local area",
  invalidLocalIncome: "Local income is mandatory when filing for local area",
};

export const ticketDefaultMessages: any = {
  open: "This ticket will soon be assigned to one of our associates who will get in touch with you at the earliest. Thank you for the co-operation.",
  inProgress:
    "One of our associates is already looking into this query, you will be contacted within 24hrs. Thank you for using our services.",
  closed:
    "This ticket has been closed. If you feel this was wrong please raise another ticket and we will try to take a look into it. Thank you for using our services.",
};
