import ReviewCopyModifier from "./ReviewCopyModifier";

export default class StateReviewDetailsModifier {
  private state1Pos: { x: number; y: number };
  private stateNum1Pos;
  private stateTax1Pos;
  private stateIncome1Pos;
  private state2Pos;
  private stateNum2Pos;
  private stateTax2Pos;
  private stateIncome2Pos;

  private reviewCopyMod: ReviewCopyModifier;

  constructor(
    reviewCopyMod: ReviewCopyModifier,
    state1PrevFieldXCoord: number,
    state1PrevFieldYCoord: number,
    stateNum1PrevFieldXCoord?: number,
    stateNum1PrevFieldYCoord?: number
  ) {
    this.reviewCopyMod = reviewCopyMod;

    this.state1Pos = {
      x: state1PrevFieldXCoord - 10,
      y: state1PrevFieldYCoord - 24,
    };

    if (stateNum1PrevFieldXCoord && stateNum1PrevFieldYCoord) {
      this.stateNum1Pos = {
        x: stateNum1PrevFieldXCoord - 40,
        y: stateNum1PrevFieldYCoord - 24,
      };
    } else {
      this.stateNum1Pos = {
        x: this.state1Pos.x + 50,
        y: this.state1Pos.y,
      };
    }

    this.stateTax1Pos = {
      x: this.stateNum1Pos.x + 80,
      y: this.stateNum1Pos.y,
    };
    this.stateIncome1Pos = {
      x: this.stateNum1Pos.x + 160,
      y: this.stateNum1Pos.y,
    };
    this.state2Pos = {
      x: this.state1Pos.x,
      y: this.state1Pos.y - 13,
    };
    this.stateNum2Pos = {
      x: this.stateNum1Pos.x,
      y: this.stateNum1Pos.y - 13,
    };
    this.stateTax2Pos = {
      x: this.stateTax1Pos.x,
      y: this.stateTax1Pos.y - 13,
    };
    this.stateIncome2Pos = {
      x: this.stateIncome1Pos.x,
      y: this.stateIncome1Pos.y - 13,
    };
  }

  public modifyStateNameDetail(state1: string, state2: string) {
    const page = this.reviewCopyMod.getPdfPage(1);

    // State 1
    page.drawText(state1, {
      x: this.state1Pos.x,
      y: this.state1Pos.y,
      ...this.reviewCopyMod.getOptions(),
    });

    // State 2
    page.drawText(state2, {
      x: this.state2Pos.x,
      y: this.state2Pos.y,
      ...this.reviewCopyMod.getOptions(),
    });
  }

  public modifyStateNumDetail(
    stateNum1: string,
    stateNum2: string,
    state1?: string,
    state2?: string
  ): void;

  public modifyStateNumDetail(
    stateNum1: string,
    stateNum2: string,
    state1: string,
    state2: string
  ) {
    const page = this.reviewCopyMod.getPdfPage(1);

    if (!state1)
      // State num 1
      page.drawText(stateNum1, {
        x: this.stateNum1Pos.x,
        y: this.stateNum1Pos.y,
        ...this.reviewCopyMod.getOptions(),
      });
    // State 1 / State num 1
    else
      page.drawText(`${state1} / ${stateNum1}`, {
        x: this.stateNum1Pos.x + 30,
        y: this.stateNum1Pos.y,
        ...this.reviewCopyMod.getOptions(),
      });

    if (!state2)
      // State num 2
      page.drawText(stateNum2, {
        x: this.stateNum2Pos.x,
        y: this.stateNum2Pos.y,
        ...this.reviewCopyMod.getOptions(),
      });
    // State 2 / State num 2
    else
      page.drawText(`${state2} / ${stateNum2}`, {
        x: this.stateNum2Pos.x + 30,
        y: this.stateNum2Pos.y,
        ...this.reviewCopyMod.getOptions(),
      });
  }

  public modifyStateTaxDetail(
    stateTax1: number,
    stateTax2: number,
    toBeginWith?: boolean,
    paddingX?: number,
    paddingY?: number
  ) {
    const page = this.reviewCopyMod.getPdfPage(1);

    if (!toBeginWith) {
      let formattedAmt = "";
      if (stateTax1) {
        // State tax 1
        formattedAmt = (stateTax1 / 100).toFixed(2);
        page.drawText(formattedAmt, {
          x: this.stateTax1Pos.x + (!paddingX ? 0 : paddingX),
          y: this.stateTax1Pos.y + (!paddingY ? 0 : paddingY),
          ...this.reviewCopyMod.getOptions(),
        });
      }

      if (stateTax2) {
        // State tax 2
        formattedAmt = (stateTax2 / 100).toFixed(2);
        page.drawText(formattedAmt, {
          x: this.stateTax2Pos.x + (!paddingX ? 0 : paddingX),
          y: this.stateTax2Pos.y + (!paddingY ? 0 : paddingY),
          ...this.reviewCopyMod.getOptions(),
        });
      }
    } else {
      let formattedAmt = "";

      if (stateTax1) {
        // State tax 1
        formattedAmt = (stateTax1 / 100).toFixed(2);
        page.drawText(formattedAmt, {
          x: this.state1Pos.x + 10 + (!paddingX ? 0 : paddingX),
          y: this.state1Pos.y + (!paddingY ? 0 : paddingY),
          ...this.reviewCopyMod.getOptions(),
        });
      }

      if (stateTax2) {
        // State tax 2
        formattedAmt = (stateTax2 / 100).toFixed(2);
        page.drawText(formattedAmt, {
          x: this.state2Pos.x + 10 + (!paddingX ? 0 : paddingX),
          y: this.state2Pos.y + (!paddingY ? 0 : paddingY),
          ...this.reviewCopyMod.getOptions(),
        });
      }
    }
  }

  public modifyStateIncomeDetail(
    stateIncome1: number,
    stateIncome2: number,
    paddingX?: number,
    paddingY?: number
  ) {
    const page = this.reviewCopyMod.getPdfPage(1);

    let formattedAmt = "";

    if (stateIncome1) {
      // State income 1
      formattedAmt = (stateIncome1 / 100).toFixed(2);
      page.drawText(formattedAmt, {
        x: this.stateIncome1Pos.x + (!paddingX ? 0 : paddingX),
        y: this.stateIncome1Pos.y + (!paddingY ? 0 : paddingY),
        ...this.reviewCopyMod.getOptions(),
      });
    }

    if (stateIncome2) {
      // State income 2
      formattedAmt = (stateIncome2 / 100).toFixed(2);
      page.drawText(formattedAmt, {
        x: this.stateIncome2Pos.x + (!paddingX ? 0 : paddingX),
        y: this.stateIncome2Pos.y + (!paddingY ? 0 : paddingY),
        ...this.reviewCopyMod.getOptions(),
      });
    }
  }
}
