import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";

export default class Review1099NECModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;

  private amount1: number;
  private amount4: number;

  private directSalesIndicator: boolean;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;
  private stateIncome1: number;
  private stateIncome2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount1: number,
    amount4: number,
    directSalesIndicator: boolean,
    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number,
    stateIncome1: number,
    stateIncome2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount1 = amount1;
    this.amount4 = amount4;

    this.directSalesIndicator = directSalesIndicator;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
    this.stateIncome1 = stateIncome1;
    this.stateIncome2 = stateIncome2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // Draw second tin notice if it is marked
      if (this.secondTinNotice) {
        const secondTinNoticePos = {
          x: this.getLeftSideXCoord() + 215,
          y: 221,
        };
        await this.modifySecondTinNotice(secondTinNoticePos);
      }

      // *** RIGHT SIDE *** //

      this.drawCalendarYear();

      // Nonemployee Compensation (amount 1)
      const amount1Pos = {
        x: this.getRecTinPos().x + 65 * 2,
        y: this.getRecTinPos().y + 9,
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount1Pos.x,
        y: height - amount1Pos.y - this.getFontSize(),
        ...options,
      });

      // Direct sales indicator
      const directSalesIndicatorPos = {
        x: amount1Pos.x + 177,
        y: amount1Pos.y + 10,
      };
      if (this.directSalesIndicator) {
        this.getReviewCopy().registerFontkit(fontkit);
        const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
        const fontBuffer = await tickMarkFontRes.arrayBuffer();
        const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
        firstPage.drawText("z", {
          x: directSalesIndicatorPos.x,
          y: height - directSalesIndicatorPos.y - 8 * 2,
          ...this.getOptions(),
          size: 8,
          font: tickMark,
        });
      }

      // Federal Income tax withheld (amount 4)
      const amount4Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y + 72,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: height - amount4Pos.y - this.getFontSize(),
        ...options,
      });

      // State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: amount4Pos.x,
          prevFieldYCoord: height - amount4Pos.y - this.getFontSize(),
        },
        {
          stateNum1: this.stateNum1,
        },
        {
          stateTax1: this.stateTax1,
        },
        this.stateIncome1,
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        },
        this.stateIncome2
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string },
    stateTax1Obj: { stateTax1: number },
    stateIncome1: number,
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number },
    stateIncome2: number
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord
    );

    // State details
    // State tax
    stateDetailsMod.modifyStateTaxDetail(stateTax1Obj.stateTax1, stateTax2Obj.stateTax2, true);
    // State num
    stateDetailsMod.modifyStateNumDetail(
      stateNum1Obj.stateNum1,
      stateNum2Obj.stateNum2,
      state1Obj.state1,
      state2Obj.state2
    );
    // State income
    stateDetailsMod.modifyStateIncomeDetail(stateIncome1, stateIncome2);
  }
}
