import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";
import formatTin from "../form/utils/formatTin";
import maskTin from "../utils/maskTin";

export default class Review1099KModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;

  private amount1: number;
  private amount2: number;
  private amount4: number;
  private amount5: number;
  private amount6: number;
  private amount7: number;
  private amount8: number;
  private amount9: number;
  private amountA: number;
  private amountC: number;
  private amountF: number;
  private amountD: number;
  private amountB: number;
  private amountE: number;
  private amountG: number;

  private type_of_filer_indicator: number;
  private type_of_payment_indicator: number;
  private pse_name: string;
  private pse_name_cont: string;
  private pse_phone_number: string;
  private merchant_category_code: string;
  private number_of_payment_transactions: number;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;
  private stateIncome1: number;
  private stateIncome2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount1: number,
    amount2: number,
    amount4: number,
    amount5: number,
    amount6: number,
    amount7: number,
    amount8: number,
    amount9: number,
    amountA: number,
    amountC: number,
    amountF: number,
    amountD: number,
    amountB: number,
    amountE: number,
    amountG: number,
    type_of_filer_indicator: number,
    type_of_payment_indicator: number,
    pse_name: string,
    pse_name_cont: string,
    pse_phone_number: string,
    merchant_category_code: string,
    number_of_payment_transactions: number,
    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number,
    stateIncome1: number,
    stateIncome2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount4 = amount4;
    this.amount5 = amount5;
    this.amount6 = amount6;
    this.amount7 = amount7;
    this.amount8 = amount8;
    this.amount9 = amount9;
    this.amountA = amountA;
    this.amountC = amountC;
    this.amountF = amountF;
    this.amountD = amountD;
    this.amountB = amountB;
    this.amountE = amountE;
    this.amountG = amountG;

    this.type_of_filer_indicator = type_of_filer_indicator;
    this.type_of_payment_indicator = type_of_payment_indicator;
    this.pse_name = pse_name;
    this.pse_name_cont = pse_name_cont;
    this.pse_phone_number = pse_phone_number;
    this.merchant_category_code = merchant_category_code;
    this.number_of_payment_transactions = number_of_payment_transactions;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
    this.stateIncome1 = stateIncome1;
    this.stateIncome2 = stateIncome2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // *** LEFT SIDE *** //

      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      // Type of filer indicator
      if (this.type_of_filer_indicator > 0) {
        const isPSE = this.type_of_filer_indicator === 1;
        const isEPF = this.type_of_filer_indicator === 2;
        const typeOfFilerIndicatiorPos = {
          x: this.getLeftSideXCoord() + 110,
          y: height - 128 - this.getFontSize() - (isPSE ? 24 : isEPF ? 40 : 0),
        };
        firstPage.drawText("z", {
          x: typeOfFilerIndicatiorPos.x,
          y: typeOfFilerIndicatiorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Type of payment indicator
      if (this.type_of_payment_indicator > 0) {
        const isPaymentCard = this.type_of_payment_indicator === 1;
        const isThirdPartyNetwork = this.type_of_payment_indicator === 2;
        const typeOfPaymentIndicatiorPos = {
          x: this.getLeftSideXCoord() + 110 * 2.12,
          y:
            height - 128 - this.getFontSize() - (isPaymentCard ? 24 : isThirdPartyNetwork ? 40 : 0),
        };
        firstPage.drawText("z", {
          x: typeOfPaymentIndicatiorPos.x,
          y: typeOfPaymentIndicatiorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Payee's name / Recipient name
      const payeeNamePos = {
        x: this.getLeftSideXCoord() + 4,
        y: height - 190 - this.getFontSize(),
      };
      firstPage.drawText(
        this.getRecipientData().recipient_name + " " + this.getRecipientData().recipient_name_cont,
        {
          x: payeeNamePos.x,
          y: payeeNamePos.y,
          ...options,
        }
      );

      // Payee / Recipient street address
      const streetAddressPos = {
        x: payeeNamePos.x,
        y: payeeNamePos.y - 36,
      };
      firstPage.drawText(
        this.getRecipientData().recipient_address_line1 +
          " " +
          this.getRecipientData().recipient_address_line2,
        {
          x: streetAddressPos.x,
          y: streetAddressPos.y,
          ...options,
        }
      );

      // City, state, country and zip
      const payeeResidencePos = {
        x: streetAddressPos.x,
        y: streetAddressPos.y - 50,
      };
      firstPage.drawText(
        this.getRecipientData().recipient_city +
          " " +
          this.getRecipientData().recipient_state +
          " " +
          this.getRecipientData().recipient_country +
          " " +
          this.getRecipientData().recipient_zipcode,
        {
          x: payeeResidencePos.x,
          y: payeeResidencePos.y,
          ...options,
        }
      );

      // PSE name and telephone number
      const pseDetailPos = {
        x: payeeResidencePos.x,
        y: payeeResidencePos.y - 24,
      };
      firstPage.drawText(this.pse_name + " " + this.pse_name_cont + " " + this.pse_phone_number, {
        x: pseDetailPos.x,
        y: pseDetailPos.y,
        ...options,
      });

      // Account number
      const accountNumberPos = {
        x: pseDetailPos.x,
        y: pseDetailPos.y - 36,
      };
      firstPage.drawText(this.accountNumber, {
        x: accountNumberPos.x,
        y: accountNumberPos.y,
        ...options,
      });

      // Second tin notice
      const secondTinNoticePos = {
        x: accountNumberPos.x + 216,
        y: accountNumberPos.y - 4,
      };
      firstPage.drawText("z", {
        x: secondTinNoticePos.x,
        y: secondTinNoticePos.y,
        ...options,
        size: 8,
        font: tickMark,
      });

      // *** RIGHT SIDE *** //

      // Calendar year
      this.drawCalendarYear(0, 12);

      // Payer / Filer tin
      const filerTinType = this.getPayerData().payer_tin_type;
      let filerTin = formatTin(this.getPayerData().payer_tin, filerTinType);

      filerTin =
        filerTinType === "EIN"
          ? filerTin
          : this.getToMaskTin()
          ? maskTin(filerTin, filerTinType)
          : filerTin;

      const filerTinPos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 48 - this.getFontSize(),
      };
      firstPage.drawText(filerTin || "", {
        x: filerTinPos.x,
        y: filerTinPos.y,
        ...options,
      });

      // Recipient / Payee tin
      const payeeTinType = this.getRecipientData().recipient_tin_type;
      let payeeTin = formatTin(this.getRecipientData().recipient_tin, payeeTinType);

      payeeTin =
        payeeTinType === "EIN"
          ? payeeTin
          : this.getToMaskTin()
          ? maskTin(payeeTin, payeeTinType)
          : payeeTin;

      const payeeTinPos = {
        x: filerTinPos.x,
        y: filerTinPos.y - 24,
      };
      firstPage.drawText(payeeTin || "", {
        x: payeeTinPos.x,
        y: payeeTinPos.y,
        ...options,
      });

      // Gross amount of payment card/third party network transactions (amount 1)
      const amount1Pos = {
        x: payeeTinPos.x,
        y: payeeTinPos.y - 37,
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount1Pos.x,
        y: amount1Pos.y,
        ...options,
      });

      // Card not present transactions (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 30,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Merchant category code
      const mccPos = {
        x: amount2Pos.x + 100,
        y: amount2Pos.y,
      };
      firstPage.drawText(this.merchant_category_code, {
        x: mccPos.x,
        y: mccPos.y,
        ...options,
      });

      // Number of payment transactions
      const numberOfPaymentTransactionPos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 30,
      };
      firstPage.drawText(this.number_of_payment_transactions.toString(), {
        x: numberOfPaymentTransactionPos.x,
        y: numberOfPaymentTransactionPos.y,
        ...options,
      });

      // Federal income tax withheld (amount 4)
      const amount4Pos = {
        x: mccPos.x,
        y: mccPos.y - 30,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: amount4Pos.y,
        ...options,
      });

      // January payments (amount 5)
      const amount5Pos = {
        x: numberOfPaymentTransactionPos.x,
        y: numberOfPaymentTransactionPos.y - 24,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: amount5Pos.y,
        ...options,
      });

      // February payments (amount 6)
      const amount6Pos = {
        x: amount4Pos.x,
        y: amount4Pos.y - 24,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount6Pos.x,
        y: amount6Pos.y,
        ...options,
      });

      // March payments (amount 7)
      const amount7Pos = {
        x: amount5Pos.x,
        y: amount5Pos.y - 24,
      };
      formattedAmt = (this.amount7 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount7Pos.x,
        y: amount7Pos.y,
        ...options,
      });

      // April payments (amount 8)
      const amount8Pos = {
        x: amount6Pos.x,
        y: amount6Pos.y - 24,
      };
      formattedAmt = (this.amount8 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount8Pos.x,
        y: amount8Pos.y,
        ...options,
      });

      // May payments (amount 9)
      const amount9Pos = {
        x: amount7Pos.x,
        y: amount7Pos.y - 24,
      };
      formattedAmt = (this.amount9 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount9Pos.x,
        y: amount9Pos.y,
        ...options,
      });

      // June payments (amount A)
      const amountAPos = {
        x: amount8Pos.x,
        y: amount8Pos.y - 24,
      };
      formattedAmt = (this.amountA / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountAPos.x,
        y: amountAPos.y,
        ...options,
      });

      // July payments (amount B)
      const amountBPos = {
        x: amount9Pos.x,
        y: amount9Pos.y - 24,
      };
      formattedAmt = (this.amountB / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountBPos.x,
        y: amountBPos.y,
        ...options,
      });

      // August payments (amount C)
      const amountCPos = {
        x: amountAPos.x,
        y: amountAPos.y - 24,
      };
      formattedAmt = (this.amountC / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountCPos.x,
        y: amountCPos.y,
        ...options,
      });

      // September payments (amount D)
      const amountDPos = {
        x: amountBPos.x,
        y: amountBPos.y - 24,
      };
      formattedAmt = (this.amountD / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountDPos.x,
        y: amountDPos.y,
        ...options,
      });

      // October payments (amount E)
      const amountEPos = {
        x: amountCPos.x,
        y: amountCPos.y - 24,
      };
      formattedAmt = (this.amountE / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountEPos.x,
        y: amountEPos.y,
        ...options,
      });

      // November payments (amount F)
      const amountFPos = {
        x: amountDPos.x,
        y: amountDPos.y - 24,
      };
      formattedAmt = (this.amountF / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountFPos.x,
        y: amountFPos.y,
        ...options,
      });

      // December payments (amount G)
      const amountGPos = {
        x: amountEPos.x,
        y: amountEPos.y - 24,
      };
      formattedAmt = (this.amountG / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountGPos.x,
        y: amountGPos.y,
        ...options,
      });

      //   State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: amountFPos.x,
          prevFieldYCoord: amountFPos.y,
        },
        {
          stateNum1: this.stateNum1,
          prevFieldXCoord: amountGPos.x + 56,
          prevFieldYCoord: amountGPos.y,
        },
        {
          stateTax1: this.stateTax1,
        },
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        }
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateTax1Obj: { stateTax1: number },
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number }
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord,
      stateNum1Obj.prevFieldXCoord,
      stateNum1Obj.prevFieldYCoord
    );

    // State details
    // State name
    stateDetailsMod.modifyStateNameDetail(state1Obj.state1, state2Obj.state2);
    // State num
    stateDetailsMod.modifyStateNumDetail(stateNum1Obj.stateNum1, stateNum2Obj.stateNum2);
    // State tax
    stateDetailsMod.modifyStateTaxDetail(stateTax1Obj.stateTax1, stateTax2Obj.stateTax2, false, 5);
  }
}
