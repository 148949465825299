import React, { createContext, useState } from "react";
import AdminNavbar from "../../components/AdminRelatedComponents/AdminNavbar";
import FireFiling from "../../components/AdminRelatedComponents/FireFiling";
import AdminManagement from "../../components/AdminRelatedComponents/AdminManagement";
import PriceManagement from "../../components/AdminRelatedComponents/PriceManagement";
import TrackTransactions from "../../components/AdminRelatedComponents/TrackTransactions";
import TicketDetails from "../../components/AdminRelatedComponents/SupportTicketRelated/TicketDetails";
import SidePanelListOptions from "../../components/AdminRelatedComponents/SidePanelListOptions";
import ManagementToolList from "../../components/AdminRelatedComponents/ManagementToolList";
import AssignedTicketList from "../../components/AdminRelatedComponents/SupportTicketRelated/AssignedTicketList";

const managementToolMap: any = {
  "fire-filing": <FireFiling />,
  "admin-management": <AdminManagement />,
  "price-management": <PriceManagement />,
  "transaction-management": <TrackTransactions />,
};

export const TicketSelectionCtx = createContext<{
  handleTicketClick: (ticketId: string) => void;
  currentSelectedTicket: string; // ticket id;
  selectCurrentTicket: React.Dispatch<React.SetStateAction<string>>;
} | null>(null);

export default function AdminDashboard() {
  const [selectedTool, selectTool] = useState("fire-filing"); // by default the fire filing system will be selected

  const [selectedList, selectList] = useState("tools"); // default selection will be tools

  const [currentSelectedTicket, selectCurrentTicket] = useState(""); // ticket id

  // This function will handle whenever any ticket from the notification container
  // is clicked
  const handleClickOnTicket = (ticketId: string) => {
    selectList("assigned-tickets");
    selectCurrentTicket(ticketId);
  };

  return (
    <TicketSelectionCtx.Provider
      value={{ handleTicketClick: handleClickOnTicket, currentSelectedTicket, selectCurrentTicket }}
    >
      <main className="h-screen flex items-center relative">
        <AdminNavbar />
        <div className="side-panel-wrapper w-[25%] h-full overflow-auto flex flex-col justify-between bg-[#F6F5F5]">
          <div>
            <SidePanelListOptions selectedList={selectedList} selectList={selectList} />
            {selectedList.includes("tools") && (
              <ManagementToolList selectedTool={selectedTool} selectTool={selectTool} />
            )}
            {selectedList.includes("assigned-tickets") && <AssignedTicketList />}
          </div>
        </div>

        <div className="management-tool-container w-[75%] h-full px-6 overflow-auto">
          {selectedList.includes("tools") && managementToolMap[selectedTool]}
          {selectedList.includes("assigned-tickets") && currentSelectedTicket && <TicketDetails />}
        </div>
      </main>
    </TicketSelectionCtx.Provider>
  );
}
