import React from "react";
import "../../../styles/forms/globalComponentStyles/RecipientDetails.css";
import { useSelector, useDispatch } from "react-redux";
import {
  borrowerReferredRecLabels,
  formFieldErrMsg,
  payeeReferredrecipientDetails,
  recipientDetails,
  recipientDetailsFor1099C,
  recipientDetailsFor1099S,
} from "../../../lib/languagePacks/en-us";
import RecipientTin from "./InternalComponents/RecipientTin";
import Address from "./Address";
import CustomSearch from "../../decorations/CustomSearch";
import {
  SET_TO_DISABLE_SCREEN,
  SET_PHONE_DISPLAY,
  REC_PHONE,
  TO_SHOW_FORM_VALIDATION_ERR,
  TO_SHOW_INVALID_TIN_ERROR,
} from "../../../redux/actions/action.types";
import handleGetRecDetails from "../../../lib/form/utils/handleGetRecData";
import logger from "../../../logger";
import updateTinDisplay from "../../../lib/form/utils/Display Update/updateTinDisplay";
import updateZipDisplay from "../../../lib/form/utils/Display Update/updateZipDisplay";
import updatePhoneDisplay from "../../../lib/form/utils/Display Update/updatePhoneDisplay";
import formatPhonenum from "../../../lib/form/utils/formatPhone";
import Indicator from "../../Indicator";
import {
  ERROR_POPUP,
  FORM1099_A,
  FORM1099_C,
  FORM1099_K,
  FORM1099_S,
  RECIPIENT_ALL_FIELDS,
  RECIPIENT_COUNTRY,
  RECIPIENT_NAME,
  RECIPIENT_PHONE_NUMBER,
  RECIPIENT_STATE,
} from "../../../lib/constants";
import { useEffect, useState } from "react";
import { OnlineAccessValidator } from "../../../lib/validators";
import handlePopup from "../../../lib/popup/handlePopup";
import devLogInstance from "../../../lib/loggerConfig";
import { BsInfoCircleFill } from "react-icons/bs";
import displayInfoModal from "../../../lib/StateSetters/displayInfoModal";
import OnlineAccessModalBody from "../../decorations/Modal/OnlineAccessModalBody/OnlineAccessModalBody";
import { useRecTinUnknown } from "../../../lib/customHooks";

const ONLINE_ACCESS_INFO_ICON = "INFO_ICON";

export const formsWithRecAsTransferor = [FORM1099_S];
export const formsWithRecAsPayee = [FORM1099_K];
export const formsWithRecAsBorrower = [FORM1099_A];

export default function RecipientDetails({
  globalFormData,
  setGlobalFormData,
  formType,
  isCorrection,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const recipientNames = useSelector((state: any) => state.recipientNames);
  const phoneDisplay = useSelector((state: any) => state.phoneDisplay);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const [isDisabled, setIsDisabled] = useState(true);
  const { isRecTinUnknown } = useRecTinUnknown();
  // const [isPayerNew, setIsPayerNew] = useState(globalFormData.payer_is_new_or_updated);
  const dispatch = useDispatch();

  // Monitor if the payer is new or updated value changes, then update the state
  // useMemo(() => {
  //   if (globalFormData.payer_is_new_or_updated) setIsPayerNew(true);
  //   else setIsPayerNew(false);
  // }, [globalFormData.payer_is_new_or_updated]);

  // Remove all form field error indicators initially
  useEffect(() => {
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: RECIPIENT_ALL_FIELDS,
        toShowErr: false,
      },
    });
  }, [dispatch]);

  // Remove all invalin tin errors on component destroy
  useEffect(() => {
    dispatch({
      type: TO_SHOW_INVALID_TIN_ERROR,
      payload: {
        type: "",
        toShowErr: false,
      },
    });
    return () => {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: "",
          toShowErr: false,
        },
      });
    };
  }, [globalFormData.recipient_tin_type, dispatch]);

  // handle input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    switch (id) {
      case "email-rec":
        value.length <= 0
          ? setGlobalFormData({
              ...globalFormData,
              online_access: false,
              recipient_email: value,
              recipient_is_new_or_updated: true,
            })
          : setGlobalFormData({
              ...globalFormData,
              recipient_email: value,
              recipient_is_new_or_updated: true,
            });
        break;

      case "phone-rec":
        // Remove any error/warning indicators
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: RECIPIENT_PHONE_NUMBER,
            toShowErr: false,
          },
        });

        // Enable the extn field
        setIsDisabled(false);

        // Format the phone number display
        const formattedNum = formatPhonenum(value);
        formattedNum
          ? dispatch({
              type: SET_PHONE_DISPLAY,
              phoneType: REC_PHONE,
              payload: formattedNum,
            })
          : dispatch({
              type: SET_PHONE_DISPLAY,
              phoneType: REC_PHONE,
              payload: "",
            });

        // Update the phone number on recipient's global form data
        if (value.length <= 14) {
          const updatedVal = value
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(" ", "")
            .replaceAll("-", "");
          setGlobalFormData({
            ...globalFormData,
            recipient_phone_number: updatedVal,
          });
        }
        // Disable the extn field
        if (value.length <= 0) {
          // Remove any existing extn data and update the global phone data
          setGlobalFormData({
            ...globalFormData,
            recipient_phone_number: "",
            recipient_phone_number_extn: "",
            recipient_is_new_or_updated: true,
          });
          // Disable the extn field
          setIsDisabled(true);
        }
        break;

      case "extn-rec":
        if (value.length <= 5) {
          setGlobalFormData({
            ...globalFormData,
            recipient_phone_number_extn: value ? value : "",
            recipient_is_new_or_updated: true,
          });
        }
        break;

      case "name-cont":
        if (value.length <= 40) {
          // Set the value on global form data
          setGlobalFormData({
            ...globalFormData,
            recipient_name_cont: value,
            recipient_is_new_or_updated: true,
          });
        }
        break;

      case "online-access-checkbox":
        OnlineAccessValidator.validate(globalFormData.recipient_email)
          .then(() => {
            // Update the value on global data
            setGlobalFormData({
              ...globalFormData,
              online_access: !globalFormData.online_access,
              recipient_is_new_or_updated: true,
            });
          })
          .catch((errObj) => {
            handlePopup(errObj.message, ERROR_POPUP, dispatch);
          });

        break;

      default:
        break;
    }
  };

  // Update the global data
  const updateGlobalFormData = (updatedData: any, type: string) => {
    if (type === "new") {
      setGlobalFormData({
        ...globalFormData,
        ...updatedData,
        recipient_is_new_or_updated: true,
      });
    } else if (type === "existing") {
      setGlobalFormData({
        ...globalFormData,
        ...updatedData,
        recipient_is_new_or_updated: false,
      });

      // // Update the recipient tin display
      updateTinDisplay(
        updatedData.recipient_tin,
        updatedData.recipient_tin_type,
        "recipient",
        dispatch
      );

      // // Update the recipient zip display
      updateZipDisplay(updatedData.recipient_zipcode, "recipient", dispatch);

      // // Update the payer phone number display
      updatePhoneDisplay(updatedData.recipient_phone_number, REC_PHONE, dispatch);

      // Remove all mandatory field warnings from recipient block
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: RECIPIENT_ALL_FIELDS,
          toShowErr: false,
        },
      });
    }
  };

  const getRecipientDetails = async (recId: string) => {
    devLogInstance.log(recId);
    // Start the screen disabler
    dispatch({
      type: SET_TO_DISABLE_SCREEN,
      payload: true,
    });

    // Handle getting recipient details
    const data = await handleGetRecDetails(recId, dispatch);

    // Stop the screen disabler
    dispatch({
      type: SET_TO_DISABLE_SCREEN,
      payload: false,
    });
    logger.log(data);
    if (data.recipient_details?.recipient_tin_type === null) {
      isRecTinUnknown(true);
    }
    // Update the global form data
    updateGlobalFormData(data.recipient_details, "existing");

    // Remove any warnings from react select components
    // Remove any mandatory field warning from payer country select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: RECIPIENT_COUNTRY,
        toShowErr: false,
      },
    });

    // Remove any mandatory field warning from payer state select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: RECIPIENT_STATE,
        toShowErr: false,
      },
    });
  };

  // Handle click event
  const handleClick = (e: React.MouseEvent<SVGElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case ONLINE_ACCESS_INFO_ICON:
        // Display the info modal
        displayInfoModal(true, <OnlineAccessModalBody />, "Online Access", dispatch);
        break;

      default:
        break;
    }
  };

  return (
    <div
      style={{
        border: `1px solid ${style.eFormBorderColor}`,
        borderTop: "0",
        padding: "0",
      }}
    >
      <div className="recName-nameCont-container">
        <div className="recipient-name-input-wrapper">
          {/* <label htmlFor="recipient-name">
            {!formsWithRecAsPayee.includes(formType) &&
            !formsWithRecAsTransferor.includes(formType) &&
            !formsWithRecAsBorrower.includes(formType)
              ? recipientDetails.recipientNamePlaceholder
              : formsWithRecAsTransferor.includes(formType)
              ? recipientDetailsFor1099S.recipientNamePlaceholder
              : formsWithRecAsPayee.includes(formType) &&
                payeeReferredrecipientDetails.recipientNamePlaceholder}

            {formsWithRecAsBorrower.includes(formType) && borrowerReferredRecLabels.recNameLabel}

            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label> */}
          <label htmlFor="recipient-name">
            {formType === FORM1099_C
              ? recipientDetailsFor1099C.recipientNamePlaceholder
              : !formsWithRecAsPayee.includes(formType) &&
                !formsWithRecAsTransferor.includes(formType) &&
                !formsWithRecAsBorrower.includes(formType)
              ? recipientDetails.recipientNamePlaceholder
              : formsWithRecAsTransferor.includes(formType)
              ? recipientDetailsFor1099S.recipientNamePlaceholder
              : formsWithRecAsPayee.includes(formType) &&
                payeeReferredrecipientDetails.recipientNamePlaceholder}

            {formsWithRecAsBorrower.includes(formType) && borrowerReferredRecLabels.recNameLabel}

            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>

          <CustomSearch
            searchList={recipientNames}
            value={globalFormData.recipient_name}
            updateGlobalData={updateGlobalFormData}
            handleAPICall={getRecipientDetails}
            ddType="rec_names"
          />
          {showValidationError.onRecipientName && (
            <Indicator
              type={RECIPIENT_NAME}
              message={
                !formsWithRecAsPayee.includes(formType) ||
                !formsWithRecAsTransferor.includes(formType) ||
                !formsWithRecAsBorrower.includes(formType)
                  ? formFieldErrMsg.nec1099.recipient.emptyName
                  : formsWithRecAsTransferor.includes(formType)
                  ? formFieldErrMsg.s1099.recipient.emptyName
                  : formsWithRecAsPayee.includes(formType)
                  ? formFieldErrMsg.payeeReferredRecipient.recipient.emptyName
                  : formsWithRecAsBorrower.includes(formType) &&
                    formFieldErrMsg.borrowerReferredRecipient.emptyName
              }
            />
          )}
        </div>
        <div className="name-contd-input-wrapper">
          <label htmlFor="name-contd">{recipientDetails.nameContPlaceHolder}</label>
          <input
            className="recipient-details-input"
            id="name-cont"
            type="text"
            onChange={handleChange}
            value={globalFormData.recipient_name_cont}
          />
        </div>
      </div>

      <RecipientTin
        globalFormData={globalFormData}
        setGlobalFormData={setGlobalFormData}
        formType={formType}
      />
      <Address
        globalFormData={globalFormData}
        setGlobalFormData={setGlobalFormData}
        type="recipient"
        formType={formType}
      />

      <div className="personal-info-wrapper">
        <div className="misc-detail-container-rec">
          <div className="email-wrapper">
            <label htmlFor="email-rec">{recipientDetails.emailPlaceholder}</label>
            <input
              className="payer-details-input"
              id="email-rec"
              type="email"
              value={globalFormData.recipient_email}
              autoComplete="off"
              onChange={handleChange}
            />
          </div>
          <div className="phone-wrapper">
            <label htmlFor="phone-rec">{recipientDetails.phonePlaceholder}</label>
            <input
              className="payer-details-input"
              type="text"
              id="phone-rec"
              value={phoneDisplay.recPhone ?? ""}
              autoComplete="off"
              onChange={handleChange}
            />
            {showValidationError.onRecipientPhone && (
              <Indicator
                type={RECIPIENT_PHONE_NUMBER}
                message={formFieldErrMsg.nec1099.invalidPhoneNum}
              />
            )}
          </div>
          <div className="extn-wrapper">
            <label htmlFor="extn">{recipientDetails.extnPlaceholder}</label>
            <input
              className="recipient-details-input"
              type="number"
              id="extn-rec"
              value={
                !globalFormData.recipient_phone_number_extn
                  ? ""
                  : globalFormData.recipient_phone_number_extn
              }
              onChange={handleChange}
              autoComplete="off"
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3 py-4 px-4 font-semibold">
        <span>
          <input
            type="checkbox"
            id="online-access-checkbox"
            onChange={handleChange}
            checked={globalFormData.online_access}
            disabled={isCorrection}
          />
          <label>
            {`${recipientDetails.onlineAccessConfirm.part1} ${formType} ${recipientDetails.onlineAccessConfirm.part2}`}
          </label>
        </span>
        <BsInfoCircleFill
          id={ONLINE_ACCESS_INFO_ICON}
          className="cursor-pointer"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}
