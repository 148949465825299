import React from "react";
import AmountHeading from "../AmountContainer/AmountHeading/AmountHeading";
import { r1099AmountDisplayTexts } from "../../../../lib/languagePacks/en-us";
import DateInput from "../../../decorations/DateInput/DateInput";
import AmountContainer from "../AmountContainer/AmountContainer";

export default function Box13({
  clearDate,
  callbackRelatedToDate,
  dateGroup,
  dateVal,
  styles,
  showDateRequiredIndicator,
  toShowDateRequired,
}: any) {
  return (
    <AmountContainer
      class_="misc-info" // Only one class can be passed
      style={{ borderRight: `1px solid black`, ...styles }}
    >
      <AmountHeading heading={r1099AmountDisplayTexts.dateOfPayment} amountNum="13" />
      <DateInput
        minDate={new Date(2000, 11, 31)}
        maxDate={new Date(2022, 11, 31)}
        clearDate={clearDate}
        callback={callbackRelatedToDate}
        groupId={dateGroup}
        classes="display-from-top-left"
        value={dateVal}
        showDateRequiredIndicator={showDateRequiredIndicator}
        toShowDateRequired={toShowDateRequired}
      />
    </AmountContainer>
  );
}
