import { axiosInstance } from "../axiosConfig";
import devLogInstance from "../loggerConfig";
import Handler, { Main } from "./main";

export default async function raiseTicketHelper(
  query: string,
  responseType: string,
  setResponseMessageLoader: React.Dispatch<
    React.SetStateAction<{
      initial: boolean;
      responseTypeSelector: boolean;
      ticketRaiseResponse: boolean;
    }>
  >
) {
  try {
    setResponseMessageLoader((prevState) => {
      return { ...prevState, ticketRaiseResponse: true };
    });

    const { data } = await axiosInstance.post("/support/raise_ticket", {
      query,
      response_type: responseType,
    });
    devLogInstance.log({ response_after_raising_ticket: data });

    setResponseMessageLoader((prevState) => {
      return { ...prevState, ticketRaiseResponse: false };
    });

    return data.ticket_id;
  } catch (err) {
    await Handler.handleError(err, Main.stateDispatch, () => {
      raiseTicketHelper(query, responseType, setResponseMessageLoader);
    });
  }
}
