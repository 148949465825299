import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import devLogInstance from "../loggerConfig";
import ReviewCopyModifier from "./ReviewCopyModifier";
// import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";

export default class Review1099DIVModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;

  private amount1: number;
  private amount2: number;
  private amount3: number;
  private amount5: number;
  private amount6: number;
  private amount7: number;
  private amount8: number;
  private amount9: number;
  private amountA: number;
  private amountD: number;
  private amountB: number;
  private amountE: number;
  private amountH: number;
  private amountJ: number;
  private amountC: number;
  private amountF: number;
  private amountG: number;

  private fatcaFilingIndicator: boolean;
  private foreign_country_or_us_Possession: string;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount1: number,
    amount2: number,
    amount3: number,
    amountC: number,
    amount5: number,
    amount6: number,
    amount7: number,
    amount8: number,
    amount9: number,
    amountA: number,
    amountD: number,
    amountB: number,
    amountE: number,
    amountH: number,
    amountJ: number,
    amountF: number,
    amountG: number,
    foreign_country_or_us_Possession: string,
    fatcaFilingIndicator: boolean,
    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amountC = amountC;
    this.amount5 = amount5;
    this.amount6 = amount6;
    this.amount7 = amount7;
    this.amount8 = amount8;
    this.amount9 = amount9;
    this.amountA = amountA;
    this.amountD = amountD;
    this.amountB = amountB;
    this.amountE = amountE;
    this.amountH = amountH;
    this.amountJ = amountJ;
    this.amountF = amountF;
    this.amountG = amountG;

    this.fatcaFilingIndicator = fatcaFilingIndicator;
    this.foreign_country_or_us_Possession = foreign_country_or_us_Possession;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      await this.fillLSFForFormsWithFatcaFiling(
        this.fatcaFilingIndicator,
        this.accountNumber,
        this.secondTinNotice,
        -3
      );

      // *** RIGHT SIDE *** //

      // Calendar year
      this.drawCalendarYear();

      // Total ordinary dividends (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 61 - this.getFontSize(),
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount1Pos.x,
        y: amount1Pos.y,
        ...options,
      });

      // Qualified dividends penalty (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 36,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Total capital gain distr. (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 24,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount3Pos.x,
        y: amount3Pos.y,
        ...options,
      });

      // Unrecap. Sec. 1250 gain (amount 6)
      const amount6Pos = {
        x: amount3Pos.x + 100,
        y: amount3Pos.y,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount6Pos.x,
        y: amount6Pos.y,
        ...options,
      });

      // Section 1202 gain (amount 7)
      const amount7Pos = {
        x: amount3Pos.x,
        y: amount3Pos.y - 24,
      };
      formattedAmt = (this.amount7 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount7Pos.x,
        y: amount7Pos.y,
        ...options,
      });

      // Collectibles (28%) rate gain (amount 8)
      const amount8Pos = {
        x: amount6Pos.x,
        y: amount6Pos.y - 24,
      };
      formattedAmt = (this.amount8 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount8Pos.x,
        y: amount8Pos.y,
        ...options,
      });

      // Section 897 ordinary dividends (amount H)
      const amountHPos = {
        x: amount7Pos.x,
        y: amount7Pos.y - 24,
      };
      formattedAmt = (this.amountH / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountHPos.x,
        y: amountHPos.y,
        ...options,
      });

      // Section 897 capital gain (amount J)
      const amountJPos = {
        x: amount8Pos.x,
        y: amount8Pos.y - 24,
      };
      formattedAmt = (this.amountJ / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountJPos.x,
        y: amountJPos.y,
        ...options,
      });

      // Non-dividend distributions (amount 9)
      const amount9Pos = {
        x: amountHPos.x,
        y: amountHPos.y - 24,
      };
      formattedAmt = (this.amount9 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount9Pos.x,
        y: amount9Pos.y,
        ...options,
      });

      // Federal income tax withheld (amount A)
      const amountAPos = {
        x: amountJPos.x,
        y: amountJPos.y - 24,
      };
      formattedAmt = (this.amountA / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountAPos.x,
        y: amountAPos.y,
        ...options,
      });

      // Section 199A dividends (amount 5)
      const amount5Pos = {
        x: amount9Pos.x,
        y: amount9Pos.y - 24,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: amount5Pos.y,
        ...options,
      });

      // Investment expenses (amount B)
      const amountBPos = {
        x: amountAPos.x,
        y: amountAPos.y - 24,
      };
      formattedAmt = (this.amountB / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountBPos.x,
        y: amountBPos.y,
        ...options,
      });

      // Foreign tax paid (amount C)
      const amountCPos = {
        x: amount5Pos.x,
        y: amount5Pos.y - 36,
      };
      formattedAmt = (this.amountC / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountCPos.x,
        y: amountCPos.y,
        ...options,
      });

      // Foreign country or US possession (Box 8)
      const box8CharsPerLine = 26;
      const foreignCountryOrUsPossession = this.foreign_country_or_us_Possession;

      // Since the space is limited break the text content into multiple lines
      let linesNeeded = foreignCountryOrUsPossession.length / box8CharsPerLine;
      linesNeeded =
        foreignCountryOrUsPossession.length % box8CharsPerLine !== 0
          ? linesNeeded + 1
          : linesNeeded;
      devLogInstance.log({ linesNeededForBox8: linesNeeded });

      let start = 0;
      let end = 26;

      for (let i = 0; i < linesNeeded; i++) {
        const box8Pos = {
          x: amountBPos.x - 10,
          y: amountBPos.y - 22 - i * 7,
        };
        firstPage.drawText(foreignCountryOrUsPossession.substring(start, end), {
          x: box8Pos.x,
          y: box8Pos.y,
          ...options,
        });

        start = end;
        end += end;
      }

      // Cash liquidation distributions (amount D)
      const amountDPos = {
        x: amountCPos.x,
        y: amountCPos.y - 24,
      };
      formattedAmt = (this.amountD / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountDPos.x,
        y: amountDPos.y,
        ...options,
      });

      // Noncash liquidation distributions (amount E)
      const amountEPos = {
        x: amountDPos.x + 100,
        y: amountDPos.y,
      };
      formattedAmt = (this.amountE / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountEPos.x,
        y: amountEPos.y,
        ...options,
      });

      // Exempt-interest dividends (amount F)
      const amountFPos = {
        x: amountDPos.x,
        y: amountDPos.y - 36,
      };
      formattedAmt = (this.amountF / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountFPos.x,
        y: amountFPos.y,
        ...options,
      });

      // Specified private activity bond interest dividends (amount G)
      const amountGPos = {
        x: amountEPos.x,
        y: amountEPos.y - 36,
      };
      formattedAmt = (this.amountG / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountGPos.x,
        y: amountGPos.y,
        ...options,
      });

      //   State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: amountFPos.x,
          prevFieldYCoord: amountFPos.y,
        },
        {
          stateNum1: this.stateNum1,
        },
        {
          stateTax1: this.stateTax1,
        },
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        }
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string },
    stateTax1Obj: { stateTax1: number },
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number }
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord
    );

    // State details
    // State name
    stateDetailsMod.modifyStateNameDetail(state1Obj.state1, state2Obj.state2);
    // State num
    stateDetailsMod.modifyStateNumDetail(stateNum1Obj.stateNum1, stateNum2Obj.stateNum2);
    // State tax
    stateDetailsMod.modifyStateTaxDetail(
      stateTax1Obj.stateTax1,
      stateTax2Obj.stateTax2,
      false,
      -20
    );
  }
}
