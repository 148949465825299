import React, { useEffect, useState } from "react";
import "./ReviewForm.component.css";
import {
  B_1099,
  DIV_1099,
  FORM1099_B,
  FORM1099_DIV,
  FORM1099_INT,
  FORM1099_K,
  FORM1099_MISC,
  FORM1099_NEC,
  FORM1099_S,
  INT_1099,
  K_1099,
  MISC_1099,
  NEC_1099,
  S_1099,
} from "../../../lib/constants";
import { reviewFormLabels, stateInfoDisplayTexts } from "../../../lib/languagePacks/en-us";
import SecondTinNotice from "../GlobalFormComponents/InternalComponents/SecondTinNotice/SecondTinNotice";
import NecRightPortion from "./NECRightPortion/NecRightPortion";
import AccountNumber from "./RFAccount/AccountNumber/AccountNumber";
import RFAccount from "./RFAccount/RFAccount";
import RFPayerDetails from "./RFPayerDetails/RFPayerDetails";
import RFPayerTin from "./RFPayerTin/RFPayerTin";
import RFRecLocation from "./RFRecLocation/RFRecLocation";
import RFRecName from "./RFRecName/RFRecName";
import RFRecTin from "./RFRecTin/RFRecTin";
import RFStreetAddress from "./RFStreetAddress/RFStreetAddress";
import EditNextBtn from "./Edit-Next/EditNextBtn";
import MiscRightPortion from "./MiscRightPortion/MiscRightPortion";
import Fatca from "../GlobalFormComponents/FATCA/Fatca";
import IntRightPortion from "./INTRightPortion/IntRightPortion";
import DivRightPortion from "./DIVRightPortion/DivRightPortion";
import SRightPortion from "./Form1099SRightPortion/SRightPortion";
import StateWithoutIncomeField from "./RFState/StateWithoutIcomeField/StateWithoutIncomeField";
import BRightPortion from "./BRightPortion/BRightPortion";
import KRightPortion from "./KRightPortion/KRightPortion";
import FilerPayment from "../FilerPayment/FilerPayment";
import PseDetails from "../PseDetails/PseDetails";
import devLogInstance from "../../../lib/loggerConfig";
import ombAndRev from "../../../lib/json/OMBandRev.json";
import fs from "fs";

const formsWithReverseFatca = [FORM1099_B];
export const formsWithStateFilingOnleft = [FORM1099_B];
const formsWithTinOnRight = [FORM1099_K];
const formsWithoutFatca = [FORM1099_K];
// const formsWithNoStateIncomeField = [FORM1099_B, FORM1099_DIV, FORM1099_INT];

export default function ReviewForm({ formType, taxYear, formData, recDataUrl }: any) {
  devLogInstance.log(formType);

  return (
    <div className="rf-main">
      <div className="rf-wrapper-container">
        <div className="rf-wrapper h-full">
          <iframe title="Review Copy" src={`${recDataUrl}#zoom=141`} className="h-full w-full" />
        </div>
      </div>
      {/* <div className="rf-wrapper-container">
        <div className="rf-wrapper">
          <div
            className="left-portion-details rf-left-portion"
            style={{ border: `1px solid black`, overflow: "hidden" }}
          >
            <RFPayerDetails formData={formData} />
            {!formsWithTinOnRight.includes(formType) && (
              <div className="tin-block" style={{ borderBottom: `1px solid black` }}>
                <RFPayerTin formData={formData} />
                <RFRecTin formData={formData} />
              </div>
            )}
            {formType === FORM1099_K && (
              <FilerPayment
                paymentTypeIndicator={formData?.type_of_payment_indicator}
                filerTypeIndicator={formData?.type_of_filer_indicator}
                inReviewForm={true}
              />
            )}
            <RFRecName formData={formData} />
            <RFStreetAddress formData={formData} />
            <RFRecLocation formData={formData} />
            {formType === FORM1099_K && (
              <PseDetails
                psename={formData?.p_s_e_name}
                psenamecont={formData?.p_s_e_name_cont}
                psephone={formData?.p_s_e_phone_number}
                inReviewForm={true}
              />
            )}
            {!formsWithReverseFatca.includes(formType) ? (
              <>
                {formType !== FORM1099_NEC &&
                  formType !== FORM1099_S &&
                  !formsWithoutFatca.includes(formType) && (
                    <Fatca globalFormData={formData} type={"review-form"} />
                  )}
                <RFAccount>
                  <AccountNumber formData={formData} />
                  {formType !== FORM1099_S && (
                    <SecondTinNotice
                      status={formData?.second_tin_notice}
                      onChangeHandler={() => {}}
                      label={reviewFormLabels.secondTinNotice}
                    />
                  )}
                </RFAccount>
              </>
            ) : (
              <>
                <RFAccount styles={{ height: "13%" }}>
                  <AccountNumber formData={formData} />
                  {formType !== FORM1099_S && (
                    <SecondTinNotice
                      status={formData?.second_tin_notice}
                      onChangeHandler={() => {}}
                      label={reviewFormLabels.secondTinNotice}
                    />
                  )}
                </RFAccount>
                <Fatca
                  globalFormData={formData}
                  type={"review-form"}
                  showCusip={true}
                  styles={{ borderTop: "1px solid black" }}
                />
              </>
            )}
            {formType && formsWithStateFilingOnleft.includes(formType) && (
              <StateWithoutIncomeField
                displayTexts={stateInfoDisplayTexts.div1099}
                formData={formData}
                styles={{ borderTop: "none", borderBottom: "none", height: "100%" }}
                onLeft={true}
              />
            )}
          </div>
          {formType === FORM1099_NEC && (
            <NecRightPortion
              formType={formType}
              taxYear={taxYear}
              formName={NEC_1099}
              formData={formData}
              ombNum={ombAndRev.omb.nec}
              revMonth={ombAndRev.rev.nec}
            />
          )}
          {formType === FORM1099_MISC && (
            <MiscRightPortion
              formType={formType}
              taxYear={taxYear}
              formName={MISC_1099}
              formData={formData}
              ombNum={ombAndRev.omb.misc}
              revMonth={ombAndRev.rev.misc}
            />
          )}
          {formType === FORM1099_INT && (
            <IntRightPortion
              formType={formType}
              formData={formData}
              formName={INT_1099}
              taxYear={taxYear}
              ombNum={ombAndRev.omb.int}
              revMonth={ombAndRev.rev.int}
            />
          )}
          {formType === FORM1099_DIV && (
            <DivRightPortion
              formType={formType}
              formData={formData}
              formName={DIV_1099}
              taxYear={taxYear}
              ombNum={ombAndRev.omb.div}
              revMonth={ombAndRev.rev.div}
            />
          )}
          {formType === FORM1099_S && (
            <SRightPortion
              formType={formType}
              formData={formData}
              formName={S_1099}
              taxYear={taxYear}
              ombNum={ombAndRev.omb.s}
              revMonth={ombAndRev.rev.s}
            />
          )}
          {formType === FORM1099_B && (
            <BRightPortion
              formType={formType}
              formData={formData}
              formName={B_1099}
              taxYear={taxYear}
              ombNum={ombAndRev.omb.b}
              revMonth={ombAndRev.rev.b}
            />
          )}
          {formType === FORM1099_K && (
            <KRightPortion
              formType={formType}
              formData={formData}
              formName={K_1099}
              taxYear={taxYear}
              ombNum={ombAndRev.omb.k}
              revMonth={ombAndRev.rev.k}
            />
          )}
        </div>
      </div> */}
      <EditNextBtn />
    </div>
  );
}
