import React from "react";
import { BsTicketPerforatedFill } from "react-icons/bs";
import TextModifier from "../../../lib/TextModifier";
import { Link } from "react-router-dom";
import { RAISED_TICKET_INFO } from "../../../lib/routes";

interface Props extends React.HTMLProps<HTMLLIElement> {
  id: string;
  query: string;
  status: string;
  creationDateStr: string;
}

export const ticketStatusMap: any = {
  open: {
    label: "Open",
    color: "text-green-500",
  },
  closed: {
    label: "Closed",
    color: "text-gray-500",
  },
  inProgress: {
    label: "In progress",
    color: "text-yellow-500",
  },
};

export default function RaisedTicketListItem(props: Props) {
  return (
    <Link to={`${RAISED_TICKET_INFO}?id=${props.id}&status=${props.status}&query=${props.query}`}>
      <li
        className={`p-4 flex items-center gap-4 cursor-pointer hover:bg-taxeve-secondary-violet ${props.className}`}
      >
        <span className="ticket-icon">
          <BsTicketPerforatedFill className="text-2xl" />
        </span>
        <div className="w-full flex items-center justify-between">
          <div>
            <h3 className="ticket-id flex items-center gap-4">
              Ticket Id: {props.id}{" "}
              <span className={`${ticketStatusMap[props.status].color}`}>
                ({ticketStatusMap[props.status].label})
              </span>
            </h3>
            <p>{TextModifier.truncate(props.query, 100)}</p>
          </div>
          <div className="creation-period">
            <em className="font-bold text-gray-400">
              Created: {TextModifier.modifyDate(new Date(props.creationDateStr))}
            </em>
          </div>
        </div>
      </li>
    </Link>
  );
}
