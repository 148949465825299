import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";
import FormatDate from "../utils/Helpers/FormatDate";

export default class Review1099BModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;

  private amount2: number;
  private amount3: number;
  private amount4: number;
  private amount5: number;
  private amount7: number;
  private amount9: number;
  private amountA: number;
  private amountC: number;
  private amountD: number;
  private amountB: number;

  private cusipNumber: string;
  private fatcaFilingIndicator: boolean;
  private applicable_check_box_of_form_8949: string;
  private description_of_property: string;
  private dateAcquired: string;
  private dateSold: string;
  private type_of_gain_or_loss_indicator: string;
  private gross_proceeds_indicator: string;
  private applicable_check_box_for_collectables: boolean;
  private applicable_check_box_for_qof: boolean;
  private noncovered_security_indicator: boolean;
  private loss_not_allowed_indicator: boolean;
  private basis_reported_to_irs: boolean;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount2: number,
    amount3: number,
    amount4: number,
    amount5: number,
    amount7: number,
    amount9: number,
    amountA: number,
    amountC: number,
    amountD: number,
    amountB: number,
    cusipNumber: string,
    fatcaFilingIndicator: boolean,
    applicable_check_box_of_form_8949: string,
    description_of_property: string,
    dateAcquired: string,
    dateSold: string,
    type_of_gain_or_loss_indicator: string,
    gross_proceeds_indicator: string,
    applicable_check_box_for_collectables: boolean,
    applicable_check_box_for_qof: boolean,
    noncovered_security_indicator: boolean,
    loss_not_allowed_indicator: boolean,
    basis_reported_to_irs: boolean,
    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amount4 = amount4;
    this.amount5 = amount5;
    this.amount7 = amount7;
    this.amount9 = amount9;
    this.amountA = amountA;
    this.amountC = amountC;
    this.amountD = amountD;
    this.amountB = amountB;

    this.cusipNumber = cusipNumber ? cusipNumber : " ";
    this.fatcaFilingIndicator = fatcaFilingIndicator;
    this.applicable_check_box_of_form_8949 = applicable_check_box_of_form_8949;
    this.description_of_property = description_of_property;
    this.dateAcquired = dateAcquired;
    this.dateSold = dateSold;
    this.type_of_gain_or_loss_indicator = type_of_gain_or_loss_indicator;
    this.gross_proceeds_indicator = gross_proceeds_indicator;
    this.applicable_check_box_for_collectables = applicable_check_box_for_collectables;
    this.applicable_check_box_for_qof = applicable_check_box_for_qof;
    this.noncovered_security_indicator = noncovered_security_indicator;
    this.loss_not_allowed_indicator = loss_not_allowed_indicator;
    this.basis_reported_to_irs = basis_reported_to_irs;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // *** LEFT SIDE *** //

      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      await this.fillLSFForFormsWithFatcaFiling(
        this.fatcaFilingIndicator,
        this.accountNumber,
        this.secondTinNotice,
        0,
        0,
        true,
        true
      );

      // Fill the account number row seperately
      const accountNumberPos = {
        x: this.getLeftSideXCoord() + 4,
        y: height - 300 - this.getFontSize(),
      };
      firstPage.drawText(this.accountNumber, {
        x: accountNumberPos.x,
        y: accountNumberPos.y,
        ...options,
      });

      const secondTinNoticePos = {
        x: accountNumberPos.x + 100 * 2.15,
        y: accountNumberPos.y - 2,
      };
      if (this.secondTinNotice) {
        firstPage.drawText("z", {
          x: secondTinNoticePos.x,
          y: secondTinNoticePos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Fill the fatca filing row seperately
      const cusipNumPos = {
        x: accountNumberPos.x,
        y: accountNumberPos.y - 24,
      };
      firstPage.drawText(this.cusipNumber, {
        x: cusipNumPos.x,
        y: cusipNumPos.y,
        ...options,
      });

      const fatcaIndicatorPos = {
        x: cusipNumPos.x + 100 * 2.22,
        y: cusipNumPos.y - 2,
      };
      if (this.fatcaFilingIndicator) {
        firstPage.drawText("z", {
          x: fatcaIndicatorPos.x,
          y: fatcaIndicatorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      //   State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: cusipNumPos.x + 47,
          prevFieldYCoord: cusipNumPos.y,
        },
        {
          stateNum1: this.stateNum1,
        },
        {
          stateTax1: this.stateTax1,
        },
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        }
      );

      // *** RIGHT SIDE *** //

      // Applicable checkbox on Form 8949
      const applicableCheckboxOn8949Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 61 - this.getFontSize(),
      };
      firstPage.drawText(this.applicable_check_box_of_form_8949, {
        x: applicableCheckboxOn8949Pos.x,
        y: applicableCheckboxOn8949Pos.y,
        ...options,
      });

      // Description of property (Box 1a)
      const box1aPos = {
        x: applicableCheckboxOn8949Pos.x - 5,
        y: applicableCheckboxOn8949Pos.y - 36,
      };
      firstPage.drawText(this.description_of_property, {
        x: box1aPos.x,
        y: box1aPos.y,
        ...options,
      });

      // Date acquired (Box 1b)
      const box1bPos = {
        x: box1aPos.x,
        y: box1aPos.y - 24,
      };
      const dateAcquiredFormatter = new FormatDate(this.dateAcquired);
      const dateAcquiredFormatted = dateAcquiredFormatter.format();
      firstPage.drawText(dateAcquiredFormatted, {
        x: box1bPos.x,
        y: box1bPos.y,
        ...options,
      });

      // Date sold or disposed (Box 1c)
      const box1cPos = {
        x: box1bPos.x + 100,
        y: box1bPos.y,
      };
      const dateSoldFormatter = new FormatDate(this.dateSold);
      const dateSoldFormatted = dateSoldFormatter.format();
      firstPage.drawText(dateSoldFormatted, {
        x: box1cPos.x,
        y: box1cPos.y,
        ...options,
      });

      // Proceeds (amount 2)
      const amount2Pos = {
        x: box1bPos.x + 4,
        y: box1bPos.y - 24,
      };
      let formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Cost or other basis (amount 3)
      const amount3Pos = {
        x: amount2Pos.x + 100,
        y: amount2Pos.y,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount3Pos.x,
        y: amount3Pos.y,
        ...options,
      });

      // Accrued market discount (amount D)
      const amountDPos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 24,
      };
      formattedAmt = (this.amountD / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountDPos.x,
        y: amountDPos.y,
        ...options,
      });

      // Wash sale loss disallowed (amount 5)
      const amount5Pos = {
        x: amount3Pos.x,
        y: amount3Pos.y - 24,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: amount5Pos.y,
        ...options,
      });

      // Type of gain or loss indicator (box 2)
      const box2CheckBoxesXCord = {
        x: amountDPos.x + 74,
      };
      const isShortTermGainOrLoss = this.type_of_gain_or_loss_indicator === "1";
      const isLongTermGainOrLoss = this.type_of_gain_or_loss_indicator === "2";
      const isOrdinaryAndShortTerm = this.type_of_gain_or_loss_indicator === "3";
      const isOrdinaryAndLongTerm = this.type_of_gain_or_loss_indicator === "4";

      if (isShortTermGainOrLoss || isOrdinaryAndShortTerm) {
        const shortTermGainOrLossCheckBoxPos = {
          x: box2CheckBoxesXCord.x,
          y: amountDPos.y - 13,
        };
        firstPage.drawText("z", {
          x: shortTermGainOrLossCheckBoxPos.x,
          y: shortTermGainOrLossCheckBoxPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      } else if (isLongTermGainOrLoss || isOrdinaryAndLongTerm) {
        const longTermGainOrLossCheckBoxPos = {
          x: box2CheckBoxesXCord.x,
          y: amountDPos.y - 24,
        };
        firstPage.drawText("z", {
          x: longTermGainOrLossCheckBoxPos.x,
          y: longTermGainOrLossCheckBoxPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      if (isOrdinaryAndShortTerm || isOrdinaryAndLongTerm) {
        const ordinaryCheckBoxPos = {
          x: box2CheckBoxesXCord.x,
          y: amountDPos.y - 36,
        };
        firstPage.drawText("z", {
          x: ordinaryCheckBoxPos.x,
          y: ordinaryCheckBoxPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Applicable checkbox for collectibles (box 3)
      if (this.applicable_check_box_for_collectables) {
        const applicableCheckboxCollectiblesPos = {
          x: amount5Pos.x + 74,
          y: amount5Pos.y - 24,
        };
        firstPage.drawText("z", {
          x: applicableCheckboxCollectiblesPos.x,
          y: applicableCheckboxCollectiblesPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Applicable checkbox for QOF (box 3)
      if (this.applicable_check_box_for_qof) {
        const applicableCheckboxQOFPos = {
          x: amount5Pos.x + 74,
          y: amount5Pos.y - 36,
        };
        firstPage.drawText("z", {
          x: applicableCheckboxQOFPos.x,
          y: applicableCheckboxQOFPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Federal income tax withheld (amount 4)
      const amount4Pos = {
        x: amountDPos.x,
        y: amountDPos.y - 36 - 24,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: amount4Pos.y,
        ...options,
      });

      // Check If non-covered security
      if (this.noncovered_security_indicator) {
        const nonCoveredSecurityPos = {
          x: amount5Pos.x + 69,
          y: amount5Pos.y - 36 - 25,
        };
        firstPage.drawText("z", {
          x: nonCoveredSecurityPos.x,
          y: nonCoveredSecurityPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Gross proceeds indicator
      if (this.gross_proceeds_indicator) {
        const isGrossProceeds = this.gross_proceeds_indicator === "1";
        const isNetProceeds = this.gross_proceeds_indicator === "2";
        const grossProceedsIndicatorPos = {
          x: amount4Pos.x + 74,
          y: amount4Pos.y - (isGrossProceeds ? 25 : isNetProceeds ? 37 : 0),
        };
        firstPage.drawText("z", {
          x: grossProceedsIndicatorPos.x,
          y: grossProceedsIndicatorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Loss not allowed indicator
      if (this.loss_not_allowed_indicator) {
        const lossNotAllowedIndicatorPos = {
          x: amount5Pos.x + 69,
          y: amount5Pos.y - 36 * 2 - 25,
        };
        firstPage.drawText("z", {
          x: lossNotAllowedIndicatorPos.x,
          y: lossNotAllowedIndicatorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Profit (or loss) realized in 2023 (amount 9)
      const amount9Pos = {
        x: amount4Pos.x,
        y: amount4Pos.y - 36 * 2,
      };
      formattedAmt = (this.amount9 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount9Pos.x,
        y: amount9Pos.y,
        ...options,
      });

      // Unrealized profit (or loss) on open contracts 12/31/2022 (amount A)
      const amountAPos = {
        x: amount9Pos.x + 100,
        y: amount9Pos.y,
      };
      formattedAmt = (this.amountA / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountAPos.x,
        y: amountAPos.y,
        ...options,
      });

      // Unrealized profit (or loss) on open contracts 12/31/2023 (amount B)
      const amountBPos = {
        x: amount9Pos.x,
        y: amount9Pos.y - 36,
      };
      formattedAmt = (this.amountB / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountBPos.x,
        y: amountBPos.y,
        ...options,
      });

      // Aggregate profit (or loss) (amount C)
      const amountCPos = {
        x: amountAPos.x,
        y: amountAPos.y - 36,
      };
      formattedAmt = (this.amountC / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountCPos.x,
        y: amountCPos.y,
        ...options,
      });

      // Basis reported to IRS indicator
      if (this.basis_reported_to_irs) {
        const indicatorPos = {
          x: amountBPos.x + 68,
          y: amountBPos.y - 24,
        };
        firstPage.drawText("z", {
          x: indicatorPos.x,
          y: indicatorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Bartering (amount 7)
      const amount7Pos = {
        x: amountCPos.x,
        y: amountCPos.y - 24,
      };
      formattedAmt = (this.amount7 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount7Pos.x,
        y: amount7Pos.y,
        ...options,
      });

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string },
    stateTax1Obj: { stateTax1: number },
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number }
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord
    );

    // State details
    // State name
    stateDetailsMod.modifyStateNameDetail(state1Obj.state1, state2Obj.state2);
    // State num
    stateDetailsMod.modifyStateNumDetail(stateNum1Obj.stateNum1, stateNum2Obj.stateNum2);
    // State tax
    stateDetailsMod.modifyStateTaxDetail(stateTax1Obj.stateTax1, stateTax2Obj.stateTax2, false);
  }
}
