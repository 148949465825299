import React from "react";
import { ga4 } from "../../App";
import ga4Const from "../../lib/json/AnalyticsRelated/analyticsConst.json";
import redirect from "../../lib/RoutingMethods/redirect";

export default function KnowMoreBtn({ linkTo, formType }: { linkTo: string; formType: string }) {
  const handleClick = () => {
    // Send GA4 event
    ga4.sendEvent({
      category: ga4Const.event.category.clickEvent,
      action: `${ga4Const.event.action.clickedKnowMoreBtn} (${formType})`,
    });

    // Redirect
    redirect(linkTo);
  };

  return (
    <button
      onClick={handleClick}
      className="text-lg py-2 px-4 rounded-lg bg-taxeve-primary-violet text-white"
    >
      Know more
    </button>
  );
}
