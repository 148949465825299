import { PDFPageDrawTextOptions, StandardFonts, rgb } from "pdf-lib";
import PDFModifier from "./PDFModifier";
import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import formatTin from "../form/utils/formatTin";
import maskTin from "../utils/maskTin";
import fontkit from "@pdf-lib/fontkit";
import { reviewCopyTagText } from "../languagePacks/en-us";

export default class ReviewCopyModifier extends PDFModifier {
  private formType: string;
  private taxYear: string | number;

  // Payer data
  private payer_name: string;
  private payer_address_line1: string;
  private payer_address_line2: string;
  private payer_city: string;
  private payer_state: string;
  private payer_country: string;
  private payer_zipcode: string;
  private payer_phone_number: string;
  private payer_tin_type: string;
  private payer_tin: number;

  // Recipient data
  private recipient_name: string;
  private recipient_name_cont: string;
  private recipient_address_line1: string;
  private recipient_address_line2: string;
  private recipient_city: string;
  private recipient_state: string;
  private recipient_country: string;
  private recipient_zipcode: string;
  private recipient_tin_type: string;
  private recipient_tin: string;

  private toMaskTin: boolean;

  private fontSize = 7;
  private options: PDFPageDrawTextOptions = { size: this.fontSize, color: rgb(0, 0.53, 0.71) };

  private regularForms = [
    "1099-A",
    "1099-G",
    "1099-NEC",
    "1099-S",
    "1099-C",
    "1099-PATR",
    "1099-Q",
  ];
  private formsWithFatcaFiling = ["1099-MISC", "1099-INT", "1099-DIV", "1099-B", "1099-R"];
  private leftSideXCoord: number = 0;

  private recTinPos = {
    x: 0,
    y: 0,
  };

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean
  ) {
    super(pdfBuffer);
    this.formType = formType;
    this.taxYear = taxYear;

    this.payer_name = payerData.payer_name;
    this.payer_address_line1 = payerData.payer_address_line1;
    this.payer_address_line2 = payerData.payer_address_line2;
    this.payer_city = payerData.payer_city;
    this.payer_state = payerData.payer_state;
    this.payer_country = payerData.payer_country;
    this.payer_zipcode = payerData.payer_zipcode;
    this.payer_phone_number = payerData.payer_phone_number;
    this.payer_tin_type = payerData.payer_tin_type;
    this.payer_tin = payerData.payer_tin;

    this.recipient_address_line1 = recData.recipient_address_line1;
    this.recipient_address_line2 = recData.recipient_address_line2;
    this.recipient_city = recData.recipient_city;
    this.recipient_country = recData.recipient_country;
    this.recipient_name = recData.recipient_name;
    this.recipient_name_cont = recData.recipient_name_cont;
    this.recipient_state = recData.recipient_state;
    this.recipient_tin = recData.recipient_tin;
    this.recipient_tin_type = recData.recipient_tin_type;
    this.recipient_zipcode = recData.recipient_zipcode;

    this.toMaskTin = toMaskTin;
  }

  // Getters
  protected getPayerData(): ReviewCopyPayerData {
    return {
      payer_name: this.payer_name,
      payer_address_line1: this.payer_address_line1,
      payer_address_line2: this.payer_address_line2,
      payer_city: this.payer_city,
      payer_state: this.payer_state,
      payer_country: this.payer_country,
      payer_phone_number: this.payer_phone_number,
      payer_tin: this.payer_tin,
      payer_tin_type: this.payer_tin_type,
      payer_zipcode: this.payer_zipcode,
    };
  }

  protected getRecipientData(): ReviewCopyRecipientData {
    return {
      recipient_name: this.recipient_name,
      recipient_name_cont: this.recipient_name_cont,
      recipient_address_line1: this.recipient_address_line1,
      recipient_address_line2: this.recipient_address_line2,
      recipient_city: this.recipient_city,
      recipient_state: this.recipient_state,
      recipient_country: this.recipient_country,
      recipient_zipcode: this.recipient_zipcode,
      recipient_tin_type: this.recipient_tin_type,
      recipient_tin: this.recipient_tin,
    };
  }

  protected getReviewCopy() {
    return this.getPdfDoc();
  }

  public getOptions() {
    return this.options;
  }

  public getFontSize() {
    return this.fontSize;
  }

  protected getToMaskTin() {
    return this.toMaskTin;
  }

  protected getFormType() {
    return this.formType;
  }

  protected getLeftSideXCoord() {
    return this.leftSideXCoord;
  }

  protected getTaxYear() {
    return this.taxYear;
  }

  public getRecTinPos() {
    return this.recTinPos;
  }

  protected getFormsWithFatcaFiling() {
    return this.formsWithFatcaFiling;
  }

  // Setters
  private setOptions(options: PDFPageDrawTextOptions) {
    this.options = options;
  }

  private setLeftSideXCoord(x: number) {
    this.leftSideXCoord = x;
  }

  public async configureReviewCopy() {
    try {
      const reviewCopy = this.getReviewCopy();

      // Embed font
      const font = await reviewCopy.embedFont(StandardFonts.HelveticaBold);

      // Set pdf modification options
      const options = {
        ...this.getOptions(),
        font,
      };
      this.setOptions(options);

      this.setLeftSideXCoord(50);
    } catch (err) {
      throw err;
    }
  }

  public async loadReviewCopy() {
    try {
      await this.loadPdfDoc();
    } catch (err) {
      throw err;
    }
  }

  private placeReviewCopyTag() {
    const page = this.getPdfPage(1);
    const { height } = page.getSize();

    const tagFontSize = 11;

    const tagPosition = {
      x: this.getLeftSideXCoord() + 181,
      y: height - 5 - tagFontSize,
    };
    page.drawText(reviewCopyTagText, {
      x: tagPosition.x,
      y: tagPosition.y,
      ...this.getOptions(),
      size: tagFontSize,
      color: rgb(1, 0, 0),
    });
  }

  public async modifyPayerData(opts?: {
    isRegularForm?: boolean;
    isFormWithFatcaFiling?: boolean;
  }) {
    try {
      // Place the Review Copy tag initially
      this.placeReviewCopyTag();

      const options = this.getOptions();

      if (options) {
        // Get the first page
        const firstPage = this.getPdfPage(1); // There will be a single page only

        const { height } = firstPage.getSize();

        // Draw payer name
        const payerDataPos = {
          x: this.getLeftSideXCoord(),
          y: 50,
        };
        firstPage.drawText(this.getPayerData().payer_name, {
          x: payerDataPos.x + 4,
          y: height - payerDataPos.y - 7 * 2,
          ...options,
        });
        // Draw payer streed address
        firstPage.drawText(
          this.getPayerData().payer_address_line1 + " " + this.getPayerData().payer_address_line2,
          {
            x: payerDataPos.x + 4,
            y: height - payerDataPos.y - this.getFontSize() * 2 - 8,
            ...options,
          }
        );
        // Draw payer city, state and zipcode
        firstPage.drawText(
          `${this.getPayerData().payer_city} ${this.getPayerData().payer_state} ${
            this.getPayerData().payer_zipcode
          }`,
          {
            x: payerDataPos.x + 4,
            y: height - payerDataPos.y - this.getFontSize() * 2 - 16,
            ...options,
          }
        );
        // Draw payer phone number
        firstPage.drawText(this.getPayerData().payer_phone_number, {
          x: payerDataPos.x + 4,
          y: height - payerDataPos.y - this.getFontSize() * 2 - 24,
          ...options,
        });

        if (this.regularForms.includes(this.formType) || opts?.isRegularForm) {
          // Draw payer tin
          const payerTinPos = {
            x: 90,
            y: 112,
          };
          const payerTin =
            this.getPayerData().payer_tin_type === "EIN"
              ? formatTin(this.getPayerData().payer_tin, this.getPayerData().payer_tin_type)
              : this.getToMaskTin()
              ? maskTin(
                  formatTin(this.getPayerData().payer_tin, this.getPayerData().payer_tin_type),
                  this.getPayerData().payer_tin_type
                )
              : formatTin(this.getPayerData().payer_tin, this.getPayerData().payer_tin_type);
          firstPage.drawText(payerTin, {
            x: payerTinPos.x + 4,
            y: height - payerTinPos.y - this.getFontSize() * 2,
            ...options,
          });
        }
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async modifyRecData(opts?: { isRegularForm?: boolean; isFormWithFatcaFiling?: boolean }) {
    try {
      const options = this.getOptions();

      if (options) {
        if (opts && opts.isFormWithFatcaFiling)
          throw new Error("This method only supports regual forms without fatca filing!");

        if (this.regularForms.includes(this.getFormType()) || opts?.isRegularForm) {
          // Get the first page
          const firstPage = this.getPdfPage(1); // There will be a single page only

          const { height } = firstPage.getSize();

          // Draw recipient tin
          this.recTinPos = {
            x: 180,
            y: 112,
          };
          const recTin =
            this.getRecipientData().recipient_tin_type === "EIN"
              ? formatTin(
                  this.getRecipientData().recipient_tin,
                  this.getRecipientData().recipient_tin_type
                )
              : this.getToMaskTin()
              ? maskTin(
                  formatTin(
                    this.getRecipientData().recipient_tin,
                    this.getRecipientData().recipient_tin_type
                  ),
                  this.getRecipientData().recipient_tin_type
                )
              : formatTin(
                  this.getRecipientData().recipient_tin,
                  this.getRecipientData().recipient_tin_type
                );
          firstPage.drawText(recTin, {
            x: this.recTinPos.x + 4,
            y: height - this.recTinPos.y - this.getFontSize() * 2,
            ...options,
          });

          // Draw recipient name
          const recNamePos = {
            x: this.getLeftSideXCoord(),
            y: 138,
          };
          const recName = this.getRecipientData().recipient_name || "";
          firstPage.drawText(recName, {
            x: recNamePos.x + 4,
            y: height - recNamePos.y - this.getFontSize() * 2,
            ...options,
          });

          // Draw recipient street address
          const recStreedAddressPos = {
            x: this.getLeftSideXCoord(),
            y: 172,
          };
          const recStreedAddress =
            this.getRecipientData().recipient_address_line1 +
            " " +
            this.getRecipientData().recipient_address_line2;
          firstPage.drawText(recStreedAddress, {
            x: recStreedAddressPos.x + 4,
            y: height - recStreedAddressPos.y - this.getFontSize() * 2,
            ...options,
          });

          // Draw recipient city, state, zipcode
          const recLocationPos = {
            x: this.getLeftSideXCoord(),
            y: 196,
          };
          const recLocation =
            this.getRecipientData().recipient_city +
            " " +
            this.getRecipientData().recipient_state +
            " " +
            this.getRecipientData().recipient_zipcode;
          firstPage.drawText(recLocation, {
            x: recLocationPos.x + 4,
            y: height - recLocationPos.y - this.getFontSize() * 2,
            ...options,
          });
        }
      } else throw new Error("Review copy pdf options is null");
    } catch (err) {
      throw err;
    }
  }

  public async modifySecondTinNotice(secondTinNoticePos: { x: number; y: number }) {
    try {
      const firstPage = this.getPdfPage(1);
      const { height } = firstPage.getSize();

      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
      firstPage.drawText("z", {
        x: secondTinNoticePos.x + 4,
        y: height - secondTinNoticePos.y - 8 * 2,
        ...this.getOptions(),
        size: 8,
        font: tickMark,
      });
    } catch (err) {
      throw err;
    }
  }

  protected drawCalendarYear(extraPaddingX?: number, extraPaddingY?: number) {
    const firstPage = this.getPdfPage(1);
    const { height } = firstPage.getSize();

    const calendarYearPos = {
      x: this.getLeftSideXCoord() + 137 * 2 + 100 + (!extraPaddingX ? 0 : extraPaddingX),
      y: 97 + (!extraPaddingY ? 0 : extraPaddingY),
    };
    const taxYear = this.getTaxYear();
    firstPage.drawText(typeof taxYear !== "string" ? taxYear.toString() : taxYear, {
      x: calendarYearPos.x,
      y: height - calendarYearPos.y - this.getFontSize(),
      ...this.getOptions(),
    });
  }

  protected drawCalendarYearPartly(paddingX?: number, paddingY?: number) {
    const firstPage = this.getPdfPage(1);
    const { height } = firstPage.getSize();

    const calendarYearPos = {
      x: this.getLeftSideXCoord() + 143 * 2 + 100 + (!paddingX ? 0 : paddingX),
      y: 97 + (!paddingY ? 0 : paddingY),
    };
    let taxYear = this.getTaxYear().toString();
    taxYear = taxYear.substring(2);
    firstPage.drawText(taxYear, {
      x: calendarYearPos.x,
      y: height - calendarYearPos.y - this.getFontSize(),
      ...this.getOptions(),
    });
  }

  // Fill left side fields for forms with fatca filing
  protected async fillLSFForFormsWithFatcaFiling(
    fatcaFilingIndicator: boolean,
    accountNumber: string,
    secondTinNoticeIndicator: boolean,
    extraCheckBoxPaddingX?: number,
    extraCheckBoxPaddingY?: number,
    toNotFillAccountNumberRow?: boolean,
    toNotFillFatcaFilingRow?: boolean,
    extraRecDetailsPaddingX?: number,
    extraRecDetailsPaddingY?: number
  ) {
    if (this.formsWithFatcaFiling.includes(this.formType)) {
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Fill payer tin
      const payerTinPos = {
        x: this.getLeftSideXCoord() + 4,
        y: 150,
      };
      const payerTin = this.getPayerData().payer_tin;
      const payerTinType = this.getPayerData().payer_tin_type;
      let formattedPayerTin = formatTin(payerTin, payerTinType);
      formattedPayerTin =
        payerTinType === "EIN"
          ? formattedPayerTin
          : this.getToMaskTin()
          ? maskTin(formattedPayerTin, payerTinType)
          : formattedPayerTin;
      firstPage.drawText(formattedPayerTin, {
        x: payerTinPos.x + 35,
        y: height - payerTinPos.y - this.getFontSize(),
        ...options,
      });

      // Fill recipient tin
      const recTinPos = {
        x: (payerTinPos.x + 35) * 2.25,
        y: payerTinPos.y,
      };
      const recTin = this.getRecipientData().recipient_tin;
      const recTinType = this.getRecipientData().recipient_tin_type;
      let formattedTin = formatTin(recTin, recTinType);
      formattedTin =
        recTinType === "EIN"
          ? formattedTin
          : this.getToMaskTin()
          ? maskTin(formattedTin, recTinType)
          : formattedTin;
      firstPage.drawText(formattedTin, {
        x: recTinPos.x,
        y: height - recTinPos.y - this.getFontSize(),
        ...options,
      });

      // Fill recipient name
      const recNamePos = {
        x: payerTinPos.x,
        y: payerTinPos.y + 42,
      };
      firstPage.drawText(
        this.getRecipientData().recipient_name + " " + this.getRecipientData().recipient_name_cont,
        {
          x: recNamePos.x,
          y: height - recNamePos.y - this.getFontSize(),
          ...options,
        }
      );

      // Fill recipient street address
      const recStreedAddressPos = {
        x: recNamePos.x,
        y: recNamePos.y + 37,
      };
      firstPage.drawText(
        this.getRecipientData().recipient_address_line1 +
          " " +
          this.getRecipientData().recipient_address_line2,
        {
          x: recStreedAddressPos.x + (extraRecDetailsPaddingX || 0),
          y: height - recStreedAddressPos.y - this.getFontSize() - (extraRecDetailsPaddingY || 0),
          ...options,
        }
      );

      // Fill recipient local address
      const recLocalAddressPos = {
        x: recStreedAddressPos.x,
        y: recStreedAddressPos.y + 35,
      };
      firstPage.drawText(
        this.getRecipientData().recipient_city +
          " " +
          this.getRecipientData().recipient_state +
          " " +
          this.getRecipientData().recipient_zipcode,
        {
          x: recLocalAddressPos.x + (extraRecDetailsPaddingX || 0),
          y: height - recLocalAddressPos.y - this.getFontSize() - (extraRecDetailsPaddingY || 0),
          ...options,
        }
      );

      if (toNotFillFatcaFilingRow) return;
      else {
        // Fill fatca filing indicator
        const fatcaFilingIndicatorPos = {
          x: recLocalAddressPos.x + 213 + (!extraCheckBoxPaddingX ? 0 : extraCheckBoxPaddingX),
          y: recLocalAddressPos.y + 36 + (!extraCheckBoxPaddingY ? 0 : extraCheckBoxPaddingY),
        };
        if (fatcaFilingIndicator) {
          this.getReviewCopy().registerFontkit(fontkit);
          const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
          const fontBuffer = await tickMarkFontRes.arrayBuffer();
          const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
          firstPage.drawText("z", {
            x: fatcaFilingIndicatorPos.x,
            y: height - fatcaFilingIndicatorPos.y - 8 * 2,
            ...this.getOptions(),
            size: 8,
            font: tickMark,
          });
        }
      }

      if (toNotFillAccountNumberRow) return;
      else {
        // Draw account number
        const accountNumPos = {
          x: this.getLeftSideXCoord(),
          y: 335,
        };
        const accountNum = accountNumber || "";
        firstPage.drawText(accountNum, {
          x: accountNumPos.x + 4,
          y: height - accountNumPos.y - this.getFontSize() * 2,
          ...options,
        });

        // Draw second tin notice if it is marked
        if (secondTinNoticeIndicator) {
          const secondTinNoticePos = {
            x:
              this.getLeftSideXCoord() + 213 + (!extraCheckBoxPaddingX ? 0 : extraCheckBoxPaddingX),
            y: 329 + (!extraCheckBoxPaddingY ? 0 : extraCheckBoxPaddingY),
          };
          await this.modifySecondTinNotice(secondTinNoticePos);
        }
      }
    } else throw new Error("Form does not support fatca filing");
  }
}
