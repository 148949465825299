import { axiosInstance } from "../../axiosConfig";
import devLogInstance from "../../loggerConfig";
import Handler, { Main } from "../main";

export default async function getAssignedTickets() {
  try {
    const { data } = await axiosInstance.get("/admin/get_assigned_tickets");
    devLogInstance.log({ response_after_getting_assigned_tickets: data });

    return data;
  } catch (err) {
    await Handler.handleError(err, Main.stateDispatch, () => {});
    throw err;
  }
}
