import ReviewCopyModifier from "./ReviewCopyModifier";

export default class LocalReviewDetailsModifier {
  private locality1Pos;
  private localTax1Pos: { x: number; y: number };
  private localIncome1Pos;
  private locality2Pos;
  private localTax2Pos;
  private localIncome2Pos;

  private reviewCopyMod: ReviewCopyModifier;

  constructor(
    reviewCopyMod: ReviewCopyModifier,
    localTax1PrevFieldXCoord: number,
    localTax1PrevFieldYCoord: number
  ) {
    this.reviewCopyMod = reviewCopyMod;

    this.localTax1Pos = {
      x: localTax1PrevFieldXCoord - 10,
      y: localTax1PrevFieldYCoord - 30 * 2,
    };

    this.locality1Pos = {
      x: this.localTax1Pos.x + 50,
      y: this.localTax1Pos.y,
    };

    this.localIncome1Pos = {
      x: this.locality1Pos.x + 160,
      y: this.locality1Pos.y,
    };
    this.locality2Pos = {
      x: this.locality1Pos.x,
      y: this.locality1Pos.y - 13,
    };
    this.localTax2Pos = {
      x: this.localTax1Pos.x,
      y: this.localTax1Pos.y - 13,
    };
    this.localIncome2Pos = {
      x: this.localIncome1Pos.x,
      y: this.localIncome1Pos.y - 13,
    };
  }

  public modifyLocalityName(locality1: string, locality2: string) {
    const page = this.reviewCopyMod.getPdfPage(1);

    // State 1
    page.drawText(locality1, {
      x: this.locality1Pos.x,
      y: this.locality1Pos.y,
      ...this.reviewCopyMod.getOptions(),
    });

    // State 2
    page.drawText(locality2, {
      x: this.locality2Pos.x,
      y: this.locality2Pos.y,
      ...this.reviewCopyMod.getOptions(),
    });
  }

  public modifyLocalTaxDetail(
    localTax1: number,
    localTax2: number,
    paddingX?: number,
    paddingY?: number
  ) {
    const page = this.reviewCopyMod.getPdfPage(1);

    let formattedAmt = "";
    if (localTax1) {
      // Local tax 1
      formattedAmt = (localTax1 / 100).toFixed(2);
      page.drawText(formattedAmt, {
        x: this.localTax1Pos.x + (!paddingX ? 0 : paddingX),
        y: this.localTax1Pos.y + (!paddingY ? 0 : paddingY),
        ...this.reviewCopyMod.getOptions(),
      });
    }

    if (localTax2) {
      // Local tax 2
      formattedAmt = (localTax2 / 100).toFixed(2);
      page.drawText(formattedAmt, {
        x: this.localTax2Pos.x + (!paddingX ? 0 : paddingX),
        y: this.localTax2Pos.y + (!paddingY ? 0 : paddingY),
        ...this.reviewCopyMod.getOptions(),
      });
    }
  }

  public modifyLocalIncomeDetail(
    localIncome1: number,
    localIncome2: number,
    paddingX?: number,
    paddingY?: number
  ) {
    const page = this.reviewCopyMod.getPdfPage(1);

    let formattedAmt = "";

    if (localIncome1) {
      // State income 1
      formattedAmt = (localIncome1 / 100).toFixed(2);
      page.drawText(formattedAmt, {
        x: this.localIncome1Pos.x + (!paddingX ? 0 : paddingX),
        y: this.localIncome1Pos.y + (!paddingY ? 0 : paddingY),
        ...this.reviewCopyMod.getOptions(),
      });
    }

    if (localIncome2) {
      // State income 2
      formattedAmt = (localIncome2 / 100).toFixed(2);
      page.drawText(formattedAmt, {
        x: this.localIncome2Pos.x + (!paddingX ? 0 : paddingX),
        y: this.localIncome2Pos.y + (!paddingY ? 0 : paddingY),
        ...this.reviewCopyMod.getOptions(),
      });
    }
  }
}
