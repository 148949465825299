import React, { useEffect, useRef, useState } from "react";
import { CollapsibleProps } from "../../../interfaces/main";
import devLogInstance from "../../lib/loggerConfig";

export default function CollapsibleContainer({
  toCollapse,
  displayText,
  height,
}: CollapsibleProps) {
  const container = useRef<HTMLDivElement | null>(null);
  const [currentheight, setHeight] = useState("");

  useEffect(() => {
    console.log(container?.current?.offsetHeight);
    setHeight(container?.current?.offsetHeight.toString() ?? "");
  }, []);

  devLogInstance.log(height);

  return (
    <div
      ref={container}
      className={`px-4 sm:px-10 ${
        !toCollapse ? `${height}` : "h-0"
      } overflow-hidden transition-all ${
        !toCollapse ? "border-b-2" : ""
      } border-b-slate-300 text-xl`}
    >
      {displayText}
    </div>
  );
}
