import React from "react";
import { AssignedTicketData } from "../../../../interfaces/main";
import TextModifier from "../../../lib/TextModifier";
import { FaCircle } from "react-icons/fa6";

interface Props extends React.HTMLProps<HTMLElement> {
  ticketData: AssignedTicketData;
}

const ticketStatusColorMap: any = {
  open: "text-green-500",
  inProgress: "text-yellow-500",
  closed: "text-gray-500",
};

export default function TicketContainer({ ticketData, className, onClick: handleClick }: Props) {
  return (
    <div onClick={handleClick} className={`px-6 py-4 rounded-r-full cursor-pointer ${className}`}>
      <div className="flex items-center gap-4">
        <FaCircle
          className={`ticket-status text-xs ${ticketStatusColorMap[ticketData.ticket_status]}`}
        />
        <div>
          <p className="ticket-id text-sm font-bold flex items-center gap-4">
            {ticketData._id}
            <em className="text-xs text-gray-400">
              ({TextModifier.modifyDate(new Date(ticketData.createdAt))})
            </em>
          </p>
          <p className="query py-2">{TextModifier.truncate(ticketData.query, 30)}</p>
        </div>
      </div>
    </div>
  );
}
