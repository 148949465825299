import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_3,
  AMOUNT_4,
  AMOUNT_5,
  AMOUNT_6,
  AMOUNT_8,
  AMOUNT_9,
  AMOUNT_A,
  AMOUNT_B,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  DISTRIBUTION_CODE_1,
  DISTRIBUTION_CODE_2,
  ERROR_POPUP,
  FIRST_YEAR_OF_ROTH_CONTRIB,
  IRA_SEP_SIMPLE_INDICATOR,
  LOCALITY_1,
  LOCALITY_2,
  LOCAL_INCOME_1,
  LOCAL_INCOME_2,
  LOCAL_TAX_1,
  LOCAL_TAX_2,
  PAGE_E_FILE,
  R_1099,
  SUBMIT_ADD_ANOTHER,
  TAXABLE_AMOUNT_NOT_DETERMINED,
  TOTAL_PERCENTAGE_DISTRO,
  TOTAl_DISTRO,
  _1099R_GROUP,
} from "../../lib/constants";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import Fatca from "./GlobalFormComponents/FATCA/Fatca";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import { r1099AmountDisplayTexts, stateInfoDisplayTexts } from "../../lib/languagePacks/en-us";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import StateInfo from "./GlobalFormComponents/StateInfo";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import PercentageAmount from "./GlobalFormComponents/AmountContainer/PercentageAmountInput/PercentageAmount";
import Box10 from "./GlobalFormComponents/1099-R-Box10_11/Box10/Box10";
import Box11 from "./GlobalFormComponents/1099-R-Box10_11/Box11/Box11";
import LocalFiling from "./GlobalFormComponents/InternalComponents/LocalFiling/LocalFiling";
import { isValidAmount } from "./Form1099-B";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import distrCodeJson from "../../lib/json/DistributionCodeRelated/distribution-codes.json";
import customFilter from "../../lib/form/utils/reactSelectFilter";
import { useDistributionCode, useRecTinUnknown } from "../../lib/customHooks";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import { TO_SHOW_FORM_VALIDATION_ERR } from "../../redux/actions/action.types";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";

export default function Form1099R({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount3: "",
    amount4: "",
    amount5: "",
    amount6: "",
    amount8: "",
    amount9: "",
    amountA: "",
    amountB: "",
  }); // for displaying on the input field

  const {
    distrCode1,
    distrCode2,
    distrCode2Options,
    handleDistrCode1Change,
    handleDistrCode2Change,
  } = useDistributionCode();

  const [miscFields, setMiscFields] = useState({
    taxable_amount_not_determined: false,
    total_distribution_indicator: false,
    first_year_of_designated_roth_contrib: 0,
    ira_sep_simple_indicator: false,
    percentage_of_total_distribution: 0,
  });
  const [date, setDate] = useState("");
  // const [isValidDate, setIsValidDate] = useState<boolean | null>(null);
  const [clearDate, setClearDate] = useState<boolean | null>(null);
  const [showDateRequiredIndicator, toShowDateRequiredIndicator] = useState(false);
  const navigate = useNavigate();

  const { recTinUnknown } = useRecTinUnknown();

  const [localFilingDetails, setLocalFilingDetails] = useState({
    local_tax_withheld1: 0,
    locality1: "",
    local_income1: 0,
    local_tax_withheld2: 0,
    locality2: "",
    local_income2: 0,
  });

  // Helper function to remove any kind of displayed error
  const removeErrorIndicator = (field: string) => {
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: field,
        toShowErr: false,
      },
    });
  };

  const handleChangeInLocalFilingDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: fieldType, value } = e.currentTarget;
    devLogInstance.log({ change_in_local_filing_field: fieldType });

    const formattedVal = handleAmountFormat(value);

    switch (fieldType) {
      case "local-tax-1":
        removeErrorIndicator(LOCAL_TAX_1);

        isValidAmount(formattedVal) &&
          setLocalFilingDetails({
            ...localFilingDetails,
            local_tax_withheld1:
              formattedVal.length <= 0 ? 0 : parseFloat(formattedVal.replace(/,/gi, "")) * 100,
          });
        break;

      case "local-tax-2":
        removeErrorIndicator(LOCAL_TAX_2);

        isValidAmount(formattedVal) &&
          setLocalFilingDetails({
            ...localFilingDetails,
            local_tax_withheld2:
              formattedVal.length <= 0 ? 0 : parseFloat(formattedVal.replace(/,/gi, "")) * 100,
          });
        break;

      case "local-income-1":
        removeErrorIndicator(LOCAL_INCOME_1);

        isValidAmount(formattedVal) &&
          setLocalFilingDetails({
            ...localFilingDetails,
            local_income1:
              formattedVal.length <= 0 ? 0 : parseFloat(formattedVal.replace(/,/gi, "")) * 100,
          });
        break;

      case "local-income-2":
        removeErrorIndicator(LOCAL_INCOME_2);

        isValidAmount(formattedVal) &&
          setLocalFilingDetails({
            ...localFilingDetails,
            local_income2:
              formattedVal.length <= 0 ? 0 : parseFloat(formattedVal.replace(/,/gi, "")) * 100,
          });
        break;

      case "locality-1":
        removeErrorIndicator(LOCALITY_1);

        setLocalFilingDetails({
          ...localFilingDetails,
          locality1: value,
        });
        break;

      case "locality-2":
        removeErrorIndicator(LOCALITY_2);

        setLocalFilingDetails({
          ...localFilingDetails,
          locality2: value,
        });
        break;

      default:
        break;
    }
  };

  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount6: formData.payment_amount6
        ? handleAmountFormat((formData.payment_amount6 / 100).toString())
        : "",
      amount8: formData.payment_amount8
        ? handleAmountFormat((formData.payment_amount8 / 100).toString())
        : "",
      amount9: formData.payment_amount9
        ? handleAmountFormat((formData.payment_amount9 / 100).toString())
        : "",
      amountA: formData.payment_amountA
        ? handleAmountFormat((formData.payment_amountA / 100).toString())
        : "",
      amountB: formData.payment_amountB
        ? handleAmountFormat((formData.payment_amountB / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      taxable_amount_not_determined: formData?.taxable_amt_not_determined === true,
      total_distribution_indicator: formData?.total_distribution_check === true,
      first_year_of_designated_roth_contrib: formData?.first_year_of_designated_roth_contrib || 0,
      ira_sep_simple_indicator: formData?.ira_sep_simple_indicator === true,
      percentage_of_total_distribution: formData?.percentage_of_total_distribution || 0,
    });

    // Set local filing details
    setLocalFilingDetails({
      local_tax_withheld1: formData?.local_tax_withheld1 || 0,
      locality1: formData?.locality1 || "",
      local_income1: formData?.local_income1 || 0,

      local_tax_withheld2: formData?.local_tax_withheld2 || 0,
      locality2: formData?.locality2 || "",
      local_income2: formData?.local_income2 || 0,
    });

    // Set distribution code
    handleDistrCode1Change({
      label: formData?.distribution_code ? formData?.distribution_code[0] : "",
      value: formData?.distribution_code ? formData?.distribution_code[0] : "",
    });
    handleDistrCode2Change({
      label: formData?.distribution_code ? formData?.distribution_code[1] : "",
      value: formData?.distribution_code ? formData?.distribution_code[1] : "",
    });

    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount3: "",
        amount4: "",
        amount5: "",
        amount6: "",
        amount8: "",
        amount9: "",
        amountA: "",
        amountB: "",
      });
      setMiscFields({
        taxable_amount_not_determined: false,
        total_distribution_indicator: false,
        first_year_of_designated_roth_contrib: 0,
        ira_sep_simple_indicator: false,
        percentage_of_total_distribution: 0,
      });

      setLocalFilingDetails({
        local_tax_withheld1: 0,
        locality1: "",
        local_income1: 0,
        local_tax_withheld2: 0,
        locality2: "",
        local_income2: 0,
      });

      handleDistrCode1Change({ label: "", value: "" });
      handleDistrCode2Change({ label: "", value: "" });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount3,
    formData.payment_amount4,
    formData.payment_amount5,
    formData.payment_amount6,
    formData.payment_amount8,
    formData.payment_amount9,
    formData.payment_amountA,
    formData.payment_amountB,
    formData.payment_amountC,
    formData.payment_amountD,
    formData.taxable_amt_not_determined,
    formData.total_distribution_check,
    formData.first_year_of_designated_roth_contrib,
    formData.ira_sep_simple_indicator,
    formData.percentage_of_total_distribution,
    formData.local_tax_withheld1,
    formData.locality1,
    formData.local_income1,
    formData.local_tax_withheld2,
    formData.locality2,
    formData.local_income2,
    formData.distribution_code,
    handleDistrCode1Change,
    handleDistrCode2Change,
  ]);

  // Date related callback
  const callbackRelatedToDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date?.split("/");
    const date_ = dateChunks[2] + dateChunks[0] + dateChunks[1];

    setDate(date_);
    // setIsValidDate(!isInvalidDate);
  };

  // Handle input change on payment amount fields and any other misc fields
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;
      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_6:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount6: formattedAmount,
          });
        break;
      case AMOUNT_8:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount8: formattedAmount,
          });
        break;
      case AMOUNT_9:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount9: formattedAmount,
          });
        break;
      case AMOUNT_A:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountA: formattedAmount,
          });
        break;
      case AMOUNT_B:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountB: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  // Handle MISC field change
  const handleMiscFieldChange = (e: any) => {
    const { id: fieldType, value } = e.target;
    switch (fieldType) {
      case TAXABLE_AMOUNT_NOT_DETERMINED:
        setMiscFields({
          ...miscFields,
          taxable_amount_not_determined: !miscFields.taxable_amount_not_determined,
        });
        break;

      case TOTAl_DISTRO:
        setMiscFields({
          ...miscFields,
          total_distribution_indicator: !miscFields.total_distribution_indicator,
        });
        break;

      case FIRST_YEAR_OF_ROTH_CONTRIB:
        // Prevent user from entering more than 4 digits
        if (value.toString().length <= 4)
          setMiscFields({
            ...miscFields,
            first_year_of_designated_roth_contrib: value,
          });
        break;

      case IRA_SEP_SIMPLE_INDICATOR:
        setMiscFields({
          ...miscFields,
          ira_sep_simple_indicator: !miscFields.ira_sep_simple_indicator,
        });
        break;

      case TOTAL_PERCENTAGE_DISTRO:
        setMiscFields({
          ...miscFields,
          percentage_of_total_distribution: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;
    devLogInstance.log(`Clicked on ---> ${submitType}`);

    let data = {
      ...formData,
      ...miscFields,
      first_year_of_designated_roth_contrib:
        miscFields.first_year_of_designated_roth_contrib <= 0
          ? ""
          : miscFields.first_year_of_designated_roth_contrib.toString(),
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
      payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
      payment_amount6: amount.amount6 ? parseFloat(amount.amount6.replace(/,/gi, "")) : 0,
      payment_amount8: amount.amount8 ? parseFloat(amount.amount8.replace(/,/gi, "")) : 0,
      payment_amount9: amount.amount9 ? parseFloat(amount.amount9.replace(/,/gi, "")) : 0,
      payment_amountA: amount.amountA ? parseFloat(amount.amountA.replace(/,/gi, "")) : 0,
      payment_amountB: amount.amountB ? parseFloat(amount.amountB.replace(/,/gi, "")) : 0,

      // Local filing details
      ...localFilingDetails,

      // Distribution code
      distribution_code: `${distrCode1}${distrCode2}`,
      // Date of payment
      date_of_payment: date,
    };
    devLogInstance.log(data);
    const paymentAmounts = [
      data.payment_amount1,
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount4,
      data.payment_amount5,
      data.payment_amount6,
      data.payment_amount8,
      data.payment_amount9,
      data.payment_amountB,
    ];
    const incomeAmounts = [
      data.payment_amount1,
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount5,
      data.payment_amount6,
      data.payment_amount8,
      data.payment_amount9,
      data.payment_amountB,
    ];
    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          paymentAmounts,
          data.payment_amount4,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                taxable_amount_not_determined: false,
                total_distribution_indicator: false,
                first_year_of_designated_roth_contrib: 0,
                ira_sep_simple_indicator: false,
                percentage_of_total_distribution: 0,
              });
              // Clear the local filing details
              setLocalFilingDetails({
                local_tax_withheld1: 0,
                locality1: "",
                local_income1: 0,
                local_tax_withheld2: 0,
                locality2: "",
                local_income2: 0,
              });
              // Clear date
              setClearDate(true);

              // Clear distr code
              handleDistrCode1Change({ label: "", value: "" });
              handleDistrCode2Change({ label: "", value: "" });
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };

  const box13props = {
    clearDate: clearDate,
    callbackRelatedToDate: callbackRelatedToDate,
    dateGroup: _1099R_GROUP,
    dateVal: formData?.date_of_payment ? formData?.date_of_payment : null,
    showDateRequiredIndicator: showDateRequiredIndicator,
    toShowDateRequired: toShowDateRequiredIndicator,
  };

  return (
    <>
      <form className="form-main-container">
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <Fatca
                displayCode={"12"}
                globalFormData={formData}
                setGlobalFormData={setFormData}
                type={"filing-form"}
                showBox10and11={true}
                _1099RBox10={<Box10 amountObj={amount} changeHandler={handleChange} />}
                _1099RBox11={
                  <Box11
                    onChange={handleMiscFieldChange}
                    value={miscFields.first_year_of_designated_roth_contrib}
                  />
                }
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
                show1099RDateOfPayment={true}
                _1099RBox13props={box13props}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      height: "50%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.grossDistro} amountNum={"1"} />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      height: "50%",
                    }}
                  >
                    <AmountHeading
                      heading={r1099AmountDisplayTexts.taxableAmount}
                      amountNum={"2a"}
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail
                  formType={formType}
                  taxYear={taxYear}
                  formName={R_1099}
                  newVersion={true}
                />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row", borderRight: `1px solid black` }}
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.taxableAmountNotDetermined}
                    amountNum="2b"
                  />
                  <input
                    id={TAXABLE_AMOUNT_NOT_DETERMINED}
                    style={{ padding: "0.5rem 1rem" }}
                    type="checkbox"
                    checked={miscFields.taxable_amount_not_determined}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row" }}
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.totalDistro} />
                  <input
                    id={TOTAl_DISTRO}
                    style={{ padding: "0.5rem 1rem" }}
                    type="checkbox"
                    checked={miscFields.total_distribution_indicator}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.capitalGain} amountNum="3" />
                  <Amount
                    type="text"
                    id={AMOUNT_3}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount3}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.taxWithheld} amountNum="4" />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.employeeContrib} amountNum="5" />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.netUnrealizedAppreciation}
                    amountNum="6"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_6}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount6}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <div style={{ width: "50%" }}>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                      width: "100%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.distroCode} amountNum="7" />
                    <div className="flex gap-5">
                      <div className="w-1/2">
                        <Select
                          id={DISTRIBUTION_CODE_1}
                          theme={new SelectStyle(style).customTheme}
                          styles={new SelectStyle(style).customStyle}
                          options={distrCodeJson}
                          filterOption={customFilter}
                          onChange={handleDistrCode1Change}
                          value={{ label: distrCode1, value: distrCode1 }}
                          placeholder=""
                        />
                      </div>
                      <div className="w-1/2">
                        <Select
                          id={DISTRIBUTION_CODE_2}
                          theme={new SelectStyle(style).customTheme}
                          styles={new SelectStyle(style).customStyle}
                          options={distrCode2Options}
                          filterOption={customFilter}
                          onChange={handleDistrCode2Change}
                          value={{ label: distrCode2, value: distrCode2 }}
                          placeholder=""
                          isDisabled={distrCode2Options.length <= 0}
                        />
                      </div>
                    </div>
                  </AmountContainer>

                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      width: "100%",
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <AmountHeading heading={r1099AmountDisplayTexts.iraSepSimpleIndicator} />
                      <input
                        id={IRA_SEP_SIMPLE_INDICATOR}
                        type="checkbox"
                        onChange={handleMiscFieldChange}
                        checked={miscFields.ira_sep_simple_indicator}
                      />
                    </div>
                  </AmountContainer>
                </div>
                <div style={{ display: "flex", width: "50%" }}>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                      width: "60%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.other} amountNum="8" />
                    <Amount
                      type="text"
                      id={AMOUNT_8}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount8}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      width: "40%",
                    }}
                  >
                    <AmountHeading heading={""} />
                    <PercentageAmount
                      type="text"
                      id={"OTHER_PERCENT_VALUE"}
                      placeHolder=""
                      class_="payer-details-input" // Only one class can be passed
                      value={""}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.totalDistroPercent}
                    amountNum="9a"
                  />
                  <PercentageAmount
                    type="number"
                    id={TOTAL_PERCENTAGE_DISTRO}
                    placeHolder=""
                    class_="payer-details-input" // Only one class can be passed
                    value={
                      miscFields.percentage_of_total_distribution <= 0
                        ? ""
                        : miscFields.percentage_of_total_distribution
                    }
                    onChangeHandler={handleMiscFieldChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.totalEmployeeContrib}
                    amountNum="9b"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_9}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount9}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.r1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.r1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.r1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.r1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.r1099.stateNoLabel}
          stateIncomeLabel={stateInfoDisplayTexts.r1099.stateIncLabel}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          formType={formType}
          isCorrection={isCorrection}
        />
        <LocalFiling
          taxWithheldLabel="Local tax withheld"
          nameOfLocalityLabel="Name of locality"
          localDistrLabel="Local distribution"
          state1={formData.state1}
          state2={formData.state2}
          localFilingDetails={localFilingDetails}
          handleChange={handleChangeInLocalFilingDetails}
        />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
