import React from "react";

interface Props extends React.HTMLProps<HTMLElement> {
  optionName: string;
}

const optionNameMap: any = {
  open: "Open",
  inProgress: "In progress",
  closed: "Closed",
};

export default function FilterOption({ optionName, className, onClick: handleClick }: Props) {
  return (
    <div
      onClick={handleClick}
      className={`rounded-full border border-blue-400 py-2 px-4 cursor-pointer ${className}`}
    >
      {optionNameMap[optionName]}
    </div>
  );
}
