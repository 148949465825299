import devLogInstance from "../../loggerConfig";

const getCachedStatusFilterOptions = (isRegular?: boolean): string[] => {
  const cachedStatusOptionsStr = !isRegular
    ? localStorage.getItem("filterByStatusOptions")
    : localStorage.getItem("statusFilterOptionsRegular");
  devLogInstance.log({ cachedStatusOptionsStr });
  return cachedStatusOptionsStr ? JSON.parse(cachedStatusOptionsStr) : [];
};

export default getCachedStatusFilterOptions;
