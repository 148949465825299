import {
  FORM1099_R,
  LOCALITY_1,
  LOCALITY_2,
  LOCAL_FILING_VALIDATION_ERR,
  LOCAL_INCOME_1,
  LOCAL_INCOME_2,
  LOCAL_TAX_1,
  LOCAL_TAX_2,
} from "../constants";

export class LocalFilingValidator {
  private localFiling1Validator: LocalFiling1Validator;
  private localFiling2Validator: LocalFiling2Validator;

  private formType: string;

  private formsWithLocalFiling: string[] = [FORM1099_R];

  private invalidFields: string[] = [];

  constructor(
    localFiling1Validator: LocalFiling1Validator,
    localFiling2Validator: LocalFiling2Validator,
    formType: string
  ) {
    this.localFiling1Validator = localFiling1Validator;
    this.localFiling2Validator = localFiling2Validator;

    this.formType = formType;
  }

  public validate() {
    return new Promise((res, rej) => {
      if (!this.formsWithLocalFiling.includes(this.formType)) return res(true); // No need to continue with validation
      // if the current form does not have any local filing

      this.localFiling1Validator.validate();
      this.localFiling2Validator.validate();

      // Get the invalid fields from the both the validators
      const invalidFields1 = this.localFiling1Validator.getInvalidFields();
      const invalidFields2 = this.localFiling2Validator.getInvalidFields();

      // Destructure and assign both the invalid fields from both local filing 1 and 2 validators
      // to the main local filing validator invalid fields
      this.invalidFields = [...invalidFields1, ...invalidFields2];

      this.invalidFields.length > 0
        ? rej({ status: LOCAL_FILING_VALIDATION_ERR, data: this.invalidFields })
        : res(true);
    });
  }
}

export class LocalFiling1Validator {
  private localTax1: number;
  private locality1: string;
  private localIncome1: number;

  private invalidFields: string[] = [];

  constructor(localTax1: number, locality1: string, localIncome1: number) {
    this.localTax1 = localTax1;
    this.locality1 = locality1;
    this.localIncome1 = localIncome1;
  }

  // Getters
  public getInvalidFields() {
    return this.invalidFields;
  }

  public validate() {
    if ((this.localTax1 || this.localIncome1) && !this.locality1)
      !this.invalidFields.includes(LOCALITY_1) && this.invalidFields.push(LOCALITY_1);

    if (this.localTax1 && this.localTax1 >= this.localIncome1)
      !this.invalidFields.includes(LOCAL_TAX_1) && this.invalidFields.push(LOCAL_TAX_1);

    if (this.locality1 && !this.localTax1)
      !this.invalidFields.includes(LOCAL_TAX_1) && this.invalidFields.push(LOCAL_TAX_1);

    if (this.locality1 && !this.localIncome1)
      !this.invalidFields.includes(LOCAL_INCOME_1) && this.invalidFields.push(LOCAL_INCOME_1);
  }
}

export class LocalFiling2Validator {
  private localTax2: number;
  private locality2: string;
  private localIncome2: number;

  private invalidFields: string[] = [];

  constructor(localTax2: number, locality2: string, localIncome2: number) {
    this.localTax2 = localTax2;
    this.locality2 = locality2;
    this.localIncome2 = localIncome2;
  }

  // Getters
  public getInvalidFields() {
    return this.invalidFields;
  }

  public validate() {
    if ((this.localTax2 || this.localIncome2) && !this.locality2)
      !this.invalidFields.includes(LOCALITY_2) && this.invalidFields.push(LOCALITY_2);

    if (this.localTax2 && this.localTax2 >= this.localIncome2)
      !this.invalidFields.includes(LOCAL_TAX_2) && this.invalidFields.push(LOCAL_TAX_2);

    if (this.locality2 && !this.localTax2)
      !this.invalidFields.includes(LOCAL_TAX_2) && this.invalidFields.push(LOCAL_TAX_2);

    if (this.locality2 && !this.localIncome2)
      !this.invalidFields.includes(LOCAL_INCOME_2) && this.invalidFields.push(LOCAL_INCOME_2);
  }
}
