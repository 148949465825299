import {
  ReviewCopyPayerData,
  ReviewCopyPaymentData,
  ReviewCopyRecipientData,
} from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";

export class Review1099GModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean;

  private amount1: number;
  private amount2: number;
  private amount4: number;
  private amount5: number;
  private amount6: number;
  private amount7: number;
  private amount9: number;
  private taxYearOfRefund: string;
  private tradeOrBusinessIndicator: boolean;

  private state1: string;
  private state2: string;
  private payerStateNum1: string;
  private payerStateNum2: string;
  private stateTax1: number;
  private stateTax2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    paymentAmounts: ReviewCopyPaymentData,
    taxYearOfRefund: string,
    tradeOrBusinessIndicator: boolean,
    state1: string,
    state2: string,
    payerStateNum1: string,
    payerStateNum2: string,
    stateTax1: number,
    stateTax2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;
    this.amount1 = paymentAmounts.payment_amount1 || 0;
    this.amount2 = paymentAmounts.payment_amount2 || 0;
    this.amount4 = paymentAmounts.payment_amount4 || 0;
    this.amount5 = paymentAmounts.payment_amount5 || 0;
    this.amount6 = paymentAmounts.payment_amount6 || 0;
    this.amount7 = paymentAmounts.payment_amount7 || 0;
    this.amount9 = paymentAmounts.payment_amount9 || 0;
    this.taxYearOfRefund = taxYearOfRefund;
    this.tradeOrBusinessIndicator = tradeOrBusinessIndicator;

    this.state1 = state1;
    this.state2 = state2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
    this.payerStateNum1 = payerStateNum1;
    this.payerStateNum2 = payerStateNum2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // Draw second tin notice if it is marked
      if (this.secondTinNotice) {
        const secondTinNoticePos = {
          x: this.getLeftSideXCoord() + 215,
          y: 221,
        };
        await this.modifySecondTinNotice(secondTinNoticePos);
      }

      // *** RIGHT SIDE *** //

      // Calendar year
      this.drawCalendarYear();

      // Box 2 amount is for tax year (tax year of refund)
      const taxYearOfRefundPos = {
        x: this.getLeftSideXCoord() + 135 * 2,
        y: 120,
      };
      firstPage.drawText(this.taxYearOfRefund, {
        x: taxYearOfRefundPos.x,
        y: height - taxYearOfRefundPos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // Trade or business indicator
      const tradeOrBusinessIndicatorPos = {
        x: this.getLeftSideXCoord() + 165 * 2 + 100,
        y: taxYearOfRefundPos.y + 41,
      };
      if (this.tradeOrBusinessIndicator) {
        this.getReviewCopy().registerFontkit(fontkit);
        const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
        const fontBuffer = await tickMarkFontRes.arrayBuffer();
        const tickMark = await this.getReviewCopy().embedFont(fontBuffer);
        firstPage.drawText("z", {
          x: tradeOrBusinessIndicatorPos.x,
          y: height - tradeOrBusinessIndicatorPos.y - 8 * 2,
          ...this.getOptions(),
          size: 8,
          font: tickMark,
        });
      }

      // *** Fill the amount fields ***
      let formattedAmt = "";

      // Unemployment compensation (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: 61,
      };
      formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount1Pos.x,
        y: height - amount1Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // State or local income tax refunds, credits, or offsets (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: 97,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: height - amount2Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // Federal income tax withheld (amount 4)
      const amount4Pos = {
        x: amount1Pos.x + 100,
        y: 121,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: height - amount4Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // RTAA payments (amount 5)
      const amount5Pos = {
        x: amount1Pos.x,
        y: taxYearOfRefundPos.y + 25,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: height - amount5Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // Taxable grants (amount 6)
      const amount6Pos = {
        x: amount4Pos.x,
        y: amount5Pos.y,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount6Pos.x,
        y: height - amount6Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // Agriculture payments (amount 7)
      const amount7Pos = {
        x: amount1Pos.x,
        y: amount5Pos.y + 24,
      };
      formattedAmt = (this.amount7 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount7Pos.x,
        y: height - amount7Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // Market gain (amount 9)
      const amount9Pos = {
        x: amount1Pos.x,
        y: amount7Pos.y + 24,
      };
      formattedAmt = (this.amount9 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount9Pos.x,
        y: height - amount9Pos.y - this.getFontSize(),
        ...this.getOptions(),
      });

      // State Details //

      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: amount9Pos.x,
          prevFieldYCoord: height - amount9Pos.y - this.getFontSize(),
        },
        {
          stateNum1: this.payerStateNum1,
        },
        {
          stateTax1: this.stateTax1,
        },
        {
          state2: this.state2,
        },
        {
          stateNum2: this.payerStateNum2,
        },
        {
          stateTax2: this.stateTax2,
        }
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string },
    stateTax1Obj: { stateTax1: number },
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number }
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord
    );

    // State details
    // State name
    stateDetailsMod.modifyStateNameDetail(state1Obj.state1, state2Obj.state2);
    // State num
    stateDetailsMod.modifyStateNumDetail(stateNum1Obj.stateNum1, stateNum2Obj.stateNum2);
    // State tax
    stateDetailsMod.modifyStateTaxDetail(stateTax1Obj.stateTax1, stateTax2Obj.stateTax2);
  }
}
