import React, { createContext, useState } from "react";
import Navbar from "../components/navbar";
import UserSupportToolWindowContainer from "../components/SupportTicketRelated/UserRelated/UserSupportToolWindowContainer";
import RaiseTicketWindow from "../components/SupportTicketRelated/UserRelated/RaiseTicketWindow";
import SidePanel from "../components/decorations/SidePanel";
import { BiSupport } from "react-icons/bi";
import { PanelOptionContext } from "./BusinessSettings";
import TrackComplaintsWindow from "../components/SupportTicketRelated/UserRelated/TrackComplaintsWindow";

export const SupportToolSelectionCtx = createContext<{
  selectSupportTool: React.Dispatch<React.SetStateAction<string>>;
} | null>(null);

const supportToolWindowMap: any = {
  0: <TrackComplaintsWindow />, // option 1
  1: <RaiseTicketWindow />, // option 2
};

const supportOptions = ["Track complaints", "Raise ticket"];

export default function HelpAndSupport() {
  const [selectedTool, selectTool] = useState(0); // By default the first option will be selected

  return (
    <div className="page">
      <Navbar />
      <div className="page-main-body h-screen pt-24 flex">
        {/* <SupportToolSelectionCtx.Provider value={{ selectSupportTool: selectTool }}> */}
        <PanelOptionContext.Provider value={{ option: selectedTool, selectOption: selectTool }}>
          <SidePanel
            heading="Support options"
            headingIcon={<BiSupport className="text-3xl" />}
            options={supportOptions}
          />
          <UserSupportToolWindowContainer window={supportToolWindowMap[selectedTool]} />
        </PanelOptionContext.Provider>
      </div>
    </div>
  );
}
