import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import fontkit from "@pdf-lib/fontkit";
import FormatDate from "../utils/Helpers/FormatDate";
import ReviewCopyModifier from "./ReviewCopyModifier";

export class Review1099CModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private date_of_identifiable_event: string;
  private personal_liability_indicator: boolean;
  private debt_description: string;
  private identifiable_event_code: string;

  private amount2: number;
  private amount3: number;
  private amount7: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    date_of_identifiable_event: string,
    amount2: number,
    amount3: number,
    amount7: number,
    personal_liability_indicator: boolean,
    debt_description: string,
    identifiable_event_code: string
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;

    this.date_of_identifiable_event = date_of_identifiable_event;
    this.personal_liability_indicator = personal_liability_indicator;
    this.debt_description = debt_description;
    this.identifiable_event_code = identifiable_event_code;

    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amount7 = amount7;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // *** LEFT *** //

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // *** RIGHT *** //

      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      this.drawCalendarYearPartly();

      // Date of identifiable event
      const dateOfIdentifiableEvtPos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 48 - this.getFontSize(),
      };
      const dateOfIdentifiableEvtFormatter = new FormatDate(this.date_of_identifiable_event || "");
      const formattedDateOfIdentifiableEvt = dateOfIdentifiableEvtFormatter.format();
      firstPage.drawText(formattedDateOfIdentifiableEvt, {
        x: dateOfIdentifiableEvtPos.x,
        y: dateOfIdentifiableEvtPos.y,
        ...options,
      });

      // Amount of debt discharged (amount 2)
      const amount2Pos = {
        x: dateOfIdentifiableEvtPos.x,
        y: dateOfIdentifiableEvtPos.y - 25,
      };
      let formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Interest included in Amount Code 2 (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 24,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount3Pos.x,
        y: amount3Pos.y,
        ...options,
      });

      // Debt description
      const debtDescriptionPos = {
        x: amount3Pos.x - 10,
        y: amount3Pos.y - 24,
      };
      firstPage.drawText(this.debt_description, {
        ...debtDescriptionPos,
        ...options,
      });

      // Personal liability indicator
      if (this.personal_liability_indicator) {
        const liabilityIndicatorPos = {
          x: debtDescriptionPos.x + 185,
          y: debtDescriptionPos.y - 55,
        };
        firstPage.drawText("z", {
          ...liabilityIndicatorPos,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Identifiable event code
      const identifiableEvtCodePos = {
        x: debtDescriptionPos.x,
        y: debtDescriptionPos.y - 36 * 3,
      };
      firstPage.drawText(this.identifiable_event_code, {
        ...identifiableEvtCodePos,
        ...options,
      });

      // Fair market value of property (amount 7)
      const amount7Pos = {
        x: identifiableEvtCodePos.x + 110,
        y: identifiableEvtCodePos.y,
      };
      formattedAmt = (this.amount7 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount7Pos,
        ...options,
      });

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
