import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";

export default class Review1099SAModifier extends ReviewCopyModifier {
  private distributionCode: string;
  private hsaIndicator: boolean;
  private archerMSAIndicator: boolean;
  private maMSAIndicator: boolean;
  private accountNumber: string;

  private amount1: number;
  private amount2: number;
  private amount4: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    distributionCode: string,
    hsaIndicator: boolean,
    archerMSAIndicator: boolean,
    maMSAIndicator: boolean,

    amount1: number,
    amount2: number,
    amount4: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.hsaIndicator = hsaIndicator;
    this.archerMSAIndicator = archerMSAIndicator;
    this.maMSAIndicator = maMSAIndicator;
    this.distributionCode = distributionCode;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount4 = amount4;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData({ isRegularForm: true });
      // Fill Recipient data
      await this.modifyRecData({ isRegularForm: true });

      this.drawCalendarYearPartly();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // *** RIGHT SIDE *** //
      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      // Gross distribution (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 121 - this.getFontSize(),
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount1Pos,
        ...options,
      });

      // Earnings on excess cont. (amount 2)
      const amount2Pos = {
        x: amount1Pos.x + 100,
        y: amount1Pos.y,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount2Pos,
        ...options,
      });

      // Distribution code
      const distrCodePos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 30,
      };
      firstPage.drawText(this.distributionCode, {
        ...distrCodePos,
        ...options,
      });

      // FMV on date of death (amount 4)
      const amount4Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 35,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount4Pos,
        ...options,
      });

      // HSA indicator (Box 5)
      const hsaIndicatorPos = {
        x: distrCodePos.x + 30,
        y: distrCodePos.y - 18,
      };
      if (this.hsaIndicator) {
        firstPage.drawText("z", {
          ...hsaIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // Archer MSA indicator (Box 5)
      const archerMSAIndicatorPos = {
        x: hsaIndicatorPos.x,
        y: hsaIndicatorPos.y - 15,
      };
      if (this.archerMSAIndicator) {
        firstPage.drawText("z", {
          ...archerMSAIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // MA MSA indicator (Box 5)
      const maMSAIndicatorPos = {
        x: archerMSAIndicatorPos.x,
        y: archerMSAIndicatorPos.y - 18,
      };
      if (this.maMSAIndicator) {
        firstPage.drawText("z", {
          ...maMSAIndicatorPos,
          ...options,
          font: tickMark,
        });
      }

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
