import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";

export default class Review1099PATRModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;
  private specified_coop: boolean;

  private amount1: number;
  private amount2: number;
  private amount3: number;
  private amount4: number;
  private amount5: number;
  private amount6: number;
  private amountB: number;
  private amountC: number;
  private amountD: number;
  private amount7: number;
  private amount8: number;
  private amountA: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount1: number,
    amount2: number,
    amount3: number,
    amount4: number,
    amount5: number,
    amount6: number,
    amountB: number,
    amountC: number,
    amountD: number,
    amount7: number,
    amount8: number,
    amountA: number,
    specified_coop: boolean
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amount4 = amount4;
    this.amount5 = amount5;
    this.amount6 = amount6;
    this.amountB = amountB;
    this.amountC = amountC;
    this.amountD = amountD;
    this.amount7 = amount7;
    this.amount8 = amount8;
    this.amountA = amountA;

    this.specified_coop = specified_coop;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // Draw second tin notice if it is marked
      if (this.secondTinNotice) {
        const secondTinNoticePos = {
          x: this.getLeftSideXCoord() + 216,
          y: 220,
        };
        await this.modifySecondTinNotice(secondTinNoticePos);
      }

      // *** RIGHT SIDE *** //

      this.drawCalendarYearPartly();

      // Patronage dividends (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 49 - this.getFontSize(),
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount1Pos,
        ...options,
      });

      // Nonpatronage dividends (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 24,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount2Pos,
        ...options,
      });

      // Per-unit retain allocations (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 24,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount3Pos,
        ...options,
      });

      // Federal Income tax withheld (amount 4)
      const amount4Pos = {
        x: amount3Pos.x,
        y: amount3Pos.y - 24,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount4Pos,
        ...options,
      });

      // Redeemed nonqualified notices (amount 5)
      const amount5Pos = {
        x: amount4Pos.x + 100,
        y: amount4Pos.y,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount5Pos,
        ...options,
      });

      // Section 199A(g) deduction (amount 6)
      const amount6Pos = {
        x: amount4Pos.x,
        y: amount4Pos.y - 36,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount6Pos,
        ...options,
      });

      // Qualified Payments (Sec.199A(b)(7)) (amount B)
      const amountBPos = {
        x: amount6Pos.x + 100,
        y: amount6Pos.y,
      };
      formattedAmt = (this.amountB / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amountBPos,
        ...options,
      });

      // Section 199A(a) Qualified items (amount C)
      const amountCPos = {
        x: amount6Pos.x,
        y: amount6Pos.y - 24,
      };
      formattedAmt = (this.amountC / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amountCPos,
        ...options,
      });

      // Section 199A(a) SSTB items (amount D)
      const amountDPos = {
        x: amountBPos.x,
        y: amountBPos.y - 24,
      };
      formattedAmt = (this.amountD / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amountDPos,
        ...options,
      });

      // Investment credit (amount 7)
      const amount7Pos = {
        x: amountCPos.x,
        y: amountCPos.y - 24,
      };
      formattedAmt = (this.amount7 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount7Pos,
        ...options,
      });

      // Work opportunity credit (amount 8)
      const amount8Pos = {
        x: amountDPos.x,
        y: amountDPos.y - 24,
      };
      formattedAmt = (this.amount8 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount8Pos,
        ...options,
      });

      // Other credit and deductions (amount A)
      const amountAPos = {
        x: amount7Pos.x,
        y: amount7Pos.y - 24,
      };
      formattedAmt = (this.amountA / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amountAPos,
        ...options,
      });

      // Specified coop.
      if (this.specified_coop) {
        this.getReviewCopy().registerFontkit(fontkit);
        const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
        const fontBuffer = await tickMarkFontRes.arrayBuffer();
        const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

        const specifiedCoopPos = {
          x: amountAPos.x + 154,
          y: amountAPos.y,
        };
        firstPage.drawText("z", {
          ...specifiedCoopPos,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
