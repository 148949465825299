import React, { useEffect, useState } from "react";
import { TicketComment, TicketDetailsData } from "../../../interfaces/main";
import EmailUser from "../AdminRelatedComponents/EmailUser";
import { MdEmail } from "react-icons/md";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector } from "react-redux";
import TicketStatus from "./TicketStatus";
import devLogInstance from "../../lib/loggerConfig";
import updateTicketStatus from "../../lib/Handlers/UpdateTicketStatus.handler";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import CommentSection from "./CommentSection";
import { FaComments } from "react-icons/fa6";
import CommentForm from "../AdminRelatedComponents/SupportTicketRelated/CommentForm";
import handleAddingCommentOnTicket from "../../lib/Handlers/AddCommentOnTicket.handler";

interface Props extends React.HTMLProps<HTMLElement> {
  onAdminPortal: boolean;
  payload: TicketDetailsData;
  comments: TicketComment[];
  ticketId: string;
  refetchFn: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<any, Error>>;
}

const serializeTicketStatusOptions = () => {
  return [
    {
      label: <TicketStatus ticketStatus="open" />,
      value: "open",
    },
    {
      label: <TicketStatus ticketStatus="inProgress" />,
      value: "inProgress",
    },
    {
      label: <TicketStatus ticketStatus="closed" />,
      value: "closed",
    },
  ];
};

const selectInitialTicketStatus = (ticketStatus: string) => {
  devLogInstance.log(ticketStatus);
  const currentStatus = serializeTicketStatusOptions().filter(
    (option) => option.value === ticketStatus
  );
  return currentStatus[0]; // Get the single item from the array;
};

export default function TicketDetailComponent({
  onAdminPortal,
  payload,
  comments,
  ticketId,
  refetchFn,
}: Props) {
  const [emailUser, toEmailUser] = useState(false);
  // const [callUser, toCallUser] = useState(false);
  const style = useSelector((state: any) => state.themeChange);

  const [ticketStatus, setTicketStatus] = useState({
    label: <TicketStatus ticketStatus="" />,
    value: "",
  });

  const [disableCommentForm, toDisableCommentForm] = useState(true);

  useEffect(() => {
    setTicketStatus(selectInitialTicketStatus(payload.ticket_status));

    return () =>
      setTicketStatus({
        label: <TicketStatus ticketStatus="" />,
        value: "",
      });
  }, [payload.ticket_status]);

  // Handle status update
  const handleStatusUpdate = async (option: any) => {
    devLogInstance.log(option);
    // Update the ticket status state
    setTicketStatus(option);

    // Call the API to update the status
    await updateTicketStatus([ticketId], option.value);

    // Refetch the latest data
    refetchFn();
  };

  // Handle adding comment
  const handleAddingComment = (comment: string) => {
    // Call the handler API to handle adding comment to the ticket
    handleAddingCommentOnTicket(comment, ticketId);

    // Refresh the data
    refetchFn();
  };

  return (
    <div className="">
      <header className="ticket-status-container py-2 border-b border-b-gray-400 flex items-center justify-between">
        <h1 className="text-taxeve-primary-violet flex items-center gap-4">
          Status:{" "}
          <span className={`w-48`}>
            {/* {ticketStatusMap[payload.ticket_status]} */}
            <Select
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              id="ticket_status"
              options={serializeTicketStatusOptions()}
              onChange={handleStatusUpdate}
              value={ticketStatus}
              placeholder=""
              isSearchable={false}
            />
          </span>
        </h1>

        <div className="ticket-id flex items-center gap-3">
          <h2 className="text-taxeve-primary-violet">Ticket Id:</h2>
          <p className="text-lg font-bold text-gray-700">{ticketId}</p>
        </div>
      </header>

      <div className="ticket-query-container py-6">
        <div className="w-fit p-4 rounded-xl shadow-md">
          <h3 className="user-name text-wrap text-lg flex items-center gap-4">
            {payload.user_id.full_name}
            <em className="user-phonenumber text-sm font-semibold text-gray-500">
              (Phone: {payload.user_id.phonenumber})
            </em>
          </h3>
          <p className="query text-lg">{payload.query}</p>
        </div>
        <div className="response-type mt-10 text-center text-lg font-semibold text-gray-500">
          <em>
            {!payload.response_type
              ? "User has not provided any response type"
              : `User wants a response by ${payload.response_type}`}
          </em>
        </div>
      </div>

      <footer className="flex items-center gap-6 p-4">
        {emailUser && (
          <EmailUser
            subject={`Response to Ticket ID: ${ticketId}`}
            recipient={payload.user_id.email}
            toEmailUser={toEmailUser}
          />
        )}

        {!disableCommentForm && (
          <CommentForm
            disableCommentForm={toDisableCommentForm}
            handlePostingComment={handleAddingComment}
          />
        )}

        {!emailUser && disableCommentForm && (
          <button
            id="email-user-btn"
            onClick={() => toEmailUser(true)}
            className="py-3 px-4 rounded-lg bg-taxeve-primary-violet hover:opacity-80 text-white font-semibold flex items-center justify-center gap-2"
          >
            <MdEmail className="text-lg" /> Email user
          </button>
        )}

        {disableCommentForm && !emailUser && (
          <button
            id="add-comment-btn"
            onClick={() => toDisableCommentForm(false)}
            className="py-3 px-4 rounded-lg bg-taxeve-primary-yellow hover:opacity-80 text-white font-semibold flex items-center justify-center gap-2"
          >
            <FaComments className="text-lg" /> Add comment
          </button>
        )}
      </footer>

      <CommentSection comments={comments} />
    </div>
  );
}
