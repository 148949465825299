import { axiosInstance } from "../axiosConfig";
import devLogInstance from "../loggerConfig";
import DisableScreen from "../utils/disableScreen";
import Handler, { Main } from "./main";

export default async function updateTicketStatus(ticketIds: string[], status: string) {
  try {
    DisableScreen.enable();

    const { data } = await axiosInstance.put("/admin/update_ticket_status", {
      ticket_ids: ticketIds,
      status: status,
    });

    devLogInstance.log({ response_after_updating_ticket_status: data });

    DisableScreen.disable();
  } catch (err) {
    Handler.handleError(err, Main.stateDispatch, () => {});
  }
}
