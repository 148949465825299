import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import FormatDate from "../utils/Helpers/FormatDate";
import ReviewCopyModifier from "./ReviewCopyModifier";
import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";
import LocalReviewDetailsModifier from "./LocalReviewDetailsModifier";

export default class Review1099RModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private firstYearOfRothContrib: string;
  private dateOfPayment: string;
  private fatcaFilingIndicator: boolean;
  private taxableAmtNotDetermined: boolean;
  private totalDistrIndicator: boolean;
  private distributionCode: string;
  private iraSepSimpleIndicator: boolean;
  private percentOfTotalDistr: number;

  private amount1: number;
  private amount2: number;
  private amount3: number;
  private amount4: number;
  private amount5: number;
  private amount6: number;
  private amountB: number;
  private amount8: number;
  private amount9: number;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;
  private stateIncome1: number;
  private stateIncome2: number;

  private locality1: string;
  private localTax1: number;
  private localIncome1: number;

  private locality2: string;
  private localTax2: number;
  private localIncome2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    firstYearOfRothContrib: string,
    dateOfPayment: string,
    fatcaFilingIndicator: boolean,
    taxableAmtNotDetermined: boolean,
    totalDistrIndicator: boolean,
    distributionCode: string,
    iraSepSimpleIndicator: boolean,
    percentOfTotalDistr: number,

    amount1: number,
    amount2: number,
    amount3: number,
    amount4: number,
    amount5: number,
    amount6: number,
    amountB: number,
    amount8: number,
    amount9: number,

    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number,
    stateIncome1: number,
    stateIncome2: number,

    locality1: string,
    localTax1: number,
    localIncome1: number,
    locality2: string,
    localTax2: number,
    localIncome2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.firstYearOfRothContrib = firstYearOfRothContrib;
    this.dateOfPayment = dateOfPayment;
    this.fatcaFilingIndicator = fatcaFilingIndicator;
    this.taxableAmtNotDetermined = taxableAmtNotDetermined;
    this.totalDistrIndicator = totalDistrIndicator;
    this.distributionCode = distributionCode;
    this.iraSepSimpleIndicator = iraSepSimpleIndicator;
    this.percentOfTotalDistr = percentOfTotalDistr;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amount4 = amount4;
    this.amount5 = amount5;
    this.amount6 = amount6;
    this.amountB = amountB;
    this.amount8 = amount8;
    this.amount9 = amount9;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
    this.stateIncome1 = stateIncome1;
    this.stateIncome2 = stateIncome2;

    this.locality1 = locality1;
    this.localTax1 = localTax1;
    this.localIncome1 = localIncome1;
    this.locality2 = locality2;
    this.localTax2 = localTax2;
    this.localIncome2 = localIncome2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.fillLSFForFormsWithFatcaFiling(
        this.fatcaFilingIndicator || false,
        this.accountNumber,
        false,
        -3,
        0,
        false,
        false,
        0,
        12
      );

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Amount allocable to IRR (Payment amount B)
      const amountBPosCords = {
        x: this.getLeftSideXCoord() + 12,
        y: height - 290 - this.getFontSize() - 24,
      };
      const amountB = (this.amountB / 100).toFixed(2);
      firstPage.drawText(amountB, {
        ...amountBPosCords,
        ...options,
      });

      // 1st year of ROTH contrib.
      const rothContribYearPosCords = {
        x: amountBPosCords.x + 130,
        y: amountBPosCords.y,
      };
      firstPage.drawText(this.firstYearOfRothContrib, {
        ...rothContribYearPosCords,
        ...options,
      });

      //   // Draw account number
      //   const accountNumPos = {
      //     x: this.getLeftSideXCoord(),
      //     y: 220,
      //   };
      //   const accountNum = this.accountNumber || "";
      //   firstPage.drawText(accountNum, {
      //     x: accountNumPos.x + 4,
      //     y: height - accountNumPos.y - this.getFontSize() * 2,
      //     ...options,
      //   });

      // Date of payment
      const dateOfPaymentPosCords = {
        x: rothContribYearPosCords.x + 57,
        y: rothContribYearPosCords.y - 33,
      };
      const dateOfPmtFormatter = new FormatDate(this.dateOfPayment || "");
      const formattedDateOfPayment = dateOfPmtFormatter.format();
      firstPage.drawText(formattedDateOfPayment, {
        ...dateOfPaymentPosCords,
        ...options,
      });

      // *** RIGHT SIDE *** //
      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      // Gross distribution (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 61 - this.getFontSize(),
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount1Pos,
        ...options,
      });

      // Taxable amount (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 36,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount2Pos,
        ...options,
      });

      // Taxable amount not determined (Box 2b)
      const box2bPosCords = {
        x: amount2Pos.x + 67,
        y: amount2Pos.y - 22,
      };
      if (this.taxableAmtNotDetermined) {
        firstPage.drawText("z", {
          ...box2bPosCords,
          ...options,
          font: tickMark,
        });
      }
      // Total distribution (Box 2b)
      const totalDistrPosCords = {
        ...box2bPosCords,
        x: box2bPosCords.x + 87,
      };
      if (this.totalDistrIndicator) {
        firstPage.drawText("z", {
          ...totalDistrPosCords,
          ...options,
          font: tickMark,
        });
      }

      // Capital gain (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 24 * 3,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount3Pos,
        ...options,
      });

      // Federal Income tax withheld (amount 4)
      const amount4Pos = {
        x: amount3Pos.x + 100,
        y: amount3Pos.y,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount4Pos,
        ...options,
      });

      // Employee contributions (amount 5)
      const amount5Pos = {
        x: amount3Pos.x,
        y: amount3Pos.y - 24 * 2,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount5Pos,
        ...options,
      });

      // Net unrealized appreciation in employer’s securities (amount 6)
      const amount6Pos = {
        x: amount5Pos.x + 100,
        y: amount5Pos.y,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount6Pos,
        ...options,
      });

      // Distribution code(s)
      const distributionCodePosCords = {
        x: amount5Pos.x,
        y: amount5Pos.y - 36,
      };
      firstPage.drawText(this.distributionCode, {
        ...distributionCodePosCords,
        ...options,
      });

      // IRA / SEP / SIMPLE indicator
      const iraSepSimpleIndicatorPosCords = {
        x: distributionCodePosCords.x + 70,
        y: distributionCodePosCords.y,
      };
      if (this.iraSepSimpleIndicator) {
        firstPage.drawText("z", {
          ...iraSepSimpleIndicatorPosCords,
          ...options,
          font: tickMark,
        });
      }

      // Other (amount 8)
      const amount8Pos = {
        x: distributionCodePosCords.x + 100,
        y: distributionCodePosCords.y,
      };
      formattedAmt = (this.amount8 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount8Pos,
        ...options,
      });

      // TODO: There is a percentage field for amount 8 as well
      // work on it when the doubt regarding this particular field is resolved

      // Percentage of total distr.
      const percentOfTotalDistrPosCords = {
        x: iraSepSimpleIndicatorPosCords.x - 7,
        y: iraSepSimpleIndicatorPosCords.y - 24,
      };
      const percentOfTotalDistr =
        this.percentOfTotalDistr && this.percentOfTotalDistr > 0
          ? this.percentOfTotalDistr.toString()
          : "";
      firstPage.drawText(percentOfTotalDistr, {
        ...percentOfTotalDistrPosCords,
        ...options,
      });

      // Total employee contrib (amount 9)
      const amount9Pos = {
        x: amount8Pos.x,
        y: amount8Pos.y - 24,
      };
      formattedAmt = (this.amount9 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        ...amount9Pos,
        ...options,
      });

      // State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: percentOfTotalDistrPosCords.x,
          prevFieldYCoord: percentOfTotalDistrPosCords.y,
        },
        {
          stateNum1: this.stateNum1,
          prevFieldXCoord: amount9Pos.x,
          prevFieldYCoord: amount9Pos.y,
        },
        {
          stateTax1: this.stateTax1,
        },
        this.stateIncome1,
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        },
        this.stateIncome2
      );

      // Local details
      this.fillLocalDetails(
        {
          locality1: this.locality1,
          prevFieldXCoord: percentOfTotalDistrPosCords.x,
          prevFieldYCoord: percentOfTotalDistrPosCords.y,
        },
        {
          localTax1: this.localTax1,
        },
        this.localIncome1,
        {
          locality2: this.locality2,
        },
        {
          localTax2: this.localTax2,
        },
        this.localIncome2
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateTax1Obj: { stateTax1: number },
    stateIncome1: number,
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number },
    stateIncome2: number
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord
    );

    // State details
    // State tax
    stateDetailsMod.modifyStateTaxDetail(stateTax1Obj.stateTax1, stateTax2Obj.stateTax2, true, -65);
    // State num
    stateDetailsMod.modifyStateNumDetail(
      stateNum1Obj.stateNum1,
      stateNum2Obj.stateNum2,
      state1Obj.state1,
      state2Obj.state2
    );
    // State income
    stateDetailsMod.modifyStateIncomeDetail(stateIncome1, stateIncome2, -65);
  }

  private fillLocalDetails(
    locality1Obj: { locality1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    localTax1Obj: { localTax1: number },
    localIncome1: number,
    locality2Obj: { locality2: string },
    localTax2Obj: { localTax2: number },
    localIncome2: number
  ): void {
    const localDetailsModder: LocalReviewDetailsModifier = new LocalReviewDetailsModifier(
      this,
      locality1Obj.prevFieldXCoord,
      locality1Obj.prevFieldYCoord
    );

    // Local details
    // Local tax
    localDetailsModder.modifyLocalTaxDetail(localTax1Obj.localTax1, localTax2Obj.localTax2, -55);
    // Local name
    localDetailsModder.modifyLocalityName(locality1Obj.locality1, locality2Obj.locality2);
    // State income
    localDetailsModder.modifyLocalIncomeDetail(localIncome1, localIncome2, -65);
  }
}
