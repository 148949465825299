import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import SelectStyle from "../../../lib/ReactSelectTheme/reactSelectStyle";
import devLogInstance from "../../../lib/loggerConfig";

interface Props {
  handleRaisingTicket: (responseType: string) => void;
  disable?: boolean;
}

const responseTypeOptions = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Callback",
    value: "callback",
  },
];

export default function ResponseTypeSelector({ handleRaisingTicket, disable }: Props) {
  const style = useSelector((state: any) => state.themeChange);

  const handleResponseTypeSelection = (option: any) => {
    devLogInstance.log(option);

    const value = option.value;

    // Call the main handler to handle raising ticket
    handleRaisingTicket(value);
  };

  return (
    <div>
      Please select how you would like to get a response:
      <div className="py-2">
        <Select
          theme={new SelectStyle(style).customTheme}
          styles={new SelectStyle(style).customStyle}
          id="response-type-selector"
          options={responseTypeOptions}
          onChange={handleResponseTypeSelection}
          //   value={ticketStatus}
          placeholder=""
          isSearchable={false}
          isDisabled={disable}
        />
      </div>
    </div>
  );
}
