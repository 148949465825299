import React, { useContext } from "react";
import { TicketNotificationData } from "../../../../interfaces/main";
import TextModifier from "../../../lib/TextModifier";
import { TicketSelectionCtx } from "../../../pages/AdminRelatedPages/AdminDashboard";
import TicketIcon from "./TicketIcon";

interface Props extends React.HTMLProps<HTMLElement> {
  ticketObj: TicketNotificationData;
  isNew: boolean;
  handleMouseOver: (ticketId: string) => void;
}

export default function TicketNotificationContainer({
  ticketObj,
  isNew,
  handleMouseOver,
  className,
}: Props) {
  const ticketSelectionCtx = useContext(TicketSelectionCtx);
  if (!ticketSelectionCtx) return <pre>Ticket selection context is null</pre>;

  const handleHover = () => {
    handleMouseOver(ticketObj.ticketId);
  };

  const handleClick = () => {
    ticketSelectionCtx.handleTicketClick(ticketObj.ticketId);
  };

  return (
    <div className={`px-4 hover:bg-gray-200`} onMouseOver={handleHover}>
      <div onClick={handleClick} className={`flex p-4 items-center gap-4 relative ${className}`}>
        {isNew && (
          <i className="absolute top-1 left-1 -rotate-[14deg] text-green-400 font-semibold">New</i>
        )}
        <TicketIcon />
        <span>
          <h3>Ticket ID: {ticketObj.ticketId}</h3>
          <p>{TextModifier.truncate(ticketObj.ticketQuery, 30)}</p>
        </span>
      </div>
    </div>
  );
}
