import React, { useState } from "react";
import { BsFillSendFill } from "react-icons/bs";

interface Props {
  handleRaisingTicket: (query: string) => void;
}

export default function RaiseTicketForm({ handleRaisingTicket }: Props) {
  const [query, setQuery] = useState("");

  const handleQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    setQuery(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent page reload

    setQuery(""); // Clear the textarea

    handleRaisingTicket(query);
  };

  return (
    <form className="py-2 px-6" onSubmit={handleSubmit}>
      <div className="flex items-center gap-4">
        <textarea
          placeholder="Write your query/complaint here..."
          className="border border-gray-400 rounded-lg text-black p-4 w-full resize-none"
          rows={4}
          value={query}
          onChange={handleQueryChange}
        />
        <button
          disabled={!query}
          className="p-4 w-15 h-15 bg-taxeve-primary-violet rounded-full flex items-center justify-center"
        >
          <BsFillSendFill className="text-2xl text-white" />
        </button>
      </div>
    </form>
  );
}
