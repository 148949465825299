import React from "react";
import MessageLoadingAnimation from "./MessageLoadingAnimation";

interface Props {
  message: string | React.ReactNode;
  messenger: string | React.ReactNode;
  isLoading: boolean;
  rightAligned?: boolean;
}

export default function MessageBubble({ message, messenger, isLoading, rightAligned }: Props) {
  return (
    <div className={`px-4 py-5 ${rightAligned && "flex items-center justify-end"}`}>
      <div className="w-fit bg-white rounded-xl shadow-md p-4 min-w-[10rem] max-w-[30rem]">
        {!isLoading ? (
          <>
            <section className="messenger-name font-bold">{messenger}</section>
            <section className="message-body">{message}</section>
          </>
        ) : (
          <div className="flex justify-center">
            <MessageLoadingAnimation />
          </div>
        )}
      </div>
    </div>
  );
}
