import React from "react";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
// import { FaMicrosoft } from "react-icons/fa";
// import { SiIntuit } from "react-icons/si";
import { Link } from "react-router-dom";

export default function ThirdPartyLogin() {
  return (
    <section className="third-party-login p-4 flex justify-center gap-4 items-center">
      <div className="google-login transition-all hover:scale-110">
        <Link
          to={`${process.env.REACT_APP_API_ENDPOINT}/auth/google`}
          className="flex flex-col items-center justify-center"
        >
          <div className="border border-slate-300 py-2 px-3 rounded-lg w-fit h-12">
            <FcGoogle className="text-3xl" />
          </div>
          <p className="text-gray-600">Google</p>
        </Link>
      </div>

      <div className="microsoft-login transition-all hover:scale-110">
        <Link
          to={`${process.env.REACT_APP_API_ENDPOINT}/auth/microsoft`}
          className="flex flex-col items-center justify-center"
        >
          <div className="border border-slate-300 py-2 px-3 rounded-lg w-fit h-12">
            {/* <FaMicrosoft className="text-3xl" /> */}
            <img src="/Assets/microsoft_logo.png" alt="Microsoft" className="w-7" />
          </div>
          <p className="text-gray-600">Microsoft</p>
        </Link>
      </div>

      <div className="linkedin-login transition-all hover:scale-110">
        <Link
          to={`${process.env.REACT_APP_API_ENDPOINT}/auth/linkedin`}
          className="flex flex-col items-center justify-center"
        >
          <div className="border border-slate-300 py-2 px-3 rounded-lg w-fit h-12">
            <BsLinkedin className="text-3xl text-blue-700" />
          </div>
          <p className="text-gray-600">Linkedin</p>
        </Link>
      </div>
    </section>
  );
}
