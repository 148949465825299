import React from "react";
import { BiSupport } from "react-icons/bi";
import { Link } from "react-router-dom";
import { HELP_AND_SUPPORT } from "../lib/routes";

interface Props extends React.HTMLProps<React.ReactNode> {}

export default function HelpAndSupportIcon({ className }: Props) {
  return (
    <Link to={HELP_AND_SUPPORT} className="flex items-center justify-center px-2">
      <BiSupport id="help-and-support-icon" className={`${className}`} />
    </Link>
  );
}
