import React, { useEffect, useState } from "react";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import { useDispatch, useSelector } from "react-redux";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_3,
  CORRECTION_INITIAL,
  COVERDELL_ESA,
  DEFAULT_NON_FOREIGN_COUNTRY,
  DESIGNATED_BENEFICIARY,
  ERROR_POPUP,
  PAGE_E_FILE,
  PRIVATE_TUTION,
  Q_1099,
  STATE_TUTION,
  SUBMIT_ADD_ANOTHER,
  TRUSTEE_TO_TRUSTEE_TRANSFER,
} from "../../lib/constants";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import { useNavigate } from "react-router-dom";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import { isValidAmount } from "./Form1099-B";
import devLogInstance from "../../lib/loggerConfig";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";
import { useRecTinUnknown } from "../../lib/customHooks";

export default function Form1099Q({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount3: "",
  }); // for displaying on the input field

  const [miscFields, setMiscFields] = useState({
    trustee_to_trustee_transfer_indicator: false,
    private_tution_indicator: false,
    state_tution_indicator: false,
    coverdell_esa_indicator: false,
    designated_beneficiary_indicator: false,
  });

  const { recTinUnknown } = useRecTinUnknown();

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
    });

    // Set the misc field values
    setMiscFields({
      trustee_to_trustee_transfer_indicator:
        formData?.trustee_to_trustee_transfer_indicator || false,
      private_tution_indicator: formData?.private_tution_indicator || false,
      state_tution_indicator: formData?.state_tution_indicator || false,
      coverdell_esa_indicator: formData?.coverdell_esa_indicator || false,
      designated_beneficiary_indicator: formData?.designated_benificiary_indicator || false,
    });

    // Reset
    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount3: "",
      });

      setMiscFields({
        trustee_to_trustee_transfer_indicator: false,
        private_tution_indicator: false,
        state_tution_indicator: false,
        coverdell_esa_indicator: false,
        designated_beneficiary_indicator: false,
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount3,
    formData?.trustee_to_trustee_transfer_indicator,
    formData?.private_tution_indicator,
    formData?.state_tution_indicator,
    formData?.coverdell_esa_indicator,
    formData?.designated_benificiary_indicator,
  ]);

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;

      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;

      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  const handleMiscFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case TRUSTEE_TO_TRUSTEE_TRANSFER:
        setMiscFields({
          ...miscFields,
          trustee_to_trustee_transfer_indicator: !miscFields.trustee_to_trustee_transfer_indicator, // check and uncheck
        });
        break;

      case PRIVATE_TUTION:
        setMiscFields({
          ...miscFields,
          state_tution_indicator: false,
          coverdell_esa_indicator: false,
          private_tution_indicator: !miscFields.private_tution_indicator, // check and uncheck
        });
        break;

      case STATE_TUTION:
        setMiscFields({
          ...miscFields,
          private_tution_indicator: false,
          coverdell_esa_indicator: false,
          state_tution_indicator: !miscFields.state_tution_indicator, // check and uncheck
        });
        break;

      case COVERDELL_ESA:
        setMiscFields({
          ...miscFields,
          private_tution_indicator: false,
          state_tution_indicator: false,
          coverdell_esa_indicator: !miscFields.coverdell_esa_indicator, // check and uncheck
        });
        break;

      case DESIGNATED_BENEFICIARY:
        setMiscFields({
          ...miscFields,
          designated_beneficiary_indicator: !miscFields.designated_beneficiary_indicator, // check and uncheck
        });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,

      // Misc fields
      ...miscFields,
    };
    devLogInstance.log({ data_before_submitting: data });

    //   const incomeAmounts = [data.payment_amount2];

    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [data.payment_amount1, data.payment_amount2, data.payment_amount3],
          0,
          [data.payment_amount1, data.payment_amount2, data.payment_amount3],
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                trustee_to_trustee_transfer_indicator: false,
                private_tution_indicator: false,
                state_tution_indicator: false,
                coverdell_esa_indicator: false,
                designated_beneficiary_indicator: false,
              });
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };

  return (
    <>
      <form className="form-main-container">
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      height: "50%",
                    }}
                  >
                    <AmountHeading heading={"Gross distribution"} amountNum={"1"} />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      height: "50%",
                    }}
                  >
                    <AmountHeading heading={"Earnings"} amountNum={"2"} />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail
                  formType={formType}
                  taxYear={taxYear}
                  formName={Q_1099}
                  newVersion={true}
                />
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={"Basis"} amountNum="3" />
                  <Amount
                    type="text"
                    id={AMOUNT_3}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount3}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <AmountHeading heading={"Trustee to trustee transfer"} amountNum="4" />
                  <input
                    id={TRUSTEE_TO_TRUSTEE_TRANSFER}
                    style={{ padding: "0.5rem 1rem" }}
                    type="checkbox"
                    checked={miscFields.trustee_to_trustee_transfer_indicator}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={"Distribution is from:"} amountNum="5" />
                  <em className="font-semibold">Qualified tution program:-</em>
                  <div className="flex items-center justify-between">
                    <p>Private</p>
                    <input
                      id={PRIVATE_TUTION}
                      style={{ padding: "0.5rem 1rem" }}
                      type="checkbox"
                      checked={miscFields.private_tution_indicator}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <p>State</p>
                    <input
                      id={STATE_TUTION}
                      style={{ padding: "0.5rem 1rem" }}
                      type="checkbox"
                      checked={miscFields.state_tution_indicator}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <p>Coverdell ESA</p>
                    <input
                      id={COVERDELL_ESA}
                      style={{ padding: "0.5rem 1rem" }}
                      type="checkbox"
                      checked={miscFields.coverdell_esa_indicator}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <AmountHeading
                    heading={"Check if the recipient is not the designated beneficiary"}
                    amountNum={"6"}
                  />
                  <input
                    id={DESIGNATED_BENEFICIARY}
                    style={{ padding: "0.5rem 1rem" }}
                    type="checkbox"
                    checked={miscFields.designated_beneficiary_indicator}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
