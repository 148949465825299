import React, { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";

interface Props {
  placeholder?: string;
  handleSearch: (searchText: string) => void;
}

export default function SearchBar(props: Props) {
  const [searchText, setSearchText] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setSearchText(value); // Maintain state

    props.handleSearch(value); // Handle search
  };

  return (
    <div className="flex items-center rounded-lg overflow-hidden border border-gray-400 w-[30%]">
      <input
        type="text"
        placeholder={props.placeholder ?? "Type to search"}
        className="py-1 px-2 rounded-none border-none focus:outline-none w-full"
        onChange={handleChange}
        value={searchText}
      />
      <span className="px-3 py-2">
        <IoSearchOutline className="text-xl" />
      </span>
    </div>
  );
}
