import React, { useContext, useMemo, useState } from "react";
import FilterOption from "./FilterOption";
import { TICKET_CLOSED, TICKET_IN_PROGRESS, TICKET_OPEN } from "../../lib/constants";
import { TicketFilterCtx } from "../AdminRelatedComponents/SupportTicketRelated/AssignedTicketList";
import getCachedStatusFilterOptions from "../../lib/utils/Helpers/GetCachedStatusFilterOptions";

const statusOptions: string[] = [TICKET_OPEN, TICKET_IN_PROGRESS, TICKET_CLOSED];

interface Props {
  isRegularUser?: boolean;
}

export default function TicketFilterWindow({ isRegularUser }: Props) {
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<string[]>(
    getCachedStatusFilterOptions(isRegularUser)
  );

  const ticketFilterCtx = useContext(TicketFilterCtx);
  if (!ticketFilterCtx) throw new Error("Ticket filter ctx is null");

  // Cache the filter status options everytime the list changes
  useMemo(() => {
    const cacheFilterByStatusOptions = () => {
      !isRegularUser
        ? localStorage.setItem("filterByStatusOptions", JSON.stringify(selectedStatusOptions))
        : localStorage.setItem("statusFilterOptionsRegular", JSON.stringify(selectedStatusOptions));
    };
    cacheFilterByStatusOptions();
  }, [selectedStatusOptions, isRegularUser]);

  const handleSelectingFilterOpt = (optionName: string) => {
    // If the option already exists in the selected list
    // then remove it or else push it
    if (selectedStatusOptions.includes(optionName)) {
      // Get the idx of the option in the list
      const idxOfOption = selectedStatusOptions.indexOf(optionName);
      selectedStatusOptions.splice(idxOfOption, 1); // Remove the option
      setSelectedStatusOptions([...selectedStatusOptions]); // Update the state (for updating the style)
      ticketFilterCtx.handleFilterByStatus([...selectedStatusOptions]); // Filter the entire list in the parent component
      return;
    }

    setSelectedStatusOptions([...selectedStatusOptions, optionName]);
    ticketFilterCtx.handleFilterByStatus([...selectedStatusOptions, optionName]); // Filter the entire list in the parent component
  };

  return (
    <div className="absolute z-10 bg-white shadow-xl p-4 rounded-lg">
      <section className="filter-by-status">
        <p className="text-sm font-bold">Filter by status:</p>
        <div className="filter-by-status-options flex items-center gap-4 p-2">
          {statusOptions.map((option, i) => (
            <FilterOption
              onClick={() => handleSelectingFilterOpt(option)}
              key={i}
              optionName={option}
              className={`text-sm ${
                selectedStatusOptions.includes(option) && "bg-blue-400 text-white"
              }`}
            />
          ))}
        </div>
      </section>
    </div>
  );
}
