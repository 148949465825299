import React from "react";
import { r1099AmountDisplayTexts } from "../../../../../lib/languagePacks/en-us";
import { FIRST_YEAR_OF_ROTH_CONTRIB } from "../../../../../lib/constants";

interface Props {
  onChange: (e: any) => void;
  value: number;
}

export default function Box11({ onChange, value }: Props) {
  return (
    <>
      <h4 className="flex gap-2">
        <p>11</p> {r1099AmountDisplayTexts.rothContribYear}
      </h4>
      <input
        id={FIRST_YEAR_OF_ROTH_CONTRIB}
        className="py-2"
        type="number"
        onChange={onChange}
        value={value <= 0 ? "" : value}
      />
    </>
  );
}
