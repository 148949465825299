/* NOTE: Make sure to look into and change the route in backend as well 
         if that route is used and changed here.
*/

import { AFTER_LOGIN_URL_IDENTIFIER } from "./constants";

export const LANDING_PAGE = "/";
export const VERIFY_EMAIL = "/activate";
export const SIGNIN = "/signin";
export const REGISTER = "/register";
export const PASSWORD_RESET = "/reset-password";

// Main / After login routes
export const HOME = `/${AFTER_LOGIN_URL_IDENTIFIER}/home`;
export const E_FILING = `/${AFTER_LOGIN_URL_IDENTIFIER}/e-filing`;
export const REVIEW = `/${AFTER_LOGIN_URL_IDENTIFIER}/review`;
export const FILE_UPLOAD = `/${AFTER_LOGIN_URL_IDENTIFIER}/file-upload`;
export const CART = `/${AFTER_LOGIN_URL_IDENTIFIER}/cart`;
export const SETTINGS = `/${AFTER_LOGIN_URL_IDENTIFIER}/settings`;
export const ACCOUNT_SETTINGS = `${SETTINGS}/account`;
export const PROFILE_SETTINGS = `${SETTINGS}/profile`;
export const HISTORY_PAGE = `/${AFTER_LOGIN_URL_IDENTIFIER}/order-history`;
export const TEMPLATES_DOWNLOAD = `/${AFTER_LOGIN_URL_IDENTIFIER}/download-templates`;
export const CORRECTION = `/${AFTER_LOGIN_URL_IDENTIFIER}/1099-correction`;
export const BUSINESS_SETTINGS = `/${AFTER_LOGIN_URL_IDENTIFIER}/business-settings`;
export const SWITCH_ACCOUNTS = `/${AFTER_LOGIN_URL_IDENTIFIER}/account-switch`;
export const HELP_AND_SUPPORT = `/${AFTER_LOGIN_URL_IDENTIFIER}/help-and-support`;
export const RAISED_TICKET_INFO = `${HELP_AND_SUPPORT}/ticket-details`;

// Info page routes
export const PRICING_PAGE = "/pricing";
export const CONTACT_US_PAGE = "/contact-us";
export const _1099_FORMS_PAGE = "/1099-forms";
export const WHY_CHOOSE_US_PAGE = "/features";
export const ENQUIRY = "/inquiry";

// Admin routes
export const ADMIN = `/admin-dashboard/${process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""}`;
export const ADMIN_LOGIN = `/admin-login/${process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""}`;
export const ADMIN_MANAGEMENT = `/admin-management/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_PRICE_MANAGEMENT = `/admin-price-management/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_PASSWORD_CHANGE = `/admin-change-password/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_FIRE_FILING = `/admin-fire-filing/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_TRACK_TRANSACTIONS = `/admin-track-transactions/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_TICKET_DETAILS = `/admin-ticket-details`;
export const ADMIN_PASSWORD_RESET = `/admin${PASSWORD_RESET}/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;

// Online access portal routes
export const ONLINE_ACCESS_LANDING = "/online-access";
export const ONLINE_ACCESS_LOGIN = "/online-access/login";
export const ONLINE_ACCESS_CONSENT = "/online-access/consent";
export const ONLINE_ACCESS_DASHBOARD = "/online-access/dashboard";

export const TERMS_AND_CONDITION = "/terms-and-condition";
export const PRIVACY_POLICY = "/privacy-policy";
