import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import ReviewCopyModifier from "./ReviewCopyModifier";
// import fontkit from "@pdf-lib/fontkit";
import StateReviewDetailsModifier from "./StateDetailsModifier";

export default class Review1099INTModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private secondTinNotice: boolean = false;

  private amount1: number;
  private amount2: number;
  private amount3: number;
  private amount4: number;
  private amount5: number;
  private amount6: number;
  private amount8: number;
  private amount9: number;
  private amountA: number;
  private amountD: number;
  private amountB: number;
  private amountE: number;

  private payersRTN: string | undefined | null;
  private fatcaFilingIndicator: boolean;
  private foreign_country_or_us_Possession: string;
  private cusipNumber: string | undefined | null;

  private state1: string;
  private state2: string;
  private stateNum1: string;
  private stateNum2: string;
  private stateTax1: number;
  private stateTax2: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    secondTinNotice: boolean,
    amount1: number,
    amount2: number,
    amount3: number,
    amount4: number,
    amount5: number,
    amount6: number,
    amount8: number,
    amount9: number,
    amountA: number,
    amountD: number,
    amountB: number,
    amountE: number,
    payersRTN: string,
    foreign_country_or_us_Possession: string,
    cusipNumber: string,
    fatcaFilingIndicator: boolean,
    state1: string,
    state2: string,
    stateNum1: string,
    stateNum2: string,
    stateTax1: number,
    stateTax2: number
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;
    this.secondTinNotice = secondTinNotice;

    this.amount1 = amount1;
    this.amount2 = amount2;
    this.amount3 = amount3;
    this.amount4 = amount4;
    this.amount5 = amount5;
    this.amount6 = amount6;
    this.amount8 = amount8;
    this.amount9 = amount9;
    this.amountA = amountA;
    this.amountD = amountD;
    this.amountB = amountB;
    this.amountE = amountE;

    this.fatcaFilingIndicator = fatcaFilingIndicator;
    this.payersRTN = payersRTN;
    this.foreign_country_or_us_Possession = foreign_country_or_us_Possession;
    this.cusipNumber = cusipNumber;

    this.state1 = state1;
    this.state2 = state2;
    this.stateNum1 = stateNum1;
    this.stateNum2 = stateNum2;
    this.stateTax1 = stateTax1;
    this.stateTax2 = stateTax2;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      await this.fillLSFForFormsWithFatcaFiling(
        this.fatcaFilingIndicator,
        this.accountNumber,
        this.secondTinNotice,
        2
      );

      // *** RIGHT SIDE *** //

      // Calendar year
      this.drawCalendarYear(13);

      // Payers RTN
      const payersRTNPos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 53 - this.getFontSize(),
      };
      // Draw only when the value is available
      if (this.payersRTN && this.payersRTN !== "0") {
        firstPage.drawText(this.payersRTN, {
          x: payersRTNPos.x,
          y: payersRTNPos.y,
          ...options,
        });
      }

      // Interest income (amount 1)
      const amount1Pos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: payersRTNPos.y - 44,
      };
      let formattedAmt = (this.amount1 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount1Pos.x,
        y: amount1Pos.y,
        ...options,
      });

      // Early withdrawal penalty (amount 2)
      const amount2Pos = {
        x: amount1Pos.x,
        y: amount1Pos.y - 36,
      };
      formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Interest on US savings bond (amount 3)
      const amount3Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 36,
      };
      formattedAmt = (this.amount3 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount3Pos.x,
        y: amount3Pos.y,
        ...options,
      });

      // Federal income tax withheld (amount 4)
      const amount4Pos = {
        x: amount3Pos.x,
        y: amount3Pos.y - 24,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: amount4Pos.y,
        ...options,
      });

      // Investment expenses (amount 5)
      const amount5Pos = {
        x: amount4Pos.x + 100,
        y: amount4Pos.y,
      };
      formattedAmt = (this.amount5 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount5Pos.x,
        y: amount5Pos.y,
        ...options,
      });

      // Foreign tax paid (amount 6)
      const amount6Pos = {
        x: amount4Pos.x,
        y: amount4Pos.y - 24,
      };
      formattedAmt = (this.amount6 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount6Pos.x,
        y: amount6Pos.y,
        ...options,
      });

      // Foreign country or U.S. territory (Box 7)
      const box7Pos = {
        x: amount5Pos.x - 10,
        y: amount5Pos.y - 24,
      };
      firstPage.drawText(this.foreign_country_or_us_Possession, {
        x: box7Pos.x,
        y: box7Pos.y,
        ...options,
      });

      // Tax exempt interest (amount 8)
      const amount8Pos = {
        x: amount6Pos.x,
        y: amount6Pos.y - 36,
      };
      formattedAmt = (this.amount8 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount8Pos.x,
        y: amount8Pos.y,
        ...options,
      });

      // Specified private activity bond interest (amount 9)
      const amount9Pos = {
        x: amount8Pos.x + 100,
        y: amount8Pos.y,
      };
      formattedAmt = (this.amount9 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount9Pos.x,
        y: amount9Pos.y,
        ...options,
      });

      // Market Discount (amount A)
      const amountAPos = {
        x: amount8Pos.x,
        y: amount8Pos.y - 36,
      };
      formattedAmt = (this.amountA / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountAPos.x,
        y: amountAPos.y,
        ...options,
      });

      // Bond premium (amount B)
      const amountBPos = {
        x: amount9Pos.x,
        y: amount9Pos.y - 36,
      };
      formattedAmt = (this.amountB / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountBPos.x,
        y: amountBPos.y,
        ...options,
      });

      // Bond premium on tax-exempt bond (amount D)
      const amountDPos = {
        x: amountBPos.x,
        y: amountBPos.y - 24,
      };
      formattedAmt = (this.amountD / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountDPos.x,
        y: amountDPos.y,
        ...options,
      });

      // Bond premium on Treasury obligations (amount E)
      const amountEPos = {
        x: amountAPos.x,
        y: amountAPos.y - 24,
      };
      formattedAmt = (this.amountE / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amountEPos.x,
        y: amountEPos.y,
        ...options,
      });

      // Cusip number
      const cusipNumPos = {
        x: amountEPos.x - 10,
        y: amountEPos.y - 30,
      };
      if (this.cusipNumber) {
        firstPage.drawText(this.cusipNumber, {
          x: cusipNumPos.x,
          y: cusipNumPos.y,
          ...options,
        });
      }

      //   State details
      this.fillStateDetails(
        {
          state1: this.state1,
          prevFieldXCoord: amountDPos.x,
          prevFieldYCoord: amountDPos.y,
        },
        {
          stateNum1: this.stateNum1,
        },
        {
          stateTax1: this.stateTax1,
        },
        {
          state2: this.state2,
        },
        {
          stateNum2: this.stateNum2,
        },
        {
          stateTax2: this.stateTax2,
        }
      );

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private fillStateDetails(
    state1Obj: { state1: string; prevFieldXCoord: number; prevFieldYCoord: number },
    stateNum1Obj: { stateNum1: string },
    stateTax1Obj: { stateTax1: number },
    state2Obj: { state2: string },
    stateNum2Obj: { stateNum2: string },
    stateTax2Obj: { stateTax2: number }
  ): void {
    const stateDetailsMod: StateReviewDetailsModifier = new StateReviewDetailsModifier(
      this,
      state1Obj.prevFieldXCoord,
      state1Obj.prevFieldYCoord
    );

    // State details
    // State name
    stateDetailsMod.modifyStateNameDetail(state1Obj.state1, state2Obj.state2);
    // State num
    stateDetailsMod.modifyStateNumDetail(stateNum1Obj.stateNum1, stateNum2Obj.stateNum2);
    // State tax
    stateDetailsMod.modifyStateTaxDetail(
      stateTax1Obj.stateTax1,
      stateTax2Obj.stateTax2,
      false,
      -20
    );
  }
}
