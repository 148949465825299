import React, { useEffect, useLayoutEffect, useState } from "react";
import Navbar from "../components/navbar";
import { PRICING_PAGE, REGISTER } from "../lib/routes";
import { BsChevronDown } from "react-icons/bs";
import CollapsibleContainer from "../components/CollapsibleContainer/CollapsibleContainer";
import { faq } from "../lib/languagePacks/en-us";
import { TbPointFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Handler from "../lib/Handlers/main";
import devLogInstance from "../lib/loggerConfig";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import scrollToTop from "../lib/utils/scrollToTop";

// const calculateDiscount = (discountType: string, fedPrice: number, discountPercent: number) => {
//   switch (discountType) {
//     case "percentage":
//       const discount = (discountPercent / 100) * fedPrice;
//       const discountedAmt = fedPrice - discount;
//       return discountedAmt;

//     default:
//       return 0;
//   }
// };

export default function Pricing() {
  useEffect(() => {
    scrollToTop();

    return () => {
      scrollToTop();
    };
  }, []);

  const [clickedOn, setClickedOn] = useState({
    faqSubFees: false,
    faqcdromfiling: false,
    faqsfReconciliationForm: false,
    faqPaymultiple: false,
    faqRefundpolicy: false,
  });
  const [maxForms, setMaxForms] = useState<number[]>([]);
  // const [discountPercents, setDiscountPercents] = useState<number[]>([]);
  // const [discountAmounts, setDiscountAmounts] = useState<number[]>([]);
  // const [discountType, setDiscountType] = useState("");
  const [fedPrice, setFedPrice] = useState<number[]>([]);
  const [fixedPricing, setFixedPricing] = useState({
    oaPrice: 0,
    pmPrice: 0,
    statePrice: 0,
  });

  // Get the latest prices
  useLayoutEffect(() => {
    Handler.handleGettingLatestPrices().then((res: any) => {
      devLogInstance.log(res);
      const [fedprice, stateprice, pmprice, oaprice, forms] = res;

      setMaxForms(forms);
      // setDiscountPercents(discountPercents);
      // setDiscountType(discountType);
      setFedPrice(fedprice);
      // setDiscountAmounts(discountAmounts);
      setFixedPricing({
        oaPrice: oaprice,
        pmPrice: pmprice,
        statePrice: stateprice,
      });

      // // Initialize the Price
      // Price.federalPrice = fedprice;
      // Price.stateFileprice = stateprice;
      // Price.onlineAccessPrice = oaprice;
      // Price.postalMailingPrice = pmprice;

      // // Initialize the discount related values
      // Price.discountType = discountType;
      // Price.discountAmountArray = discountAmounts;
      // Price.discountPercentArray = discountPercents;
      // Price.minItemsArray = maxForms;
    });
  }, []);

  const handleClick = (e: any) => {
    const { id } = e.target.parentElement;
    console.log(id);
    switch (id) {
      case "faq-sub-fees":
        setClickedOn({
          ...clickedOn,
          faqSubFees: !clickedOn.faqSubFees,
        });
        break;

      case "faq-cdrom-filing":
        setClickedOn({
          ...clickedOn,
          faqcdromfiling: !clickedOn.faqcdromfiling,
        });
        break;

      case "faq-reconciliation-form":
        setClickedOn({
          ...clickedOn,
          faqsfReconciliationForm: !clickedOn.faqsfReconciliationForm,
        });
        break;

      case "faq-multi-payment":
        setClickedOn({
          ...clickedOn,
          faqPaymultiple: !clickedOn.faqPaymultiple,
        });
        break;

      case "faq-refund-policy":
        setClickedOn({
          ...clickedOn,
          faqRefundpolicy: !clickedOn.faqRefundpolicy,
        });
        break;

      default:
        break;
    }
  };

  // Send navigation report to google analytics
  useEffect(() => {
    ga4.sendEvent({
      category: ga4Const.event.category.navigation,
      action: `${ga4Const.event.action.navigateIn} to Pricing page`,
    });
    return () => {
      ga4.sendEvent({
        category: ga4Const.event.category.navigation,
        action: `${ga4Const.event.action.navigateOut} from Pricing page`,
      });
    };
  }, []);

  return (
    <div>
      <Navbar page={PRICING_PAGE} />
      <section id="banner" className="banner">
        <div className="nested-div-in-banner">
          <div className="text-xl text-white">
            <h1 className="text-3xl">Pricing</h1>
          </div>
        </div>
      </section>
      <section id="pricing-table" className="px-4 sm:px-80 py-8">
        <h1 className="text-2xl py-2 font-bold">1099 Forms</h1>
        <table className="text-xl">
          <thead>
            <tr className="bg-gray-600 text-white">
              <td className="py-2">No. of forms</td>
              <td className="text-center">Federal E-File</td>
              <td className="text-center">Corrections</td>
            </tr>
          </thead>
          <tbody>
            {maxForms.map((formNum, i) => {
              const totalArrayLength = maxForms.length;
              const start = i - 1;
              const limit = totalArrayLength - 1;
              return (
                <tr key={i}>
                  <td className="py-2">
                    {start >= 0
                      ? i !== limit
                        ? `${maxForms[start] + 1} to ${formNum} forms`
                        : `${maxForms[start]}+ forms`
                      : `First ${formNum} forms`}
                  </td>
                  <td className="text-center">$ {fedPrice[i].toFixed(2)}</td>
                  <td className="text-center"> $ {fedPrice[i].toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p className="text-xl py-2">
          <em>Note:- Prices are for each form</em>
        </p>
      </section>
      <section id="fixed-pricing" className="px-4 sm:px-80 py-8">
        <h1 className="text-2xl font-bold">Prices are fixed for:-</h1>
        <ul className="px-8 py-2 flex flex-col gap-4 text-xl">
          <li className="list-disc">
            State E-Filing:{" "}
            <span className="px-4 font-bold">${fixedPricing.statePrice.toFixed(2)}</span>
          </li>
          {/* <li className="list-disc">
            Online Access: <span className="px-2 font-bold">${fixedPricing.oaPrice}</span>
          </li> */}
          <li className="list-disc">
            Postal Mailing:{" "}
            <span className="px-2 font-bold">${fixedPricing.pmPrice.toFixed(2)}</span>
          </li>
        </ul>
      </section>
      <section id="faq" className="px-4 sm:px-80 py-8">
        <div className="py-8 font-bold text-center">
          <h1>Frequently Asked Questions</h1>
        </div>
        <div>
          <div id="faq-sub-fees">
            <div
              id="faq-sub-fees"
              className={`sm:px-10 py-6 ${
                !clickedOn.faqSubFees ? "border-b-2" : ""
              } border-b-slate-300 cursor-pointer`}
              onClick={handleClick}
            >
              <h2 className="flex justify-between items-center font-semibold">
                {faq.questions.pricing.subfees}{" "}
                <span
                  className={`transition-transform origin-center ${
                    clickedOn.faqSubFees ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <BsChevronDown />
                </span>
              </h2>
            </div>
            <CollapsibleContainer
              toCollapse={!clickedOn.faqSubFees}
              displayText={faq.answers.pricing.subfees}
              height="h-106p"
            />
          </div>

          <div id="faq-cdrom-filing">
            <div
              id="faq-cdrom-filing"
              className={`text-xl sm:px-10 py-6 ${
                !clickedOn.faqcdromfiling ? "border-b-2" : ""
              } border-b-slate-300 cursor-pointer`}
              onClick={handleClick}
            >
              <h2 className="flex justify-between items-center font-semibold">
                {faq.questions.pricing.cdromfiling}{" "}
                <span
                  className={`transition-transform origin-center ${
                    clickedOn.faqcdromfiling ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <BsChevronDown />
                </span>
              </h2>
            </div>
            <CollapsibleContainer
              toCollapse={!clickedOn.faqcdromfiling}
              displayText={faq.answers.pricing.cdromfiling}
              height="h-106p"
            />
          </div>

          <div id="faq-reconciliation-form">
            <div
              id="faq-reconciliation-form"
              className={`text-xl sm:px-10 py-6 ${
                !clickedOn.faqsfReconciliationForm ? "border-b-2" : ""
              } border-b-slate-300 cursor-pointer`}
              onClick={handleClick}
            >
              <h2 className="flex justify-between items-center font-semibold">
                {faq.questions.pricing.reconciliationForm}{" "}
                <span
                  className={`transition-transform origin-center ${
                    clickedOn.faqsfReconciliationForm ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <BsChevronDown />
                </span>
              </h2>
            </div>
            <CollapsibleContainer
              toCollapse={!clickedOn.faqsfReconciliationForm}
              displayText={faq.answers.pricing.reconciliationForm}
              height="h-106p"
            />
          </div>

          {/* <div id="faq-multi-payment">
            <div
              id="faq-multi-payment"
              className={`text-xl sm:px-10 py-6 ${
                !clickedOn.faqPaymultiple ? "border-b-2" : ""
              } border-b-slate-300 cursor-pointer`}
              onClick={handleClick}
            >
              <h2 className="flex justify-between items-center font-semibold">
                {faq.questions.pricing.multiplePayment}{" "}
                <span
                  className={`transition-transform origin-center ${
                    clickedOn.faqPaymultiple ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <BsChevronDown />
                </span>
              </h2>
            </div>
            <CollapsibleContainer
              toCollapse={!clickedOn.faqPaymultiple}
              displayText={faq.answers.pricing.multiplepayment}
              height="h-106p"
            />
          </div> */}

          <div id="faq-refund-policy">
            <div
              id="faq-refund-policy"
              className={`text-xl sm:px-10 py-6 ${
                !clickedOn.faqRefundpolicy ? "border-b-2" : ""
              } border-b-slate-300 cursor-pointer`}
              onClick={handleClick}
            >
              <h2 className="flex justify-between items-center font-semibold">
                {faq.questions.pricing.refundPolicy}{" "}
                <span
                  className={`transition-transform origin-center ${
                    clickedOn.faqRefundpolicy ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <BsChevronDown />
                </span>
              </h2>
            </div>
            <CollapsibleContainer
              toCollapse={!clickedOn.faqRefundpolicy}
              displayText={
                <ul>
                  <li className="h-12 flex gap-3">
                    <span className="py-1">
                      <TbPointFilled />
                    </span>
                    {faq.answers.pricing.refundPolicy.pt1}
                  </li>
                  <li className="h-12 flex gap-3">
                    <span className="py-1">
                      <TbPointFilled />
                    </span>
                    {faq.answers.pricing.refundPolicy.pt2}
                  </li>
                  <li className="h-20 flex gap-3">
                    <span className="py-1">
                      <TbPointFilled />
                    </span>
                    {faq.answers.pricing.refundPolicy.pt3}
                  </li>
                  {/* <li className="h-20 flex gap-3">
                    <span className="py-1">
                      <TbPointFilled />
                    </span>
                    {faq.answers.pricing.refundPolicy.pt4}
                  </li> */}
                  {/* <li className="h-40 flex gap-3">
                    <span className="py-1">
                      <TbPointFilled />
                    </span>
                    <span>{faq.answers.pricing.refundPolicy.pt5}</span>
                  </li> */}
                </ul>
              }
              height="h-40"
            />
          </div>
        </div>
      </section>
      <section id="get-started" className="px-4 sm:px-80 py-8">
        <div id="tagline" className="text-2xl text-center">
          <h1>
            <span className="text-taxeve-primary-violet">File</span> your first form today!
          </h1>
          {/* <h2 className="text-xl py-2">
            <em>The Smart Business Owners Choice</em>
          </h2> */}
        </div>
        <div className="text-center py-8">
          <Link
            to={REGISTER}
            className="py-3 px-8 rounded-lg bg-red-500 text-white text-xl hover:shadow-xl"
          >
            Get Started
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
}
