import devLogInstance from "./loggerConfig";
import DateModifier from "./utils/DateModifier";

export default class TextModifier {
  public static truncate(text: string, truncateUpto: number) {
    return truncateUpto >= text.length ? text : `${text.substring(0, truncateUpto)}....`;
  }

  public static modifyDate(date: Date) {
    const msAgo = DateModifier.getMilliSecondsAgo(date);

    devLogInstance.log({ msAgo });

    if (msAgo >= 1000 && msAgo < 1000 * 60) return Math.floor(msAgo / 1000) + "s ago";

    if (msAgo >= 1000 * 60 && msAgo < 1000 * 60 * 60)
      return Math.floor(msAgo / (1000 * 60)) + "min ago";

    if (msAgo >= 1000 * 60 * 60 && msAgo < 1000 * 60 * 60 * 24)
      return Math.floor(msAgo / (1000 * 60 * 60)) + "h ago";

    if (msAgo >= 1000 * 60 * 60 * 24 && msAgo < 1000 * 60 * 60 * 24 * 7)
      return Math.floor(msAgo / (1000 * 60 * 60 * 24)) + "d ago";

    if (msAgo >= 1000 * 60 * 60 * 24 * 7 && msAgo < 1000 * 60 * 60 * 24 * 7 * 4)
      return Math.floor(msAgo / (1000 * 60 * 60 * 24 * 7)) + "w ago";

    if (msAgo >= 1000 * 60 * 60 * 24 * 7 * 4 && msAgo < 1000 * 60 * 60 * 24 * 7 * 4 * 12)
      return Math.floor(msAgo / (1000 * 60 * 60 * 24 * 7 * 4)) + "m ago";

    if (msAgo >= 1000 * 60 * 60 * 24 * 7 * 4 * 12)
      return Math.floor(msAgo / (1000 * 60 * 60 * 24 * 7 * 4 * 12)) + "y ago";

    return msAgo + "ms ago";
  }
}
