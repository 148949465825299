import React from "react";
import "../../styles/decorations/Dropdown.css";
import { useSelector } from "react-redux";

export default function Dropdown({
  type,
  displayList,
  filterIdx,
  handleMouseEnter,
  handleClick,
  removeHover,
}: any) {
  const style = useSelector((state: any) => state.themeChange);

  return (
    <div
      className={`dd-container ${type === "rec_names" ? "w-[30rem]" : "w-[100%]"}`}
      style={{
        background: style.ddBg,
        boxShadow: `4px 4px 10px ${style.ddShadow}`,
        // width: type === "rec_names" ? "30rem" : "initial",
      }}
    >
      {type === "payer_names" &&
        displayList.map((payerObj: any, i: number) => {
          return (
            <div
              key={i}
              id={i.toString()}
              onMouseEnter={handleMouseEnter}
              onMouseDown={handleClick}
              className={`dd-item ${i === filterIdx && "bg-[#b262d163]"} ${
                !removeHover && "hover:bg-[#b262d163]"
              }`}
            >
              <div style={{ fontWeight: "bold" }}>{payerObj.payer_name}</div>
              <div style={{ fontWeight: "bold" }}>|</div>
              <div style={{ fontWeight: "bold" }}>
                {payerObj.payer_tin_type}: {payerObj.payer_tin}
              </div>
            </div>
          );
        })}
      {type === "rec_names" &&
        displayList.map((recObj: any, i: number) => {
          return (
            <div
              key={i}
              id={i.toString()}
              onMouseEnter={handleMouseEnter}
              onMouseDown={handleClick}
              className={`dd-item ${i === filterIdx && "bg-[#b262d163]"} ${
                !removeHover && "hover:bg-[#b262d163]"
              }`}
            >
              <div style={{ fontWeight: "bold" }}>{recObj.recipient_name}</div>
              {recObj.recipient_tin && (
                <>
                  <div style={{ fontWeight: "bold" }}>|</div>
                  <div style={{ fontWeight: "bold" }}>
                    {recObj.recipient_tin_type}: {recObj.recipient_tin}
                  </div>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
}
