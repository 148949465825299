import React from "react";
import Navbar from "../components/navbar";
import { PRIVACY_POLICY } from "../lib/routes";
import Footer from "../components/Footer/Footer";

export default function PrivacyPolicy() {
  return (
    <div className="page">
      <Navbar page={PRIVACY_POLICY} />
      <div className="px-4 lg:px-40 pt-40 pb-20">
        <div>
          <h1 className="text-2xl">PRIVACY POLICY FOR TAX EVE</h1>
          <p className="font-bold text-gray-500 py-4">Last updated on 01/10/2024.</p>
        </div>
        <ul className="py-6 flex flex-col gap-8">
          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">1. Introduction</h2>
            <p>
              At Tax Eve, we are committed to protecting your privacy and ensuring the
              confidentiality of your information. This Privacy Policy explains how we collect, use,
              and safeguard your personal information.
            </p>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">2. Information we collect</h2>
            <ul className="flex flex-col gap-6 py-2">
              <li className="flex flex-col gap-2">
                <h3>2.1 Personal Information</h3>
                <p>
                  We may collect personal information such as your name, contact details, financial
                  information, and other relevant information necessary for providing our services.
                  This information is collected when you engage our services, fill out forms on our
                  website, or communicate with us.
                </p>
              </li>

              <li className="flex flex-col gap-2">
                <h3>2.2 Non-Personal Information</h3>
                <p>
                  We may also collect non-personal information about your use of our website, such
                  as your IP address, browser type, the pages you visit, and the time and date of
                  your visit.
                </p>
              </li>
            </ul>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">3. How We Use Your Information</h2>
            <ul className="flex flex-col gap-6 py-2">
              <li className="flex flex-col gap-2">
                <h3>3.1 Providing Services</h3>
                <p>
                  We use your information to provide our services, respond to your inquiries, and
                  manage our relationship with you.
                </p>
              </li>

              <li className="flex flex-col gap-2">
                <h3>3.2 Legal Obligations</h3>
                <p>
                  We may use your information to comply with legal obligations, such as maintaining
                  records for tax purposes or responding to legal requests.
                </p>
              </li>

              <li className="flex flex-col gap-2">
                <h3>3.3 Improving Our Services</h3>
                <p>
                  We may use your information to comply with legal obligations, such as maintaining
                  records for tax purposes or responding to legal requests.We may use your
                  information to improve our services, develop new services, and understand the
                  needs and interests of our clients.
                </p>
              </li>
            </ul>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">4. Information Sharing and Disclosure</h2>
            <ul className="flex flex-col gap-6 py-2">
              <li className="flex flex-col gap-2">
                <h3>4.1 Service Providers</h3>
                <p>
                  We may share your information with our service providers who perform functions on
                  our behalf, such as IT service providers, payment processors, and customer service
                  providers.
                </p>
              </li>

              <li className="flex flex-col gap-2">
                <h3>4.2 Legal Requirements</h3>
                <p>
                  We may disclose your information if required by law, such as in response to a
                  court order or legal process, to defend against legal claims, or to investigate or
                  take action regarding illegal activities or suspected fraud.
                </p>
              </li>

              <li className="flex flex-col gap-2">
                <h3>4.3 Business Transfers</h3>
                <p>
                  If we are involved in a merger, acquisition, or sale of all or a portion of our
                  assets, your information may be transferred as part of that transaction.
                </p>
              </li>
            </ul>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">5. Data Security</h2>
            <p>
              We implement appropriate security measures to protect your personal information from
              unauthorized access, use, or disclosure. These measures include physical security,
              technical security measures, and administrative procedures.
            </p>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">6. Your Rights</h2>
            <p>
              You have the right to access or correct your personal information. You can also object
              to or restrict our processing of your information. To exercise these rights, please
              contact us at support@taxeve.com.
            </p>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">7. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. We will notify you of any changes
              by posting the new Privacy Policy on our website. Your continued use of our services
              after the changes have been posted will constitute your acceptance of the changes.
            </p>
          </li>

          <li className="flex flex-col gap-2">
            <h2 className="font-semibold">8. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at{" "}
              <span className="text-taxeve-primary-violet font-bold">
                <a href="mailto:support@taxeve.com">support@taxeve.com.</a>
              </span>
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}
