import { axiosInstance } from "../../axiosConfig";
import devLogInstance from "../../loggerConfig";
import Handler, { Main } from "../main";

export default async function getTicketDetailsAdmin(ticketId: string) {
  try {
    const { data } = await axiosInstance.get("/admin/get_ticket_details", {
      headers: {
        ticketid: ticketId,
      },
    });
    devLogInstance.log({ response_after_getting_ticket_details: data });
    return data;
  } catch (err: any) {
    await Handler.handleError(err, Main.stateDispatch, () => {});
    throw err;
  }
}
