import React from "react";
import Navbar from "../components/navbar";
import { PASSWORD_RESET } from "../lib/routes";
import TaxEveImg from "../components/TaxEveImg";
import PasswordResetForm from "../components/PasswordResetForm";
import { USER_ACCOUNT } from "../lib/constants";

interface Props {
  accountType: string;
}

export default function ResetPassword({ accountType }: Props) {
  return (
    <main>
      {accountType === USER_ACCOUNT && <Navbar page={PASSWORD_RESET} />}
      <div className="min-h-[100vh] py-20 px-4 lg:px-16 flex flex-col justify-center">
        <section className="flex justify-between mt-12">
          <TaxEveImg />
          <PasswordResetForm accountType={accountType} />
        </section>
      </div>
    </main>
  );
}
