import React from "react";
import { Link } from "react-router-dom";
import { REGISTER } from "../../lib/routes";

export default function EFileBtn() {
  return (
    <Link to={REGISTER} className="text-lg py-2 px-8 rounded-lg bg-red-500 text-white">
      E-File
    </Link>
  );
}
