import React, { useState } from "react";
import Handler from "../../lib/Handlers/main";
import devLogInstance from "../../lib/loggerConfig";
import { FaDollarSign, FaPercentage } from "react-icons/fa";
import Select, { SingleValue } from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import { useDispatch, useSelector } from "react-redux";
import { AdminCouponData, AdminPriceData } from "../../../interfaces/main";
import AddUserId, { REMOVE_USER_ID, USER_ID } from "./AddUserId";
import { CustomArray } from "../../lib/utils/CustomArray";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import handlePopup from "../../lib/popup/handlePopup";
import { ERROR_POPUP, SUCCESS_POPUP } from "../../lib/constants";

const VIEW_PRICE = "VIEW_PRICE";
const FEDERAL_PRICE = "FEDERAL_PRICE";
const ONLINE_ACCESS_PRICE = "ONLINE_ACCESS_PRICE";
const POSTAL_MAILING_PRICE = "POSTAL_MAILING_PRICE";
const STATE_PRICE = "STATE_PRICE";

const MIN_NO_OF_FORMS = "MIN_NO_OF_FORMS";
const NO_OF_FORMS = "NO_OF_FORMS";
const DISCOUNT_PERCENTS = "DISCOUNT_PERCENTS";
const DISCOUNT_AMOUNTS = "DISCOUNT_AMOUNTS";

const VIEW_COUPONS = "VIEW_COUPONS";

const COUPON_CODE = "COUPON_CODE";
const MIN_CART_AMT = "MIN_CART_AMT";
const DISCOUNT_PERCENT = "DISCOUNT_PERCENT";
const MAX_DISCOUNT_PERCENT = "MAX_DISCOUNT_PERCENT";
const DISCOUNT_AMT = "DISCOUNT_AMT";

const ADD_USER_ID_INPUT_FIELD = "ADD_USER_ID";

const DELETE_COUPON = "DELETE_COUPON";

const discountTypeOptions = [
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Amount",
    value: "amount",
  },
];

// Coupon discount type select options
const couponDiscountTypeSelectOptions = [
  {
    label: "None",
    value: "",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Amount",
    value: "amount",
  },
];

// Coupon availability select options
const couponAvailabilityOptions = [
  {
    label: "None",
    value: "",
  },
  {
    label: "Global",
    value: "global",
  },
  {
    label: "Personal",
    value: "personal",
  },
];

interface SelectValueTemplate {
  label: string;
  value: string;
}

interface UserIdState {
  arrIdxs: number[];
  userIds: string[];
}

export function isLastElement(currentIdx: number, arrayLength: number): boolean {
  return currentIdx === arrayLength - 1 ? true : false;
}

let counter = 0;

const editablePrices = [
  FEDERAL_PRICE,
  ONLINE_ACCESS_PRICE,
  STATE_PRICE,
  POSTAL_MAILING_PRICE,
  MIN_NO_OF_FORMS,
  NO_OF_FORMS,
  DISCOUNT_PERCENTS,
  DISCOUNT_AMOUNTS,
];

export default function PriceManagement() {
  const style = useSelector((state: any) => state.themeChange);
  const [adminPrices, setAdminPrices] = useState<AdminPriceData[]>([]);
  const [adminCoupons, setAdminCoupons] = useState<AdminCouponData[]>([]);
  const [showSubmitChangeBtn, setShowSubmitChangeBtn] = useState(false);
  const [adminCouponData, setAdminCouponData] = useState<AdminCouponData>({
    coupon_code: "",
    min_total_cart_amount: 0,
    discount_type: "",
    coupon_discount_percentage: 0,
    percentage_max_amount: 0,
    coupon_discount_amount: 0,
    coupon_availability: "",
    user_id: [],
  });
  const [userIdsObj, setUserIdsObj] = useState<UserIdState>({
    arrIdxs: [0],
    userIds: [],
  });
  const stateSetter = useDispatch();

  // devLogInstance.log(adminPrices);
  devLogInstance.log(adminCouponData);
  devLogInstance.log(userIdsObj);

  // Handle click event
  const handleClick = (
    e: React.MouseEvent<HTMLElement>,
    idx?: number | null,
    id_?: string | null
  ) => {
    const { id } = e.currentTarget;

    switch (id) {
      case VIEW_PRICE:
        // Call the api to get prices
        Handler.handleGettingAdminPrices().then((res) => {
          devLogInstance.log("Response from server after calling the get admin prices API");
          devLogInstance.log(res);
          res && setAdminPrices(res.priceData);
        });
        break;

      case VIEW_COUPONS:
        // Call the api to get coupons
        Handler.handleGettingAdminCoupons().then((res) => {
          devLogInstance.log("Response from server after calling the get admin coupons API");
          devLogInstance.log(res);
          res && setAdminCoupons(res);
        });
        break;

      case ADD_USER_ID_INPUT_FIELD:
        setUserIdsObj({
          ...userIdsObj,
          arrIdxs: [...userIdsObj.arrIdxs, ++counter],
        });
        break;

      case REMOVE_USER_ID:
        if (idx !== undefined && idx !== null) {
          --counter;
          // Remove the ith element from the user ids arr and arr idx array in the user ids obj
          const _userIds = userIdsObj.userIds;
          const _idxArr = userIdsObj.arrIdxs;
          const updatedUserIdsArr = CustomArray.removeElement(idx, _userIds);
          const updatedArrIdxs = CustomArray.removeElement(idx, _idxArr);
          setUserIdsObj({
            arrIdxs: [...updatedArrIdxs],
            userIds: [...updatedUserIdsArr],
          });
        }
        break;

      case DELETE_COUPON:
        console.log(id_);
        if (id_) {
          Handler.handleDeletingCoupon(id_).then((res) => {
            devLogInstance.log("Response from server after deleting a coupon");
            devLogInstance.log(res);
            // Get the updated data
            Handler.handleGettingAdminCoupons().then((res) => {
              handlePopup("Coupon deleted", SUCCESS_POPUP, stateSetter);
              res && setAdminCoupons(res);
            });
          });
        }
        break;

      default:
        break;
    }
  };

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, idx?: number) => {
    const { id, value: value_ } = e.currentTarget;
    const inputType = id.split(";")[0] ?? id;
    const priceDataIdx =
      inputType !== FEDERAL_PRICE ? parseInt(id.split(";")[1]) : parseInt(id.split(";")[2]);

    editablePrices.includes(inputType) && setShowSubmitChangeBtn(true);

    const value = parseFloat(value_).toFixed(2);

    let updatedAdminPrices = adminPrices;

    const adminPriceData = adminPrices[priceDataIdx];
    let updatedAdminPriceData: AdminPriceData;

    switch (inputType) {
      case FEDERAL_PRICE:
        const idx_ = parseInt(id.split(";")[1]);
        devLogInstance.log(`Fed price idx: ${idx_}`);

        const fedPrice = adminPriceData.federal_price;

        fedPrice[idx_] = value ? parseFloat(value) : 0;

        updatedAdminPriceData = {
          ...adminPriceData,
          federal_price: [...fedPrice],
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case ONLINE_ACCESS_PRICE:
        updatedAdminPriceData = {
          ...adminPriceData,
          online_access_price: value ? parseFloat(value) : 0,
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case POSTAL_MAILING_PRICE:
        updatedAdminPriceData = {
          ...adminPriceData,
          postal_mailing_price: value ? parseFloat(value) : 0,
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case STATE_PRICE:
        updatedAdminPriceData = {
          ...adminPriceData,
          state_price: value ? parseFloat(value) : 0,
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case NO_OF_FORMS:
        const nofarrIdx = parseInt(id.split(";")[2]);
        const nofarr = adminPriceData.no_of_forms;
        nofarr[nofarrIdx] = value_ ? parseInt(value_) : 0;

        updatedAdminPriceData = {
          ...adminPriceData,
          no_of_forms: [...nofarr],
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case MIN_NO_OF_FORMS:
        const arrIdx = parseInt(id.split(";")[2]);
        const arr = adminPriceData.min_no_of_forms;
        arr[arrIdx] = value_ ? parseInt(value_) : 0;

        updatedAdminPriceData = {
          ...adminPriceData,
          min_no_of_forms: [...arr],
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case DISCOUNT_PERCENTS:
        const _arrIdx = parseInt(id.split(";")[2]);
        const _arr = adminPriceData.discount_percent;
        _arr[_arrIdx] = value ? parseFloat(value) : 0;

        updatedAdminPriceData = {
          ...adminPriceData,
          discount_percent: [..._arr],
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case DISCOUNT_AMOUNTS:
        const arrIdx_ = parseInt(id.split(";")[2]);
        const arr_ = adminPriceData.discount_percent;
        arr_[arrIdx_] = value ? parseFloat(value) : 0;

        updatedAdminPriceData = {
          ...adminPriceData,
          discount_percent: [...arr_],
        };
        updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
        setAdminPrices([...updatedAdminPrices]);
        break;

      case MIN_CART_AMT:
        setAdminCouponData({
          ...adminCouponData,
          min_total_cart_amount: value ? parseFloat(value) : 0,
        });
        break;

      case DISCOUNT_PERCENT:
        setAdminCouponData({
          ...adminCouponData,
          coupon_discount_percentage: value ? parseFloat(value) : 0,
        });
        break;

      case MAX_DISCOUNT_PERCENT:
        setAdminCouponData({
          ...adminCouponData,
          percentage_max_amount: value ? parseFloat(value) : 0,
        });
        break;

      case DISCOUNT_AMT:
        setAdminCouponData({
          ...adminCouponData,
          coupon_discount_amount: value ? parseFloat(value) : 0,
        });
        break;

      case COUPON_CODE:
        setAdminCouponData({
          ...adminCouponData,
          coupon_code: value_ ? value_ : "",
        });
        break;

      case USER_ID:
        if (idx !== undefined && idx !== null) {
          const _userIds = userIdsObj.userIds;
          _userIds[idx] = value_;
          setUserIdsObj({
            ...userIdsObj,
            userIds: [..._userIds],
          });
        }
        break;

      default:
        break;
    }
  };

  // Handle discount type select
  const handleDiscountTypeSelect = (options: SelectValueTemplate | null, i: number) => {
    setShowSubmitChangeBtn(true);

    const priceDataIdx = i;

    const value = options?.value;

    let updatedAdminPrices = adminPrices;

    const adminPriceData = adminPrices[priceDataIdx];
    let updatedAdminPriceData: AdminPriceData;

    if (value) {
      updatedAdminPriceData = {
        ...adminPriceData,
        discount_type: value,
      };
      updatedAdminPrices[priceDataIdx] = updatedAdminPriceData;
      setAdminPrices([...updatedAdminPrices]);
    }
  };

  const getDiscountTypeDefaultVal = (discountType: string) => {
    if (discountType === "percentage") return { label: "Percentage", value: "percentage" };
    else if (discountType === "amount") return { label: "Amount", value: "amount" };
    return null;
  };

  // Handle price edit
  const handlePriceEdit = (idx: number) => {
    const adminLevel = SessionStorage.getCacheItem("admin_level");
    if (adminLevel === "master") {
      Handler.handleEditingAdminPrices(adminPrices[idx]).then((res) => {
        devLogInstance.log("Response from server after editing the prices");
        devLogInstance.log(res);
        setShowSubmitChangeBtn(false);
        handlePopup("Prices edited", SUCCESS_POPUP, stateSetter);
      });
    } else {
      handlePopup("You do not have permission to perform this action", ERROR_POPUP, stateSetter);
    }
  };

  // Handle Adding coupon
  const handleAddCoupon = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const adminLevel = SessionStorage.getCacheItem("admin_level");
    Handler.handleAddingCoupons(adminCouponData).then((res) => {
      devLogInstance.log("Response from server after addding coupons");
      devLogInstance.log(res);
      // Get the updated data
      Handler.handleGettingAdminCoupons().then((res) => {
        handlePopup("Coupon added", SUCCESS_POPUP, stateSetter);
        // Clear all the fields and revert to default
        setAdminCouponData({
          coupon_code: "",
          min_total_cart_amount: 0,
          discount_type: "",
          coupon_discount_percentage: 0,
          percentage_max_amount: 0,
          coupon_discount_amount: 0,
          coupon_availability: "",
          user_id: [],
        });
        setUserIdsObj({
          arrIdxs: [0],
          userIds: [],
        });
        counter = 0; // default
        res && setAdminCoupons(res);
      });
    });
  };

  // Get coupon discount type value
  const getCouponDiscountTypeVal = (): SelectValueTemplate | null => {
    const map: any = {
      percentage: couponDiscountTypeSelectOptions[1],
      amount: couponDiscountTypeSelectOptions[2],
    };
    // console.log(map[`${adminCouponData.discount_type}`]);
    return map[`${adminCouponData.discount_type}`] ?? null;
  };

  // Get coupon availability value
  const getCouponAvailabilityVal = (): SelectValueTemplate | null => {
    const map: any = {
      global: couponAvailabilityOptions[1],
      personal: couponAvailabilityOptions[2],
    };

    return map[`${adminCouponData.coupon_availability}`] ?? null;
  };

  // Handle coupon discount type selection
  const handleCouponDiscountTypeSelection = (option: SingleValue<SelectValueTemplate>) => {
    option &&
      setAdminCouponData({
        ...adminCouponData,
        discount_type: option.value,
      });
  };

  // Handle coupon avbailability selection
  const handleCouponAvailabilitySelection = (option: SingleValue<SelectValueTemplate>) => {
    option &&
      setAdminCouponData({
        ...adminCouponData,
        coupon_availability: option.value,
      });
  };

  return (
    <main className="flex relative">
      <section className="w-[50%] p-6 overflow-y-auto view-prices">
        <div>
          <div>
            <button
              id={VIEW_PRICE}
              className="text-lg px-4 py-3 rounded-xl bg-blue-600 text-white"
              onClick={handleClick}
            >
              View Prices
            </button>
            {adminPrices.length !== 0 && (
              <div className="py-4 admin-price-table">
                <div>
                  {adminPrices.map((adminPriceDataObj, i) => {
                    return (
                      <div key={i} className={`${!isLastElement(i, adminPrices.length) && "mb-8"}`}>
                        <h1 className="py-2 text-2xl font-bold">
                          Form series - {adminPriceDataObj.form_type} :-
                        </h1>
                        <h1 className="text-xl py-3 font-bold underline underline-offset-4">
                          Price Table
                        </h1>
                        <table>
                          <thead>
                            <tr className="text-center">
                              {/* <td className="bg-gray-400 text-white font-semibold">
                                Federal Price
                              </td> */}
                              <td className="bg-gray-400 text-white font-semibold">
                                Online Access Price
                              </td>
                              <td className="bg-gray-400 text-white font-semibold">
                                Postal Mailing Price
                              </td>
                              <td className="bg-gray-400 text-white font-semibold">State Price</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={i} className="text-center">
                              {/* <td>
                                {adminPriceDataObj.federal_price.map((price, idx) => {
                                  return (
                                    <div key={idx} className="relative">
                                      <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                        <FaDollarSign className="text-lg" />
                                      </span>
                                      <input
                                        id={`${FEDERAL_PRICE};${idx};${i}`}
                                        type="text"
                                        className="py-1 w-[100%] text-center"
                                        value={price.toFixed(2)}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  );
                                })}
                              </td> */}
                              <td>
                                <div className="relative">
                                  <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                    <FaDollarSign className="text-lg" />
                                  </span>
                                  <input
                                    id={`${ONLINE_ACCESS_PRICE};${i}`}
                                    type="text"
                                    className="py-1 w-[100%] text-center"
                                    value={adminPriceDataObj.online_access_price.toFixed(2)}
                                    onChange={handleChange}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="relative">
                                  <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                    <FaDollarSign className="text-lg" />
                                  </span>
                                  <input
                                    id={`${POSTAL_MAILING_PRICE};${i}`}
                                    type="text"
                                    className="py-1 w-[100%] text-center"
                                    value={adminPriceDataObj.postal_mailing_price.toFixed(2)}
                                    onChange={handleChange}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="relative">
                                  <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                    <FaDollarSign className="text-lg" />
                                  </span>
                                  <input
                                    id={`${STATE_PRICE};${i}`}
                                    type="text"
                                    className="py-1 w-[100%] text-center"
                                    value={adminPriceDataObj.state_price.toFixed(2)}
                                    onChange={handleChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="py-2">
                          <table>
                            <thead>
                              <tr>
                                <td className="bg-gray-400 text-white font-semibold">
                                  Number of forms (upto)
                                </td>
                                <td className="bg-gray-400 text-white font-semibold text-center">
                                  Federal Price
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key={i} className="text-center">
                                <td className="w-[50%]">
                                  {adminPriceDataObj.no_of_forms.map((minNum, idx) => {
                                    return (
                                      <input
                                        key={idx}
                                        id={`${NO_OF_FORMS};${i};${idx}`}
                                        type="text"
                                        value={minNum}
                                        onChange={handleChange}
                                        className="py-1 w-[100%] text-center"
                                      />
                                    );
                                  })}
                                </td>
                                <td className="w-[50%]">
                                  {adminPriceDataObj.federal_price.map((price, idx) => {
                                    return (
                                      <div key={idx} className="relative">
                                        <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                          <FaDollarSign className="text-lg" />
                                        </span>
                                        <input
                                          id={`${FEDERAL_PRICE};${idx};${i}`}
                                          type="text"
                                          className="py-1 w-[100%] text-center"
                                          value={price.toFixed(2)}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    );
                                  })}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="py-2">
                          <h1 className="text-xl py-3 font-bold underline underline-offset-4">
                            Discount Table
                          </h1>
                          <div>
                            <table>
                              <thead>
                                <tr className="text-center">
                                  <td className="bg-gray-400 text-white font-semibold">
                                    Minimum no. of forms
                                  </td>
                                  <td className="bg-gray-400 text-white font-semibold">
                                    Discount Percent
                                  </td>
                                  <td className="bg-gray-400 text-white font-semibold">
                                    Discount Amount
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {adminPriceDataObj.min_no_of_forms.map((minNum, idx) => {
                                  return (
                                    <tr key={idx} className="text-center">
                                      <td>
                                        <input
                                          id={`${MIN_NO_OF_FORMS};${i};${idx}`}
                                          type="text"
                                          value={minNum}
                                          onChange={handleChange}
                                          className="py-1 w-[100%] text-center"
                                        />
                                      </td>
                                      <td>
                                        <div className="relative">
                                          <input
                                            id={`${DISCOUNT_PERCENTS};${i};${idx}`}
                                            type="text"
                                            value={adminPriceDataObj.discount_percent[idx].toFixed(
                                              2
                                            )}
                                            onChange={handleChange}
                                            className="py-1 w-[100%] text-center"
                                          />
                                          <span className="absolute right-0 top-0 h-[100%] px-2 flex items-center">
                                            <FaPercentage className="text-lg" />
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="relative">
                                          <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                            <FaDollarSign className="text-lg" />
                                          </span>
                                          <input
                                            id={`${DISCOUNT_AMOUNTS};${i};${idx}`}
                                            type="text"
                                            value={adminPriceDataObj.discount_amount[idx].toFixed(
                                              2
                                            )}
                                            onChange={handleChange}
                                            className="py-1 w-[100%] text-center"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            <div className="flex items-center gap-3 py-4">
                              <h1 className="font-bold">
                                <em>Current Discount type:-</em>
                              </h1>
                              <span className="w-[20%]">
                                <Select
                                  theme={new SelectStyle(style).customTheme}
                                  styles={new SelectStyle(style).customStyle}
                                  options={discountTypeOptions}
                                  defaultValue={getDiscountTypeDefaultVal(
                                    adminPriceDataObj.discount_type
                                  )}
                                  onChange={(options) => {
                                    handleDiscountTypeSelect(options, i);
                                  }}
                                  isSearchable={false}
                                />
                              </span>
                            </div>
                            {showSubmitChangeBtn && (
                              <div className="py-4">
                                <button
                                  className="py-3 px-4 rounded-xl bg-red-600 text-white"
                                  onClick={() => {
                                    handlePriceEdit(i);
                                  }}
                                >
                                  Submit Changes
                                </button>
                              </div>
                            )}
                            <hr
                              className={`py-4 border-t-0 border-b border-gray-400 ${
                                isLastElement(i, adminPrices.length) && "hidden"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="w-[50%] border-l border-gray-400 coupons">
        <div className="h-[50vh] border-b border-gray-400 overflow-y-auto p-6">
          <div className="view-coupons-btn-wrapper">
            <button
              id={VIEW_COUPONS}
              className="py-3 px-4 rounded-xl bg-blue-600 text-white text-lg"
              onClick={handleClick}
            >
              View coupons
            </button>
          </div>
          <div className="data-container">
            {adminCoupons.length !== 0 && (
              <div className="py-4 admin-coupon-table">
                <div>
                  {adminCoupons.map((adminCoupon, i) => {
                    return (
                      <div
                        key={i}
                        className={`${!isLastElement(i, adminCoupons.length) && "mb-8"}`}
                      >
                        <h1 className="py-2 text-2xl font-bold">
                          Coupon code - {adminCoupon.coupon_code} :-
                        </h1>
                        <h1 className="text-xl py-3 font-bold underline underline-offset-4">
                          Coupon Data Table
                        </h1>
                        <table>
                          <thead>
                            <tr className="text-center">
                              <td className="bg-gray-400 text-white font-semibold">
                                Total cart amount(min.)
                              </td>
                              <td className="bg-gray-400 text-white font-semibold">
                                Discount percent
                              </td>
                              <td className="bg-gray-400 text-white font-semibold">
                                Maximum discount percent
                              </td>
                              <td className="bg-gray-400 text-white font-semibold">
                                Discount amount
                              </td>
                              <td className="bg-gray-400 text-white font-semibold">
                                Coupon availability
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={i} className="text-center">
                              <td>{adminCoupon.min_total_cart_amount}</td>
                              <td>
                                <div className="relative">
                                  <span className="absolute right-0 top-0 h-[100%] px-2 flex items-center">
                                    <FaPercentage className="text-lg" />
                                  </span>
                                  <p>{adminCoupon.coupon_discount_percentage}</p>
                                </div>
                              </td>
                              <td>
                                <div className="relative">
                                  <span className="absolute right-0 top-0 h-[100%] px-2 flex items-center">
                                    <FaPercentage className="text-lg" />
                                  </span>
                                  <p>{adminCoupon.percentage_max_amount}</p>
                                </div>
                              </td>
                              <td>
                                <div className="relative">
                                  <span className="absolute left-0 top-0 h-[100%] px-2 flex items-center">
                                    <FaDollarSign className="text-lg" />
                                  </span>
                                  <p>{adminCoupon.coupon_discount_amount}</p>
                                </div>
                              </td>
                              <td>{adminCoupon.coupon_availability}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="flex items-center gap-3 py-4">
                          <h1 className="font-bold">
                            <em>Current Discount type:- {adminCoupon.discount_type}</em>
                          </h1>
                        </div>

                        {adminCoupon.user_id.length !== 0 && (
                          <div className="py-2">
                            <h1 className="text-xl py-3 font-bold underline underline-offset-4">
                              Users registered with this coupon
                            </h1>
                            <div>
                              <table>
                                <thead>
                                  <tr className="text-center">
                                    <td className="bg-gray-400 text-white font-semibold">
                                      Serial no.
                                    </td>
                                    <td className="bg-gray-400 text-white font-semibold">
                                      User Id
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {adminCoupon.user_id.map((userId, idx) => {
                                    return (
                                      <tr key={idx} className="text-center">
                                        <td>{idx + 1}</td>
                                        <td>{userId}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        <button
                          id={DELETE_COUPON}
                          className="py-2 px-4 rounded-lg bg-red-500 text-white"
                          onClick={(e) => {
                            handleClick(e, null, adminCoupon._id);
                          }}
                        >
                          Delete
                        </button>
                        <hr
                          className={`py-4 border-t-0 border-b border-gray-400 ${
                            isLastElement(i, adminCoupons.length) && "hidden"
                          }`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="h-[50vh] overflow-y-auto px-8 py-8 add-coupon">
          <div className="text-2xl font-bold underline underline-offset-4">Add coupon</div>
          <form className="py-4 flex gap-3 flex-wrap justify-between" onSubmit={handleAddCoupon}>
            <div className="flex flex-col w-[49%]">
              <label htmlFor={COUPON_CODE} className="input-label">
                Coupon code
              </label>
              <input
                id={COUPON_CODE}
                type="text"
                className="py-2"
                value={adminCouponData.coupon_code}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-[49%]">
              <label htmlFor={MIN_CART_AMT} className="input-label">
                Min. cart amount
              </label>
              <input
                id={MIN_CART_AMT}
                type="text"
                className="py-2"
                value={adminCouponData.min_total_cart_amount.toFixed(2) ?? ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-[49%]">
              <label htmlFor={DISCOUNT_PERCENT} className="input-label">
                Discount percent
              </label>
              <input
                id={DISCOUNT_PERCENT}
                type="text"
                className="py-2"
                value={adminCouponData.coupon_discount_percentage.toFixed(2) ?? ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-[49%]">
              <label htmlFor={MAX_DISCOUNT_PERCENT} className="input-label">
                Max. percent amount
              </label>
              <input
                id={MAX_DISCOUNT_PERCENT}
                type="text"
                className="py-2"
                value={adminCouponData.percentage_max_amount.toFixed(2) ?? ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-[49%]">
              <label htmlFor={DISCOUNT_AMT} className="input-label">
                Discount amount
              </label>
              <input
                id={DISCOUNT_AMT}
                type="text"
                className="py-2"
                value={adminCouponData.coupon_discount_amount.toFixed(2) ?? ""}
                onChange={handleChange}
              />
            </div>
            <div className="w-[100%] flex gap-3">
              <div className="w-[49%]">
                <Select
                  theme={new SelectStyle(style).customTheme}
                  styles={new SelectStyle(style).customStyle}
                  placeholder="Discount type"
                  options={couponDiscountTypeSelectOptions}
                  isSearchable={false}
                  value={getCouponDiscountTypeVal()}
                  onChange={handleCouponDiscountTypeSelection}
                />
              </div>
              <div className="w-[49%]">
                <Select
                  theme={new SelectStyle(style).customTheme}
                  styles={new SelectStyle(style).customStyle}
                  placeholder="Coupon availability"
                  options={couponAvailabilityOptions}
                  isSearchable={false}
                  value={getCouponAvailabilityVal()}
                  onChange={handleCouponAvailabilitySelection}
                />
              </div>
            </div>
            <div className="py-2 user-ids w-[100%]">
              <h1
                id={ADD_USER_ID_INPUT_FIELD}
                className="text-lg font-semibold text-blue-500 underline cursor-pointer w-[fit-content]"
                onClick={handleClick}
              >
                Add user id
              </h1>
              {userIdsObj.arrIdxs.map((arrIdx, i) => {
                return (
                  <AddUserId
                    key={i}
                    idx={i}
                    userIds={userIdsObj.userIds}
                    addUserId={handleChange}
                    removeUserId={handleClick}
                  />
                );
              })}
            </div>
            <button type="submit" className="py-3 px-4 rounded-xl bg-blue-600 text-white text-lg">
              Submit coupon data
            </button>
          </form>
        </div>
      </section>
    </main>
  );
}
