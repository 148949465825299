import { axiosInstance } from "../../axiosConfig";
import devLogInstance from "../../loggerConfig";
import Handler, { Main } from "../main";

export default async function getRaisedTickets() {
  try {
    const { data } = await axiosInstance.get("/support/get_raised_tickets");
    devLogInstance.log({ response_after_getting_raised_tickets: data });

    return data;
  } catch (err) {
    console.error(err);
    Handler.handleError(err, Main.stateDispatch, () => {
      throw err; // react query will handle the refetch
    });
  }
}
