import React from "react";

const managementToolColorMap: any = {
  "fire-filing": "bg-yellow-500 text-white",
  "admin-management": "bg-blue-500 text-white",
  "price-management": "bg-blue-500 text-white",
  "doc-management": "bg-blue-500 text-white",
  "transaction-management": "bg-green-500 text-white",
};

interface Props {
  selectedTool: string;
  selectTool: React.Dispatch<React.SetStateAction<string>>;
}

export default function ManagementToolList({ selectTool, selectedTool }: Props) {
  return (
    <ul className="side-panel flex flex-col justify-center gap-8 p-6">
      <li
        onClick={() => selectTool("fire-filing")}
        className={`fire-filing-tool py-3 px-4 rounded-lg cursor-pointer ${
          !selectedTool.includes("fire-filing") && "hover:bg-gray-200"
        } ${selectedTool.includes("fire-filing") && managementToolColorMap[selectedTool]}`}
      >
        FIRE Filing System
      </li>
      <li
        onClick={() => selectTool("admin-management")}
        className={`admin-management-tool py-3 px-4 rounded-lg cursor-pointer ${
          !selectedTool.includes("admin-management") && "hover:bg-gray-200"
        } ${selectedTool.includes("admin-management") && managementToolColorMap[selectedTool]}`}
      >
        Admin management
      </li>
      <li
        onClick={() => selectTool("price-management")}
        className={`price-management-tool py-3 px-4 rounded-lg cursor-pointer ${
          !selectedTool.includes("price-management") && "hover:bg-gray-200"
        } ${selectedTool.includes("price-management") && managementToolColorMap[selectedTool]}`}
      >
        Price management
      </li>
      <li
        onClick={() => selectTool("doc-management")}
        className={`doc-management-tool py-3 px-4 rounded-lg cursor-pointer ${
          !selectedTool.includes("doc-management") && "hover:bg-gray-200"
        } ${selectedTool.includes("doc-management") && managementToolColorMap[selectedTool]}`}
      >
        Doc management
      </li>
      <li
        onClick={() => selectTool("transaction-management")}
        className={`transaction-tracking-tool py-3 px-4 rounded-lg cursor-pointer ${
          !selectedTool.includes("transaction-management") && "hover:bg-gray-200"
        } ${
          selectedTool.includes("transaction-management") && managementToolColorMap[selectedTool]
        }`}
      >
        Track Transactions
      </li>
    </ul>
  );
}
