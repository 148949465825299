import React, { useState } from "react";
import Password from "../../components/Password";
import devLogInstance from "../../lib/loggerConfig";
import { PasswordValidator } from "../../lib/validators";
import handlePopup from "../../lib/popup/handlePopup";
import { ERROR_POPUP } from "../../lib/constants";
import { useDispatch } from "react-redux";
import Handler from "../../lib/Handlers/main";
import { PasswordChangeData } from "../../../interfaces/main";
import { SHA256 } from "crypto-js";

export const CURRENT_PASSWORD = "CURRENT_PASSWORD";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";

export default function AdminChangePassword() {
  const [passwords, setPasswords] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
  });
  const dispatch = useDispatch();

  devLogInstance.log("Password change data");
  devLogInstance.log(passwords);

  // Handle change event
  const handleChange = (value: string, id: string) => {
    switch (id) {
      case CURRENT_PASSWORD:
        setPasswords({
          ...passwords,
          currentPass: value,
        });
        break;

      case NEW_PASSWORD:
        setPasswords({
          ...passwords,
          newPass: value,
        });
        break;

      case CONFIRM_PASSWORD:
        setPasswords({
          ...passwords,
          confirmPass: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle click event
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Verify the input values
    const isValidInput = new PasswordValidator(passwords.newPass).validate();

    devLogInstance.log(`Valid password input: ${isValidInput}`);

    // Confirm the new password
    const match = passwords.newPass === passwords.confirmPass;
    devLogInstance.log(`Password confirmed: ${match}`);

    if (isValidInput && match) {
      const passChangeData: PasswordChangeData = {
        old_password: SHA256(process.env.REACT_APP_NONCE + passwords.currentPass).toString(),
        new_password: SHA256(process.env.REACT_APP_NONCE + passwords.newPass).toString(),
      };
      devLogInstance.log(`Password change data before sending to backend:`);
      devLogInstance.log(passChangeData);
      // Call the password change API
      Handler.handleChangeAdminPassword(passChangeData);
    } else if (!isValidInput) {
      handlePopup("Please provide a proper password!", ERROR_POPUP, dispatch);
    } else if (!match) {
      handlePopup("New and confirm password dosen't match!", ERROR_POPUP, dispatch);
    }
    return;
  };

  return (
    <main className="min-h-[100vh] py-24 px-80 flex flex-col gap-6">
      <section>
        <div className="current-pass">
          <label htmlFor={CURRENT_PASSWORD} className="font-bold label">
            Current Password
          </label>
          <p className="py-1">Enter current password</p>
          <Password id={CURRENT_PASSWORD} onChange={handleChange} />
        </div>
      </section>

      <section>
        <div className="new-pass">
          <label htmlFor={NEW_PASSWORD} className="font-bold label">
            New Password
          </label>
          <p className="py-1">Enter new password</p>
          <Password id={NEW_PASSWORD} onChange={handleChange} />
        </div>
      </section>

      <section>
        <div className="confirm-pass">
          <label htmlFor={CONFIRM_PASSWORD} className="font-bold label">
            Confirm Password
          </label>
          <p className="py-1">Confirm new password</p>
          <Password id={CONFIRM_PASSWORD} onChange={handleChange} />
        </div>
      </section>

      <section>
        <button className="primary-button py-3 px-4" onClick={handleClick}>
          Change password
        </button>
      </section>
    </main>
  );
}
