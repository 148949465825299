import React from "react";
import "../../../styles/forms/globalComponentStyles/Account.css";
import { accountDisplayTexts } from "../../../lib/languagePacks/en-us";
import { useSelector } from "react-redux";
import { useState } from "react";
import SecondTinNotice from "./InternalComponents/SecondTinNotice/SecondTinNotice";
import Box13 from "./1099-R-Box13/Box13";

const initial = {
  clearDate: null,
  callbackRelatedToDate: () => {},
  dateGroup: "",
  dateVal: "",
};

export default function RecAccount({
  globalFormData,
  setGlobalFormData,
  secondTinNoticeRequired = true,
  show1099RDateOfPayment = false,
  _1099RBox13props = initial,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const [isHovered, setIsHovered] = useState(false);

  // Handle input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    if (id === "account-number") {
      value.length <= 20 &&
        setGlobalFormData({
          ...globalFormData,
          payers_account_number_for_payee: value,
        });
    } else if (id === "second-tin-notice") {
      setGlobalFormData({
        ...globalFormData,
        second_tin_notice: !globalFormData.second_tin_notice,
      });
    }
  };

  // Handle hover in
  const handleHoverIn = () => {
    setIsHovered(true);
  };
  // Handle hover out
  const handleHoverOut = () => {
    setIsHovered(false);
  };
  return (
    <div
      className="account-container"
      style={{
        borderLeft: `1px solid ${style.eFormBorderColor}`,
        borderRight: secondTinNoticeRequired ? `1px solid ${style.eFormBorderColor}` : "none",
        borderBottom: `1px solid ${style.eFormBorderColor}`,
      }}
    >
      <div
        className="account-number-wrraper"
        style={{
          borderRight: `1px solid ${style.eFormBorderColor}`,
          width: !secondTinNoticeRequired ? "100%" : "75%",
        }}
      >
        <div className="rec-account-heading">
          <h4>{accountDisplayTexts.heading}</h4>{" "}
          <p
            onMouseEnter={handleHoverIn}
            onMouseLeave={handleHoverOut}
            style={{
              color: style.hoverableTextColor,
            }}
          >
            ({accountDisplayTexts.instructionDisplayText})
          </p>
        </div>
        <div className="account-input-wrraper">
          <input
            type="text"
            id="account-number"
            className="payer-details-input"
            value={globalFormData.payers_account_number_for_payee}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        {isHovered && (
          <div className="leave-blank-disclaimer">
            <div
              className="leave-blank-disclaimer-heading"
              style={{
                background: style.hoverablePopupBg,
                boxShadow: `2px 2px 10px ${style.boxShadowColor}`,
              }}
            >
              {accountDisplayTexts.accountLeaveBlankDisclaimer}
            </div>
            <div
              className="triangle-down"
              style={{
                borderTop: `10px solid ${style.hoverablePopupBg}`,
              }}
            />
          </div>
        )}
      </div>

      {secondTinNoticeRequired && (
        <SecondTinNotice
          status={globalFormData.second_tin_notice}
          onChangeHandler={handleChange}
          label={accountDisplayTexts.secondTinNotDisplayText}
          styles={{ width: "25%" }}
        />
      )}
      {!secondTinNoticeRequired && show1099RDateOfPayment && (
        <Box13
          clearDate={_1099RBox13props.clearDate}
          callbackRelatedToDate={_1099RBox13props.callbackRelatedToDate}
          dateGroup={_1099RBox13props.dateGroup}
          dateVal={_1099RBox13props.dateVal}
          showDateRequiredIndicator={_1099RBox13props.showDateRequiredIndicator}
          toShowDateRequired={_1099RBox13props.toShowDateRequired}
          styles={{ width: "40%" }}
        />
      )}
    </div>
  );
}
