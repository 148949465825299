import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import getTicketDetailsAdmin from "../../../lib/Handlers/Queries/GetTicketDetailsAdmin.handler";
import { useSelector } from "react-redux";
import loader from "../../decorations/Loader";
import TicketDetailComponent from "../../SupportTicketRelated/TicketDetailComponent";
import { useMarkTicketAsSeen } from "../../../lib/customHooks";
import { TicketSelectionCtx } from "../../../pages/AdminRelatedPages/AdminDashboard";

export default function TicketDetails() {
  // const { ticketId } = useParams();
  // devLogInstance.log({ ticketId });

  const ticketClickCtx = useContext(TicketSelectionCtx);
  if (!ticketClickCtx) throw new Error("Ticket select / click ctx is null");

  useMarkTicketAsSeen(ticketClickCtx.currentSelectedTicket || "");

  const style = useSelector((state: any) => state.themeChange);

  const ticketDetailsQuery = useQuery({
    queryKey: [`ticketDetails_${ticketClickCtx.currentSelectedTicket || ""}`],
    queryFn: () => getTicketDetailsAdmin(ticketClickCtx.currentSelectedTicket || ""),
    refetchOnWindowFocus: false,
    staleTime: 3600000, // 1 hour
  });

  if (ticketDetailsQuery.isLoading) return <div className="p-6">{loader.start(style, true)}</div>;

  if (ticketDetailsQuery.isError)
    return (
      <div className="p-6">
        <pre>{ticketDetailsQuery.error.message}</pre>
      </div>
    );

  return (
    <div className="p-6">
      <TicketDetailComponent
        onAdminPortal
        payload={ticketDetailsQuery.data.ticket_details}
        comments={ticketDetailsQuery.data.comments}
        ticketId={ticketClickCtx.currentSelectedTicket || ""}
        refetchFn={ticketDetailsQuery.refetch}
      />
    </div>
  );
}
