import React, { ReactNode } from "react";
import {
  formFieldErrMsg,
  invalidTinErrIndicator,
  lenderReferredPayerDetails,
  payerDetails,
  payerDetailsFor1099C,
  payerDetailsFor1099S,
} from "../../../../lib/languagePacks/en-us";
import { useSelector, useDispatch } from "react-redux";
import formatTin from "../../../../lib/form/utils/formatTin";
import {
  SET_PAYER_TIN_DISPLAY,
  TO_SHOW_FORM_VALIDATION_ERR,
  TO_SHOW_INVALID_TIN_ERROR,
} from "../../../../redux/actions/action.types";
import "../../../../styles/forms/globalComponentStyles/PayerTin.css";
import Indicator from "../../../Indicator";
import {
  ALL_SAME_DIGIT_INVALID_PAYER_TIN,
  ALL_ZERO_INVALID_PAYER_TIN,
  FORM1099_C,
  FORM1099_S,
  INVALID_PAYER_EIN,
  INVALID_PAYER_SSN,
  INVALID_PAYER_TIN_IN_ORDER,
  PAYER_TIN,
} from "../../../../lib/constants";
import {
  formsWithPayerAsCreditor,
  formsWithPayerAsFiler,
  formsWithPayerAsLender,
} from "../PayerDetails";
import { TinValidator } from "../../../../lib/validators";

const errIndicatorMap: Record<string, ReactNode> = {
  [INVALID_PAYER_EIN]: <Indicator type={PAYER_TIN} message={invalidTinErrIndicator.invalidEin} />,
  [INVALID_PAYER_SSN]: <Indicator type={PAYER_TIN} message={invalidTinErrIndicator.invalidSsn} />,
  [INVALID_PAYER_TIN_IN_ORDER]: (
    <Indicator type={PAYER_TIN} message={invalidTinErrIndicator.invalidTinInOrder} />
  ),
  [ALL_SAME_DIGIT_INVALID_PAYER_TIN]: (
    <Indicator type={PAYER_TIN} message={invalidTinErrIndicator.allDigitSameInvalidTin} />
  ),
  [ALL_ZERO_INVALID_PAYER_TIN]: (
    <Indicator type={PAYER_TIN} message={invalidTinErrIndicator.allZeroInvalidTin} />
  ),
};

function PayerTin({ globalFormData, setGlobalFormData, formType, isCorrection }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const tinDisplay = useSelector((state: any) => state.payerTinDisplay);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const showInvalidTinError = useSelector((state: any) => state.showInvalidTinError);
  const dispatch = useDispatch();

  // handle tin type change
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (id === "ein") {
      // Format the TIN
      const formattedTin = formatTin(tinDisplay, "EIN");

      // Update the tin display value
      formattedTin
        ? dispatch({
            type: SET_PAYER_TIN_DISPLAY,
            payload: formattedTin,
          })
        : dispatch({
            type: SET_PAYER_TIN_DISPLAY,
            payload: "",
          });

      // update the global form data
      setGlobalFormData({
        ...globalFormData,
        payer_tin_type: "EIN",
        payer_is_new_or_updated: true,
      });
    } else if (id === "ssn") {
      // Format the TIN
      const formattedTin = formatTin(tinDisplay, "SSN");

      // Update the tin display value
      formattedTin
        ? dispatch({
            type: SET_PAYER_TIN_DISPLAY,
            payload: formattedTin,
          })
        : dispatch({
            type: SET_PAYER_TIN_DISPLAY,
            payload: "",
          });

      // update the global form data payer tin type
      setGlobalFormData({
        ...globalFormData,
        payer_tin_type: "SSN",
        payer_is_new_or_updated: true,
      });
    } else if (id === "payer-tin-input") {
      // Remove any mandatory field warning on the payer tin field
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: PAYER_TIN,
          toShowErr: false,
        },
      });

      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: "",
          toShowErr: false,
        },
      });

      // SSN validation
      const isValidTin = TinValidator.ssnValidator(globalFormData.payer_tin_type, value);

      // Format the tin and update the display val
      const formattedTin = isValidTin ? formatTin(value, globalFormData.payer_tin_type) : null;
      formattedTin
        ? dispatch({
            type: SET_PAYER_TIN_DISPLAY,
            payload: formattedTin,
          })
        : dispatch({
            type: SET_PAYER_TIN_DISPLAY,
            payload: "",
          });

      // Update the payer tin on global data
      const pureVal = value.replaceAll("-", "");
      if (pureVal.length <= 9) {
        // const parsedVal = parseInt(pureVal);
        setGlobalFormData({
          ...globalFormData,
          payer_tin: pureVal ? pureVal : "",
          payer_is_new_or_updated: true,
        });
      }
    }
  };

  return (
    <div className="payer-tin-container">
      <div className="payer-tin-heading-wrapper">
        <div className="payer-tin-heading">
          <h4>
            {formType === FORM1099_C
              ? payerDetailsFor1099C.payerTinDisplayText
              : formType === FORM1099_S
              ? payerDetailsFor1099S.payerTinDisplayText
              : formsWithPayerAsLender.includes(formType)
              ? lenderReferredPayerDetails.payerTinDisplayText
              : payerDetails.payerTinDisplayText}
          </h4>
        </div>
        <div className="tin-type">
          <div
            className="ein-container"
            style={{
              background:
                globalFormData.payer_tin_type === "EIN"
                  ? style.tinSelectedBgColor
                  : style.tinBgColor,
            }}
          >
            <input
              type="radio"
              id="ein"
              onChange={handleChange}
              checked={globalFormData.payer_tin_type === "EIN"}
              autoComplete="off"
              disabled={isCorrection}
            />
            <label htmlFor="ein">EIN</label>
          </div>
          <div
            className="ssn-container"
            style={{
              background:
                globalFormData.payer_tin_type === "SSN"
                  ? style.tinSelectedBgColor
                  : style.tinBgColor,
            }}
          >
            <input
              type="radio"
              id="ssn"
              onChange={handleChange}
              checked={globalFormData.payer_tin_type === "SSN"}
              autoComplete="off"
              disabled={isCorrection}
            />
            <label htmlFor="ssn">SSN</label>
          </div>
        </div>
      </div>

      <div className="tin-input-container">
        <label htmlFor="tin-input">
          {
            formType === FORM1099_C && globalFormData.payer_tin_type === "EIN"
              ? payerDetailsFor1099C.payerEINPlaceholder
              : formType === FORM1099_C && globalFormData.payer_tin_type === "SSN"
              ? payerDetailsFor1099C.payerSSNPlaceholder
              : globalFormData.payer_tin_type === "EIN" &&
                !formsWithPayerAsFiler.includes(formType) &&
                !formsWithPayerAsLender.includes(formType)
              ? payerDetails.payerEINPlaceholder
              : globalFormData.payer_tin_type === "EIN" && formsWithPayerAsFiler.includes(formType)
              ? payerDetailsFor1099S.payerEINPlaceholder
              : globalFormData.payer_tin_type === "SSN" &&
                !formsWithPayerAsFiler.includes(formType) &&
                !formsWithPayerAsLender.includes(formType)
              ? payerDetails.payerSSNPlaceholder
              : globalFormData.payer_tin_type === "SSN" && formsWithPayerAsFiler.includes(formType)
              ? payerDetailsFor1099S.payerSSNPlaceholder
              : formsWithPayerAsLender.includes(formType)
              ? lenderReferredPayerDetails.tinType[globalFormData.payer_tin_type]
              : null /* or display default placeholder if needed */
          }
          <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
        </label>

        <input
          className="payer-details-input"
          type="text"
          value={tinDisplay}
          id="payer-tin-input"
          onChange={handleChange}
          autoComplete="off"
          disabled={isCorrection}
        />
        {showValidationError.onPayerTin && (
          <Indicator
            type={PAYER_TIN}
            message={
              !formsWithPayerAsFiler.includes(formType)
                ? formFieldErrMsg.nec1099.payer.emptyTin
                : formFieldErrMsg.s1099.payer.emptyTin
            }
          />
        )}
        {showInvalidTinError.toShowErr && errIndicatorMap[showInvalidTinError.type]}
      </div>
    </div>
  );
}

export default PayerTin;
