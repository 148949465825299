import logger from "../logger";
import {
  INVALID_AMOUNT,
  FORM_FIELD_VALIDATION_ERROR,
  PAYER_TIN,
  PAYER_ZIPCODE,
  RECIPIENT_TIN,
  RECIPIENT_ZIPCODE,
  PAYER_PHONE_NUMBER,
  RECIPIENT_PHONE_NUMBER,
  STATE_INCOME_1,
  STATE_TAX_1,
  STATE_NUMBER_1,
  STATE_INCOME_2,
  STATE_TAX_2,
  STATE_NUMBER_2,
  STATE_FILING_VALIDATION_ERROR,
  STATE_1,
  STATE_2,
  STATE_NUM_VALIDATION_ERR,
  EIN,
  INVALID_TAX_AMT,
  ONLINE_ACCESS_VALIDATION_ERR,
  CAP_PERCENT,
  PROPERTY_DESCRIPTION_ERROR,
  NO_FORM_DATA_ERROR,
  PROCEEDS_ERROR,
  INVALID_AMT,
  MIN_LEN_FOR_WV_NOT_MET,
  MIN_LEN_FOR_UTAH_NOT_MET,
  WV_STATE_NUMBER_1,
  WV_STATE_NUMBER_2,
  UTAH_STATE_NUMBER_1,
  UTAH_STATE_NUMBER_2,
  NUMBER_OF_W2_FILED,
  TOTAL_TAX_WITHHELD_FOR_W2,
  SSN,
  INVALID_PAYER_EIN,
  INVALID_PAYER_SSN,
  INVALID_PAYER_TIN_IN_ORDER,
  ALL_SAME_DIGIT_INVALID_PAYER_TIN,
  INVALID_RECIPIENT_EIN,
  INVALID_RECIPIENT_SSN,
  INVALID_RECIPIENT_TIN_IN_ORDER,
  ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN,
  FORM1099_INT,
  FORM1099_DIV,
  FORM1099_B,
  FORM1099_K,
  ALL_ZERO_INVALID_PAYER_TIN,
  ALL_ZERO_INVALID_RECIPIENT_TIN,
  INVALID_EVENT_CODE,
} from "./constants";
import stateFilingFields from "./form/FieldsToValidate/stateFilingFields";
import statesWithMandSN from "./StatesWithMandSN";
import statesWithEinAndSN from "./StatesWithEinAndSN";
import devLogInstance from "./loggerConfig";
import { formValidationErrorMsg } from "./languagePacks/en-us";
import { FormValidationErr } from "../../interfaces/main";

export class PasswordValidator {
  value: any;
  constructor(value: any) {
    this.value = value;
  }

  validate() {
    const passRegex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,20}$/;
    return passRegex.test(this.value);
  }
}

export class InputValidator {
  email: string | null;
  name: string | null;
  phone: string | null;
  password: string | null;
  constructor(
    email: string | null,
    name: string | null,
    phone: string | null,
    password: string | null
  ) {
    this.email = email;
    this.name = name;
    this.phone = phone;
    this.password = password;
  }

  validateEmail() {
    if (this.email && this.email.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  validateName() {
    if (this.name && this.name.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  validatePhone() {
    if (this.phone && this.phone.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  validatePhoneLength() {
    if (this.phone && this.phone.length === 10) {
      return true;
    } else {
      return false;
    }
  }
  validatePassword() {
    if (this.password && this.password.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}

class FormFieldsValidator {
  validate(fields: any[]) {
    return new Promise((resolve, reject) => {
      let invalidFields: any[] = [];
      fields.forEach((field) => {
        switch (field.fieldName) {
          // Payer block
          case PAYER_TIN:
            if (field.data.toString().length < 9) {
              invalidFields.push(field.fieldName);
            }
            break;

          case PAYER_ZIPCODE:
            if (field.data.length < 5) {
              invalidFields.push(field.fieldName);
            }
            break;

          case PAYER_PHONE_NUMBER:
            if (field.data.length > 0 && field.data.length < 10) {
              invalidFields.push(field.fieldName);
            }
            break;

          // Recipient block
          case RECIPIENT_TIN:
            if (field.data.toString().length < 9) {
              invalidFields.push(field.fieldName);
            }
            break;

          case RECIPIENT_ZIPCODE:
            if (field.data.length < 5) {
              invalidFields.push(field.fieldName);
            }
            break;

          case RECIPIENT_PHONE_NUMBER:
            if (field.data.length > 0 && field.data.length < 10) {
              invalidFields.push(field.fieldName);
            }
            break;

          default:
            if (field.data.length <= 0) {
              invalidFields.push(field.fieldName);
            }
            break;
        }
      });
      if (invalidFields.length === 0) {
        resolve(true);
      } else {
        // Throw error with the list of empty fields
        reject({
          message: FORM_FIELD_VALIDATION_ERROR,
          data: invalidFields,
        });
      }
    });
  }

  public validateEnquiryData(data: any) {
    const fieldsToValidate = ["name", "email"];
    const invalidFields: string[] = [];

    fieldsToValidate.forEach((field) => {
      const value = data[field];
      if (!value) invalidFields.push(field);
    });

    // Validate the phone number if phone number is present
    if (data.phone) {
      data.phone.toString().length < 10 && invalidFields.push("phone");
    }

    return invalidFields;
  }
}
export const formFieldsValidator = new FormFieldsValidator();

// Amount field validator
class AmountFieldValidator {
  validate(amounts: number[]) {
    return new Promise((resolve, reject) => {
      let invalidAmts = [];
      amounts.forEach((amount) => {
        if (amount === 0) {
          invalidAmts.push(amount);
        }
      });
      if (invalidAmts.length === amounts.length) reject(INVALID_AMOUNT);
      else resolve(true);
    });
  }
}
export const amountValidator = new AmountFieldValidator();

// Tax amount validator
class TaxAmountValidator {
  validate(taxAmount: number, incomeAmounts: number[]) {
    return new Promise((resolve, reject) => {
      let totalIncome = 0;
      incomeAmounts.forEach((amount) => {
        totalIncome = totalIncome + amount;
      });
      if (taxAmount <= CAP_PERCENT * totalIncome) {
        resolve(true);
      } else {
        reject(INVALID_TAX_AMT);
      }
    });
  }
}

export const taxAmtValidator = new TaxAmountValidator();

class EventIdentifier {
  validate(eventCode: string) {
    return new Promise((resolve, reject) => {
      if (eventCode) {
        resolve(true);
      } else {
        reject(INVALID_EVENT_CODE);
      }
    });
  }
}
export const eventIdentifierValidator = new EventIdentifier();
// State filing validator
class StateFilingValidator {
  formsWithNoStateIncome = [FORM1099_INT, FORM1099_DIV, FORM1099_B, FORM1099_K];

  validate(
    state1Details: any,
    state2Details: any,
    numberOfW2Filed: number,
    totalTaxWithheldForW2: number,
    formType: string
  ) {
    return new Promise((resolve, reject) => {
      const { state1, state_income_tax_withheld1, payer_state_number1, state_income1 } =
        state1Details;

      const { state2, state_income_tax_withheld2, payer_state_number2, state_income2 } =
        state2Details;
      logger.log("State 1 details:");
      logger.log(state1Details);
      logger.log("State 2 details:");
      logger.log(state2Details);

      // Start thes validation
      // get the fields to validate
      const fieldsToValidate = stateFilingFields();
      let invalidFields: any[] = [];
      // Loop through the array to validate each field
      fieldsToValidate.forEach((field) => {
        switch (field) {
          // State 1
          case STATE_INCOME_1:
            if (state_income1 > 0 && state1.length <= 0) {
              invalidFields.push(STATE_1);
            } else if (
              !this.formsWithNoStateIncome.includes(formType) &&
              state_income1 <= 0 &&
              state1.length > 0
            ) {
              invalidFields.push(field);
            }
            break;

          case STATE_TAX_1:
            if (state_income_tax_withheld1 > 0 && state1.length <= 0) {
              invalidFields.push(STATE_1);
            } else if (
              !this.formsWithNoStateIncome.includes(formType) &&
              state_income_tax_withheld1 > 0 &&
              state_income1 < state_income_tax_withheld1
            ) {
              invalidFields.push(field);
            }
            break;

          case STATE_NUMBER_1:
            if (payer_state_number1.length > 0 && state1.length <= 0) {
              invalidFields.push(STATE_1);
            }
            break;

          // State 2
          case STATE_INCOME_2:
            if (state_income2 > 0 && state2.length <= 0) {
              invalidFields.push(STATE_2);
            } else if (
              !this.formsWithNoStateIncome.includes(formType) &&
              state_income2 <= 0 &&
              state2.length > 0
            ) {
              invalidFields.push(field);
            }
            break;

          case STATE_TAX_2:
            if (state_income_tax_withheld2 > 0 && state2.length <= 0) {
              invalidFields.push(STATE_2);
            } else if (
              !this.formsWithNoStateIncome.includes(formType) &&
              state_income_tax_withheld2 > 0 &&
              state_income2 < state_income_tax_withheld2
            ) {
              invalidFields.push(field);
            }
            break;

          case STATE_NUMBER_2:
            if (payer_state_number2.length > 0 && state2.length <= 0) {
              invalidFields.push(STATE_2);
            }
            break;

          default:
            break;
        }
      });
      if (invalidFields.length === 0) {
        resolve(true);
      } else {
        // Throw validation error along with the invalid fields data
        reject({
          status: STATE_FILING_VALIDATION_ERROR,
          data: invalidFields,
        });
      }
    });
  }
}
export const stateFilingValidator = new StateFilingValidator();

class StateNumberValidator {
  isEINTinType(tinType: string) {
    return tinType === EIN ? true : false;
  }

  validate(state1Details: any, state2Details: any, recTinType: string) {
    const { state1, payer_state_number1 } = state1Details;
    const { state2, payer_state_number2 } = state2Details;
    const invalidStateNums: string[] = [];
    const stateNumsWithNoMinLenForWV: string[] = [];
    const stateNumsWithNoMinLenForUtah: string[] = [];
    return new Promise((resolve, reject) => {
      // Check for state group 1
      if (statesWithMandSN.includes(state1) && payer_state_number1.length <= 0) {
        invalidStateNums.push(STATE_NUMBER_1);
      }
      // Check for state group 2
      if (statesWithMandSN.includes(state2) && payer_state_number2.length <= 0) {
        invalidStateNums.push(STATE_NUMBER_2);
      }

      // Check for states that dosent require state number if they have EIN
      // Check for state group 1
      if (statesWithEinAndSN.includes(state1)) {
        if (this.isEINTinType(recTinType) && payer_state_number1.length <= 0) {
          invalidStateNums.push(STATE_NUMBER_1);
        }
      }
      // Check for state group 2
      if (statesWithEinAndSN.includes(state2)) {
        if (this.isEINTinType(recTinType) && payer_state_number2.length <= 0) {
          invalidStateNums.push(STATE_NUMBER_2);
        }
      }

      // Check if West Virginia and payer state num is of min length
      // Check for group state 1
      if (state1 === "West Virginia" && payer_state_number1.length < 8) {
        stateNumsWithNoMinLenForWV.push(WV_STATE_NUMBER_1);
      }
      // Check for group state 2
      if (state2 === "West Virginia" && payer_state_number2.length < 8) {
        stateNumsWithNoMinLenForWV.push(WV_STATE_NUMBER_2);
      }

      // Check if Utah and payer state num is of min length
      // Check for group state 1
      if (state1 === "Utah" && payer_state_number1.length < 14) {
        stateNumsWithNoMinLenForUtah.push(UTAH_STATE_NUMBER_1);
      }
      // Check for group state 2
      if (state2 === "Utah" && payer_state_number2.length < 14) {
        stateNumsWithNoMinLenForUtah.push(UTAH_STATE_NUMBER_2);
      }

      invalidStateNums.length !== 0
        ? reject({ status: STATE_NUM_VALIDATION_ERR, data: invalidStateNums })
        : stateNumsWithNoMinLenForWV.length > 0
        ? reject({ status: MIN_LEN_FOR_WV_NOT_MET, data: stateNumsWithNoMinLenForWV })
        : stateNumsWithNoMinLenForUtah.length > 0
        ? reject({ status: MIN_LEN_FOR_UTAH_NOT_MET, data: stateNumsWithNoMinLenForUtah })
        : resolve(true);
    });
  }
}
export const stateNumberValidator = new StateNumberValidator();

export class OnlineAccessValidator {
  static validate(recipientEmail = "") {
    return new Promise((resolve, reject) => {
      if (recipientEmail) {
        const emailregex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
        const isValidEmail = emailregex.test(recipientEmail);
        isValidEmail
          ? resolve(true)
          : reject({
              status: ONLINE_ACCESS_VALIDATION_ERR,
              message: "Please provide a valid email address",
            });
      }
      reject({
        status: ONLINE_ACCESS_VALIDATION_ERR,
        message: "Recipient email is required to allow online access for the current recipient",
      });
    });
  }

  static validateEmailList(recipientEmails: string[]) {
    const invalidEmails: any[] = [];
    return new Promise((resolve, reject) => {
      recipientEmails.forEach((email, i) => {
        const emailregex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
        const isValidEmail = emailregex.test(email);
        isValidEmail
          ? devLogInstance.log("Valid email")
          : invalidEmails.push({
              status: ONLINE_ACCESS_VALIDATION_ERR,
              message: email
                ? "Please provide a valid email address"
                : "Email is required to give online access",
              idx: i,
            });
      });
      devLogInstance.log(invalidEmails);
      invalidEmails.length === 0 ? resolve(true) : reject(invalidEmails);
    });
  }
}

export class EmailValidator extends OnlineAccessValidator {
  static async validateEmail(email = "") {
    devLogInstance.log(email);
    if (email) {
      try {
        await this.validate(email);
        return true;
      } catch (err) {
        throw err;
      }
    } else {
      const err = new Error("Please enter an email id");
      throw err;
    }
  }
}

export class TinValidator {
  public static invalidInOrderTin: string[] = ["123456789"];
  public static allSameDigitInvalidTin: string[] = [
    "111111111",
    "222222222",
    "333333333",
    "444444444",
    "555555555",
    "666666666",
    "777777777",
    "888888888",
    "999999999",
  ];

  public static ssnValidator(tinType: string, tin: string): boolean {
    // SSN validation
    const isSSN = tinType === "SSN";
    let isValidTin = true;
    if (isSSN) {
      isValidTin = tin[0] === "9" || tin.substring(0, 4) === "666" ? false : true;
    }
    return isValidTin;
  }

  public static validatePayerTin({ tin, tinType }: { tin: string; tinType: string }) {
    return new Promise((res, rej) => {
      if (tinType === EIN) {
        !this.isValidEIN(tin) && rej(INVALID_PAYER_EIN);
      } else if (tinType === SSN) {
        !this.isValidSSN(tin) && rej(INVALID_PAYER_SSN);
      }
      if ((tinType === EIN || tinType === SSN) && this.invalidInOrderTin.includes(tin))
        return rej(INVALID_PAYER_TIN_IN_ORDER);
      if ((tinType === EIN || tinType === SSN) && this.allSameDigitInvalidTin.includes(tin))
        return rej(ALL_SAME_DIGIT_INVALID_PAYER_TIN);

      if (tin === "000000000") return rej(ALL_ZERO_INVALID_PAYER_TIN);

      return res(true);
    });
  }

  public static validateRecipientTin({ tin, tinType }: { tin: string; tinType: string }) {
    return new Promise((res, rej) => {
      if (tinType === EIN) {
        !this.isValidEIN(tin) && rej(INVALID_RECIPIENT_EIN);
      } else if (tinType === SSN) {
        !this.isValidSSN(tin) && rej(INVALID_RECIPIENT_SSN);
      }
      if ((tinType === EIN || tinType === SSN) && this.invalidInOrderTin.includes(tin))
        return rej(INVALID_RECIPIENT_TIN_IN_ORDER);
      if ((tinType === EIN || tinType === SSN) && this.allSameDigitInvalidTin.includes(tin))
        return rej(ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN);

      if (tin === "000000000") return rej(ALL_ZERO_INVALID_RECIPIENT_TIN);

      return res(true);
    });
  }

  public static isValidEIN(ein: string) {
    return ein.substring(0, 2) !== "00";
  }

  public static isValidSSN(ssn: string) {
    return ssn.substring(0, 3) !== "000";
  }
}

class FormValidation {
  formData: any;
}

export class Form1099BValidation extends FormValidation {
  constructor(formData: any) {
    super();
    this.formData = formData;
  }

  vaidatePropertyDescription() {
    return new Promise((resolve, reject) => {
      if (this.formData) {
        this.formData.description_of_property
          ? resolve(true)
          : reject({
              status: PROPERTY_DESCRIPTION_ERROR,
              message: formValidationErrorMsg.form1099b.propertyDescErrorMsg,
            });
      } else {
        reject({
          status: NO_FORM_DATA_ERROR,
          message: formValidationErrorMsg.noFormDataErrorMsg,
        });
      }
    });
  }

  validateProcceds() {
    return new Promise((resolve, reject) => {
      if (this.formData) {
        const proceeds = this.formData.payment_amount2;
        const collectiblesCheck = this.formData.applicable_check_box_for_collectables;
        const qofCheck = this.formData.applicable_check_box_for_qof;

        if (proceeds > 0) {
          if (collectiblesCheck || qofCheck) resolve(true);
          else
            reject({
              status: PROCEEDS_ERROR,
              message: formValidationErrorMsg.form1099b.proceedsErrorMsg,
            });
        } else {
          resolve(true);
        }
      } else {
        reject({
          status: NO_FORM_DATA_ERROR,
          message: formValidationErrorMsg.noFormDataErrorMsg,
        });
      }
    });
  }
}

export class Form1099DivValidation extends FormValidation {
  constructor(formData: any) {
    super();
    this.formData = formData;
  }

  public validateAmount3(): Promise<boolean | FormValidationErr> {
    return new Promise((res, rej) => {
      const amt3 = this.formData.payment_amount3;
      const amt6 = this.formData.payment_amount6;
      const amt7 = this.formData.payment_amount7;
      const amt8 = this.formData.payment_amount8;

      // validate
      if (amt3 >= amt6 + amt7 + amt8) {
        res(true);
        return;
      }
      const err: FormValidationErr = {
        status: INVALID_AMT,
        message: formValidationErrorMsg.form1099Div.invalidAmt3,
      };
      rej(err);
    });
  }

  public validateAmount1(): Promise<boolean | FormValidationErr> {
    return new Promise((res, rej) => {
      const amt1 = this.formData.payment_amount1;
      const amt2 = this.formData.payment_amount2;

      // Validate
      if (amt2 > 0 && amt1 === 0) {
        const err: FormValidationErr = {
          status: INVALID_AMT,
          message: formValidationErrorMsg.form1099Div.invalidAmt1,
        };
        rej(err);
      }
      res(true);
    });
  }
}
