import { PDFDocument } from "pdf-lib";

export default class PDFModifier {
  private pdfBuffer: ArrayBuffer;
  private pdfDoc: PDFDocument | null = null;

  constructor(pdfBuffer: ArrayBuffer) {
    this.pdfBuffer = pdfBuffer;
  }

  protected getPdfBuffer(): ArrayBuffer {
    return this.pdfBuffer;
  }

  protected getPdfDoc(): PDFDocument {
    if (this.pdfDoc) return this.pdfDoc;
    else throw new Error("PDFDoc is null!");
  }

  protected async loadPdfDoc() {
    try {
      // Load the pdf
      this.pdfDoc = await PDFDocument.load(this.getPdfBuffer());
    } catch (err) {
      throw err;
    }
  }

  public getPdfPage(pageNum: number) {
    try {
      if (this.pdfDoc) {
        // Get the required page of the document
        const pages = this.pdfDoc.getPages();
        const requiredPage = pages[pageNum - 1];

        return requiredPage;
      } else throw new Error("PDFDoc is null!");
    } catch (err) {
      throw err;
    }
  }
}
