import React from "react";

interface Props {
  ticketStatus: string;
}

const ticketStatusMap: any = {
  open: "Open",
  inProgress: "In Progress",
  closed: "Closed",
};

const ticketStatusColorMap: any = {
  open: "text-green-500",
  inProgress: "text-yellow-500",
  closed: "text-gray-500",
};

export default function TicketStatus({ ticketStatus }: Props) {
  return (
    <h1 className={`${ticketStatusColorMap[ticketStatus]}`}>{ticketStatusMap[ticketStatus]}</h1>
  );
}
