import React, { useContext } from "react";
import { AssignedTicketData } from "../../../../interfaces/main";
import TicketContainer from "./TicketContainer";
import { TicketSelectionCtx } from "../../../pages/AdminRelatedPages/AdminDashboard";

interface Props {
  tickets: AssignedTicketData[];
}

export default function ListOfTickets({ tickets }: Props) {
  const ticketSelectionCtx = useContext(TicketSelectionCtx);
  if (!ticketSelectionCtx) return <pre>Ticket selection ctx is null</pre>;

  return (
    <ul className="py-4">
      {tickets.map((ticketObj) => (
        <TicketContainer
          key={ticketObj._id}
          ticketData={ticketObj}
          className={`${
            ticketSelectionCtx.currentSelectedTicket.includes(ticketObj._id) &&
            "bg-[#121212] text-white"
          }`}
          onClick={() => ticketSelectionCtx.handleTicketClick(ticketObj._id)}
        />
      ))}
    </ul>
  );
}
