import React from "react";
import { TicketComment } from "../../../interfaces/main";
import Comment from "../Comment";
import TextModifier from "../../lib/TextModifier";

interface Props extends React.HTMLProps<HTMLElement> {
  comments: TicketComment[];
}

export default function CommentSection({ comments }: Props) {
  return (
    <div>
      <header className="comment-section-header p-2">
        <h2>Admin comments ({comments.length})</h2>
      </header>

      <div className="comment-section-body">
        {comments.map((comment) => (
          <Comment key={comment._id} className="p-2">
            <div className="comment-body w-fit p-4 rounded-lg shadow-md flex flex-col justify-center gap-1">
              <h3 className="commentor-name">
                Admin email: {comment.admin_id.email}{" "}
                <em className="admin-level text-gray-400">({comment.admin_id.level})</em>
              </h3>
              <p className="comment">{comment.comment}</p>
              <div className="time-period italic font-semibold text-gray-400 text-sm">
                Posted: {TextModifier.modifyDate(new Date(comment.createdAt))}
              </div>
            </div>
          </Comment>
        ))}
      </div>
    </div>
  );
}
