import { axiosInstance } from "../axiosConfig";
import devLogInstance from "../loggerConfig";
import Handler, { Main } from "./main";

export default async function markTicketAsSeen(ticketId: string) {
  try {
    const { data } = await axiosInstance.put("/admin/mark_ticket_seen", {
      ticket_id: ticketId,
    });

    devLogInstance.log({ response_after_marking_ticket_as_seen: data });
  } catch (err) {
    Handler.handleError(err, Main.stateDispatch, () => {});
  }
}
