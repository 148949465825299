import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_2,
  AMOUNT_3,
  AMOUNT_4,
  AMOUNT_5,
  AMOUNT_7,
  AMOUNT_9,
  AMOUNT_A,
  AMOUNT_B,
  AMOUNT_C,
  AMOUNT_D,
  BASIS_REPORTED_CHECK,
  B_1099,
  COLLECTIBLES,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  FORM_8949_APPCHECK_SELECT,
  GROSS_PROCEEDS,
  LONG_TERM_GAIN_OR_LOSS,
  LOSS_NOT_ALLOWED_INDICATOR,
  NET_PROCEEDS,
  NON_COVERED_SECURITY_INDICATOR,
  ORDINARY,
  PAGE_E_FILE,
  PROPERTY_DESCRIPTION,
  QOF,
  SHORT_TERM_GAIN_OR_LOSS,
  SUBMIT_ADD_ANOTHER,
  _1099B_GROUP_1,
  _1099B_GROUP_2,
} from "../../lib/constants";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import Fatca from "./GlobalFormComponents/FATCA/Fatca";
import RecAccount from "./GlobalFormComponents/RecAccount";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import {
  _8949Options,
  b1099AmountDisplayTexts,
  stateInfoDisplayTexts,
} from "../../lib/languagePacks/en-us";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import DateInput from "../decorations/DateInput/DateInput";
import StateInfo from "./GlobalFormComponents/StateInfo";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import { DECIMAL_REGEX } from "../../lib/patterns";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import scrollToTop from "../../lib/utils/scrollToTop";
import { useRecTinUnknown } from "../../lib/customHooks";

// Application checkbox on form 8949 options
const _options = [
  {
    label: "None",
    value: "none",
  },
  {
    label: _8949Options.labels.a,
    value: "A",
  },
  {
    label: _8949Options.labels.b,
    value: "B",
  },
  {
    label: _8949Options.labels.d,
    value: "D",
  },
  {
    label: _8949Options.labels.e,
    value: "E",
  },
  {
    label: _8949Options.labels.x,
    value: "X",
  },
];

export const isValidAmount = (formattedAmount: string): boolean => {
  const regex = new RegExp(DECIMAL_REGEX);
  const amount = formattedAmount.replace(/,/gi, "");
  const isValid = regex.test(amount);
  if (isValid) {
    return true;
  }
  return false;
};

export default function Form1099B({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({
    amount2: "",
    amount3: "",
    amount4: "",
    amount5: "",
    amount7: "",
    amount9: "",
    amountA: "",
    amountB: "",
    amountC: "",
    amountD: "",
  }); // for displaying on the input field
  const [miscFields, setMiscFields] = useState({
    description_of_property: "",
    applicable_check_box_for_collectables: false,
    applicable_check_box_for_qof: false,
    noncovered_security_indicator: false,
    loss_not_allowed_indicator: false,
    basis_reported_to_irs: false,
    applicable_check_box_of_form_8949: "",
    gross_proceeds_indicator: "",
  });
  const [acquiringDate, setAcquiringDate] = useState("");
  const [sellingDate, setSellingDate] = useState("");
  const [isValidAcquiringDate, setIsValidAcquiringDate] = useState<boolean | null>(null);
  const [isValidSellingDate, setIsValidSellingDate] = useState<boolean | null>(null);
  const [toShowAcquiringDateRequired, acquiringDateRequired] = useState(false);
  const [toShowSellingDateRequired, sellingDateRequired] = useState(false);
  const [clearAcquiringDate, toClearAcquiringDate] = useState<boolean | null>(null);
  const [clearSellingDate, toClearSellingDate] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const [gainOrLossIndicator, setGainOrLossIndicator] = useState({
    shortTerm: false,
    longTerm: false,
    ordinary: false,
  });
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const { recTinUnknown } = useRecTinUnknown();

  // devLogInstance.log(formData);
  devLogInstance.log(acquiringDate);
  devLogInstance.log(sellingDate);

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount7: formData.payment_amount7
        ? handleAmountFormat((formData.payment_amount7 / 100).toString())
        : "",
      amount9: formData.payment_amount9
        ? handleAmountFormat((formData.payment_amount9 / 100).toString())
        : "",
      amountA: formData.payment_amountA
        ? handleAmountFormat((formData.payment_amountA / 100).toString())
        : "",
      amountB: formData.payment_amountB
        ? handleAmountFormat((formData.payment_amountB / 100).toString())
        : "",
      amountC: formData.payment_amountC
        ? handleAmountFormat((formData.payment_amountC / 100).toString())
        : "",
      amountD: formData.payment_amountD
        ? handleAmountFormat((formData.payment_amountD / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      description_of_property: formData?.description_of_property
        ? formData?.description_of_property
        : "",
      applicable_check_box_for_collectables: formData?.applicable_check_box_for_collectables
        ? formData?.applicable_check_box_for_collectables
        : false,
      applicable_check_box_for_qof: formData?.applicable_check_box_for_qof
        ? formData?.applicable_check_box_for_qof
        : false,
      noncovered_security_indicator: formData?.noncovered_security_indicator
        ? formData?.noncovered_security_indicator
        : false,
      loss_not_allowed_indicator: formData?.loss_not_allowed_indicator
        ? formData?.loss_not_allowed_indicator
        : false,
      basis_reported_to_irs: formData?.basis_reported_to_irs
        ? formData?.basis_reported_to_irs
        : false,
      applicable_check_box_of_form_8949: formData.applicable_check_box_of_form_8949
        ? formData.applicable_check_box_of_form_8949
        : "",
      gross_proceeds_indicator: formData?.gross_proceeds_indicator
        ? formData?.gross_proceeds_indicator
        : "",
    });

    setGainOrLossIndicator({
      shortTerm:
        formData?.type_of_gain_or_loss_indicator === "1" ||
        formData?.type_of_gain_or_loss_indicator === "3",
      longTerm:
        formData?.type_of_gain_or_loss_indicator === "2" ||
        formData?.type_of_gain_or_loss_indicator === "4",
      ordinary:
        formData?.type_of_gain_or_loss_indicator === "3" ||
        formData?.type_of_gain_or_loss_indicator === "4",
    });

    return () => {
      setAmount({
        amount2: "",
        amount3: "",
        amount4: "",
        amount5: "",
        amount7: "",
        amount9: "",
        amountA: "",
        amountB: "",
        amountC: "",
        amountD: "",
      });
      setMiscFields({
        description_of_property: "",
        applicable_check_box_for_collectables: false,
        applicable_check_box_for_qof: false,
        noncovered_security_indicator: false,
        loss_not_allowed_indicator: false,
        basis_reported_to_irs: false,
        applicable_check_box_of_form_8949: "",
        gross_proceeds_indicator: "",
      });

      setGainOrLossIndicator({
        shortTerm: false,
        longTerm: false,
        ordinary: false,
      });
    };
  }, [
    formData.payment_amount2,
    formData.payment_amount3,
    formData.payment_amount4,
    formData.payment_amount5,
    formData.payment_amount7,
    formData.payment_amount9,
    formData.payment_amountA,
    formData.payment_amountB,
    formData.payment_amountC,
    formData.payment_amountD,
    formData.description_of_property,
    formData.applicable_check_box_for_collectables,
    formData.applicable_check_box_for_qof,
    formData.noncovered_security_indicator,
    formData.loss_not_allowed_indicator,
    formData.basis_reported_to_irs,
    formData.applicable_check_box_of_form_8949,
    formData.gross_proceeds_indicator,
    formData.type_of_gain_or_loss_indicator,
  ]);

  // Date related callback
  const updateAcquiringDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date ? date?.split("/") : [];
    const date_ = dateChunks.length !== 0 ? dateChunks[2] + dateChunks[0] + dateChunks[1] : "";

    setAcquiringDate(date_);
    setIsValidAcquiringDate(!isInvalidDate);
  };

  const updateSellingDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date ? date?.split("/") : [];
    const date_ = dateChunks.length !== 0 ? dateChunks[2] + dateChunks[0] + dateChunks[1] : "";

    setSellingDate(date_);
    setIsValidSellingDate(!isInvalidDate);
  };

  // Handle input change on payment amount fields and any other misc fields
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;
      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_7:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount7: formattedAmount,
          });
        break;
      case AMOUNT_9:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount9: formattedAmount,
          });
        break;
      case AMOUNT_A:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountA: formattedAmount,
          });
        break;
      case AMOUNT_B:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountB: formattedAmount,
          });
        break;
      case AMOUNT_C:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountC: formattedAmount,
          });
        break;
      case AMOUNT_D:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountD: formattedAmount,
          });
        break;
      default:
        break;
    }
  };

  // Handle MISC field change
  const handleMiscFieldChange = (e: any) => {
    const { id: fieldType, value } = e.target;
    switch (fieldType) {
      case COLLECTIBLES:
        setMiscFields({
          ...miscFields,
          applicable_check_box_for_collectables: !miscFields.applicable_check_box_for_collectables,
        });
        break;

      case QOF:
        setMiscFields({
          ...miscFields,
          applicable_check_box_for_qof: !miscFields.applicable_check_box_for_qof,
        });
        break;

      case NON_COVERED_SECURITY_INDICATOR:
        setMiscFields({
          ...miscFields,
          noncovered_security_indicator: !miscFields.noncovered_security_indicator,
        });
        break;

      case LOSS_NOT_ALLOWED_INDICATOR:
        setMiscFields({
          ...miscFields,
          loss_not_allowed_indicator: !miscFields.loss_not_allowed_indicator,
        });
        break;

      case BASIS_REPORTED_CHECK:
        setMiscFields({
          ...miscFields,
          basis_reported_to_irs: !miscFields.basis_reported_to_irs,
        });
        break;

      case PROPERTY_DESCRIPTION:
        value.length <= 40 &&
          setMiscFields({
            ...miscFields,
            description_of_property: value,
          });
        break;

      case GROSS_PROCEEDS:
        setMiscFields({
          ...miscFields,
          gross_proceeds_indicator: miscFields.gross_proceeds_indicator === "1" ? "" : "1",
        });
        break;

      case NET_PROCEEDS:
        setMiscFields({
          ...miscFields,
          gross_proceeds_indicator: miscFields.gross_proceeds_indicator === "2" ? "" : "2",
        });
        break;

      case SHORT_TERM_GAIN_OR_LOSS:
        setGainOrLossIndicator({
          ...gainOrLossIndicator,
          shortTerm: !gainOrLossIndicator.shortTerm,
        });
        break;

      case LONG_TERM_GAIN_OR_LOSS:
        setGainOrLossIndicator({
          ...gainOrLossIndicator,
          longTerm: !gainOrLossIndicator.longTerm,
        });
        break;

      case ORDINARY:
        setGainOrLossIndicator({
          ...gainOrLossIndicator,
          ordinary: !gainOrLossIndicator.ordinary,
        });
        break;

      default:
        break;
    }
  };

  // Handle React select selection
  const handleSelection = (selectedObj: any) => {
    devLogInstance.log("Selected APP Check on Form 8949");
    devLogInstance.log(selectedObj);

    // Update the Misc fields state
    if (selectedObj.value !== "none") {
      setMiscFields({
        ...miscFields,
        applicable_check_box_of_form_8949: selectedObj.value,
      });
    } else {
      setMiscFields({
        ...miscFields,
        applicable_check_box_of_form_8949: "",
      });
    }
  };

  // Handle form submit
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;
    devLogInstance.log(`Clicked on ---> ${submitType}`);
    let typeOfGainOrLoss = "";

    // Setting the type of gain or loss indicator
    if (gainOrLossIndicator.shortTerm && !gainOrLossIndicator.ordinary) {
      typeOfGainOrLoss = "1";
    } else if (gainOrLossIndicator.longTerm && !gainOrLossIndicator.ordinary) {
      typeOfGainOrLoss = "2";
    } else if (gainOrLossIndicator.shortTerm && gainOrLossIndicator.ordinary) {
      typeOfGainOrLoss = "3";
    } else if (gainOrLossIndicator.longTerm && gainOrLossIndicator.ordinary) {
      typeOfGainOrLoss = "4";
    }

    devLogInstance.log("Acquiring date: " + acquiringDate);

    let data = {
      ...formData,
      ...miscFields,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
      payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
      payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,
      payment_amount9: amount.amount9 ? parseFloat(amount.amount9.replace(/,/gi, "")) : 0,
      payment_amountA: amount.amountA ? parseFloat(amount.amountA.replace(/,/gi, "")) : 0,
      payment_amountB: amount.amountB ? parseFloat(amount.amountB.replace(/,/gi, "")) : 0,
      payment_amountC: amount.amountC ? parseFloat(amount.amountC.replace(/,/gi, "")) : 0,
      payment_amountD: amount.amountD ? parseFloat(amount.amountD.replace(/,/gi, "")) : 0,
      // Acquiring and selling dates
      date_acquired: acquiringDate,
      date_sold_or_disposed: sellingDate,
      // Type of gain or loss indicator
      type_of_gain_or_loss_indicator: typeOfGainOrLoss,
    };
    devLogInstance.log(data);

    const paymentAmounts = [
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount4,
      data.payment_amount5,
      data.payment_amount7,
      data.payment_amount9,
      data.payment_amountA,
      data.payment_amountB,
      data.payment_amountC,
      data.payment_amountD,
    ];

    const incomeAmounts = [
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount5,
      data.payment_amount7,
      data.payment_amount9,
      data.payment_amountA,
      data.payment_amountB,
      data.payment_amountC,
      data.payment_amountD,
    ];

    if (isValidAcquiringDate && isValidSellingDate) {
      // Correction filing middleware
      Middleware.fileCorrection(
        {
          isCorrection: isCorrection,
          correctionSubmissionType: correctionSubmissionType,
          correctionCode1Details: data,
          correctionCode2Details: data,
          formType: data.form_type,
        },
        (err, correctionCode) => {
          if (err) {
            console.error(err);
            handlePopup(err, ERROR_POPUP, dispatch);
            return;
          }

          if (correctionCode) {
            data = {
              ...data,
              file_submission_type: correctionSubmissionType,
              correction_type: correctionCode,
              payer_is_new_or_updated: false,
              history_id: historyId,
            };
            devLogInstance.log("Data before sumbitting correction");
            devLogInstance.log(data);
          }

          devLogInstance.log("Removing cached intial data...");
          SessionStorage.removeCacheItem(CORRECTION_INITIAL);
          devLogInstance.log("Cache removed!");

          formSubmissionHelper(
            data,
            paymentAmounts,
            data.payment_amount4,
            incomeAmounts,
            setFormData,
            setAmount,
            dispatch,
            submitType,
            navigate,
            PAGE_E_FILE,
            recTinUnknown
          ).then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                description_of_property: "",
                applicable_check_box_for_collectables: false,
                applicable_check_box_for_qof: false,
                noncovered_security_indicator: false,
                loss_not_allowed_indicator: false,
                basis_reported_to_irs: false,
                applicable_check_box_of_form_8949: "",
                gross_proceeds_indicator: "",
              });
              // Clear type of gain or loss indicator
              setGainOrLossIndicator({
                shortTerm: false,
                longTerm: false,
                ordinary: false,
              });
              // Clear date
              toClearAcquiringDate(true);
              toClearSellingDate(true);
            }
          });
        }
      );
      // formSubmissionHelper(
      //   data,
      //   paymentAmounts,
      //   data.payment_amount4,
      //   incomeAmounts,
      //   setFormData,
      //   setAmount,
      //   dispatch,
      //   submitType,
      //   navigate,
      //   PAGE_E_FILE
      // ).then((res) => {
      //   if (res && submitType === SUBMIT_ADD_ANOTHER) {
      //     // Clear the local state values
      //     setMiscFields({
      //       description_of_property: "",
      //       applicable_check_box_for_collectables: false,
      //       applicable_check_box_for_qof: false,
      //       noncovered_security_indicator: false,
      //       loss_not_allowed_indicator: false,
      //       basis_reported_to_irs: false,
      //       applicable_check_box_of_form_8949: "",
      //       gross_proceeds_indicator: "",
      //     });
      //     // Clear type of gain or loss indicator
      //     setGainOrLossIndicator({
      //       shortTerm: false,
      //       longTerm: false,
      //       ordinary: false,
      //     });
      //     // Clear date
      //     toClearAcquiringDate(true);
      //     toClearSellingDate(true);
      //   }
      // });
    } else {
      acquiringDateRequired(true);
      sellingDateRequired(true);
      scrollToTop();
    }
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount globalFormData={formData} setGlobalFormData={setFormData} />
              <Fatca
                globalFormData={formData}
                setGlobalFormData={setFormData}
                type={"filing-form"}
                showCusip={true}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderTop: `1px solid ${style.eFormBorderColor}`,
                    justifyContent: "center",
                  }}
                >
                  <AmountHeading heading={b1099AmountDisplayTexts.applicableCheckboxForm8949} />
                  <Select
                    id={FORM_8949_APPCHECK_SELECT}
                    className="form-8949-applicable-checkbox"
                    theme={new SelectStyle(style).customTheme}
                    styles={new SelectStyle(style).customStyle}
                    options={_options}
                    value={_options.filter((option) => {
                      return option.value === miscFields.applicable_check_box_of_form_8949;
                    })}
                    onChange={handleSelection}
                    isSearchable={false}
                    placeholder=""
                  />
                </AmountContainer>

                <FormDetail
                  formType={formType}
                  taxYear={taxYear}
                  formName={B_1099}
                  newVersion={true}
                />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.propertyDescription}
                    amountNum="1a"
                  />
                  <input
                    id={PROPERTY_DESCRIPTION}
                    style={{ padding: "0.5rem 1rem" }}
                    type="text"
                    value={miscFields.description_of_property}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <div style={{ width: "50%" }}>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.dateAcquired} amountNum="1b" />
                    <DateInput
                      minDate={new Date(2000, 11, 31)}
                      maxDate={new Date(taxYear, 11, 31)}
                      clearDate={clearAcquiringDate}
                      callback={updateAcquiringDate}
                      groupId={_1099B_GROUP_1}
                      classes="display-from-top-left"
                      value={formData?.date_acquired}
                      showDateRequiredIndicator={toShowAcquiringDateRequired}
                      toShowDateRequired={acquiringDateRequired}
                    />
                  </AmountContainer>
                </div>
                <div style={{ width: "50%" }}>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.dateSold} amountNum="1c" />
                    <DateInput
                      minDate={new Date(2000, 11, 31)}
                      maxDate={new Date(taxYear, 11, 31)}
                      clearDate={clearSellingDate}
                      callback={updateSellingDate}
                      groupId={_1099B_GROUP_2}
                      classes="move-calendar-to-left"
                      value={formData?.date_sold_or_disposed}
                      showDateRequiredIndicator={toShowSellingDateRequired}
                      toShowDateRequired={sellingDateRequired}
                    />
                  </AmountContainer>
                </div>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="payer-direct-sales" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={b1099AmountDisplayTexts.proceeds} amountNum="1d" />
                  <Amount
                    type="text"
                    id={AMOUNT_2}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount2}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.costOrOtherBasis}
                    amountNum="1e"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_3}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount3}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.accruedMarketDiscount}
                    amountNum="1f"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_D}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountD}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.washSaleLossDisallowed}
                    amountNum="1g"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                    //   flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <AmountHeading
                      heading={b1099AmountDisplayTexts.shortTermGainOrLoss}
                      amountNum="2"
                    />
                    <input
                      id={SHORT_TERM_GAIN_OR_LOSS}
                      type="checkbox"
                      checked={gainOrLossIndicator.shortTerm}
                      disabled={gainOrLossIndicator.longTerm}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.longTermGainOrLoss} />
                    <input
                      id={LONG_TERM_GAIN_OR_LOSS}
                      type="checkbox"
                      checked={gainOrLossIndicator.longTerm}
                      disabled={gainOrLossIndicator.shortTerm}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.ordinary} />
                    <input
                      id={ORDINARY}
                      type="checkbox"
                      checked={gainOrLossIndicator.ordinary}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={`${b1099AmountDisplayTexts.checkProceedsFromHeading}:`}
                    amountNum="3"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.collectibles} />
                    <input
                      id={COLLECTIBLES}
                      type="checkbox"
                      checked={miscFields.applicable_check_box_for_collectables}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.qof} />
                    <input
                      id={QOF}
                      type="checkbox"
                      checked={miscFields.applicable_check_box_for_qof}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={b1099AmountDisplayTexts.taxWithheld} amountNum="4" />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.nonCoveredSecurityCheck}
                    amountNum="5"
                  />
                  <input
                    id={NON_COVERED_SECURITY_INDICATOR}
                    type="checkbox"
                    checked={miscFields.noncovered_security_indicator}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={`${b1099AmountDisplayTexts.reportedToIRSHeading}:`}
                    amountNum="6"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.grossProceeds} />
                    <input
                      id={GROSS_PROCEEDS}
                      type="checkbox"
                      checked={miscFields.gross_proceeds_indicator === "1"}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                    }}
                  >
                    <AmountHeading heading={b1099AmountDisplayTexts.netProceeds} />
                    <input
                      id={NET_PROCEEDS}
                      type="checkbox"
                      checked={miscFields.gross_proceeds_indicator === "2"}
                      onChange={handleMiscFieldChange}
                    />
                  </div>
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.lossNotAllowedCheck}
                    amountNum="7"
                  />
                  <input
                    id={LOSS_NOT_ALLOWED_INDICATOR}
                    type="checkbox"
                    checked={miscFields.loss_not_allowed_indicator}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.realizedProfitOrLoss}
                    amountNum="8"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_9}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount9}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.unrealizedProfitOrLoss.openContracts2022}
                    amountNum="9"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_A}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountA}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.unrealizedProfitOrLoss.openContracts2023}
                    amountNum="10"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_B}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountB}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.aggregateProfitOrLoss}
                    amountNum="11"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_C}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountC}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AmountHeading
                    heading={b1099AmountDisplayTexts.basisReportedToIRSCheck}
                    amountNum="12"
                  />
                  <input
                    id={BASIS_REPORTED_CHECK}
                    type="checkbox"
                    checked={miscFields.basis_reported_to_irs}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={b1099AmountDisplayTexts.bartering} amountNum="13" />
                  <Amount
                    type="text"
                    id={AMOUNT_7}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount7}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.b1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.b1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.b1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.b1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.b1099.stateNoLabel}
          stateIncomeLabel={""}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          formType={formType}
          isCorrection={isCorrection}
        />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
