import { ReviewCopyPayerData, ReviewCopyRecipientData } from "../../../interfaces/main";
import fontkit from "@pdf-lib/fontkit";
import FormatDate from "../utils/Helpers/FormatDate";
import ReviewCopyModifier from "./ReviewCopyModifier";

export class Review1099AModifier extends ReviewCopyModifier {
  private accountNumber: string;
  private date_of_lenders_acquisition: string;
  private personal_liability_indicator: boolean;
  private description_of_property: string;

  private amount2: number;
  private amount4: number;

  constructor(
    formType: string,
    taxYear: string | number,
    pdfBuffer: ArrayBuffer,
    payerData: ReviewCopyPayerData,
    recData: ReviewCopyRecipientData,
    toMaskTin: boolean,
    accountNumber: string,
    date_of_lenders_acquisition: string,
    amount2: number,
    amount4: number,
    personal_liability_indicator: boolean,
    description_of_property: string
  ) {
    super(formType, taxYear, pdfBuffer, payerData, recData, toMaskTin);
    this.accountNumber = accountNumber;

    this.date_of_lenders_acquisition = date_of_lenders_acquisition;
    this.personal_liability_indicator = personal_liability_indicator;
    this.description_of_property = description_of_property;

    this.amount2 = amount2;
    this.amount4 = amount4;
  }

  public async getModifiedBytes() {
    try {
      await this.configureReviewCopy();
      // Fill payer data
      await this.modifyPayerData();
      // Fill Recipient data
      await this.modifyRecData();

      // Fill form specific fields
      const options = this.getOptions();
      // Get the first page
      const firstPage = this.getPdfPage(1); // There will be a single page only

      const { height } = firstPage.getSize();

      // Draw account number
      const accountNumPos = {
        x: this.getLeftSideXCoord(),
        y: 220,
      };
      const accountNum = this.accountNumber || "";
      firstPage.drawText(accountNum, {
        x: accountNumPos.x + 4,
        y: height - accountNumPos.y - this.getFontSize() * 2,
        ...options,
      });

      // *** LEFT *** //

      // Make the tick mark font ready
      this.getReviewCopy().registerFontkit(fontkit);
      const tickMarkFontRes = await fetch("/Assets/Fonts/GuifxV2Transports-YMJo.ttf");
      const fontBuffer = await tickMarkFontRes.arrayBuffer();
      const tickMark = await this.getReviewCopy().embedFont(fontBuffer);

      this.drawCalendarYearPartly(0, -13);

      // Date of lenders acquisition
      const dateOfLendersAcquisitionPos = {
        x: this.getLeftSideXCoord() + 130 * 2,
        y: height - 59 * 2 - this.getFontSize(),
      };
      const dateOfLendersAcquisitionFormatter = new FormatDate(
        this.date_of_lenders_acquisition || ""
      );
      const formattedDateOfLendersAcquisition = dateOfLendersAcquisitionFormatter.format();
      firstPage.drawText(formattedDateOfLendersAcquisition, {
        x: dateOfLendersAcquisitionPos.x,
        y: dateOfLendersAcquisitionPos.y,
        ...options,
      });

      // Balance of principal outstanding (amount 2)
      const amount2Pos = {
        x: dateOfLendersAcquisitionPos.x + 100,
        y: dateOfLendersAcquisitionPos.y - 3,
      };
      let formattedAmt = (this.amount2 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount2Pos.x,
        y: amount2Pos.y,
        ...options,
      });

      // Fair market value of property (amount 4)
      const amount4Pos = {
        x: amount2Pos.x,
        y: amount2Pos.y - 36,
      };
      formattedAmt = (this.amount4 / 100).toFixed(2);
      firstPage.drawText(formattedAmt, {
        x: amount4Pos.x,
        y: amount4Pos.y,
        ...options,
      });

      // Personal liability indicator
      if (this.personal_liability_indicator) {
        const liabilityIndicatorPos = {
          x: amount4Pos.x + 75,
          y: amount4Pos.y - 25,
        };
        firstPage.drawText("z", {
          x: liabilityIndicatorPos.x,
          y: liabilityIndicatorPos.y,
          ...options,
          size: 8,
          font: tickMark,
        });
      }

      // Description of property
      const descOfPropertyPos = {
        x: dateOfLendersAcquisitionPos.x - 10,
        y: dateOfLendersAcquisitionPos.y - 36 * 2 - 15,
      };
      firstPage.drawText(this.description_of_property, {
        x: descOfPropertyPos.x,
        y: descOfPropertyPos.y,
        ...options,
      });

      // Serialize the modified review copy into bytes
      const reviewCopyBytes = await this.getReviewCopy().save();

      return reviewCopyBytes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
