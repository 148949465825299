import React from "react";
import "../styles/Form1099.css";
import { useDispatch } from "react-redux";
import { useHover } from "../lib/customHooks";
import { CORRECTION_FILING_MODAL } from "../lib/constants";
import toDisplayModal from "../lib/StateSetters/toDisplayModal";

const isOdd = (integer: number) => {
  if (integer % 2 === 0) {
    return false;
  }
  return true;
};

interface Props {
  title: string;
  description: string;
  filingDueDate: string;
  filingText: string;
  currentFormId: number;
  totalForms: number;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFormType: React.Dispatch<
    React.SetStateAction<{ title: string; description: string; filingDueDate: string }>
  >;
}

export default function IndividualForm({
  title,
  description,
  filingDueDate,
  filingText,
  currentFormId,
  totalForms,
  openModal,
  setFormType,
}: Props) {
  // const style = useSelector((state: any) => state.themeChange);
  const [isHovered, handleHoverIn, handleHoverOut] = useHover();
  const dispatch = useDispatch();

  // handle modal display
  const handleModalDisplay = () => {
    if (!title.includes("Correction")) {
      // Scroll to top
      window.scrollTo(0, 0);

      openModal(true);
      setFormType({
        title: title,
        description: description,
        filingDueDate: filingDueDate,
      });
      return;
    }
    toDisplayModal(true, CORRECTION_FILING_MODAL, dispatch);
  };
  return (
    <div className="form1099-container">
      <div
        className={`form1099-wrapper h-full`}
        style={{
          borderBottom: isOdd(totalForms)
            ? currentFormId === totalForms || currentFormId === totalForms - 1
              ? "none"
              : "2px solid rgba(122, 122, 122, 0.3)"
            : currentFormId === totalForms || currentFormId === totalForms - 1
            ? "none"
            : "2px solid rgba(122, 122, 122, 0.3)",
        }}
      >
        <div className="form-title-desc">
          <div className="form1099-title">{title}</div>
          <div className="form1099-description">{description}</div>
        </div>
        <button
          className={`filing-btn border border-solid border-taxeve-primary-violet ${
            !isHovered["file-btn"] || isHovered["file-btn"] === false
              ? "text-white bg-taxeve-primary-violet"
              : "text-taxeve-primary-violet bg-white"
          }`}
          onMouseOver={() => {
            handleHoverIn("file-btn");
          }}
          onMouseLeave={() => {
            handleHoverOut("file-btn");
          }}
          onClick={handleModalDisplay}
        >
          {filingText}
        </button>
      </div>
    </div>
  );
}
