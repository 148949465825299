import React from "react";
import "./MessageLoadingAnimationStyle.css";

export default function MessageLoadingAnimation() {
  return (
    <div className="stage">
      <div className="dot-flashing" />
    </div>
  );
}
